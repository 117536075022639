/* @import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Francois+One&family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&family=Hina+Mincho&display=swap"); */

* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
  /* font-size: 16px; */
  /* font-family: "DM Sans", sans-serif; */
}

.parent {
  max-width: 90%;
  margin: auto;
}

.login_image {
  /* padding: 20px 80px 0 0; */
  padding-top: 60px;
  display: flex;
  justify-content: center;
}

.login_image img {
  width: 450px;
  height: 450px;
  flex-shrink: 0;
}

.login_form {
  height: 100%;
  border: 12px;
  padding: 38px;
  border-radius: 24px;
  border: 1px solid #c9c9c9;
}

.login_form .logo {
  margin-bottom: 20px;
  width: 144px;
  height: 71.226px;
  flex-shrink: 0;
}

.login_form .login_form_div {
  position: relative;
  display: flex;
  align-items: center;
}

.login_form h2 {
  color: #024c87;
  font-weight: bold;
  font-size: 36px;
  text-transform: capitalize;
}

.login_form .otp_form_resend {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 60%;
  gap: 10px;
}

.login_form .otp_form_resend span {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.login_form .otp_form_resend button {
  border: none;
  background-color: transparent;
  font-size: 16px;
  text-decoration: underline;
}

.login_form p {
  color: #8c8c8c;
  font-size: 20px;
}

.form-input {
  margin-top: 20px;
}

.form-input input {
  border-radius: 12px;
  border: 1px solid #c9c9c9;
  /* text-transform: capitalize; */
  padding: 10px 0px 10px 0px;
  outline: none;
  padding-left: 36px !important;
}

.form-input .forget_pass_para {
  color: #8c8c8c;
  font-feature-settings: "ss02" on;
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* text-align: right; */
  margin-left: auto;
  width: fit-content;
  text-transform: capitalize;
}

.form-input .forget_pass_para a {
  font-size: 18px;
}

.form-input .login_form_btn {
  display: flex;
  width: 253px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  border-radius: 5px;
  background: #024c87;
  text-transform: capitalize;
  font-size: 20px;
  color: white;
  margin: auto;
  /* margin-top: 50px; */
}

.form-input .forget_pass_btn {
  margin-block: 30px;
}

.form-input .verify_otp_btn {
  margin-block: 30px;
}

.form-input .new_pass_btn {
  margin-block: 20px;
}

.form-input .para {
  font-size: 16px;
  text-align: center;
  margin-bottom: 0;
}

.form-input .eye-icon {
  position: absolute;
  top: 15px;
  right: 9px;
  color: gray;
  cursor: pointer;
  width: 30px;
}

.form-input .login_otp_con {
  display: flex;
  justify-content: space-between;
}

.form-input .login_otp_inp {
  display: flex;
  min-width: 72px;
  min-height: 72px;
  text-align: center;
  align-items: center;
  gap: 12.432px;
  padding: 0px !important;
  border-radius: 9.946px;
  border: 1.243px solid #d9d9d9;
}

.login_form .login_form_div .icon {
  position: absolute;
  left: 10px;
  color: gray;
  font-size: 18px;
}

.placed_candidate_testi_container {
  padding: 60px 0;
}

.placed_candidate_testi_carousel .owl-nav .owl-next,
.placed_candidate_testi_carousel .owl-nav .owl-prev {
  top: 36%;
}

.placed_candidate_testi_carousel .owl-nav .owl-prev button,
.placed_candidate_testi_carousel .owl-nav .owl-next button {
  background: #fff;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
  border: none;
  padding: 10px;
}

.placed_candidate_testi_carousel .owl-nav .owl-prev button {
  position: absolute;
  left: 0;
}

.placed_candidate_testi_carousel .owl-dots .owl-dot.active span,
.placed_candidate_testi_carousel .owl-dots .owl-dot:hover span {
  background-color: #024c87;
}

.placed_candidate_testi_carousel .owl-nav .owl-prev button,
.placed_candidate_testi_carousel .owl-nav .owl-next button {
  border: 1px solid #024c87;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #024c87;
  border-radius: 50%;
  /* color: white; */
}

.placed_candidate_testi_carousel .owl-nav .owl-prev:hover,
.placed_candidate_testi_carousel .owl-nav .owl-next:hover {
  background-color: transparent;
}

.placed_candidate_testi_carousel .owl-nav .owl-prev button {
  position: absolute;
  top: 40%;
  left: 0%;
}

.placed_candidate_testi_carousel .owl-nav .owl-next button {
  position: absolute;
  top: 40%;
  right: 0%;
}

.placed_candidate_testi_carousel .owl-nav .disabled {
  opacity: 0;
}

.placed_candidate_testi_heading h2 {
  color: #ff9a28;
  font-family: DM Sans;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  /* line-height: 167.523%; */
  /* 160.822px */
  letter-spacing: -1.36px;
}

.placed_candidate_testi_heading h2 span {
  color: #4e4e4e;
}

.placed_candidate_testi_heading p {
  color: #b3b3b3;
  text-align: center;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  max-width: 64%;
  margin: 40px auto;
  text-align: center;
  /* line-height: 130%; */
  /* 36.4px */
}

.placed_candidate_testi_item {
  border-radius: 8px;
  background: #fff;
  background: #fff;
  box-shadow: 0px 0px 9.4px 0px rgba(90, 90, 90, 0.25);
  border: 1px solid rgba(90, 90, 90, 0.25);
  color: black;
  position: relative;
  overflow: hidden;
  margin-top: 60px;
  padding-bottom: 50px;
  max-width: 85%;
  height: 545px;
  margin: auto;
}

#placed_testi_mobile_link {
  display: none;
  /* display: flex; */
  gap: 10px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.placed_candidate_testi_bluebox {
  position: relative;
  width: 100%;
  margin-top: 11%;
  background: #024c87;
  color: white;
  height: 240px;
  box-shadow: 0px 0px 9.4px 0px rgba(90, 90, 90, 0.25);
}

.placed_candidate_testi_bluebox_mobile {
  display: none;
}

.placed_candidate_testi_bluebox_mobile .placed_candidate_testi_info_mobile {
  display: none;
}

.placed_candidate_testi_bluebox_mobile .placed_candidate_testi_info_mobile h5 {
  color: #ffff;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  text-transform: capitalize;
  font-weight: bold;
}
.placed_candidate_testi_bluebox_mobile .placed_candidate_testi_info_mobile h6 {
  color: #ffff;
  font-family: DM Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
}

.placed_candidate_testi_bluebox #placed_candidate_testi_bluebox_vectorimg {
  position: absolute;
  top: 0px;
  left: 5%;
  width: 370px;
}

.placed_candidate_testi_bluebox #placed_candidate_testi_bluebox_vectorimg2 {
  position: absolute;
  top: -53%;
  right: 12%;
  width: 430px;
}

.placed_candidate_testi_bluebox p {
  font-family: DM Sans;
  font-size: 20px;
  padding: 60px 40px 20px 80px;
  text-align: left;
  height: 210px;
  overflow-y: auto;
}

.placed_candidate_testi_bluebox p::-webkit-scrollbar {
  width: 0;
}

.placed_candidate_testi_bluebox #placed_candidate_testi_image {
  width: 135px;
  height: 135px;
  position: absolute;
  top: -38%;
  transform: translateX(-50%);
  left: 50%;
  border-radius: 50%;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 0px 9.4px 0px rgba(90, 90, 90, 0.25);
}

.placed_candidate_testi_bluebox #placed_candidate_testi_image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.placed_candidate_testi_item #placed_candidate_testi_image_mobile {
  display: none;
  width: 80px;
  height: 80px;
  top: -28%;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  border-radius: 50%;
  background-color: white;
  padding: 5px;
  box-shadow: 0px 0px 9.4px 0px rgba(90, 90, 90, 0.25);
}

.placed_candidate_testi_item #placed_candidate_testi_image_mobile img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

#placed_testi_linkedin_img {
  display: flex;
  gap: 10px;
  margin: 35px 40px;
}

#placed_testi_linkedin_img img {
  width: 45px;
  /* height: 50px; */
}

.placed_candidate_testi_item .placed_candidate_testi_info {
  position: absolute;
  right: 5%;
  bottom: -90px;
}

.placed_candidate_testi_item .placed_candidate_testi_info h5 {
  color: #024c87;
  /* text-align: center; */
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
}

.placed_candidate_testi_item .placed_candidate_testi_info h6 {
  color: #cacaca;
  /* text-align: center; */
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.placed_candidate_map_container .placed_candidate_map_heading h1 {
  font-size: 65px;
}

.placed_candidate_map_container .placed_candidate_map_heading h2 {
  font-size: 24px;
}

.placed_candidate_customer_container .placed_candidate_customer_heading h1 {
  font-size: 48px;
  font-weight: 600;
}

.whatTestimonies_client_container .whatTestimonies_client_heading h2 {
  max-width: 100%;
}

.placed_candidate_customer_container {
  margin: 60px 0;
}

.whatTestimonies_client_container {
  margin: 60px 0;
}

.blog_card_main {
  position: relative;
  height: 440px;
  margin-bottom: 5px;
}

.blog_card_main img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.blog_card_main .blog_card_main_content {
  position: absolute;
  background-image: url("../images/blog_mian_blur.png");
  bottom: 0;
  height: 183px;
  left: 0;
  background-size: 100% 100%;
  width: 100%;
  padding: 23px;
  border-radius: 0 0 10px 10px;
}

.blog_main_badge {
  background-color: #f1ecff;
  width: 212px;
  border-radius: 22px;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 5.5px;
}

.blog_main_badge .blog_badge_circle {
  width: 17px;
  height: 17px;
  background-color: #6c40ff;
  border-radius: 50%;
}

.blog_main_badge p {
  margin: 0;
  color: #6c40ff;
  font-family: DM Sans;
  font-size: 15.485px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.blog_card_main_content h2 {
  color: white;
  font-family: DM Sans;
  font-size: 26.545px;
  font-style: normal;
  font-weight: 700;
  margin-top: 6px;
  line-height: normal;
}

.blog_card_profile {
  background-color: #024c87;
  height: 333px;
  border-radius: 10px;
  padding: 20px;
  position: relative;
}

.blog_profile_img {
  position: relative;
  width: fit-content;
}

.blog_profile_img .blog_profile_user {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  border: 1px solid gray;
  object-fit: cover;
}

.blog_profile_img .blog_profile_linkedin {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 0;
  right: -46px;
}

.blog_card_profile h2 {
  color: white;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 20px;
  z-index: 9;
  position: relative;
}

.blog_card_profile h3 {
  color: white;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  z-index: 9;
  position: relative;
}

.blog_card_profile p {
  color: white;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  z-index: 9;
  position: relative;
  line-height: 18px; /* 128.571% */
}

.blog_card_profile::before {
  content: "";
  width: 300px;
  background-image: url(../images/blog_vector1.png);
  height: 300px;
  position: absolute;
  top: 0;
  mix-blend-mode: soft-light;
  left: 0;
  opacity: 0.1;
}

.blog_card_profile::after {
  content: "";
  width: 300px;
  background-image: url(../images/blog_vector2.png);
  height: 300px;
  position: absolute;
  bottom: -48px;
  mix-blend-mode: soft-light;
  right: -28px;
  opacity: 0.1;
  background-repeat: no-repeat;
}

.blog_card_social {
  background-color: #024c87;
  border-radius: 10px;
  height: 100px;
  margin-top: 30px;
  padding: 20px 10px 10px 20px;
  position: relative;
}

.blog_card_social h3 {
  color: white;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.blog_card_social .blog_card_social_div {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 8px;
  position: relative;
  z-index: 9;
}

.blog_card_social .blog_card_social_div img {
  width: 30px;
  height: 30px;
}

.blog_card_social .blog_card_vector3 {
  position: absolute;
  top: 0;
  left: 87px;
  width: 240px;
  mix-blend-mode: exclusion;
  opacity: 30;
}

.blog_full_description {
  margin-top: 30px;
}

.blog_full_description .blog_full_description_content h1 {
  color: #1b1b1f;
  font-family: DM Sans;
  font-size: 28.904px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.blog_full_description .blog_full_description_content p {
  color: #1b1b1f;
  font-family: DM Sans;
  font-size: 19.269px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 28.904px */
  margin-block: 18px;
}

.blog_ai_content h1 {
  color: #1b1b1f;
  font-family: DM Sans;
  font-size: 28.904px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 22px;
}

.blog_ai_content .blog_ai_img {
  width: 744px;
  height: 510px;
  border-radius: 21.41px;
  border: 1.071px solid #1b1b1f;
}

.blog_ai_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.blog_ai_content h3 {
  margin-top: 30px;
  color: #1b1b1f;
  font-family: DM Sans;
  font-size: 19.269px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 28.904px */
}

.blog_ai_content p {
  color: #1b1b1f;
  font-family: DM Sans;
  font-size: 19.269px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.blog_card_social_full {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.blog_card_social_full .blog_card_social_div {
  margin-top: 0;
}

.blog_card_social_div h3 {
  z-index: 9;
  position: relative;
}

.blog_card_social_full .blog_card_vector3 {
  height: 70px;
}

.blog_ai_hr {
  height: 1px;
  background: #6c7880;
  margin-top: 32px;
}

.blog_description_articles_slider {
  margin-block: 30px;
}

.blog_description_articles h1 {
  color: #1b1b1f;
  font-family: DM Sans;
  font-size: 27px;
  text-transform: capitalize;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.blog_description_articles_item {
  border-radius: 10px;
  background: #f8f8f8;
  padding: 10px;
}

.blog_description_articles .blog_article_badge {
  background-color: white;
  width: fit-content;
  padding-inline: 10px;
  margin-block: 10px;
}

.blog_article_badge p {
  font-size: 14px;
}

.blog_article_badge .blog_badge_circle {
  width: 16px;
  height: 16px;
}

.blog_description_articles h2 {
  color: #1b1b1f;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.blog_description_articles img {
  border-radius: 8px;
}

.blog_article_time {
  color: #1b1b1f;
  font-family: DM Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.blog_card_main_article {
  color: white;
}

.blog_article_time span {
  margin-inline: 10px;
}

.blog_description_tab {
  margin-top: 30px;
}

.blog_cards_container {
  padding-bottom: 60px;
}

.blog_description_tab h2 {
  color: #1b1b1f;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}

.blog_description_tab .active {
  color: #024c87;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 10px 0 10px 20px;
  border-left: 3px solid #024c87;
}

.blog_description_tab p {
  color: #1b1b1f;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px 0 10px 20px;
  margin-bottom: 10px;
}

.blogbanner_container .blogbanner_left {
  background: #ecf4ff;
}

.blogbanner_container .blog_banner_black {
  background: #024c87;
}

/* .blogbanner_container .blog_banner_middle {
  display: none;
} */

.blog_cards_fluid {
  padding: 0;
}

.blog_cards_left_article {
  background: #ecf4ff;
  padding-bottom: 12px;
}

.blog_cards_left_article .blog_cards_article_section h1 {
  color: #373737;
  font-family: DM Sans;
  font-size: 48px;
  text-align: left;
  font-style: normal;
  text-transform: capitalize;
  padding: 40px 0 20px 0;
  font-weight: 500;
  line-height: 134.2%; /* 64.416px */
}

.blog_cards_left_article .blog_cards_article_section {
  width: 240px;
  margin: auto;
  text-align: left;
}

.blog_cards_left_article .blog_cards_article_section h2 {
  color: #373737;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 134.2%; /* 42.944px */
  margin-bottom: 20px;
}

/* .blog_cards_article_section_item {
  padding-block: 30px;
} */

.blog_cards_article_section_item p {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 134.2%; /* 32.208px */
  letter-spacing: 1.08px;
  padding: 5px 12px;
  margin-bottom: 7px;
}

.blog_cards_article_section_item .active {
  border-radius: 8px;
  background-color: #024c87;
  color: #fff;
  padding-block: 12px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.blog_cards_article_section_item .active p {
  color: #fff;
  margin-bottom: 0;
  padding: 0;
}

.blog_cards_searchbar {
  display: flex;
  color: #000;
  padding-block: 50px;
  gap: 10%;
}

.blog_cards_searchbar .blog_cards_searchbar_tab {
  width: 60%;
}

.blog_cards_searchbar .blog_cards_searchbar_tab span {
  display: flex;
  gap: 40px;
  justify-content: center;
  padding-bottom: 15px;
  position: relative;
}

.blog_cards_searchbar .blog_cards_searchbar_tab span::before {
  content: "";
  width: 100%;
  height: 3px;
  background-color: #d9d9d9;
  position: absolute;
  bottom: 23px;
  z-index: -1;
}

.blog_cards_searchbar_tab .active {
  border-bottom: 3px solid #024c87;
  padding-bottom: 10px;
}

.blog_cards_searchbar_tab h3 {
  color: #024c87;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 134.2%; /* 32.208px */
  letter-spacing: 1.08px;
}

.blog_cards_searchbar_tab .blog_cards_searchbar_tab_line {
  width: 100%;
  background-color: #e3e3e3;
  height: 3px;
  position: relative;
}

.blog_cards_searchbar_tab .blog_cards_searchbar_tab_line::before {
  content: "";
  position: absolute;
  width: 140px;
  height: 5px;
  background: #024c87;
  left: 33px;
  top: -1px;
  border-radius: 10px;
}

.blog_cards_searchbar_tab_tag img {
  width: 16px;
  height: 16px;
}

.blog_cards_searchbar_tab_tag {
  border-radius: 31px;
  background: #ffecec;
  width: 255px;
  padding: 16px 32px;
  display: flex;
  align-items: center;
  font-size: 24px;
  text-transform: capitalize;
  gap: 16px;
  height: 50px;
  margin-top: 24px;
}

.blog_cards_searchbar .blog_cards_search {
  width: 30%;
  margin-right: 50px;
}

.blog_cards_search .blog_cards_search_input {
  position: relative;
}

.blog_cards_search .blog_cards_search_input input {
  border-radius: 8px;
  background: #f5f5f5;
  border: 0;
  outline: 0;
  padding: 18px 58px;
  color: rgba(158, 158, 158, 1);
  font-size: 16px;
  text-transform: capitalize;
  font-family: inter;
}

.blog_cards_search_input .blog_cards_search_icon {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 24px;
  top: 19px;
  color: rgba(158, 158, 158, 1);
}

.blog_cards_data {
  border-radius: 6px;
  border: 1px solid rgba(17, 17, 17, 0.1);
  background: #fff;
  padding-block: 48px;
}

.blog_cards_data img {
  height: 300px;
  object-fit: cover;
  /* width: 300px; */
}

.blog_cards_data_text {
  color: #111;
  padding-left: 20px;
}

.blog_cards_data_text_head {
  display: flex;
  gap: 16px;
  align-items: baseline;
}

.blog_cards_data_text_head span {
  border-radius: 2px;
  background: #111;
  padding: 4px 8px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  line-height: 30px;
}

.blog_cards_data_text h2 {
  color: #111;
  font-family: DM Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  padding-right: 20px;
  margin-top: 18px;
  line-height: 120%; /* 48px */
}

.blog_cards_data_text p {
  font-size: 16px;
  opacity: 0.6;
  margin-top: 18px;
}

.blog_cards_data_text .blog_dynamic_para {
  height: 95px;
  overflow: hidden;
}

.blog_cards_data_btn {
  display: flex;
  align-items: center;
  gap: 5px;
  border: 1px solid #111;
  border-radius: 4px;
  padding: 8px 16px;
  background: transparent;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  margin-top: 22px;
}

.blog_cards_data_slider {
  margin-top: 40px;
}

.blog_cards_data_item {
  border-radius: 6px;
  border: 1px solid rgba(17, 17, 17, 0.1);
}

.blog_cards_data_item img {
  height: 250px;
  object-fit: cover;
}

.blog_cards_data_item_text {
  padding: 20px;
  color: #111;
}

.blog_cards_data_item_text h2 {
  font-size: 24px;
}

.blog_cards_data_item_text p {
  font-size: 16px;
  opacity: 0.6;
}
.blog_cards_data_item .blog_cards_data_item_para {
  height: 77px;
  overflow: hidden;
}

.ourteam_carousel.dashboard_newcourse_carousel .owl-nav .owl-prev {
  left: 0;
  top: 30%;
}

.ourteam_carousel.dashboard_newcourse_carousel .owl-nav .owl-next {
  right: 0;
  top: 30%;
}

.mentor_team_container .mentor_card_circle {
  width: 70%;
}

.whatTestimonies_client_container {
  margin: 0;
}

.placed_candidate_advice_container .placed_candidate_advice_left {
  height: 100%;
}

.placed_customer_img .placed_customer_location {
  display: flex;
  align-items: center;
  gap: 7px;
}

.placed_customer_location .placed_customer_location_icon {
  margin-left: -5px;
}

.placed_customer_carousel .placed_customer_item .placed_customer_img {
  align-items: flex-start;
}

.placed_customer_carousel .placed_customer_item .placed_customer_item_div {
  align-items: flex-start;
}

.placed_customer_carousel .placed_customer_item .placed_customer_img h5 {
  margin-bottom: 1px;
}

.homeprogram_container {
  background-color: #e3f1fc;
}

.features-section {
  margin: 0;
}

/*  */

.getstartcarousel_desc .location {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: gray;
  font-weight: 500;
  gap: 3px;
}

.getstartcarousel_desc .location div {
  display: flex;
  align-items: center;
}

.getstartcarousel_desc .location .getstartcarousel_desc_link {
  width: 25px;
  height: 25px;
}

/*  */

.mentor_community_div .location {
  display: flex;
  align-items: baseline;
  color: #989ea6;
  font-weight: 400;
  gap: 3px;
}

.mentor_second_carrousel .center {
  margin-right: 70px !important;
}

.mentor_second_carrousel .center img {
  width: 170px !important;
  margin-top: -18px;
  height: 170px !important;
  box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.3);
  padding: 10px;
}

.placed_name_div .location {
  display: flex;
  align-items: baseline;
  color: black;
  font-weight: 500;
  gap: 3px;
  margin-top: 2px;
}

.placed_customer_img .location {
  display: flex;
  align-items: baseline;
  font-weight: 500;
  gap: 3px;
  margin-top: 2px;
}

.placed_candidate_testi_info .location {
  display: flex;
  align-items: baseline;
  font-weight: 400;
  gap: 3px;
  color: #cacaca;
  margin-top: 2px;
}

.placed_candidate_testi_info .location h6 {
  margin-bottom: 0;
}

.middle_section_carousel .middle_item_desc_btns button:first-child {
  background-color: #ff9a28;
  color: #fff;
  border: 1px solid #ff9a28;
}

.mentor_second_carrousel .mentor_second_img {
  height: 0;
}

.mentor_card_circle .owl-carousel .owl-stage {
  padding-block: 30px;
}

.mentor_team_container {
  padding-bottom: 0;
}

.mentor_second_carrousel .mentor_second_img img {
  width: 120px;
  height: 120px;
}

.contactus_form_container .contact_form_all .contact_form_inputall select {
  appearance: none;
}

.contactus_form_container .contact_form_all .contact_form_inputall {
  position: relative;
}

.contactus_form_container
  .contact_form_all
  .contact_form_inputall
  .contact_form_inputall_dropdown {
  position: absolute;
  right: 13px;
  top: 41px;
}

.about_footer_container .footer_second_div .about_footer_heading {
  margin-top: 101px;
  display: block;
}

.razorpay_div {
  width: 63%;
}
.PaymentButton-Button-contents {
  margin-left: -9px;
}

.job_middle_section_container .job_middle_left .job_middle_inp_group {
  position: relative;
}

.job_middle_section_container .job_middle_left .job_middle_inp_group ul {
  position: absolute;
  top: 50px;
  list-style: none;
  left: 0;
  padding: 0;
  width: 100%;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
}

.job_middle_section_container .job_middle_left .job_middle_inp_group ul li {
  padding: 8px 12px;
  border-bottom: 1px solid #d6d6d6;
  cursor: pointer;
  background-color: white;
  color: #000;
  font-weight: 500;
}

.job_middle_section_container .job_middle_left .job_middle_inp_group input {
  padding-inline: 10px;
}
.job_middle_section_container .job_middle_left .job_middle_inp_group {
  justify-content: unset;
}
.job_middle_section_container .job_middle_left .job_middle_inp_group input {
  width: 60%;
}

/**************** Blog Dasboard CSS ************************/

.blog_dashboard_container {
  padding: 0;
}

.blog_dashboard_sidebar {
  /* border: 2px solid rgba(245, 239, 235, 0.4); */
  background: #fff;
  height: 100%;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.4s;
}

.blog_dashboard_sidebar .blog_dashboard_logo img {
  width: 175px;
  margin-bottom: 15px;
  margin-left: 10px;
}

.blog_dashboard_head {
  display: flex;
  gap: 27px;
  align-items: center;
  width: 100%;
}

.blog_dashboard_head img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
}

.blog_dashboard_head h3 {
  color: rgba(36, 34, 32, 0.4);
  font-family: "DM Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.blog_dashboard_head h4 {
  color: #242220;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 5px;
}

.blog_dashboard_sidebar hr {
  opacity: 0.1;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #432c2c 0%,
    rgba(80, 28, 28, 0) 100%
  );
  height: 2px;
}

.blog_dashboard_sidebar_list h3 {
  color: rgba(36, 34, 32, 0.4);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "DM Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 218.182% */
  letter-spacing: 0.4px;
  text-transform: uppercase;
  padding-inline: 24px;
}

.blog_dashboard_listitem {
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 14px 20px;
}

.blog_dashboard_listitem h2 {
  color: #7d7d7d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  text-transform: capitalize;
  font-weight: 500;
  margin: 0;
  line-height: 20px; /* 125% */
}

.blog_dashboard_dropdown {
  /* background: #ecf4ff; */
  padding-inline: 20px;
  display: flex;
  align-items: center;
  width: fit-content;
  padding-block: 14px;
  border-radius: 10px;
  gap: 16px;
  position: relative;
  cursor: pointer;
  /* justify-content: space-between; */
  min-width: 235px;
}

.blog_dashboard_dropdown_active {
  background: #ecf4ff;
  min-width: 235px;
}

.blog_dashboard_dropdown_active .blog_dashboard_dropup_icon {
  color: #000;
  opacity: 1;
}

.blog_dashboard_input .blog_dashboard_select {
  position: relative;
}

.blog_dashboard_input .blog_dashboard_select select {
  width: 100%;
  appearance: none;
}

.blog_dashboard_select .blog_dashboard_select_dropdown {
  position: absolute;
  color: #000;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  right: 3%;
}

.blog_dashboard_dropdown p {
  color: #7d7d7d;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  text-transform: capitalize;
  margin: 0;
}

.blog_dashboard_dropdown_active p {
  color: #000;
}
.blog_dashboard_dropup_icon {
  color: gray;
  font-size: 20px;
  opacity: 0.4;
  cursor: pointer;
  margin-left: auto;
}

.blog_dashboard_dropdown .blog_dashboard_dropdown_item {
  position: absolute;
  top: 60px;
  left: 50px;
  transition: 0.4s;
}

.blog_dashboard_dropdown_item li {
  list-style: none;
  color: #7d7d7d;
  font-family: Inter;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
  padding: 12px 16px;
  border-radius: 18px;
}

/* .blog_dashboard_dropdown_item li:first-child {
  background: #ecf4ff;
} */

.blog_dashboard_dropdown_item li.active {
  background: #ecf4ff;
  color: #000;
}

.blog_dashboard_dropdown_vector {
  position: absolute;
  top: 0;
  left: -17px;
}

.blog_dashboard_sidebar_logout_btn {
  position: absolute;
  bottom: 4%;
  border-radius: 8px;
  background: #024c87;
  display: flex;
  width: 252px;
  padding: var(--Spacing-spacing-lg, 12px) 86px var(--Spacing-spacing-lg, 12px)
    16px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  border: 0;
  outline: 0;
  color: white;
  font-size: 20px;
  line-height: 144.5%;
  text-transform: capitalize;
}

.blog_dashboard_sidebar_logout_btn p {
  margin: 0;
}

.blog_dashboard_banner {
  /* background-image: url(http://localhost:3000/static/media/blogdash_cover.54ad0214f55ab5a71424.png); */
  height: 280px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.blog_dashboard_banner .blog_dashboard_cover_edit {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #024c87;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.blog_dashboard_banner .blog_dashboard_cover_edit svg {
  fill: #fff;
  cursor: pointer;

  font-size: 25px;
}

.blog_dashboard_profile_update {
  background: #eebe00;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  position: absolute;
  bottom: -16%;
  left: 6%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.blog_dashboard_profile_update img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.blog_dashboard_camera_icon {
  width: 48px;
  height: 48px;
  background-color: #024c87;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  position: absolute;
  right: 0;
  bottom: 16px;
}

.blog_dashboard_profile_update h2 {
  color: #024c87;
  font-family: "DM Sans";
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  padding-top: 15px;
  width: max-content;
  /* margin-left: 40px; */
}

.blog_dashboard_profile_update p {
  color: #024c87;
  font-family: "DM Sans";
  text-transform: uppercase;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: max-content;
}

.blog_dashboard_form {
  padding-top: 170px;
  padding-inline: 70px;
}

.blog_dashboard_form .blog_dashboard_input {
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
}

.blog_dashboard_input label {
  color: #1f1f1f;
  font-family: "DM Sans";
  font-size: 20px;
  margin-bottom: 21px;
  font-style: normal;
  text-transform: capitalize;
  font-weight: 500;
  line-height: normal;
}

.blog_dashboard_input input,
.blog_dashboard_input select {
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  padding: 23px 28px;
  font-size: 16px;
  /* text-transform: capitalize; */
  outline: none;
}

.blog_dashboard_input .blog_dashboard_input_div {
  margin-bottom: 18px;
}

.social_input input,
.social_input select {
  /* margin-bottom: 18px; */
  width: 100%;
}

.blog_dashboard_form .blog_dashboard_update_btn {
  width: 133px;
  height: 50px;
  border-radius: 8px;
  background: #024c87;
  border: 0;
  color: white;
  font-size: 20px;
  margin-left: auto;
  text-transform: capitalize;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.desktop_sidemenu {
  width: 7%;
  /* padding-block: 120px; */
  /* transition: all 0.5s; */
}

.desktop_fulldashboard {
  width: 93%;
}

.desktop_sidemenu .blog_dashboard_logo img {
  width: 120%;
}

.blog_dashboard_logo {
  display: flex;
  justify-content: center;
}

/* .desktop_sidemenu .blog_dashboard_head h3,
.blog_dashboard_head h4 {
  display: none;
} */

.desktop_sidemenu .blog_dashboard_listitem h2 {
  display: none;
}

.desktop_sidemenu .blog_dashboard_head_title {
  display: none;
}

.desktop_sidemenu .blog_dashboard_dropdown p {
  display: none;
}

.desktop_sidemenu .blog_dashboard_dropdown .blog_dashboard_dropdown_item {
  display: none;
}

.desktop_sidemenu .blog_dashboard_dropup_icon {
  display: none;
}

/* .desktop_sidemenu .blog_dashboard_sidebar {
  padding-top: 110px;
} */

.desktop_sidemenu .blog_dashboard_sidebar_list h3 {
  padding-inline: 20px;
}

.desktop_sidemenu .blog_dashboard_head {
  padding-left: 12px;
}

.desktop_sidemenu .blog_dashboard_sidebar_btn {
  background-color: #ffff;
  border: 1px solid #ccc;
  right: -13%;
  top: 6%;
}

.desktop_sidemenu .blog_dashboard_sidebar_logout_btn {
  width: fit-content;
  background: white;
  color: #024c87;
  padding: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.desktop_sidemenu .blog_dashboard_sidebar_logout_btn p {
  display: none;
}

.blog_dashboard_hamburger_icon {
  display: none;
}

.blog_dashboard_form_container {
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 20px;
  position: relative;
}

.blog_dashboard_form_container .blog_dashboard_update_name {
  margin-top: 45px;
  padding-left: 120px;
}

.blog_dashboard_form_container .blog_dashboard_update_name h2 {
  color: #024c87;
  font-family: "DM Sans";
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  padding-top: 15px;
}

.blog_dashboard_form_container .blog_dashboard_update_name p {
  color: #024c87;
  font-family: "DM Sans";
  text-transform: capitalize;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.blog_dashboard_mobile_sidebar_cross {
  display: none;
  position: absolute;
  right: 5%;
  top: 2%;
  background-color: #024c87;
  color: white;
  font-size: 23px;
  border: none;
  outline: none;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  cursor: pointer;
}

.blog_dashboard_change_password {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.blog_dashboard_change_password button {
  border-radius: 44px;
  background: #f89321;
  padding: 8px 24px;
  border: 0;
  outline: 0;
  color: white;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 500;
}

.blog_dashboard_form span {
  color: var(--Grey-N500, #737373);
  font-feature-settings: "ss02" on;
  font-family: "DM Sans";
  font-size: 16px;
  text-transform: capitalize;
  font-style: normal;
  font-weight: 400;
}

.blog_dashboard_profile_div {
  display: flex;
  gap: 48px;
  align-items: flex-start;
}

.blog_dashboard_profile_div .blog_dashboard_camera_icon {
  width: 38px;
  height: 38px;
  font-size: 17px;
}

.blog_dashboard_profile {
  background: #eebe00;
  width: 144px;
  height: 144px;
  border-radius: 50%;
  text-align: center;
  position: relative;
}

.blog_dashboard_profile img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.blog_dashboard_profile_text h2 {
  color: #024c87;
  font-family: "DM Sans";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  padding-top: 15px;
}

.blog_dashboard_profile_text p {
  color: #024c87;
  font-family: "DM Sans";
  text-transform: uppercase;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.blog_dashboard_profile_social {
  display: flex;
  gap: 20px;
  padding-top: 10px;
}

.blog_dashboard_profile_social img {
  width: 30px;
  height: 30px;
}

.blog_dashboard_profile_container {
  padding: 80px;
  height: 100vh;
  overflow-y: auto;
}

.blog_dashboard_profile_container .blog_dashboard_profile_text h2 {
  padding-top: 0;
  margin: 0;
}

.blog_dashboard_profile_location {
  border-radius: var(--radius-md, 24px);
  background: #ecf4ff;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  color: #024c87;
  text-transform: capitalize;
  font-size: 20px;
}

.blog_dashboard_profile_location p {
  margin: 0;
}

.blog_dashboard_profile_edit {
  border-radius: 44px;
  border: 1px solid #7d7d7d;
  display: inline-flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 20px;
  color: #242220;
  text-transform: capitalize;
  margin-left: auto;
}

.blog_dashboard_profile_edit p {
  margin: 0;
}

.blog_dashboard_profile_tabs {
  list-style: none;
  margin-top: 45px;
  color: black;
  display: flex;
  gap: 9px;
  padding-left: 0;
  padding-bottom: 33px;
  border-bottom: 1px solid rgba(47, 46, 45, 0.171);
}

.blog_dashboard_profile_tabs li {
  color: #242220;
  padding: 4px 16px;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  border-radius: 41px;
  border: 1px solid #ecf4ff;
}

.blog_dashboard_profile_tabs li.active {
  background-color: #ecf4ff;
  font-weight: 600;
}

.blog_dashboard_upload_div .blog_upload_div {
  border-radius: 8px;
  border: 2px dashed #d9d9d9;
  padding: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.blog_upload_div h2 {
  color: #242220;
  font-family: "DM Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.blog_upload_div p {
  color: #242220;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  opacity: 0.7;
  font-weight: 500;
  line-height: 140.759%; /* 22.521px */
}

.blog_upload_div button {
  border-radius: 44px;
  background: #024c87;
  text-transform: capitalize;
  padding: 16px 24px;
  color: white;
  border: 0;
  outline: 0;
  margin-top: 12px;
  font-size: 20px;
}

.blog_dashboard_upload_div .blog_draft_div {
  background-repeat: no-repeat;
  border-radius: 16px;
  background: linear-gradient(
      183deg,
      rgba(102, 102, 102, 0) 2.07%,
      rgba(0, 0, 0, 0.68) 65.65%
    ),
    url(../images/blog_draft.png);
  background-size: cover;
  height: 285px;
  position: relative;
  padding: 26px;
  background-position: center;
}

.blog_draft_div .blog_draft_content {
  position: absolute;
  bottom: 7px;
  width: 89%;
}

.blog_draft_div .blog_learn_more {
  color: #8c8c8c;
  display: flex;
  font-size: 25px;
  background: transparent;
  margin-left: auto;
  border: 0;
  outline: 0;
  position: relative;
  transition: none;
  transform: none;
}

.blog_learn_more .blog_learn_more_dropdown {
  position: absolute;
  top: 30px;
  right: 12px;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
}

.blog_learn_more_dropdown button {
  background-color: transparent;
  border: 0;
  outline: 0;
  display: flex;
  width: 119px;
  padding: 10px 16px;
  align-items: center;
  gap: 10px;
  text-transform: capitalize;
}

.blog_learn_more_dropdown button:first-child {
  color: #024c87;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 112.5% */
}

.blog_learn_more_dropdown button:last-child {
  color: #e30e00;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 112.5% */
  background-color: #ecf4ff;
}

.blog_draft_content .draft_badge {
  border-radius: 26px;
  background: #d4ffcd;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  text-transform: capitalize;
  color: #00b031;
  padding: 8px 14px;
  width: fit-content;
}

.draft_badge p {
  margin: 0;
}

.draft_badge span {
  font-size: 26px;
}

.blog_draft_content .blog_draft_text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.blog_draft_text h3 {
  font-size: 20px;
  margin-top: 10px;
  text-transform: capitalize;
}

.blog_draft_text button {
  border: 1px solid white;
  border-radius: 40px;
  background: transparent;
  outline: 0;
  color: white;
  width: 63px;
  height: 27px;
  text-transform: capitalize;
}

.blog_dashboard_cards {
  background-color: #f0f0f0;
  height: 250px;
}

.blog_dashboard_choose_domain_container {
  padding-top: 80px;
  /* padding-bottom:0 ; */
  padding-inline: 50px;
  color: #000;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
}

.blog_dashboard_choose_domain_maindiv {
  padding-bottom: 0;
  padding-inline: 50px;
  padding: 0;
  color: #000;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}

.blog_dashboard_choose_domain_container .blog_dashboard_choose_domain {
  width: 629px;
  height: 511px;
  padding: 33px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px #e0e0e0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.blog_dashboard_choose_domain h2 {
  color: #1c1c1c;
  font-family: "DM Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 144.5%; /* 34.68px */
}

.blog_dashboard_choose_domain p {
  color: #6a6a6a;
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 144.5%; /* 28.9px */
}

.blog_dashboard_choose_domain select {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(175, 175, 175, 0.75);
  padding: 16px;
  border: 0;
  outline: 0;
  width: 100%;
  color: #8c8c8c;
  font-size: 18px;
  margin-top: 30px;
}

.blog_dashboard_choose_domain .blog_dashboard_choose_domain_next_btn {
  width: fit-content;
  display: flex;
  height: 44px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  gap: 10px;
  border: 0;
  outline: 0;
  color: white;
  border-radius: 8px;
  text-transform: capitalize;
  background: #024c87;
  margin-left: auto;
}

.blog_dashboard_choose_domain_three_btn {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* position: absolute; */
  bottom: 3%;
  width: 100%;
}

.blog_dashboard_choose_domain_three_btn button {
  padding: 12px 24px;
  font-size: 18px;
  border-radius: 30px;
  background: transparent;
  border: 0;
  outline: none;
}

.blog_dashboard_choose_domain_three_btn div {
  display: flex;
  gap: 20px;
}

.blog_dashboard_choose_domain_three_btn
  .blog_dashboard_choose_domain_cancel_btn {
  border: 1px solid #7d7d7d;
}

.blog_dashboard_choose_domain_three_btn
  .blog_dashboard_choose_domain_savedraft_btn {
  background: #f5f5f5;
}

.blog_dashboard_choose_domain_three_btn
  .blog_dashboard_choose_domain_continue_btn {
  background: #242220;
  color: white;
}

.blog_dashboard_choose_domain_container .blog_dashboard_upload_thumb {
  height: 80%;
  /* display: flex; */
  margin: 40px 0 0 0;
  border-radius: 8px;
  border: 2px dashed #d9d9d9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: -moz-fit-content;
  /* padding: 210px 120px; */
  width: 66%;
}

.blog_dashboard_upload_thumb h2 {
  color: #242220;
  font-family: "DM Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.blog_dashboard_upload_thumb p {
  color: #242220;
  text-align: center;
  opacity: 0.7;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  width: 75%;
  /* margin: auto; */
  line-height: 140.759%;
}

.blog_dashboard_upload_thumb span {
  border-radius: 44px;
  background: #024c87;
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: white;
  font-size: 20px;
  border: 0;
  margin-top: 25px;
  outline: 0;
}

.blog_dashboard_upload_thumb .blog_dashboard_upload_dropzone input {
  display: none;
}

.blog_dashboard_home {
  width: 100%;
  height: 100vh;
  color: #000;
  overflow-y: auto;
}

.blog_dashboard_home .blog_dashboard_home_navbar {
  padding-top: 40px;
  padding-inline: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.blog_dashboard_home_navbar_input {
  position: relative;
}

.blog_dashboard_home_navbar_input .blog_dashboard_home_search {
  position: absolute;
  left: 7%;
  top: 25%;
  font-size: 27px;
  opacity: 0.7;
}

.blog_dashboard_home_navbar .blog_dashboard_home_navbar_input input {
  border-radius: 12px;
  background: #fdfdfd;
  box-shadow: 0px 0px 4px 0px #ebebeb;
  width: 607px;
  height: 58px;
  outline: 0;
  border: 0;
  padding-left: 84px;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.blog_dashboard_home_navbar input::placeholder {
  color: #000;
}

.blog_dashboard_home_navbar_right {
  display: flex;
  align-items: flex-start;
  gap: 18px;
}

/* blog dashboard */

/* blog dashboard container */

.blog_dashboard_home_notifications {
  border: 1px solid #ecf4ff;
  border-radius: 50%;
  background: #ededed;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  position: relative;
}

/* .blog_dashboard_home_notifications span {
  display: none;
} */

.blog_dashboard_home_notifications span {
  position: absolute;
  display: inline-block;
  top: -7px;
  right: -8px;
  width: 30px;
  color: red;
}

.blog_dashboard_home_navbar_right .blog_dashboard_home_navbar_dropdown {
  display: flex;
  gap: 16px;
  position: relative;
  align-items: center;
}

.blog_dashboard_home_navbar_dropdown .blog_dashboard_home_navbar_dropdown_list {
  position: absolute;
  top: 60px;
  left: -42px;
  box-shadow: 0px 0px 4px 0px #ebebeb;
  background-color: white;
  z-index: 99;
}

.blog_dashboard_home_navbar_dropdown_list .listitem {
  display: flex;
  width: 250px;
  height: 50px;
  padding: 10px;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.blog_dashboard_home_navbar_dropdown_list .listitem:last-child {
  background-color: #ecf4ff;
  cursor: pointer;
}

.blog_dashboard_home_navbar_dropdown_list .listitem label {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 112.5% */
  cursor: pointer;
}

.blog_dashboard_home_navbar_dropdown_list .listitem input {
  display: flex;
  width: var(--Components-Button-Component-onlyIconSizeLG, 18px);
  height: var(--Components-Button-Component-onlyIconSizeLG, 18px);
  padding: 5px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.blog_dashboard_home_navbar_dropdown img {
  width: 48px;
  border-radius: 50%;
  height: 48px;
  object-fit: cover;
}

.blog_dashboard_home_navbar_dropdown h3 {
  color: #979797;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  text-transform: capitalize;
  margin: 0;
  font-weight: 500;
}

.blog_dashboard_home_navbar_dropdown p {
  color: #242220;
  font-family: "DM Sans";
  font-size: 14px;
  text-transform: capitalize;
  font-style: normal;
  font-weight: 600;
  margin: 0;
}

.blog_dashboard_home_navbar_dropdown .navbar_chevronup {
  color: gray;
  font-size: 22px;
  cursor: pointer;
}

.blog_dashboard_home_content {
  padding: 0px 30px;
  margin-top: 30px;
  height: 100%;
  position: relative;
}

.blog_dashboard_home_content::after {
  position: absolute;
  left: 0;
  top: 0;
  height: 1.6px;
  content: "";
  width: 100%;
  background-color: #e3e3e3;
}

.blog_dashboard_home_content .blog_dashboard_home_divider_div {
  display: flex;
  justify-content: center;
  height: auto;
}

.blog_dashboard_home_content .blog_dashboard_home_divider {
  width: 1.6px;
  border-radius: 3px;
  height: 100%;
  background: #e3e3e3;
}

.blog_dashboard_home_content_left_header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.blog_dashboard_home_content_left_header h3 {
  color: #024c87;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.blog_dashboard_home_content_left_header p {
  color: #7d8185;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.blog_dashboard_home_content_left_header span {
  color: #a1a1a1;
  display: flex;
  align-items: center;
  gap: 10px;
  text-transform: capitalize;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.blog_dashboard_home_content_tabs {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: start;
  margin-top: 13px;
  padding: 0px 30px;
  margin-top: 32px;
}
.blog_dashboard_home_content_tabs button {
  border: 0;
  outline: 0;
  background-color: transparent;
  border-radius: 41px;
  border: 1px solid #ecf4ff;
  display: flex;
  font-weight: 500;
  font-size: 16px;
  height: 32px;
  text-transform: capitalize;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
/* 
.blog_dashboard_home_content_tabs button:first-child {
  background: #ecf4ff;
} */

.blog_dashboard_home_blog_cards_container {
  margin-top: 34px;
  height: 74vh;
  overflow-y: auto;
}

.blog_dashboard_home_blog_cards {
  background-color: white;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 21px;
}

.blog_dashboard_home_blog_cards_img {
  position: relative;
}

.blog_dashboard_home_blog_cards_img img {
  width: 224.713px;
  height: 167px;
  object-fit: cover;
}

.blog_dashboard_home_blog_cards_img .blog_cards_draft_badge {
  position: absolute;
  bottom: 12px;
  left: 12px;
  border-radius: 26px;
  background: #d4ffcd;
  display: flex;
  width: auto;
  height: 27px;
  padding: 4px var(--Spacing-spacing-lg, 12px);
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  text-transform: capitalize;
  color: #00b031;
  font-size: 14px;
}

.blog_dashboard_home_blog_cards_img .blog_cards_draft_badge span {
  font-weight: 600;
  font-size: 22px;
}

.blog_dashboard_home_blog_cards h3 {
  color: #242220;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 26px */
  text-transform: capitalize;
}

.blog_dashboard_home_blog_cards p {
  color: #7d8185;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  width: 70%;
  line-height: 150%; /* 24px */
}

.blog_dashboard_home_blog_cards_content_header div {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.blog_dashboard_home_blog_cards_content_header button {
  border-radius: 43px;
  border: 1px solid #a1a1a1;
  display: flex;
  width: 63px;
  height: 27px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: transparent;
  font-size: 16px;
  text-transform: capitalize;
  color: gray;
  outline: 0;
  margin-left: auto;
}

.blog_dashboard_home_blog_create {
  margin-block: 40px;
}

.blog_dashboard_home_blog_create_box {
  display: flex;
  /* width: 316px; */
  padding: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 2px dashed #d9d9d9;
}

.blog_dashboard_home_blog_create h2 {
  color: #242220;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  margin-bottom: 16px;
  font-weight: 700;
  line-height: 130%; /* 26px */
  text-transform: capitalize;
}

.blog_dashboard_home_blog_create_box h3 {
  color: #242220;
  text-align: center;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.blog_dashboard_home_blog_create_box p {
  opacity: 0.7;
  color: #242220;
  text-align: center;
  font-family: "DM Sans";
  padding-inline: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140.759%; /* 22.521px */
}

.blog_dashboard_home_blog_create_box button {
  display: flex;
  width: 256px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 44px;
  background: #024c87;
  color: white;
  border: 0;
  outline: 0;
  font-size: 18px;
}

.blog_dashboard_home_rating h2 {
  color: #242220;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  margin-bottom: 16px;
  font-weight: 700;
  line-height: 130%; /* 26px */
  text-transform: capitalize;
}

.blog_dashboard_home_rating .blog_dashboard_home_rating_box {
  position: relative;
  background-color: white;
  border-left: 8px solid #224c87;
  display: flex;
  padding: var(--Spacing-spacing-2xl, 20px) var(--Spacing-spacing-2xl, 20px)
    var(--Spacing-spacing-2xl, 20px) 32px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.blog_dashboard_home_rating_box h3 {
  color: #242220;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 14px;
}

.blog_dashboard_home_rating_box img {
  width: 122px;
  height: 24.4px;
  margin-bottom: 20px;
}

.blog_dashboard_home_rating_box span {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
}

.blog_dashboard_home_rating_box span h6 {
  color: #d8d8d8;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.blog_dashboard_home_rating_box p {
  color: #7d8185;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
}

.blog_dashboard_insert_content_container {
  height: 100vh;
  position: relative;
  overflow-y: auto;
  padding: 20px 30px;
}

.blog_dashboard_insert_content_container .blog_dashboard_insert_content_div {
  width: 814px;
  /* height: 500px; */
  /* border: 1px solid black; */
  /* display: flex; */
  /* margin: auto; */
  position: relative;
  /* align-items: center; */
  justify-content: center;
}

.blog_dashboard_insert_content_div .blog_dashboard_insert_content_data {
  width: 491px;
  margin: auto;
  /* height: 40px; */
  /* border: 1px solid black; */
}

.blog_dashboard_insert_content_data input {
  color: #000;
  font-family: "DM Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 10px;
  width: 100%;
  outline: 0;
  border: 0;
  border-bottom: 2px solid #d9d9d9;
}

.blog_dashboard_insert_content_data input::placeholder {
  color: #d9d9d9;
}

/* .blog_dashboard_insert_content_data img {
  margin-top: 14px;
  margin-bottom: 50px;
} */

.blog_dashboard_insert_content_data img {
  margin-top: 14px;
  margin-bottom: 50px;
  height: 330px;
  width: 330px;
  object-fit: cover;
}

.blog_dashboard_insert_content_btn {
  border-radius: 44px;
  border: 1px solid #7d7d7d;
  display: flex;
  width: fit-content;
  padding: 11px 24px;
  font-weight: 500;
  justify-content: center;
  text-transform: capitalize;
  align-items: center;
  color: #242220;
  font-size: 20px;
  gap: 10px;
  background-color: transparent;
  position: relative;
  margin: auto;
}

.blog_dashboard_insert_content_btn::before {
  content: "";
  position: absolute;
  left: -105%;
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
}

.blog_dashboard_insert_content_btn::after {
  content: "";
  position: absolute;
  right: -105%;
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
}

.blog_dashboard_insert_content_img_div {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog_dashboard_insert_content_img_div .blog_dashboard_edit_thumb_btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  padding: 12px 28px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 44px;
  background: #f89321;
  border: 0;
  outline: 0;
  text-transform: capitalize;
  color: white;
  font-size: 18px;
  transition: all 0.3s;
  display: none;
}

.blog_dashboard_insert_content_img_div:hover .blog_dashboard_edit_thumb_btn {
  display: block;
  transition: all 0.4s;
}

.blog_dashboard_insert_content_rightbar {
  position: fixed;
  top: 0;
  right: -100%;
  height: 100vh;
  transition: all 0.5s;
  border: 0.5px solid rgba(245, 239, 235, 0.9);
  background: #fff;
  width: 430px;
  height: 963px;
  flex-shrink: 0;
  color: black;
  padding: 80px 0 0 50px;
  transition: all 0.3s;
  border-left: 1.5px solid #e3e3e3;
}

.blog_dashboard_insert_content_rightbar.active {
  right: 0;
  height: 100vh;
  z-index: 9;
}

.blog_dashboard_insert_content_rightbar_middle h1 {
  color: #242220;
  font-family: "DM Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  margin-block: 30px;
  line-height: normal;
  text-transform: capitalize;
}

.blog_dashboard_insert_content_rightbar_middle p {
  color: #242220;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 20px;
  text-transform: capitalize;
  line-height: normal;
}

.blog_dashboard_insert_content_rightbar_textpart {
  display: flex;
  align-items: center;
  gap: 150px;
  margin-bottom: 16px;
}

.blog_dashboard_insert_content_rightbar_textpart span {
  display: flex;
  align-items: center;
  gap: 8px;
}

.blog_dashboard_insert_content_rightbar_textpart span h4 {
  color: #242220;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  text-transform: capitalize;
  margin: 0;
  font-weight: 400;
  line-height: normal;
}

.blog_dashboard_insert_content_rightbar_imgpart {
  display: flex;
  gap: 12px;
  align-items: center;
}

.blog_dashboard_insert_content_rightbar_imgpart h4 {
  margin: 0;
  color: #242220;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  text-transform: capitalize;
  font-weight: 400;
  line-height: normal;
}

.blog_dashboard_register {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.blog_dashboard_register .blog_dashboard_register_img img {
  width: 500px;
  height: 500px;
}

.blog_dashboard_register_form {
  border-radius: 24px;
  border: 1px solid #c9c9c9;
  background: #fff;
  display: inline-flex;
  padding: 30px;
  flex-direction: column;
  width: 100%;
  scale: 0.9;
}

.blog_dashboard_register_form form {
  margin-top: 40px;
}

.blog_dashboard_register_form .blog_dashboard_register_logo img {
  width: 127px;
  height: 62.817px;
  flex-shrink: 0;
  margin-bottom: 16px;
}

.blog_dashboard_register_form h1 {
  color: #242220;
  font-family: "DM Sans";
  font-size: 32px;
  font-style: normal;
  text-transform: capitalize;
  font-weight: 500;
  line-height: normal;
}

.blog_dashboard_register_form p {
  color: #333;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.blog_dashboard_register_form p span {
  text-decoration: underline;
}

.blog_dashboard_register_form .blog_dashboard_register_input {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.blog_dashboard_register_input label {
  color: #242220;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 10px;
  line-height: normal;
  text-transform: capitalize;
}

.blog_dashboard_register_input input {
  border-radius: var(--radius-xs, 12px);
  border: 1px solid #c9c9c9;
  outline: 0;
  height: 56px;
  padding-inline: 20px;
  font-size: 18px;
}

.blog_dashboard_register_form span {
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.blog_dashboard_register_show_password {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  padding-block: 8px;
  font-weight: 500;
  color: #242220;
}

.blog_dashboard_register_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.blog_dashboard_register_btn p {
  text-decoration: underline;
  margin: 0;
}

.blog_dashboard_register_btn button {
  border-radius: 32px;
  opacity: 0.25;
  background: #111;
  display: flex;
  width: 259px;
  height: 64px;
  padding: 17px 32.5px 18px 33.5px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: white;
  font-size: 22px;
  border: 0;
  outline: 0;
  font-weight: 500;
}

.blog_dashboard_register_btn_active button {
  background-color: #024c87;
  opacity: 1;
}

.blog_dashboard_register_footer {
  margin-top: 12px;
  font-weight: 500;
  color: #242220;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.blog_dashboard_register_footer select {
  border: 0;
  text-transform: capitalize;
  outline: 0;
  font-size: 16px;
}

.blog_dashboard_register_footer ul {
  display: flex;
  list-style: none;
  gap: 20px;
  margin: 0;
  font-size: 16px;
  text-transform: capitalize;
}

.blog_dashboard_input_phone {
  display: flex;
  border: 1px solid #d9d9d9;
}

.blog_dashboard_input_phone .react-tel-input {
  width: 122px;
  display: flex;
}

.blog_dashboard_input_phone .react-tel-input .form-control {
  width: 100%;
  border: none;
  /* padding-right: 0; */
}

.blog_dashboard_input_phone input {
  border: 0;
  width: 100%;
  padding-inline: 0;
}

.react-tel-input .form-control {
  padding-right: 0;
}

.blog_dashboard_upload_div {
  margin: 20px;
}

.blog_description_content {
  padding-bottom: 20px;
}

.blog_description_content h1 {
  color: #1b1b1f;
  font-family: "DM Sans";
  font-size: 28.904px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 30px;
  margin-bottom: 27px;
}

.blog_description_content p {
  color: #1b1b1f;
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
}

.blog_description_content_container {
  position: relative;
  padding-left: 60px;
}

.blog_description_content_container .blog_dashboard_choose_domain_three_btn {
  margin-top: 24px;
}

.blog_dashboard_home_content_tabs button.active {
  background-color: #ecf4ff;
  font-weight: 600;
}

.blog_dashboard_home_blog_cards_content_header {
  width: 100%;
}

.blog_account_type_logo img {
  width: 150px;
  /* height: 40px; */
}

.blog_account_type_container {
  /* padding-top: 40px; */
  height: 100vh;
  overflow: scroll;
  padding-bottom: 140px;
}

.blog_account_type_container::-webkit-scrollbar {
  display: none;
}

.blog_account_type_box {
  color: #000;
  /* padding-top: 45px; */
}

.blog_account_type_box h2 {
  text-align: center;
}

.blog_account_type_box p {
  max-width: 60%;
  text-align: center;
  font-size: 20px;
  margin: auto;
  color: #bcbcbc;
}

.blog_account_type_select {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  position: relative;
  border-radius: 8px;
}

.blog_account_type_select .blog_account_type_or {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f4f4f4;
  width: 66px;
  /* text-align: center; */
  height: 41px;
  display: flex;
  align-items: center;
  font-size: 20px;
  justify-content: center;
  border-radius: 7px;
  color: #a7a7a7;
  border: 1px solid #716f6f35;
}

.blog_account_type_select_box {
  width: 266px;
  height: 266px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
}

.blog_account_type_select_box img {
  width: 84px;
}

.blog_account_type_select_box h3 {
  font-size: 32px;
}

.blog_account_type_select_box:first-child {
  background-color: #024c87;
  color: white;
  cursor: pointer;
}

.blog_account_type_select_box:nth-child(2) {
  color: #aaaaaa;
  background: #f4f4f4;
  cursor: pointer;
}

.blog_dashboard_listitem.active {
  background-color: #ecf4ff;
  border-radius: 8px;
  width: 235px;
}

.blog_dashboard_listitem.active h2 {
  color: #000;
}

/* .blog_dashboard_listitem.active svg {
  fill: #000;
} */

.blog_dashboard_home_blog_no {
  text-align: center;
  font-size: 18px;
  /* text-transform: capitalize; */
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  justify-content: center;
}

.dashboard_new_header_info_sinup_btn {
  background-color: #024c87;
  color: white;
  border: 0;
  outline: 0;
  padding: 6px 20px;
  font-size: 18px;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 10px;
}

.blog_signup_left_div_logo img {
  width: 150px;
}

.blog_signup_left_div {
  padding: 40px;
}

/* .blog_signup_left_div .blog_signup_img {
  padding-left: 50px;
  padding-top: 90px;
} */

.blog_signup_img img {
  width: 549px;
}

.blog_signup_right_div {
  background-color: white;
  color: #000;
  padding-top: 200px;
  height: 100vh;
  box-shadow: 9px -2px 6px 9px rgba(227, 227, 227, 1);
  padding-inline: 20px;
}

.blog_signup_right_div .blog_signup_form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.blog_signup_form h3 {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 30px;
}

.blog_signup_form h3 span {
  color: #9e9e9e;
}

.blog_signup_form input {
  border: 1px solid rgba(108, 108, 108, 1);
  padding: 15px 24px;
  font-size: 20px;
  font-weight: 500;
  outline: 0;
  margin: auto;
  width: 410px;
  height: 56px;
  border-radius: 8px;
  color: rgba(112, 112, 112, 1);
  margin-bottom: 17px;
  cursor: not-allowed;
  background: #d1d1d1;
  opacity: 0.5;
}

.blog_signup_form p {
  font-size: 16px;
  color: #b3b3b3;
  font-weight: 500;
}

.blog_signup_form p span {
  color: #224c87;
}

.blog_signup_form button {
  background-color: rgba(2, 76, 135, 1);
  color: white;
  border: 0;
  outline: 0;
  width: 338px;
  padding: 8px 24px;
  margin: auto;
  font-size: 26px;
  border-radius: 8px;
  margin-top: 40px;
  margin-bottom: 27px;
}

.blog_otp_left_div_logo img {
  width: 150px;
}

.blog_otp_left_div {
  padding: 40px;
}

.blog_otp_left_div .blog_otp_img {
  padding-left: 50px;
  padding-top: 90px;
}

.blog_otp_img img {
  width: 500px;
}

.blog_otp_right_div {
  background-color: white;
  color: #000;
  padding-top: 200px;
  height: 100vh;
  box-shadow: 9px -2px 6px 9px rgba(227, 227, 227, 1);
  padding-inline: 20px;
}

.blog_otp_right_div .blog_otp_content_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blog_otp_content_box h2 {
  font-style: 32px;
  font-weight: 500;
  font-family: DM Sans;
}

.blog_otp_content_box p {
  font-size: 16px;
  color: rgba(179, 179, 179, 1);
  font-weight: 500;
}

.blog_otp_content_box .otp_form_resend {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  width: 60%;
  margin-top: 20px;
  gap: 10px;
}

.blog_otp_content_box .otp_form_resend span {
  color: #024c87;
  font-size: 16px;
  font-weight: 600;
}

.blog_otp_content_box .otp_form_resend button {
  border: none;
  background-color: transparent;
  font-size: 16px;
  color: rgba(179, 179, 179, 1);
}

.blog_otp_box {
  margin-top: 20px;
}

.blog_otp_box .login_otp_con {
  gap: 24px;
}

.blog_otp_box .login_otp_inp {
  /* padding: 15px 24px; */
  height: 50px;
  width: 50px !important;
  border-radius: 8px;
  color: rgba(112, 112, 112, 1);
  outline: none;
  border: 1px solid rgba(108, 108, 108, 1);
}

.blog_otp_verify button {
  background-color: rgba(2, 76, 135, 1);
  color: white;
  border: 0;
  outline: 0;
  width: 338px;
  padding: 8px 24px;
  margin: auto;
  font-size: 26px;
  border-radius: 8px;
  margin-top: 40px;
  margin-bottom: 27px;
}

.desktop_sidemenu .blog_dashboard_listitem {
  width: fit-content;
}

.dashboard_profile_password_box {
  position: relative;
}

.dashboard_profile_password_icon {
  position: absolute;
  color: #6e6d7ace;
  top: 27px;
  right: 10px;
}

.blog_dashboard_container_row {
  display: flex;
}

.blog_dashboard_container_row .blog_dashboard_container_child_first {
  width: 25%;
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}

.blog_dashboard_container_row .blog_dashboard_container_child_second {
  width: 100%;
}

.blog_dashboard_home_blog_no img {
  width: 50%;
  margin-bottom: 30px;
}

.blog_dashboard_home_blog_no h2 {
  margin-top: 22px;
  font-size: 22px;
  font-weight: normal;
}

.desktop_sidemenu {
  max-width: 7%;
  /* padding-block: 120px; */
  /* transition: all 0.5s; */
}

.blog_cards_right_container {
  padding-inline: 50px;
}

.blogbanner_right {
  margin-left: auto;
  width: 70%;
}

.blogbanner_right .rounded-shimmer {
  border-radius: 50%;
}

.blog_cards_article_section_last {
  padding-bottom: 0;
}

.blog_dashboard_container_child_first .blog_dashboard_sidebar_btn {
  background-color: #dbcad4;
  border: 0;
  outline: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-size: 16px;
  color: gray;
  position: absolute;
  right: -3%;
  z-index: 9;
  top: 8%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: none;
  transform: none;
}

.blog_dashboard_container_child_first .blog_dashboard_sidebar_btn_notactive {
  right: -9%;
  background-color: #ffff;
  border: 1px solid #ccc;
}

.blog_dashboard_password_form {
  padding-top: 155px;
}

.blog_dashboard_password_form_div {
  padding-inline: 24px;
}

.blog_dashboard_password_form_div .blog_dashboard_password_form {
  background-color: rgba(248, 248, 248, 1);
  padding: 40px;
  border-radius: 10px;
  margin-top: 25px;
  /* padding-inline: 25px; */
}

.blog_dashboard_password_form .blog_dashboard_input input {
  padding: 14px 28px;
  box-shadow: 2px 3px 14px 0px rgba(217, 217, 217, 1);
  border: 0;
  /* margin-bottom: 15px; */
}

.blog_dashboard_password_form .blog_dashboard_input label {
  margin-bottom: 0;
}

.blog_dashboard_password_form_div h2 {
  font-size: 28px;
  color: rgba(31, 31, 31, 1);
  padding-left: 35px;
  margin: 0;
}

.blog_dashboard_password_form_div p {
  font-size: 17px;
  color: rgba(115, 115, 115, 1);
  padding-left: 35px;
}

.blog_dashboard_password_form .blog_dashboard_input {
  margin-bottom: 0;
}

.blog_dashboard_pass_row {
  margin-bottom: 39px;
}

.blog-dashboard_home_dynamic_para {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  height: 115px;
}

.blog-dashboard_home_dynamic_para p {
  width: 100%;
}

.dashboard_notification_container {
  position: fixed;
  width: 38%;
  height: 80%;
  bottom: 5%;
  right: 1%;
  border-radius: 0px 0px 16px 0px;
  background: #fff;
  z-index: 999;
  box-shadow: -24px -20px 44px -30px rgba(0, 0, 0, 0.1);
}

/* .blog_cards_article_section_item .active:hover {
  background-color: rgba(227, 237, 250, 1);
  transition: all 0.3s;
  color: rgba(92, 92, 92, 1);
} */

.blog_cards_article_section_item .blog_sidebar_item:hover {
  background-color: rgba(227, 237, 250, 1);
  transition: all 0.3s;
  color: rgba(92, 92, 92, 1);
  border-radius: 8px;
  box-shadow: 0px 0px 1.6px 0 rgba(235, 235, 235, 1);
}

.blog_cards_article_section_item
  .active:hover
  .blog_cards_article_section_item_dropdown
  .blog_cards_article_section_dropdown_icon {
  color: rgba(92, 92, 92, 1);
}

.blog_cards_article_section_item_dropdown {
  position: relative;
}
.blog_cards_article_section_item_dropdown
  .blog_cards_article_section_dropdown_icon {
  font-size: 26px;
}

.blog_cards_article_section_item .blog_cards_article_section_dropdown_list {
  background-color: white;
  /* box-shadow: 1px 2px 12px 0px rgba(0, 0, 0, 0.1); */
  box-shadow: 0px 2px 5.1px 0px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  border-radius: 7px;
  margin-top: 8px;
  margin-bottom: 12px;
  padding: 8px;
}

.blog_cards_article_section_dropdown_list p {
  margin: 0;
  font-size: 18px;
  cursor: pointer;
  margin: 0px 4px;
}

.blog_cards_article_section_item
  .blog_cards_article_section_dropdown_list
  .isActive {
  border-left: 5px solid #024c87;
  border-radius: 3px;
}

/*  */

.blog_desc_container .blog_desc_right .blog_desc_item3 {
  margin-top: 40px;
}
.blog_desc_container .blog_desc_right .blog_desc_item2 {
  margin-top: 40px;
}
.blog_desc_container .blog_desc_right .blog_desc_item_two {
  margin-top: 0;
}

.blog_desc_container .blog_desc_right .blog_desc_item {
  margin-bottom: 10px;
  padding: 60px;
}

.blog_gallery_container {
  padding-top: 80px;
  height: auto;
}

.blog_gallery_container::after {
  height: 577px;
  width: 600px;
  top: 81%;
}

.blog_joinus_container {
  position: relative;
  z-index: 9;
  margin-top: 100px;
  margin-bottom: 15%;
}

.blog_joinus_container .blog_joinus_left h1 {
  font-size: 40px;
  margin-bottom: 40px;
}

.blog_joinus_container .blog_joinus_left p {
  font-size: 24px;
  max-width: 100%;
}

.blog_joinus_container .blog_joinus_right .blog_tagone h2 {
  font-size: 24px;
}

.blog_joinus_container .blog_joinus_right .blog_tagtwo h2 {
  font-size: 24px;
  margin-bottom: 70px;
  margin-top: 30px;
}

.blog_joinus_container .blog_joinus_right .blog_tagtwo {
  padding-left: 50px;
}

.blog_joinus_container .blog_joinus_right .blog_tagtwo p {
  font-size: 24px;
}

.blog_joinus_container .blog_joinus_right .blog_tagone {
  padding: 30px 50px;
}

.blog_desc_container .blog_desc_left h1 {
  font-size: 36px;
}

.blog_desc_container .blog_desc_left p {
  font-size: 24px;
}

.blog_desc_container .blog_desc_right .blog_desc_item h1 {
  font-size: 48px;
}

.blogbanner_container .blog_banner_middle {
  right: 32%;
  position: absolute;
  top: 37%;
  left: auto;
}

/* .blogbanner_container {
  height: 80vh;
} */

.blogbanner_container .blog_banner_middle img {
  width: 70%;
}

.blogbanner_container .blogbanner_left h1 {
  /* font-size: 62px; */
  padding-top: 30px;
}

.blogbanner_container .blog_banner_black {
  width: 60%;
}

.blogbanner_container .blogbanner_left {
  width: 60%;
}

.blogbanner_container .blogbanner_card .blogbanner_card_wrapper h6 {
  font-size: 24px;
}

.blog_desc_container .blog_desc_right .blog_desc_item1 {
  box-shadow: 0px 2px 14px -5px rgba(0, 0, 0, 0.25);
}

.blog_gallery_two {
  position: absolute;
  bottom: -62px;
}

.blog_desc_container .blog_desc_right .blog_desc_item_two {
  margin-top: 0;
}

.blog_cards_article_section_first {
  padding-bottom: 30px;
}
.blog_cards_article_section_two {
  padding-bottom: 35px;
}
.blog_cards_left_article .blog_cards_article_section_three h2 {
  margin-bottom: 0;
}

.blog_cards_article_section_dropdown_list p {
  font-size: 18px;
  padding-block: 5px;
}

.blog_cards_left_article .blog_cards_article_section {
  width: 300px;
  margin: auto;
  text-align: left;
}

.blog_cards_mobile_data_view {
  display: none;
  color: black;
  display: flex;
  gap: 15px;
  box-shadow: 0px 0px 4px 0px rgba(235, 235, 235, 1);
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 20px;
}

.blog_cards_mobile_data_view img {
  object-fit: cover;
  width: 35%;
  height: 122px;
}

.blog_cards_mobile_data_view .blog_cards_mobile_data_view_text {
  width: 65%;
  position: relative;
}

.blog_cards_mobile_data_view .blog_cards_mobile_data_view_text h2 {
  font-size: 12px;
}

.blog_cards_mobile_data_view .blog_cards_mobile_data_view_text span {
  display: flex;
  font-weight: 500;
  justify-content: end;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.blog_cards_mobile_data_view_text .blog_cards_mobile_data_view_badge {
  display: flex;
  align-items: center;
  color: rgba(108, 64, 255, 1);
  background-color: rgba(241, 236, 255, 1);
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 15px;
  padding-inline: 6px;
  padding-block: 2px;
  margin-bottom: 10px;
  gap: 5px;
}

.blog_cards_mobile_data_view_text .blog_cards_mobile_data_view_badge p {
  margin: 0;
  font-size: 12px;
}

.blogbanner_container .blogbanner_card {
  width: 347px;
}

.blogbanner_container .blogbanner_card .blogbanner_card_wrapper {
  width: auto;
}

.drop_item .mobile_nav_item img {
  width: 20px;
  margin-right: 6px;
}

.blog_card_main .blog_card_main_mobile {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.blog_card_main .blog_card_main_mobile h2 {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
}

.blog_card_main .blog_card_main_mobile img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.blog_card_main .blog_card_main_mobile .blog_card_main_mobile_dot {
  width: 5px;
  height: 5px;
  background: white;
  border-radius: 50%;
}

.blog_card_main_article_mobile_social {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  padding-left: 10px;
}

.blog_card_main_article_mobile_social img {
  width: 30px;
  height: 30px;
}

.blog_cards_mobile_data_view_text .blog_cards_mobile_data_view_badge .span {
  width: 5px;
  height: 5px;
  background-color: rgba(108, 64, 255, 1);
  border-radius: 50%;
}

/*  */

.blog_dashboard_mobile_header {
  padding: 12px;
  position: relative;
}

.blog_dashboard_mobile_header_div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.blog_dashboard_mobile_header img {
  width: 100px;
}

.blog_dashboard_mobile_header_rightside {
  display: flex;
  align-items: center;
  gap: 10px;
}

.blog_dashboard_mobile_header_rightside_circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: rgba(2, 76, 135, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -1px -0.2px 4px 1px rgba(217, 217, 217, 1);
  font-size: 18px;
  position: relative;
}

.blog_dashboard_mobile_header_rightside_circle img {
  border-radius: 50%;
  height: 26px;
  object-fit: cover;
  width: 26px;
}

.blog_dashboard_mobile_header_rightside_circle span {
  position: absolute;
  top: -2px;
  right: 9px;
  font-size: 19px;
  color: red;
  display: none;
}

.blog_dashboard_mobile_header_rightside_circle span.active {
  display: block;
}

.blog_dashboard_mobile_header_rightside_circle_input {
  width: 100%;
  z-index: 99;
  margin-top: 10px;
}

.blog_dashboard_mobile_header_rightside_circle_input input {
  width: 100%;
  outline: 0;
  border: 0;
  box-shadow: 0px -2px 15px 0px rgba(234, 234, 234, 1);
  margin-top: 0px;
  padding: 8px;
  font-size: 15px;
}

.blog_dashboard_mobile_header {
  display: none;
}

.blog_dashboard_mobile_header .blog_dashboard_mobile_header_hamburger {
  font-size: 33px;
  color: rgba(2, 76, 135, 1);
}

.blog_dashboard_home_blog_mobile_cards {
  padding: 12px;
  border-radius: 8px;
  display: flex;
  gap: 20px;
  margin-bottom: 18px;
  box-shadow: 0px 0px 4px 0px rgba(235, 235, 235, 1);
}

.blog_dashboard_home_blog_mobile_cards_img {
  position: relative;
}

.blog_dashboard_home_blog_mobile_cards_badge {
  position: absolute;
  left: 6px;
  color: rgba(108, 64, 255, 1);
  background-color: rgba(241, 236, 255, 1);
  bottom: 6px;
  font-size: 9px;
  border-radius: 10px;
  padding: 0 6px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.blog_dashboard_home_blog_mobile_cards_badge span {
  width: 5px;
  height: 5px;
  background-color: rgba(108, 64, 255, 1);
  border-radius: 50%;
}

.blog_dashboard_home_blog_mobile_cards img {
  width: 127px;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.blog_dashboard_home_blog_mobile_cards_text h3 {
  font-size: 14px;
}

.blog_dashboard_home_blog_mobile_cards_text p {
  font-size: 10px;
  /* display: flex;
  justify-content: end; */
  gap: 5px;
}

.blog_dashboard_choose_domain .css-1u9des2-indicatorSeparator {
  width: 0;
}

.blog_dashboard_choose_domain .css-13cymwt-control {
  border: 0;
  height: 42px;
  box-shadow: 0px 0px 4px 0px rgba(175, 175, 175, 0.75);
}

.blog_dashboard_home_blog_no button {
  background-color: rgba(2, 76, 135, 1);
  color: white;
  font-size: 16px;
  border: 0;
  outline: 0;
  padding: 12px 20px;
  border-radius: 50px;
}

.blog_dashboard_choose_domain_three_btn button p {
  margin-bottom: 0;
}

/*  dashboard-certificate */

.dashboard_certificate_container {
  background-color: #fff;
  /* height: 100%; */
  color: rgba(51, 51, 51, 1);
  padding: 30px;
}

.dashboard_certificate_container .dashboard_certificate_heading {
  font-size: 32px;
}

.dashboard_certificate_container .dashboard_certificate_header {
  box-shadow: 0px 0px 4.4px 0px rgba(217, 217, 217, 1);
  padding: 26px;
  border-radius: 8px;
  margin-top: 30px;
  margin-bottom: 45px;
  position: relative;
}

.dashboard_certificate_header img {
  position: absolute;
  right: 30px;
  width: 280px;
  top: -8px;
}

.dashboard_certificate_header h2 {
  font-size: 30px;
}

.dashboard_certificate_header p {
  margin: 0;
  font-size: 24px;
  font-weight: 400;
  width: 60%;
  color: rgba(92, 92, 92, 1);
}

.dashboard_certificate_container .dashboard_certificate_download_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.dashboard_certificate_container .dashboard_certificate_download_section h2 {
  font-size: 28px;
  font-weight: bold;
}

.dashboard_certificate_download_section h3 {
  font-size: 24px;
  color: rgba(92, 92, 92, 1);
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 20px;
}

.dashboard_certificate_download_section button {
  background-color: rgba(2, 76, 135, 1);
  color: white;
  /* padding: 12px 20px 12px 20px; */
  width: 200px;
  height: 48px;
  border: 0;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 400;
  outline: 0;
}

.dashboard_certificate_container .dashboard_certificate_cards_container {
  display: flex;
  margin-top: 45px;
  flex-wrap: wrap;
  gap: 30px;
}

.dashboard_certificate_cards_container .dashboard_certificate_card {
  width: 308px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 5.2px 0px rgba(188, 188, 188, 1);
}

.dashboard_certificate_card img {
  width: 100%;
}

.dashboard_certificate_card .dashboard_certificate_card_text {
  position: absolute;
  bottom: 0;
  background-color: white;
  left: 0;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(217, 217, 217, 1);
}

.dashboard_certificate_card_text .dashboard_certificate_card_text_btn {
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
}

.dashboard_certificate_card_text_btn .share_btn {
  width: 42px;
  height: 42px;
  background-color: rgba(2, 76, 135, 1);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard_certificate_card_text h2 {
  font-size: 28px;
  font-weight: 700;
  margin: 0;
}

.dashboard_certificate_card_text p {
  font-size: 20px;
  font-weight: 500;
  color: rgba(92, 92, 92, 1);
  margin: 0;
}

.dashboard_certificate_card_text button {
  width: 200px;
  height: 45px;
  background: transparent;
  font-weight: 500;
  border-radius: 10px;
  font-size: 20px;
  color: rgba(2, 76, 135, 1);
  border: 1px solid rgba(2, 76, 135, 1);
  /* margin-top: 20px; */
  /* margin-left: auto; */
}

.dashboard_certificate_refer_container {
  background-color: rgba(2, 76, 135, 1);
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  padding: 28px 40px;
  margin-top: 50px;
}

.dashboard_certificate_refer_container h3 {
  color: white;
  font-weight: 500;
  font-size: 32px;
}

.dashboard_certificate_refer_container img {
  width: 254px;
  margin-top: auto;
}

.dashboard_certificate_input {
  border-radius: 8px;
  background-color: white;
  padding: 20px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  width: 500px;
  justify-content: space-between;
}

.dashboard_certificate_input input {
  border: 0;
  outline: 0;
  font-size: 24px;
  font-weight: 500;
  color: rgba(92, 92, 92, 1);
  background-color: transparent;
}

.dashboard_certificate_input label {
  color: rgba(92, 92, 92, 1);
  font-size: 20px;
  font-weight: 400;
}

.dashboard_certificate_input span {
  display: flex;
  flex-direction: column;
}

.dashboard_certificate_input button {
  width: 160px;
  font-size: 24px;
  font-weight: 400;
  color: white;
  border: 0;
  outline: 0;
  border-radius: 8px;
  height: 50px;
  background-color: rgba(66, 65, 65, 1);
}

.dashboard_certificate_container .dashboard_certificate_rating_container {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 30px;
  border-radius: 8px;
  padding: 28px;
}

.dashboard_certificate_rating_container h2 {
  font-size: 28px;
  font-weight: 600;
}

.dashboard_certificate_rating_container p {
  font-weight: 500;
  font-size: 24px;
  color: rgba(92, 92, 92, 1);
}

.dashboard_certificate_img {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard_certificate_img .certificate_img {
  width: 300px;
}

.dashboard_certificate_container .certificate_thankyou_text {
  text-align: center;
  font-weight: 600;
  font-size: 32px;
  margin-top: 24px;
  color: rgba(51, 51, 51, 1);
}

.dashboard_certificate_container .certificate_triangle {
  margin-top: 20px;
}

/*  */

.dashboard_certificate_containe .certificate_triangle {
  width: 100px;
  height: 100px;
}

.dashboard_certificate_card .dashboard_certificate_card_text {
  font-weight: 600;
  font-size: 24px;
}

.dashboard_certificate_modal {
  display: flex;
  flex-direction: column;
  color: #000;
  padding: 20px;
}

.dashboard_certificate_modal h2 {
  font-size: 24px;
  color: rgba(2, 76, 135, 1);
  font-weight: 700;
}

.dashboard_certificate_modal label {
  font-weight: 500;
  font-size: 20px;
  color: rgba(2, 76, 135, 1);
  margin-bottom: 10px;
}

.dashboard_certificate_modal input {
  width: 100%;
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 4px;
  padding: 16px 20px;
  height: 43px;

  outline: 0;
}

.dashboard_certificate_modal .dashboard_certificate_note {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
}

.dashboard_certificate_modal .dashboard_certificate_note p {
  margin: 0;
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  font-weight: 500;
}

.dashboard_certificate_modal .dashboard_certificate_note p span {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: rgba(51, 51, 51, 1);
}

.dashboard_certificate_modal button {
  background-color: rgba(2, 76, 135, 1);
  /* padding: 12px 14px; */
  border-radius: 4px;
  color: white;
  font-weight: 400;
  border: 0;
  outline: 0;
  font-size: 16px;
  width: 138px;
  height: 39px;
  margin-left: auto;
  margin-top: 20px;
}

.dashboard_no_certificate {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  flex-direction: column;
  gap: 20px;
  text-align: center;
}

.dashboard_no_certificate img {
  width: 325px;
}

.dashboard_no_certificate h2 {
  font-size: 28px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
}

/*  */

.certificate_preview_modal h2 {
  color: #024c87;
  font-size: 32px;
  text-align: center;
}

/*  */

.dashboard_certificate_mobile_card {
  width: 308px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  /* box-shadow: 0px 0px 3.92px 0px rgba(188, 188, 188, 1); */
  /* 0px 0px 4px 0px rgba(0, 0, 0, 0.25) */
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.dashboard_certificate_mobile_card img {
  width: 100%;
}

.dashboard_certificate_mobile_card .dashboard_certificate_mobile_card_text {
  position: absolute;
  bottom: 0;
  background-color: white;
  left: 0;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(217, 217, 217, 1);
}

.dashboard_certificate_mobile_card_text h2 {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  text-transform: capitalize;
}

.dashboard_certificate_mobile_card_text p {
  font-size: 12px;
  font-weight: 500;
  color: rgba(92, 92, 92, 1);
  margin: 0;
}

.dashboard_certificate_mobile_card_text button {
  width: 100px;
  height: 45px;
  background: transparent;
  font-weight: 500;
  border-radius: 10px;
  font-size: 16px;
  color: rgba(2, 76, 135, 1);
  border: 1px solid rgba(2, 76, 135, 1);
  margin-top: 20px;
  margin-left: auto;
}

.dashboard_certificate_mobile_card {
  width: auto;
}

.dashboard_certificate_mobile_carousel {
  margin-top: 20px;
}

.dashboard_assignment_countdown {
  color: rgb(92, 92, 92);
  margin-top: 5px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
}

.dashboard_assignment_countdown_div {
  justify-content: end;
  display: flex;
}

.dashboard_assignment_countdown .dashboard_assignment_deadline {
  margin-left: auto;
  color: black;
  margin-right: 8px;
  font-weight: 400;
  font-size: 12px;
}

.dashboard_assignment_countdown_div div {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.dashboard_assignment_list_items_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard_assignment_list_items_header span {
  font-size: 10px;
  background-color: #f3f3f3;
  border-radius: 10px;
  color: rgba(140, 140, 140, 1);
  font-weight: 500;
  padding-inline: 8px;
  padding-block: 1px;
}

/* dashbord help  */

.dashboard_help_btn {
  position: absolute;
  right: 4%;
  bottom: 90px;
}

.dashboard_help_btn .dashboard_help_cancel {
  position: absolute;
  bottom: -18px;
  right: 0px;
  background-color: rgba(2, 76, 135, 1);
  color: white;
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 50%;
  font-size: 25px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dashboard_help_btn .dashboard_help_button {
  height: 50px;
  width: 180px;
  outline: 0;
  border: 0;
  border-radius: 50px;
  background-color: rgba(2, 76, 135, 1);
  color: white;
  font-size: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.dashboard_help_btn .dashboard_help_button.active {
  display: none;
}

.dashboard_help_btn .dashboard_help_button img {
  width: 18px;
  height: 18px;
}

.dashboard_helpcenter_box {
  position: absolute;
  color: black;
  right: 2%;
  bottom: 130px;
  /* top: 3%; */
  background-color: white;
  z-index: 999;
  border-radius: 12px;
  box-shadow: 0px 0px 6px 0px rgba(140, 140, 140, 0.5);
  width: 420px;
  overflow: hidden;
}

/* .dashboard_helpcenter_box.active {
  opacity: 1;
  transform: translateY(-96%);
  transition: opacity 500ms, transform 500ms;
} */

.dashboard_helpcenter_box .dashboard_helpcenter_title {
  background-color: rgba(2, 76, 135, 1);
  color: white;
  border-radius: 12px 12px 0px 0px;
  display: flex;
  align-items: center;
  height: 64px;
  padding-left: 30px;
}

.dashboard_helpcenter_title h2 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
}

.dashboard_helpcenter_title svg {
  cursor: pointer;
}

.dashboard_helpcenter_middle_box {
  padding: 20px 30px 20px 30px;
}

.dashboard_helpcenter_middle_box .dashboard_helpcenter_input {
  box-shadow: 0px 0px 4px 0px rgba(179, 179, 179, 1);
  position: relative;
  border-radius: 5px;
  display: none;
  margin-bottom: 26px;
}

.dashboard_helpcenter_middle_box .dashboard_helpcenter_accordian {
  height: 250px;
  overflow-y: scroll;
}

.dashboard_helpcenter_accordian::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #f5f5f5;
}

.dashboard_helpcenter_accordian::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 5px;
}

.dashboard_helpcenter_accordian::-webkit-scrollbar-thumb {
  background-color: #024c87;
  border-radius: 5px;
}

.dashboard_helpcenter_input input {
  width: 100%;
  border: 0;
  outline: 0;
  border-radius: 5px;
  padding: 14px 40px;
  font-weight: 400;
  font-size: 16px;
  color: rgba(179, 179, 179, 1);
}

.dashboard_helpcenter_input .dashboard_helpcenter_search {
  position: absolute;
  left: 14px;
  top: 16px;
  font-size: 20px;
  font-weight: bold;
  color: rgba(140, 140, 140, 1);
}

.dashboard_helpcenter_middle_box h1 {
  font-size: 24px;
  font-weight: 500;
  color: rgba(66, 66, 66, 1);
  margin: 0;
}

.dashboard_helpcenter_middle_box h1 span {
  color: rgba(125, 129, 133, 1);
}
.dashboard_helpcenter_middle_box p {
  color: rgba(138, 138, 138, 1);
  font-size: 16px;
  font-weight: 400;
}

.dashboard_helpcenter_accordian .accordion-button::after {
  content: none;
}

.dashboard_helpcenter_accordian .accordion-button {
  border-radius: 10px;
}

.dashboard_helpcenter_accordian .accordion-button:not(.collapsed) {
  box-shadow: none;
  background-color: transparent;
}
.dashboard_helpcenter_accordian .accordion-button:focus {
  box-shadow: none;
  border-color: transparent;
}

.dashboard_helpcenter_accordian .accordion_header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

/* .dashboard_helpcenter_accordian
  .accordion-item:first-of-type
  .accordion-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.dashboard_helpcenter_accordian .accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
} */

.dashboard_helpcenter_accordian h3 {
  font-size: 16px;
  margin: 0;
  font-weight: 500;
  color: rgba(31, 31, 31, 1);
}

.dashboard_helpcenter_accordian .dashboard_accordian {
  margin-top: 20px;
  border: 0.5px solid #dee2e6;
  border-radius: 5px;
}

.dashboard_helpcenter_box .dashboard_helpcenter_bottom {
  background-color: rgba(246, 246, 246, 1);
  padding: 15px 30px;
  border-radius: 0px 0px 12px 12px;
}

.dashboard_helpcenter_bottom h2 {
  font-size: 20px;
  color: rgba(51, 51, 51, 1);
  font-weight: 500;
}

.dashboard_helpcenter_bottom button {
  border-radius: 8px;
  background-color: rgba(2, 76, 135, 1);
  padding: 15px;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  text-align: center;
  color: white;
  border: 0;
  outline: 0;
  margin-top: 18px;
}

.dashboard_helpcenter_box .dashboard_helpcenter_contact_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 10px;
  height: 100%;
  background-color: rgba(92, 92, 92, 0.58);
}

.dashboard_helpcenter_contact_container .dashboard_helpcenter_contact {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  border-radius: 16px 16px 0px 0px;
}

.dashboard_helpcenter_contact .dashboard_helpcener_contact_box {
  padding: 20px 30px 10px 30px;
  border-bottom: 2px solid rgba(234, 234, 234, 1);
}

.dashboard_helpcenter_contact .dashboard_helpcenter_contact_hr {
  width: 96px;
  background-color: rgba(241, 241, 241, 1);
  height: 4px;
  margin: auto;
}

.dashboard_helpcenter_contact .dashboard_helpcenter_contact_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.dashboard_helpcenter_contact_header h2 {
  margin: 0;
  color: rgba(51, 51, 51, 1);
  font-size: 24px;
  font-weight: 600;
}

.dashboard_helpcenter_contact_header span {
  width: 31px;
  height: 31px;
  color: white;
  font-size: 19px;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  background-color: rgba(2, 76, 135, 1);
  border-radius: 4px;
  cursor: pointer;
}

.dashboard_helpcenter_contact_option {
  padding: 20px 30px 0px 30px;
  height: 175px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.dashboard_helpcenter_contact_option img {
  width: 24px;
  height: 24px;
}

.dashboard_helpcenter_contact_option .dashboard_helpcenter_contact_option_item {
  display: flex;
  align-items: center;
  color: rgba(92, 92, 92, 1);
  font-size: 20px;
  font-weight: 400;
  gap: 10px;
  cursor: pointer;
}

.dashboard_helpcenter_contact_option .dashboard_helpcenter_contact_query_input {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  padding: 20px 30px 0px 30px;
  /* height: 175px; */
  background-color: white;
}

.dashboard_helpcenter_contact_query_input
  .dashboard_helpcenter_contact_query_input_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard_helpcenter_contact_query_input
  .dashboard_helpcenter_contact_query_input_box
  input {
  width: 355px;
  border: 1px solid rgba(225, 225, 225, 1);
  border-radius: 4px;
  padding: 20px 16px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(179, 179, 179, 1);
  height: 51px;
  outline: none;
}

.dashboard_helpcenter_contact_query_input_box div {
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 45px;
  background-color: rgba(2, 76, 135, 1);
}

.dashboard_helpcenter_contact_query_input_box div img {
  width: 20px;
  height: 20px;
}

.dashboard_helpcenter_contact_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 20px;
  text-align: center;
}

.dashboard_helpcenter_contact_modal img {
  width: 93px;
  height: 86px;
}

.dashboard_helpcenter_contact_modal h2 {
  color: rgba(51, 51, 51, 1);
  font-size: 32px;
  font-weight: 700;
}

.dashboard_helpcenter_contact_modal p {
  color: rgba(92, 92, 92, 1);
  font-size: 18px;
  font-weight: 400;
}

.student_university_container {
  padding-block: 50px;
}

.student_university_container .student_university_header {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.student_university_header h2 {
  font-size: 48px;
  font-weight: 700;
  color: rgba(2, 76, 135, 1);
}

.student_university_header p {
  font-size: 24px;
  font-weight: 400;
  color: rgba(92, 92, 92, 1);
  width: 90%;
}

.student_university_container .student_university_content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-block: 50px;
  row-gap: 50px;
}

.student_university_content .student_university_content_box {
  /* border: 1px solid rgba(rgba(0, 0, 0, 0.18)); */
  /* box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.03); */
  border: 1px solid rgba(153, 153, 153, 1);
  width: 268px;
  height: 266px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.student_university_content_box.animate {
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(0px);
  }
  100% {
    opacity: 1;
    transform: translateY(20px);
  }
}

.student_university_content_box img {
  width: 68%;
  height: 90%;
  object-fit: contain;
}

.our_university_container .student_university_showmore_btn {
  background-color: rgba(2, 76, 135, 1);
  border-radius: 8px;
  width: 200px;
  height: 56px;
  display: flex;
  align-items: center;
  color: white;
  font-size: 24px;
  gap: 14px;
  cursor: pointer;
  font-weight: 500;
  justify-content: center;
  margin: auto;
  margin-top: 30px;
}

.student_university_container .student_university_showmore_btn {
  background-color: rgba(2, 76, 135, 1);
  border-radius: 8px;
  width: 200px;
  height: 56px;
  display: flex;
  align-items: center;
  color: white;
  font-size: 24px;
  gap: 14px;
  cursor: pointer;
  font-weight: 500;
  justify-content: center;
  margin: auto;
  margin-top: 30px;
}

.student_placed_banner_container {
  background-color: rgba(2, 76, 135, 1);
  height: 316px;
}

.student_placed_banner_container_div {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}

.student_placed_banner_container_div h2 {
  font-size: 36px;
  font-weight: 700;
}

.student_placed_banner_container_div p {
  font-size: 20px;
  font-weight: 400;
  margin: 0;
}

.student_placed_banner_container_div img {
  width: 475px;
  height: 316px;
}

.student_placed_banner_container_div .student_placed_banner_left {
  padding-top: 54px;
  padding-right: 54px;
}

.our_university_container {
  padding-block: 50px;
}

.our_university_container .our_university_container_div {
  /* position: relative; */
  /* display: flex; */
  /* width: 100%; */
  overflow: hidden;
  -webkit-mask: linear-gradient(
    90deg,
    transparent,
    #fff 20%,
    #fff 80%,
    transparent
  );
  mask: linear-gradient(90deg, transparent, #fff 29%, #fff 89%, transparent);
}

.our_university_container .our_university_container_div {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  gap: 29px;
  row-gap: 40px;
}

.our_university_container_div .our_university_box {
  width: 302px;
  height: 406px;
  background-color: white;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 14px 30px 0px rgba(0, 0, 0, 0.03);
}

.our_university_box .our_university_box_head {
  background-color: rgba(227, 241, 252, 1);
  height: 174px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.our_university_box_head .our_university_box_head_img {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0.18);
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.our_university_box_head_img img {
  width: 64px;
  height: 64px;
  object-fit: contain;
}

.our_university_box .our_university_box_text {
  padding: 20px 13px;
}

.our_university_box .our_university_box_text h2 {
  font-size: 20px;
  font-weight: 500;
  color: rgba(80, 80, 80, 1);
}

.our_university_box .our_university_box_text p {
  font-size: 16px;
  color: rgba(80, 80, 80, 1);
  font-weight: 400;
  height: 147px;
  overflow-y: auto;
}

.our_university_container button {
  background-color: rgba(2, 76, 135, 1);
  border-radius: 8px;
  width: 215px;
  height: 65px;
  display: flex;
  align-items: center;
  color: white;
  font-size: 24px;
  gap: 14px;
  font-weight: 500;
  justify-content: center;
  margin: auto;
  margin-top: 60px;
  cursor: pointer;
  border: 0;
}

/*  */

.student_university_content_box.animate {
  transition: opacity 0.5s;
  opacity: 1;
}

.student_university_content_box.animate.ng-hide {
  opacity: 0;
}

.student_university_content_box.animate {
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.new_featured_program_container {
  /* background-color: transparent; */
  margin-top: 50px;
  padding: 50px;
  padding-bottom: 100px;
}

.new_featured_program_container
  .new_featured_program_item_data
  .new_featured_program_item_text {
  background-color: white;
  padding: 27px 20px;
  /* border-radius: 0px 0px 12px 12px; */
}

.new_featured_program_item_text h3 {
  font-size: 25px;
  font-weight: 400;
  text-transform: capitalize;
  color: rgba(26, 36, 52, 1);
}

.new_featured_program_item_text p {
  color: rgba(156, 156, 156, 1);
  font-size: 16px;
  font-weight: 400;
  height: 100px;
  overflow-y: auto;
}

.new_featured_program_item_data .new_featured_program_price_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 22px;
}

.new_featured_program_item_data_img img {
  height: 200px;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
}

.new_featured_program_price_section h3 {
  font-size: 18px;
  margin-bottom: 0;
}

.new_featured_program_price_section h4 {
  color: rgba(140, 140, 140, 1);
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0;
}

.new_featured_program_price_section h4 span {
  color: rgba(0, 128, 0, 1);
  font-weight: 400;
  font-size: 20px;
  margin-left: 6px;
}

.new_featured_program_price_section button {
  background-color: rgba(2, 76, 135, 1);
  width: 180px;
  color: white;
  border-radius: 50px;
  border: 0;
  font-size: 16px;
  justify-content: center;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 10px;
  outline: 0;
  height: 45px;
}

.new_featured_program_item_data_right_buttons {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.new_featured_program_item_data_right_buttons button {
  width: 160px;
  /* padding-inline: 42px; */
  box-shadow: 0px 1px 4.6px 0px rgba(231, 231, 231, 1);
  height: 42px;
  background-color: white;
  border: 0;
  outline: 0;
  font-weight: 400;
  font-size: 20px;
  border-radius: 50px;
  color: rgba(92, 92, 92, 1);
}

.new_featured_program_item_data_right_buttons button.active span {
  background: rgba(2, 76, 135, 1);
  color: white;
  font-size: 18px;
  z-index: 99;
  padding: 8px 30px;
  position: relative;
  border-radius: 50px;
}

.new_featured_program_item_data_right_buttons button.active {
  height: 58px;
  border-radius: 15px 15px 0 0;
  position: relative;
  box-shadow: none;
}

.new_featured_program_tabs {
  background-color: white;
  padding: 25px;
  border-radius: 20px;
  margin-top: 10px;
  height: 400px;
  /* height: 100%; */
  overflow-y: auto;
  transition: 0.3s;
  color: black;
}

.new_featured_program_tabs.active {
  margin-top: -1px;
}

.new_featured_program_tabs .new_program_faq_accordion_header h3 {
  font-size: 20px;
  color: rgba(2, 76, 135, 1);
}

.new_featured_program_tabs .accordion-item {
  border: 0;
  border-bottom: 2px solid rgba(234, 234, 234, 1);
}

.new_featured_program_tabs .accordion-item:last-child {
  border-bottom: 0;
}

.new_featured_program_tabs .accordion-button {
  padding: 10px;
}

.new_featured_program_tabs .accordion-button:not(.collapsed) {
  background-color: white;
  box-shadow: none;
  border: 0;
  color: #024c87;
}

.new_featured_program_tabs .accordion-button.collapsed {
  box-shadow: none;
}
.new_featured_program_tabs .new_program_faq_accordion_body {
  padding: 10px;
}

.new_featured_program_tabs .new_program_faq_accordion_body h4 {
  font-size: 20px;
  color: rgba(2, 76, 135, 1);
}

/* .new_featured_program_tabs .new_featured_program_tools {
  padding: 13px 4px;
} */

.new_featured_program_tabs .new_featured_program_tools {
  padding: 30px 0px 30px 0px;
  height: 0;
}

/* .new_featured_program_tabs .new_featured_program_tools .new_tools {
  width: 80%;
} */

.new_featured_program_tabs .new_featured_program_duration {
  padding: 11px;
  height: 0;
  margin-top: 0;
}

.new_featured_program_duration h3 {
  color: rgba(2, 76, 135, 1);
  font-size: 20px;
  font-weight: 500;
}

.new_featured_program_duration p {
  color: rgba(156, 156, 156, 1);
  font-weight: 400;
  font-size: 16px;
  margin: 0;
}

.new_featured_program_duration .new_featured_program_duration_month {
  border-bottom: 1px solid rgba(234, 234, 234, 1);
  border-width: 80%;
  padding-bottom: 25px;
}

.new_featured_program_duration .new_featured_program_duration_dates {
  border-bottom: 1px solid rgba(234, 234, 234, 1);
  border-width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-bottom: 25px;
}

.new_featured_program_duration
  .new_featured_program_duration_dates
  .new_featured_program_duration_dates_right {
  padding-right: 50px;
}

.new_featured_program_duration .new_featured_program_duration_batch {
  /* border-bottom: 1px solid rgba(234, 234, 234, 1); */
  border-width: 80%;
  margin-top: 20px;
  padding-bottom: 15px;
}

.new_featured_program_tabs .new_featured_program_mentors {
  padding: 0px 0px;
}

.new_featured_program_mentors h2 {
  font-size: 24px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
}

.new_featured_program_mentors .new_featured_program_mentors_content {
  margin-inline: 100px 20px;
  box-shadow: 0px 0px 4px 0px rgba(179, 179, 179, 1);
  border-radius: 12px;
  background-color: white;
  margin-top: 12px;
  position: relative;
  padding: 22px 20px 20px 100px;
}

.new_featured_program_mentors_content h3 {
  color: rgba(51, 51, 51, 1);
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}

.new_featured_program_mentors_content span {
  color: rgba(179, 179, 179, 1);
  font-size: 16px;
  font-weight: 400;
  display: block;
  margin-bottom: 10px;
}

.new_featured_program_mentors_content p {
  color: rgba(92, 92, 92, 1);
  font-size: 14px;
  font-weight: 400;
  height: 150px;
  overflow-y: auto;
}

.new_featured_program_mentors_content
  .new_featured_program_mentors_content_linkedin {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: end;
}

.new_featured_program_mentors_content
  .new_featured_program_mentors_content_linkedin
  img {
  width: 30px;
  height: 30px;
  margin-left: auto;
}

.new_featured_program_mentors_content_linkedin p {
  color: rgba(179, 179, 179, 1);
  font-weight: 400;
  font-size: 14px;
}

.new_featured_program_mentors_content .new_featured_program_mentors_img {
  position: absolute;
  top: 50px;
  border: 1px solid #dadada;
  object-fit: cover;
  object-position: top;
  /* width: 100px; */
  max-width: 180px;
  height: 200px;
  left: -100px;
}

.new_featured_program_tabs .new_featured_program_projects {
  display: flex;
  /* align-items: center; */
  /* gap: 90px; */
  flex-wrap: wrap;
  row-gap: 30px;
  column-gap: 30px;
  /* justify-content: space-between; */
}

.new_featured_program_projects .new_featured_program_projects_box {
  background-color: rgba(246, 246, 246, 1);
  width: 208px;
  position: relative;
  margin-left: 38px;
  font-size: 13px;
  border-radius: 0px 8px 8px 0px;
  padding: 10px 10px 10px 10px;
  height: 160px;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
}

.new_featured_program_projects .new_featured_program_projects_box h3 {
  font-size: 16px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 9px;
}

.new_featured_program_projects .new_featured_program_projects_box p {
  font-size: 12px;
  margin: 0;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}

.new_featured_program_projects .new_featured_program_project_box_skills {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.new_featured_program_project_box_skills
  .new_featured_program_project_box_skills_content {
  width: fit-content;
  padding: 8px 12px;
  border-radius: 50px;
  background-color: rgba(255, 220, 215, 1);
}

.new_featured_program_project_box_skills
  .new_featured_program_project_box_skills_content:nth-child(2) {
  background-color: #e5f2fc;
}
.new_featured_program_project_box_skills
  .new_featured_program_project_box_skills_content:nth-child(3) {
  background-color: #e5e7ff;
}

.new_featured_program_projects .new_featured_program_projects_box::before {
  content: "";
  width: 10px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -1px;
  -webkit-box-shadow: inset 47px -107px 53px -50px rgba(NaN, 186, NaN, 0.13);
  -moz-box-shadow: inset 47px -107px 53px -50px rgba(NaN, 186, NaN, 0.13);
  box-shadow: inset 47px -107px 53px -50px rgba(NaN, 186, NaN, 0.13);
  box-shadow: 20px 0 10px -20px rgba(0, 0, 0, 0.45) inset;
  border-radius: 0 0 2px 2px;
}

.new_featured_program_projects_box p {
  font-size: 12px;
  color: rgba(80, 80, 80, 1);
}

.new_featured_program_projects_box .new_featured_program_projects_box_no {
  position: absolute;
  top: 50%;
  left: -40px;
  transform: translateY(-50%);
  background: rgba(2, 76, 135, 1);
  width: 39px;
  height: 65px;
  border-radius: 50px 0 0 50px;
  display: flex;
  align-items: end;
  justify-content: end;
  /* z-index: -1; */
  color: white;
  font-size: 25px;
  font-weight: bold;
  padding: 6px;
}

.new_featured_program_carousel {
  position: relative;
}

.new_featured_program_carousel .owl-nav .owl-next button,
.new_featured_program_carousel .owl-nav .owl-prev button {
  /* border: 1px solid #024c87; */
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #024c87; */
  border-radius: 50%;
  background: #fff;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
  border: none;
  padding: 7px;
}

.new_featured_program_carousel .owl-nav .owl-next:hover {
  background: transparent;
}
.new_featured_program_carousel .owl-nav .owl-prev:hover {
  background: transparent;
}

.new_featured_program_carousel .owl-nav .owl-next,
.new_featured_program_carousel .owl-nav .owl-prev {
  top: 45%;
  transform: translateY(-50%);
  position: absolute;
}

.new_featured_program_carousel .owl-nav .owl-next {
  right: -20px;
}
.new_featured_program_carousel .owl-nav .owl-prev {
  left: -20px;
}

.new_featured_program_carousel .owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #024c87;
  width: 45px;
}

.new_featured_program_carousel .owl-dots .owl-dot.active span {
  background: #024c87;
  width: 45px;
  position: relative;
}

.new_featured_program_item_data_right_buttons button.active::before {
  content: "";
  width: 55px;
  height: 40px;
  position: absolute;
  bottom: 0;
  right: 100%;
  border-bottom-right-radius: 25px;
  box-shadow: 22px 0 0 0;
  color: white;
}

.new_featured_program_item_data_right_buttons button.active::after {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 0;
  left: 100%;
  border-bottom-left-radius: 25px;
  box-shadow: -22px 0 0 0;
  color: white;
}

.new_featured_program_item_data_right_buttons
  button:first-child.active::before {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: -17px;
  right: 100%;
  border-bottom-right-radius: 0px;
  box-shadow: 22px 0 0 0;
  color: white;
}
.new_featured_program_item_data_right_buttons button:last-child.active::after {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: -17px;
  left: 100%;
  border-bottom-left-radius: 0px;
  box-shadow: -22px 0 0 0;
  color: white;
}

/* .new_featured_mobile_program_container{

} */

.new_featured_mobile_program_container {
  display: none;
}

.new_featured_mobile_program_container .new_featured_mobile_program_item {
  box-shadow: 0px 0px 4px 0px rgba(217, 217, 217, 1);
  color: rgba(26, 36, 52, 1);
  padding: 16px 14px;
  border-radius: 12px;
  width: 95%;
}

.new_featured_mobile_program_item .new_featured_mobile_program_item_img {
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.new_featured_mobile_program_item h2 {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  margin-top: 14px;
}

.new_featured_mobile_program_item .homeprogram_mobile_card_list img {
  width: 16px;
}

.new_featured_mobile_program_item .homeprogram_mobile_card_list {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  /* flex-direction: column; */
  gap: 15px;
  color: rgba(140, 140, 140, 1);
  font-weight: 400;
}

.new_featured_mobile_program_item .new_featured_mobile_program_batch {
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
}

.new_featured_mobile_program_batch .batch_div h3 {
  font-size: 16px;
}

.new_featured_mobile_program_batch .batch_div p {
  color: rgba(156, 156, 156, 1);
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: 400;
}

.new_featured_mobile_program_batch .batch_div .month {
  display: flex;
  justify-content: end;
}

.new_featured_mobile_program_item .new_featured_mobile_program_enroll {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.new_featured_mobile_program_enroll .price {
  display: flex;
  align-items: center;
  gap: 6px;
}

.new_featured_mobile_program_enroll .rating {
  display: flex;
  align-items: center;
  gap: 4px;
}

.new_featured_mobile_program_enroll .rating .starRating {
  margin-bottom: 3px;
}

.new_featured_mobile_program_enroll h4 {
  color: rgba(26, 36, 52, 1);
  font-size: 14px;
  font-weight: 600;
}

.new_featured_mobile_program_enroll .rating img {
  width: 100px;
  margin-bottom: 4px;
  /* height: 100px; */
}

.new_featured_mobile_program_enroll h4 del {
  color: rgba(140, 140, 140, 1);
  font-size: 10px;
  font-weight: 400;
}

.new_featured_mobile_program_enroll button {
  border-radius: 4px;
  border: 0;
  outline: 0;
  color: white;
  width: 77px;
  height: 30px;
  background-color: rgba(2, 76, 135, 1);
}

.new_featured_mobile_program_container .owl-carousel .owl-stage-outer {
  padding: 30px 10px 4px 10px;
}

.new_featured_mobile_program_carousel {
  position: relative;
}

.new_featured_mobile_program_carousel .owl-nav .owl-next button,
.new_featured_mobile_program_carousel .owl-nav .owl-prev button {
  /* border: 1px solid #024c87; */
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #024c87; */
  border-radius: 50%;
  background: #fff;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
  border: none;
  padding: 7px;
}

.new_featured_mobile_program_carousel .owl-nav .owl-next:hover {
  background: transparent;
}
.new_featured_mobile_program_carousel .owl-nav .owl-prev:hover {
  background: transparent;
}

.new_featured_mobile_program_carousel .owl-nav .owl-next,
.new_featured_mobile_program_carousel .owl-nav .owl-prev {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}

.new_featured_mobile_program_carousel .owl-nav .owl-next {
  right: -10px;
}
.new_featured_mobile_program_carousel .owl-nav .owl-prev {
  left: -10px;
}

.new_featured_mobile_program_carousel .owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #024c87;
  width: 45px;
}

.new_featured_mobile_program_carousel
  .owl-theme
  .owl-dots
  .owl-dot.active
  span {
  background: #024c87;
  width: 45px;
  position: relative;
}

.enroll_stepone_container .enroll_stepone_heading span {
  display: flex;
  align-items: center;
  gap: 16px;
}

.enroll_stepone_container .enroll_stepone_heading span .enroll_step_backbtn {
  font-size: 16px;
  cursor: pointer;
}

.enroll_stepone_container .enroll_stepone_btn .enroll_stepone_next {
  margin-left: auto;
}

.enroll_stepthree_paynow_btn {
  position: absolute;
  bottom: 30px;
  right: 20px;
}

.certificate_preview_info_container .certificate_preview_info_header {
  padding-block: 30px;
}
.certificate_preview_info_container .certificate_preview_info_header h4 {
  font-size: 20px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
}
.certificate_preview_info_container .certificate_preview_info_header h2 {
  color: rgba(51, 51, 51, 1);
  font-size: 30px;
  font-weight: 500;
}

.certificate_preview_info_item .certificate_preview_info_item_card {
  box-shadow: 0px 0px 6.2px 0px rgba(217, 217, 217, 1);
  border-radius: 12px;
  padding: 20px;
  display: flex;
  gap: 20px;
}

.certificate_preview_info_item_card .certificate_preview_info_item_card_img {
  min-width: 103px;
  height: 103px;
  border-radius: 50%;
  background-color: rgba(227, 227, 227, 1);
}

.certificate_preview_info_item_card
  .certificate_preview_info_item_card_content
  h4 {
  font-size: 20px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
}
.certificate_preview_info_item_card
  .certificate_preview_info_item_card_content
  h3 {
  font-size: 20px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
}
.certificate_preview_info_item_card
  .certificate_preview_info_item_card_content
  p {
  color: rgba(80, 80, 80, 1);
  font-size: 16px;
  font-weight: 400;
}

.certificate_preview_info_item .certificate_preview_info_enroll {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.certificate_preview_info_enroll div h2 {
  color: rgba(51, 51, 51, 1);
  font-size: 30px;
  margin: 0;
  font-weight: 500;
  text-decoration: underline;
}

.certificate_preview_info_enroll div p {
  color: rgba(51, 51, 51, 1);
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: 500;
}

.certificate_preview_info_enroll div span {
  display: flex;
  align-items: center;
}

.certificate_preview_info_enroll div span img {
  width: 100px;
}

.certificate_preview_info_enroll div span p {
  font-size: 14px;
  margin: 0;
  font-weight: 500;
}

.certificate_preview_info_enroll button {
  width: 170px;
  background-color: rgba(2, 76, 135, 1);
  height: 54px;
  border: 0;
  font-size: 16px;
  font-weight: 500;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: white;
  border-radius: 12px;
}

/*  */
.certificate_share_modal {
  padding-block: 10px;
}

.certificate_share_modal h2 {
  color: rgba(51, 51, 51, 1);
  font-size: 32px;
  font-weight: 700;
}
.certificate_share_modal p {
  font-size: 19px;
  font-weight: 400;
  color: rgba(92, 92, 92, 1);
}

.certificate_share_modal .certificate_share_modal_social-media {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
}

.certificate_share_modal .certificate_share_modal_social-media div {
  display: flex;
  flex-direction: column;
  color: rgba(92, 92, 92, 1);
  font-size: 18px;
  font-weight: 400;
  align-items: center;
  gap: 12px;
}

.certificate_share_modal .certificate_share_modal_social-media img {
  width: 70px;
}

.certificate_share_modal .certificate_copy_input {
  position: relative;
  margin-top: 30px;
}

.certificate_copy_input input {
  width: 100%;
  border: 1px solid rgba(140, 140, 140, 1);
  border-radius: 8px;
  padding: 16px 20px;
  outline: 0;
  font-size: 18px;
  font-weight: 400;
}

.certificate_copy_input label {
  position: absolute;
  color: rgba(0, 102, 216, 1);
  font-weight: 700;
  top: 13px;
  right: 10px;
  font-size: 22px;
}

.assignment_resourses_container .assignment_resource_icon {
  padding-inline: 25px;
}

.assignment_resourses_container
  .assignment_resource_icon
  .assignment_resource_scale
  .icon_scale {
  scale: 1.7;
}

/*  */

.dashboard_new_mobile_footer {
  display: none;
}
.dashboard_new_mobile_nav {
  display: none;
}

.new_featured_program_item_data_right {
  height: 456px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 3px 0px 4.6px -4px rgba(231, 231, 231, 1),
    -3px 0px 4.6px -4px rgba(231, 231, 231, 1),
    0px 3px 4.6px -4px rgba(231, 231, 231, 1);
}

.new_featured_program_item_data {
  height: 456px;
  border-radius: 22px;
  overflow: hidden;
  box-shadow: 3px 0px 4.6px -4px rgba(231, 231, 231, 1),
    -3px 0px 4.6px -4px rgba(231, 231, 231, 1),
    0px 3px 4.6px -4px rgba(231, 231, 231, 1);
}

/*  */
.home_gradient_bg {
  background: linear-gradient(0deg, #ffffff 2.8%, #e5f2fc 48.3%, #ffffff 100%);
  background-size: 100% 106%;
  background-repeat: no-repeat;
}

.new_featured_program_tabs .new_program_faq_accordion_body ul li {
  margin-bottom: 10px;
}

.new_featured_program_tabs .new_program_faq_accordion_body ul li span {
  margin-right: 10px;
}

.new_featured_program_tabs .new_program_faq_accordion_body ul {
  padding-left: 0;
  list-style: none;
}

.new_featured_program_tabs .new_program_faq_accordion_body {
  padding-inline: 15px;
  padding-block: 0;
}

.dashboard_mobile_profile_container {
  margin-top: 50px;
  /* height: 70vh;
  overflow-y: auto; */
}

.dashboard_mobile_profile_container .dashboard_mobile_profile_div {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  flex-direction: column;
  text-align: center;
}

.dashboard_mobile_profile_div .profile_div {
  width: 82px;
  height: 82px;
  margin-bottom: 15px;
}

.dashboard_mobile_profile_div .profile_div img {
  width: 72px;
  height: 72px;
  object-fit: cover;
  border-radius: 50%;
}

.dashboard_mobile_profile_div h2 {
  color: rgba(92, 92, 92, 1);
  font-weight: 500;
  font-size: 20px;
  text-transform: capitalize;
  margin: 0;
}

.dashboard_mobile_profile_div p {
  color: rgba(179, 179, 179, 1);
  font-weight: 400;
  text-transform: capitalize;
  font-size: 12px;
}

.dashboard_mobile_profile_container .dashboard_mobile_profile_blue_bg {
  background-color: rgba(240, 248, 255, 1);
  width: 100%;
  height: 50vh;
  margin-top: 50px;
  position: relative;
  border-radius: 20px 20px 0 0;
  position: relative;
}

.dashboard_mobile_profile_blue_bg .dashboard_mobile_profile_list {
  position: absolute;
  list-style: none;
  border-radius: 8px;
  padding: 16px;
  top: -40px;
  /*  */
  /*  */
  background-color: white;
  box-shadow: 0px 0px 4px 0px rgba(217, 217, 217, 1);
  width: 90%;
  left: 5%;
  transform: (-50%);
}

.dashboard_mobile_profile_list li {
  color: rgba(51, 51, 51, 1);
  display: flex;
  align-items: center;
  font-size: 17px;
  gap: 10px;
  font-weight: 500;
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.dashboard_mobile_profile_list .dashboard_mobile_profile_last_list {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.dashboard_mobile_profile_list .right_icon {
  margin-left: auto;
  font-size: 20px;
}

.dashboard_mobile_profile_edit_form form {
  display: flex;
  flex-direction: column;
}

.dashboard_mobile_profile_edit_form form .dashboard_mobile_profile_input_div {
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  position: relative;
  margin-bottom: 18px;
}

.dashboard_mobile_profile_input_div .down_icon {
  position: absolute;
  right: 10px;
  color: rgba(168, 168, 168, 1);
  top: 36px;
  font-size: 20px;
}

.dashboard_mobile_profile_input_div label {
  font-size: 16px;
  color: rgba(31, 31, 31, 1);
  font-weight: 500;
}

.dashboard_mobile_profile_input_div input {
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 4px;
  height: 45px;
  padding: 16px 20px;
  color: rgba(217, 217, 217, 1);
  font-size: 14px;
  font-weight: 400;
  outline: none;
}

.dashboard_mobile_profile_input_div input::placeholder {
  color: rgba(217, 217, 217, 1);
}

.dashboard_mobile_profile_input_div select {
  padding-block: 0;
  appearance: none;
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 4px;
  height: 45px;
  padding: 10px 20px;
  color: rgba(217, 217, 217, 1);
  font-size: 14px;
  font-weight: 400;
  outline: none;
}

.dashboard_mobile_profile_edit_form form button {
  background-color: rgba(51, 51, 51, 1);
  color: white;
  border-radius: 8px;
  width: 73px;
  height: 37px;
  border: 0;
  font-size: 16px;
  font-weight: 400;
  outline: 0;
  margin-left: auto;
}

.dashboard_mobile_change_password_container {
  margin-top: 35px;
  color: black;
}

.dashboard_mobile_change_password_container form {
  display: flex;
  flex-direction: column;
}

.dashboard_mobile_change_password_container
  .dashboard_mobile_change_pass_input {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 18px;
}

.dashboard_mobile_change_pass_input label {
  font-size: 16px;
  color: rgba(31, 31, 31, 1);
  font-weight: 500;
}

.dashboard_mobile_change_pass_input input {
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 4px;
  height: 45px;
  padding: 16px 20px;
  color: rgba(217, 217, 217, 1);
  font-size: 14px;
  font-weight: 400;
  outline: none;
}

.dashboard_mobile_change_password_container form button {
  background-color: rgba(51, 51, 51, 1);
  color: white;
  border-radius: 8px;
  width: 173px;
  height: 37px;
  border: 0;
  font-size: 16px;
  font-weight: 400;
  outline: 0;
  margin-left: auto;
}

.dashboard_mobile_profile_input_div .mobile_phone_input {
  display: flex;
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 4px;
}

.mobile_phone_input .react-tel-input {
  width: 29%;
}

.mobile_phone_input input {
  width: 74%;
}

.mobile_phone_input .react-tel-input .form-control {
  width: 100%;
  border: 0;
  font-size: 14px;
}

.new_featured_program_item {
  padding: 3px;
}

/* .blog_dashboard_mobile_header_rightside_circle img */
/* .dashboard_notification_container */

.new_featured_program_carousel .owl-nav .disabled {
  opacity: 0;
}

/*  */

.dashboard_schedule_mobile_btn {
  display: none;
}

/* .student_university_content_box img */
.dashboard_ticket_container {
  color: black;
  padding: 30px 20px;
  padding-bottom: 0;
}

.dashboard_ticket_container .dashboard_ticket_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard_ticket_header .dashboard_ticket_header_right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.dashboard_ticket_header h2 {
  font-size: 28px;
  color: rgba(59, 59, 59, 1);
  font-weight: 500;
}

.dashboard_ticket_header_right .dashboard_ticket_header_input {
  width: 358px;
  background-color: rgba(2, 76, 135, 1);
  height: 48px;
  color: white;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 20px;
  border-radius: 6px;
}

.dashboard_ticket_header_input button {
  background: transparent;
  border: 0;
  outline: 0;
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboard_ticket_header_input .dashboard_ticket_header_input_div {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
}

.dashboard_ticket_header_input_div input {
  height: 100%;
  padding-left: 30px;
  /* border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; */
  outline: 0;
  border: 0;
}

.dashboard_ticket_header_input_div .search_icon {
  position: absolute;
  left: 8px;
  color: rgba(140, 140, 140, 1);
  font-size: 18px;
  top: 16px;
}

.dashboard_ticket_header .dashboard_ticket_header_notification_part {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboard_ticket_header_notification_part .profile_icon {
  width: 28px;
  height: 28px;
}
.dashboard_ticket_header_notification_part .help_icon {
  width: 28px;
  height: 34px;
}

.dashboard_ticket_header_notification_part .notification_icon {
  position: relative;
}

.dashboard_ticket_header_notification_part .notification_icon .icon {
  width: 28px;
  height: 30px;
}

.dashboard_ticket_header_notification_part .notification_icon span {
  height: 11px;
  background-color: red;
  position: absolute;
  right: 2px;
  top: 4px;
  width: 11px;
  border-radius: 50%;
}

.dashboard_ticket_container .dashboard_ticket_chats_container {
  box-shadow: 0px 0px 7px 0px rgba(227, 227, 227, 1);
  border-radius: 5px;
  background-color: white;
  width: 100%;
  margin-top: 4px;
  display: flex;
}

.dashboard_ticket_chats_container .dashboard_ticket_chats_list {
  border: 1px solid rgba(213, 217, 221, 1);
  width: 430px;
}

.dashboard_ticket_chats_container .dashboard_ticket_chats_header {
  border-bottom: 1px solid rgba(213, 217, 221, 1);
  padding: 15px 30px;
}

.dashboard_ticket_chats_header .dashboard_ticket_chats_input {
  width: 100%;
  position: relative;
}

.dashboard_ticket_chats_input input {
  width: 100%;
  border: 1px solid rgba(217, 217, 217, 1);
  border-radius: 6px;
  padding: 8px 30px;
  outline: none;
}

.dashboard_ticket_chats_input .search_icon {
  position: absolute;
  left: 9px;
  font-size: 18px;
  color: rgba(154, 163, 171, 1);
  top: 10px;
}

.dashboard_ticket_chats_list .dashboard_ticket_chats_listitem {
  border-bottom: 1px solid rgba(211, 216, 221, 1);
  padding: 15px 30px;
  display: flex;
  gap: 20px;
  position: relative;
}

.dashboard_ticket_chats_list .dashboard_ticket_chats_notification {
  position: absolute;
  right: 14px;
  top: 33%;
  background: #024c87;
  height: 20px;
  min-width: 20px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard_ticket_chats_list .dashboard_ticket_chats_listitem.active {
  background-color: rgba(243, 245, 250, 1);
}

.dashboard_ticket_chats_listitem .dashboard_ticket_chats_listitem_detail span {
  display: flex;
  align-items: center;
  gap: 12px;
}

.dashboard_ticket_chats_listitem_detail
  span
  .dashboard_ticket_chats_listitem_detail_respond {
  border-radius: 1px;
  width: fit-content;
  padding-inline: 4px;
  margin-bottom: 7px;
  font-size: 11px;
  font-weight: 400;
  color: rgba(44, 94, 198, 1);
  background-color: rgba(204, 218, 249, 1);
  border: 1px solid rgba(179, 192, 221, 1);
}

.dashboard_ticket_chats_listitem .dashboard_ticket_chats_listitem_profile_img {
  width: 38px;
  height: 38px;
  font-size: 17px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: rgba(2, 76, 135, 1);
}

.dashboard_ticket_chats_listitem_detail
  .dashboard_ticket_chats_listitem_detail_overdue {
  background-color: rgba(255, 236, 240, 1);
  border: 1px solid rgba(254, 213, 219, 1);
  font-size: 11px;
  font-weight: 400;
  color: rgba(200, 32, 36, 1);
  border-radius: 1px;
  width: fit-content;
  padding-inline: 4px;
  margin-bottom: 7px;
}

.dashboard_ticket_chats_listitem_detail h4 {
  font-size: 13px;
  color: rgba(3, 24, 73, 1);
  font-weight: 400;
  margin-bottom: 0;
  display: flex;
  gap: 20px;
  margin-bottom: 5px;
}

.dashboard_ticket_chats_listitem_detail h4 span {
  color: rgba(2, 76, 135, 1);
  font-size: 12px;
  font-weight: 400;
}

.dashboard_ticket_chats_listitem .dashboard_ticket_chats_listitem_author_name {
  display: flex;
  align-items: center;
  font-weight: 400;
  color: rgba(154, 163, 171, 1);
  font-size: 12px;
}

.dashboard_ticket_chats_listitem_author_name .dot {
  width: 4px;
  height: 4px;
  background-color: rgba(154, 163, 171, 1);
  margin-inline: 7px;
  border-radius: 5px;
}

.dashboard_ticket_chats_container .dashboard_ticket_chats_box {
  width: 70%;
  border-block: 1px solid rgba(211, 216, 221, 1);
}

.dashboard_ticket_chats_box .dashboard_ticket_chats_box_header {
  border-bottom: 1px solid rgba(211, 216, 221, 1);
  padding: 18px 30px;
  display: flex;
  align-items: center;
  gap: 20px;
  color: rgba(3, 24, 73, 1);
  font-weight: 400;
}

.dashboard_ticket_chats_box_header .dashboard_ticket_chats_box_profile_img {
  width: 33px;
  height: 33px;
  background-color: rgba(2, 76, 135, 1);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: 400;
}

.dashboard_ticket_chats_box .dashboard_ticket_chats_box_message_container {
  padding: 15px 30px;
}

.dashboard_ticket_chats_box_message_container
  .dashboard_ticket_chats_box_receiver {
  display: flex;
  align-items: center;
  gap: 20px;
}

.dashboard_ticket_chats_box_receiver
  .dashboard_ticket_chats_box_receiver_profile {
  width: 33px;
  height: 33px;
  background-color: rgba(2, 76, 135, 1);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: 400;
  border-radius: 2px;
}

.dashboard_ticket_chats_box_receiver
  .dashboard_ticket_chats_box_receiver_message {
  width: fit-content;
  height: 33px;
  padding-inline: 10px;
  background-color: rgba(2, 76, 135, 1);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 400;
  border-radius: 2px;
}

.dashboard_ticket_chats_box_message_container p {
  /* width: fit-content; */
  /* height: 33px; */
  padding-inline: 10px;
  background-color: rgba(2, 76, 135, 1);
  color: white;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  font-size: 13px;
  font-weight: 400;
  border-radius: 2px;
  margin-top: 10px;
  /* width: 90%; */
  /* margin-left: 52px; */
  padding: 10px 20px;
  margin-bottom: 10px;
  width: fit-content;
  flex-direction: column;
}

.dashboard_ticket_chats_box_message_container span {
  color: rgba(3, 24, 73, 1);
  font-size: 12px;
  font-weight: 400;
  /* margin-left: auto; */
  display: flex;
  justify-content: end;
}

.dashboard_ticket_chats_box_message_container
  .dashboard_ticket_chats_box_giver {
  justify-content: end;
  flex-direction: row-reverse;
  margin-top: 20px;
}

.dashboard_ticket_chats_box_message_container
  .dashboard_ticket_chats_box_giver_reply {
  background-color: rgba(243, 245, 250, 1);
  color: rgba(3, 24, 73, 1);
  /* margin-right: 52px; */
  margin-left: auto;
}

.dashboard_ticket_chats_box_giver .dashboard_ticket_chats_box_receiver_profile {
  background-color: rgba(243, 245, 250, 1);
  color: rgba(3, 24, 73, 1);
}

.dashboard_ticket_chats_box_giver .dashboard_ticket_chats_box_receiver_message {
  background-color: rgba(243, 245, 250, 1);
  color: rgba(3, 24, 73, 1);
}

.dashboard_ticket_chats_box_message_container
  .dashboard_ticket_chat_box_textarea {
  width: 100%;
  margin-top: 10px;
  position: relative;
}

.dashboard_ticket_chat_box_textarea textarea {
  width: 100%;
  border: 1px solid rgba(211, 216, 221, 1);
  border-radius: 5px;
  outline: none;
  padding: 5px;
  height: 120px;
  text-align: left;
  resize: none;
}

.dashboard_ticket_chat_box_textarea button {
  position: absolute;
  right: 10px;
  bottom: 15px;
  background-color: rgba(2, 76, 135, 1);
  color: white;
  border-radius: 4px;
  border: none;
  outline: none;
  width: 70px;
  height: 30px;
  cursor: pointer;
}

.industry_professional_container h2 {
  font-size: 48px;
  font-weight: 700;
  color: rgba(2, 76, 135, 1);
  text-align: center;
}

.industry_professional_container .industry_professional_main_div {
  margin-top: 90px;
  background-color: rgba(246, 249, 252, 1);
  padding-block: 45px;
}

.industry_professional_main_div .industry_professional_card {
  position: relative;
}

.industry_professional_main_div .industry_professional_card img {
  width: 100%;

  height: 100%;
}

.industry_professional_main_div .industry_professional_content h3 {
  color: rgba(92, 92, 92, 1);
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 15px;
}

.industry_professional_main_div .industry_professional_content p {
  font-size: 24px;
  font-weight: 500;
  color: rgba(92, 92, 92, 1);
}

.industry_professional_card .industry_professional_video_card {
  position: absolute;
  bottom: 15px;
  left: 5%;
  background-color: white;
  width: 90%;
  border-radius: 8px;
  padding: 13px 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.industry_professional_card .industry_professional_video_card img {
  width: 35px;
  height: 35px;
}

.industry_professional_video_card h3 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
  color: rgba(80, 80, 80, 1);
}

.industry_professional_video_card h4 {
  color: rgba(80, 80, 80, 1);
  font-size: 18px;
  font-weight: 400;
}

.industry_professional_content .industry_professional_three_cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.industry_professional_three_cards .industry_professional_rating {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 31%;
  border-radius: 8px;
}

.industry_professional_three_cards .industry_professional_rating p {
  color: rgba(2, 76, 135, 1);
  font-size: 28px;
  margin: 0;
  font-weight: 600;
}

.industry_professional_three_cards .industry_professional_program {
  border-radius: 8px;
  width: 31%;
  height: 80px;
  background-color: white;
  font-size: 28px;
  color: rgba(2, 76, 135, 1);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.industry_professional_three_cards .industry_professional_company {
  width: 31%;
  height: 80px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.industry_professional_three_cards .industry_professional_company img {
  width: 160px;
  height: 60px;
}

.dashboard_ticket_chats_box_message_container p b {
  margin-top: 2px;
  width: 100%;
  display: block;
  text-align: right;
}

/* start-end */

.dashboard_ticket_chats_box_message_container .dashboard_ticket_chatlist {
  height: 49vh;
  overflow-y: auto;
}

.dashboard_ticket_chats_container .dashboard_ticket_chats_list_items {
  height: 72vh;
  overflow-y: auto;
}

.scrollbar-hide::-webkit-scrollbar {
  width: 0;
}

.ticket_chat_notallowed_divider {
  height: 2px;
  width: 100%;
  background-color: #c0c0c0;
  margin-top: 14px;
}

.dashboard_ticket_chats_box .ticket_chat_notallowed {
  font-size: 16px;
  color: #c0c0c0;
  text-align: center;
  margin-top: 20px;
  font-weight: 400;
  margin-top: 14px;
}

.dashboard_ticket_chats_list .dashboard_ticket_chats_no_found {
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
}

.dashboard_helpcenter_box .dashboard_helpcenter_title {
  background-color: rgba(2, 76, 135, 1);
  color: white;
  border-radius: 12px 12px 0px 0px;
  display: flex;
  align-items: center;
  height: 64px;
  padding-inline: 30px;
  justify-content: space-between;
  font-size: 30px;
}

.program_price_container {
  border: 2px solid #024c87;
  margin-bottom: 20px;
}

.program_price_container .program_price_left {
  background-color: #024c87;
  padding: 30px;
}

.program_price_container .program_price_left h4 {
  background-color: #f1f1f1;
  padding: 8px 28px;
  color: #2e2e2e;
  font-weight: 500;
  font-size: 20px;
  width: fit-content;
  border-radius: 5px;
  margin-bottom: 20px;
}

.program_price_container .program_price_left h3 {
  font-size: 20px;
  font-weight: 400;
  color: white;
  margin: 0;
  padding-bottom: 20px;
}

.program_price_container .program_price_left h2 {
  font-size: 30px;
  font-weight: 600;
  padding-block: 25px;
  border-block: 1px solid white;
}

.program_price_container .program_price_left ul {
  list-style: none;
  padding: 0;
  padding-block: 25px;
}

.program_price_left ul li {
  display: flex;
  gap: 6px;
  align-items: center;
  margin-bottom: 3px;
  font-size: 14px;
  font-weight: 400;
}

.program_price_left ul li span {
  background-color: white;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  font-size: 6px;
  font-weight: blod;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccd2e3;
  color: #024c87;
}

.program_price_left .program_price_left_buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 19px;
}

.program_price_left_buttons button {
  border: 0;
  border-radius: 8px;
  padding: 8px 0;
  outline: 0;
  font-size: 16px;
  width: 90%;
  font-weight: 500;
  color: #024c87;
}

.program_price_left_buttons p {
  font-weight: 400;
  font-size: 18px;
  text-decoration: underline;
}

.program_price_container .program_price_right {
  padding: 30px;
}

.program_price_container .program_price_right h4 {
  color: #7d8185;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
}

.program_price_container .program_price_right h3 {
  color: #292d32;
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  padding-bottom: 47px;
}

.program_price_container .program_price_right h2 {
  font-size: 30px;
  font-weight: 600;
  color: #2e2e2e;
  margin: 0;
  /* padding-block: 25px; */
  /* border-block: 1px solid #024c87; */
}

.program_price_right .program_price_right_install {
  margin-top: 20px;
  margin-bottom: 44px;
}

.program_price_right .program_price_right_install h4 {
  font-size: 500;
  color: #2e2e2e;
  margin-bottom: 15px;
}

.program_price_right .program_price_right_install p {
  color: #2e2e2e;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 0;
}

.program_price_right .program_price_right_buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 19px;
}

.program_price_right_buttons button {
  border: 0;
  border-radius: 8px;
  background-color: #024c87;
  padding: 8px 0;
  outline: 0;
  font-size: 16px;
  width: 90%;
  font-weight: 500;
  color: white;
}

.program_price_right_buttons p {
  font-weight: 500;
  font-size: 18px;
  text-decoration: underline;
  color: #024c87;
  cursor: pointer;
}

.program_price_modal_container h2 {
  font-size: 20px;
  color: #0a090b;
  font-weight: 500;
  margin-bottom: 40px;
}

.program_price_modal_container label {
  font-size: 14px;
  color: #2d2b32;
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
}

.program_price_modal_container .program_price_modal_container_input {
  border-radius: 8px;
  box-shadow: 0px 1.5px 1px -1px #0a090b12;
  border: 1px solid #e6e6e6;
  outline: none;
  padding: 0px 12px;
  height: 40px;
  font-weight: 400;
  color: #0a090b;
  font-size: 14px;
  width: 100%;
}

.program_price_modal_container .program_price_modal_two_row {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}

.program_price_modal_two_row
  .program_price_modal_select
  .program_price_modal_select_div {
  display: flex;
  border-radius: 8px;
  box-shadow: 0px 1.5px 1px -1px #0a090b12;
  border: 1px solid #e6e6e6;
  outline: none;
  padding: 0px 12px;
  font-weight: 400;
  color: #0a090b;
  font-size: 14px;
  width: 100%;
  height: 40px;
  overflow: hidden;
}

.program_price_modal_two_row .program_price_modal_select {
  width: 48%;
}
.program_price_modal_two_row .program_price_modal_install {
  width: 48%;
}

.program_price_modal_select .program_price_modal_select_div input,
select {
  border: 0;
  outline: 0;
  color: black;
}

.program_price_modal_select_div select {
  border-right: 1px solid #e6e6e6;
  appearance: none;
  width: 60px;
}

.program_price_modal_select_div input {
  padding-left: 20px;
}

.program_price_modal_install .program_price_modal_install_div {
  display: flex;
  border-radius: 8px;
  box-shadow: 0px 1.5px 1px -1px #0a090b12;
  border: 1px solid #e6e6e6;
  outline: none;
  padding: 0px 12px;
  font-weight: 400;
  color: #0a090b;
  font-size: 14px;
  width: 100%;
  height: 40px;
  overflow: hidden;
}

.program_price_modal_install_div input {
  border: none;
  outline: 0;
  width: 65%;
}

.program_price_modal_install_div span {
  border-left: 1px solid #e6e6e6;
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7f7d83;
}

.program_price_modal_container .program_price_modal_table_div {
  width: 100%;
}

.program_price_modal_table_div .program_price_modal_table {
  /* border: 1px solid #e4e7ec; */
  border-radius: 10px;
  margin-top: 20px;
  width: 100%;
}

.program_price_modal_table tr th {
  color: #475467;
  text-align: center;
  padding: 12px;
  background-color: #f9fafb;
  /* border:  0px 1px 1px 0px solid #E4E7EC; */
  border-width: 1px 1px 1px 1px;

  border-style: solid;

  border-color: #e4e7ec;

  /* border-bottom: 1px solid  #e4e7ec; */
}

.program_price_modal_table tr td {
  color: #5c5c5c;
  text-align: center;
  padding: 12px;
  border-width: 1px 1px 1px 1px;

  border-style: solid;

  border-color: #e4e7ec;
}

.program_price_modal_table tr td:first-child {
  font-weight: 500;
  color: #101828;
}

.program_price_modal_table tr .program_price_modal_table_course_fee {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  color: #abafb1;
  font-weight: 400;
  font-size: 13px;
}

.program_price_modal_table .balance-row .balance-text {
  color: #3b8fee;
}
/* 
 */

.program_price_modal_container button {
  color: white;
  background-color: #1751d0;
  padding: 10px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  border: 0;
  margin-top: 20px;
}

.program_price_right .program_price_right_install_dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: 25px;
  border-block: 1px solid #024c87;
  position: relative;
  background-color: white;
}

.program_price_right_install_dropdown i {
  color: black;
}

.program_price_right_install_dropdown
  .program_price_right_install_dropdown_list {
  box-shadow: 0px 0px 4px 0px #0000001f;
  padding: 10px;
  border-radius: 8px;
  position: absolute;
  right: 0;
  font-size: 14px;
  color: #06110a80;
  font-weight: 400;
  top: 60px;
  background-color: white;
  list-style: none;
}

.program_price_right_install_dropdown_list li {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #06110a80;
}

.our_university_img_box img {
  width: 100px;
  margin-inline: 20px;
  padding: 10px;
}

.our_university_img_box:hover {
  transform: scale(1.2);
  transition: all 0.5s ease;
  cursor: pointer;
}

.mobile_testimonial_carousel .slide {
  margin: 0 30px;
}

.shimmer {
  animation: shimmer 2.2s linear infinite forwards !important;
}

.shimmer-card {
  height: 100%;
}

.getstarted_input_all {
  position: relative;
}

.getstarted_input_all select {
  appearance: none;
  border: 1px solid #81868a;
  cursor: pointer;
}

.getstarted_input_all svg {
  position: absolute;
  top: 8px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  color: black;
}

.home_course_card_div {
  margin-top: 80px;
}

.home_course_card_list {
  height: 150px;
}

.home_course_card .home_course_card_icon {
  margin-top: 0;
  padding-top: 0;
}

/* national */

/* .home_testi_video_outer_container {
  text-align: center;
} */

.home_testi_video_outer_container h2 {
  color: #024c87;
  font-weight: bold;
  text-align: center;
  padding-bottom: 30px;
  font-size: 48px;
}

.home_testi_video_inner_container {
  background-color: #f6f9fc;
  padding-block: 30px;
}

.home_testi_video_inner_container .home_testi_video_left_div {
  box-shadow: 0px 0px 6.5px 0px #d9d9d9;
  height: 480px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  /* padding: 9px; */
  margin: 15px;
}

.home_testi_video_left_div video {
  position: absolute;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  object-fit: cover;
}

.home_testi_video_left_div .home_test_video_layer {
  position: absolute;
  bottom: 0;
  background: white;
  color: black;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
  bottom: 5%;
  border-radius: 8px;
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-direction: column; */
}

.home_test_video_layer svg {
  color: #5c5c5c;
}

.home_test_video_layer h3 {
  font-size: 20px;
  font-weight: 600;
  color: #5c5c5c;
  margin-bottom: 0;
}

.home_test_video_layer p {
  font-size: 16px;
  margin-bottom: 0;
  color: #5c5c5c;
  font-weight: 400;
}

.home_testi_video_inner_container .home_testi_video_right_div {
  margin: 15px;
}

.home_testi_video_inner_container .home_testi_video_right_div h2 {
  color: #5c5c5c;
  text-align: left;
  padding-right: 150px;
  margin-bottom: 0;
  font-size: 30px;
}

.home_testi_video_inner_container .home_testi_video_right_div p {
  color: #5c5c5c;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.home_testi_video_right_div .home_testi_content {
  display: flex;
  gap: 30px;
  padding-top: 50px;
}

.home_testi_content .home_testi_content_div {
  background-color: white;
  padding: 8px 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.home_testi_content .home_testi_content_div p {
  color: #024c87;
  font-weight: 600;
  font-size: 28px;
}

.home_testi_content_div img {
  width: 170px;
  height: 60px;
}

/* ending */

.new_program_fees_container {
  padding-bottom: 52px;
}

.new_program_fees_container .new_program_fees_tab {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.new_program_fees_tab button {
  padding: 15px 40px;
  font-size: 18px;
  font-weight: 500;
  border: 0;
  outline: 0;
  background-color: rgba(223, 232, 250, 1);
  color: rgba(40, 40, 40, 1);
  font-weight: 400;
}

.new_program_fees_tab button.active {
  background-color: #1751d0;
  color: white;
}

/* .new_prograees_tab button:first-child {
  background-color: #1751d0;
  color: white;
}

.new_program_fees_tab button:last-child {
  color: #282828;
  background-color: #dfe8fa;
} */

.new_program_fees_container .new_program_fees_container_div {
  padding-top: 40px;
  display: flex;
  justify-content: center;
}

.new_program_fees_container_div .new_program_fees_container_div_left {
  background-color: #1751d0;
  flex-basis: 32%;
  padding: 40px;
}

.new_program_fees_container_div_left button {
  background-color: #e1f1fe;
  padding: 8px 28px;
  border-radius: 5px;
  border: 0;
  outline: 0;
  color: #2e2e2e;
  font-weight: 500;
  margin-bottom: 18px;
}

.new_program_fees_container_div_left p {
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
}

.new_program_fees_container_div_left hr {
  color: white;
  height: 2px;
}

.new_program_fees_container_div_left ul {
  padding: 0;
  list-style: none;
  margin-bottom: 40px;
}

.new_program_fees_container_div_left ul li {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}

.new_program_fees_container_div_left h2 {
  font-weight: 400;
  font-size: 26px;
}

.new_program_fees_container_div_left .new_program_fees_accept_img {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
  gap: 20px;
}

.new_program_fees_container_div .new_program_fees_container_div_right {
  border: 3px solid #1751d0;
  flex-basis: 32%;
  padding: 40px;
}

.new_program_fees_container_div_right .new_program_fees_one_time_pay_box {
  box-shadow: 0px 1.5px 4px 1px #0a090b12;
  padding: 10px;
  border-radius: 6px;
}

.new_program_fees_one_time_pay_box img {
  width: 40px;
  margin-bottom: 10px;
}

.new_program_fees_one_time_pay_box h3 {
  color: #0a090b;
  font-size: 20px;
  font-weight: 500;
}

.new_program_fees_one_time_pay_box p {
  font-size: 17px;
  color: #4f4d55;
  margin: 0;
  font-weight: 400;
}

.new_program_fees_container_div_right .new_program_fees_right_icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block: 60px;
}

.new_program_fees_right_icons .new_program_fees_right_icon_div {
  color: #292d32;
  font-weight: 400;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.new_program_fees_right_icons .new_program_fees_right_icon_div svg {
  width: 65px;
}

.new_program_fees_container_div_right .new_program_fees_right_price {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.new_program_fees_right_price h3 {
  font-weight: 400;
  font-size: 24px;
  color: #353535;
}

.new_program_fees_right_price h2 {
  color: #353535;
  font-size: 32px;
  font-weight: 700;
}

.new_program_fees_right_price button {
  background-color: #1751d0;
  padding-block: 12px;
  color: white;
  border: 0;
  outline: 0;
  border-radius: 8px;
  width: 80%;
  margin-top: 20px;
}

.monthly_new_program_fees_container_div {
  gap: 30px;
}

.monthly_new_program_fees_container_div .monthly_new_program_fees_left {
  border-radius: 8px;
}

.monthly_new_program_fees_left button {
  background-color: #f1f1f1;
  color: #353535;
}

.monthly_new_program_fees_left p {
  font-size: 16px;
}

.monthly_new_program_fees_left .new_program_fees_start_now {
  background-color: white;
  padding-block: 12px;
  border-radius: 4px;
  color: #1751d0;
  width: 80%;
  display: block;
  margin: auto;
}

.monthly_new_program_fees_left ul li {
  margin-bottom: 10px;
}

/* .monthly_new_program_fees_container_div .monthly_new_program_fees_right {
  background-color: transparent;
} */

.monthly_new_program_fees_container_div .monthly_new_program_fees_right {
  border: 3px solid #0000;
  border-radius: 8px;
  background: linear-gradient(#ffffff, #ffffff) padding-box,
    linear-gradient(var(--angle), #070707, #687aff) border-box;
  animation: 8s rotate linear infinite;
  color: #353535;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

.monthly_new_program_fees_container_div .monthly_new_program_fees_right button {
  background-color: rgba(241, 241, 241, 1);
  color: rgba(53, 53, 53, 1);
}

.monthly_new_program_fees_right hr {
  background-color: gray;
}

.monthly_new_program_fees_right p {
  font-size: 16px;
  color: #353535;
}

.monthly_new_program_fees_container_div
  .monthly_new_program_fees_right
  .new_program_fees_start_now {
  background-color: rgba(53, 53, 53, 1);
  padding-block: 12px;
  border-radius: 4px;
  color: white;
  width: 80%;
  display: block;
  margin: auto;
}

.monthly_new_program_fees_right ul li {
  margin-bottom: 10px;
}

/* start */
.enroll_stepthree_priceinfo .enroll_stepthree_installment .accordion-item {
  border: none;
}

.enroll_stepthree_priceinfo .enroll_stepthree_installment .accordion-button {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: rgba(0, 0, 0, 1);
}

.enroll_stepthree_installment .enroll_stepthree_acc_header h2 {
  font-size: 24px;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.enroll_stepthree_installment .enroll_stepthree_acc_header h2 span {
  width: 14px;
  height: 14px;
  display: block;
  background-color: rgba(2, 76, 135, 1);
  border-radius: 50%;
}

.enroll_stepthree_installment .enroll_stepthree_acc_body {
  padding-left: 30px;
  position: relative;
}

.enroll_stepthree_installment .enroll_stepthree_acc_body::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: rgba(67, 67, 67, 1);
  left: 5px;
}

.enroll_stepthree_installment .enroll_stepthree_acc_body_first::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 133%;
  background-color: rgba(67, 67, 67, 1);
  left: 5px;
}

.enroll_stepthree_installment .enroll_stepthree_acc_body ul {
  padding: 0;
  list-style: none;
}

.enroll_stepthree_acc_body ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(230, 230, 230, 1);
  margin-bottom: 20px;
}

.enroll_stepthree_acc_body ul li h4 {
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  width: 200px;
}

.enroll_stepthree_acc_body ul li h6 {
  width: 65px;
  text-align: end;
}

.enroll_stepthree_installment .enroll_stepthree_installment1_btn {
  margin-left: auto;
  width: -moz-fit-content;
  width: fit-content;
  background-color: rgba(2, 76, 135, 1);
  border-radius: 5px;
  color: white;
  padding: 6px 20px;
  border: none;
  display: block;
  font-size: 16px;
}

.enroll_stepthree_installment .enroll_stepthree_installment2_btn {
  margin-left: auto;
  width: -moz-fit-content;
  width: fit-content;
  background-color: rgba(140, 140, 140, 1);
  border-radius: 5px;
  color: white;
  padding: 6px 20px;
  border: none;
  display: block;
  font-size: 16px;
}

.enroll_stepthree_installment .enroll_stepthree_installment_note {
  display: flex;
  align-items: center;
  /* padding-left: 20px; */
}

.enroll_stepthree_installment_note h5 {
  color: rgba(147, 147, 147, 1);
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}

.enroll_stepthree_installment_note h5 span {
  color: rgba(53, 53, 53, 1);
  font-weight: 500;
  font-size: 20px;
  margin-right: 10px;
}

/* end */

.dashboard_schedule_container {
  align-items: flex-start;
}

.dashboard_schedule_img_info button {
  background: gray;
  width: 295px;
  margin-top: 20px;
  border: 1px solid gray;
  color: #fff;
  width: fit-content;
  /* opacity: 0.5; */
  cursor: not-allowed;
  border-radius: 7px;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding: 6px 16px;
  position: relative;
}

.dashboard_schedule_img_info .dashboard_schedule_btn button span {
  display: none;
}

.dashboard_schedule_img_info .dashboard_schedule_btn_active:hover button span {
  display: inline-block;
  /* clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 39% 75%, 49% 100%, 57% 75%, 0% 75%); */
  padding: 20px 0px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  padding: 8px;
  z-index: 1;
  bottom: 142%;
  left: 30%;
  margin-left: -56px;
}

.dashboard_schedule_img_info .dashboard_schedule_btn_active button span text {
  background-color: black;
  clip-path: polygon(46% 100%, 0 0, 100% 0);
  display: inline-block;
  width: 20px;
  position: absolute;
  bottom: -13px;
  right: 45%;
  height: 20px;
}

.dashboard_notification_container .dashboard_notification_div {
  height: 100%;
}

.dashboard_notification_container .dashboard_notification_list {
  height: 80%;
}

.dashboard_notification_container .dashboard_notification_clearall {
  margin-left: auto;
}

.dashboard_notification_container .dashboard_notification_options {
  justify-content: space-between;
}

.dashboard_notification_container .dashboard_notification_options span {
  margin-right: 20px;
}

.dashboard_notification_container .dashboard_notification_item_two {
  align-items: flex-start;
}

.dashboard_main_div .transition_sidebar {
  /* padding-left: 0; */
}

.dashboard_header_div .dashboard_header_totalhour {
  display: none;
}

.dashboard_header_div .dashboard_header_menu svg {
  cursor: pointer;
}

.course_new_carousel_item_active .course_new_carousel_item_text {
  border: 2px solid #0000;
  /* border-radius: 8px; */
  background: linear-gradient(#ffffff, #ffffff) padding-box,
    linear-gradient(var(--angle), #070707, #687aff) border-box;
  animation: 8s rotate linear infinite;
  /* color: #353535; */
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

.dashboard_schedulelist_container .schedulelist_item_parent {
  height: 300px;
  overflow: auto;
}

/* start */

.dashboard_header_div {
  padding-block: 10px;
  /* display: flex;
  align-items: center;
  flex-direction: column; */
}

.dashboard_header_div .dashboard_header_list {
  width: 100%;
}

/* .dashboard_header_div .dashboard_header_list ul li {
  display: flex;
  padding-left: 50px;
} */

.assignment_resourses_container .assignment_deadline_div {
  align-items: flex-start;
}

.enroll_steptwo_container .enroll_steptwo_checkbox {
  align-items: baseline;
}

.enroll_steptwo_container .enroll_steptwo_checkbox label {
  margin-top: -9px;
}

/* start */
.dashboard_planbill_container {
  background-color: white;
  height: 100%;
  color: rgba(51, 51, 51, 1);
  padding: 20px;
}

.dashboard_planbill_container .dashboard_planbill_header {
  /* margin-top: 20px; */
}

.dashboard_planbill_header h2 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
}

.dashboard_planbill_header .dashboard_planbill_title_dropdown {
  width: 390px;
  position: relative;
}

.dashboard_planbill_header .dashboard_planbill_title_dropdown h4 {
  color: rgba(28, 30, 36, 1);
  font-size: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
  /* justify-content: space-between; */
  cursor: pointer;
}

.dashboard_planbill_title_dropdown h4 svg {
  cursor: pointer;
}

.dashboard_planbill_title_dropdown .dashboard_planbill_dropdown_content {
  position: absolute;
  left: 0;
  width: 100%;
  height: auto;
  transition: 0.3s ease-in-out;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  z-index: 99;
}

.dashboard_planbill_dropdown_content p {
  margin: 0;
  font-size: 18px;
  padding: 10px;
}
.dashboard_planbill_dropdown_content hr {
  margin-block: 0px;
}

.dashboard_planbill_detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
}

.dashboard_planbill_detail ul {
  padding: 0;
  list-style: none;
}

.dashboard_planbill_detail ul li {
  display: flex;
  align-items: center;
  gap: 20px;
}
.dashboard_planbill_detail ul li span {
  color: rgba(147, 147, 147, 1);
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
}

.dashboard_planbill_detail .dashboard_planbill_detail_list li span {
  color: rgba(28, 30, 36, 1);
}

.dashboard_planbill_cards_container {
  width: 68%;
  display: flex;
  gap: 24px;
  justify-content: start;
}

.dashboard_planbill_cards_container .dashboard_planbill_cards {
  box-shadow: 0px 0px 4px 0px rgba(220, 220, 220, 1);
  border-radius: 10px;
  padding: 20px;
  width: 32%;
  height: 340px;
  position: relative;
}

.dashboard_planbill_cards p {
  color: rgba(125, 129, 133, 1);
  font-size: 16px;
  margin-bottom: 20px;
}

.dashboard_planbill_cards .dashboard_planbill_card_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.dashboard_planbill_cards .dashboard_planbill_card_head h3 {
  font-size: 24px;
  color: rgba(51, 51, 51, 1);
  font-weight: 500;
}
.dashboard_planbill_cards .dashboard_planbill_card_head span {
  background-color: rgba(232, 243, 252, 1);
  border-radius: 4px;
  color: rgba(2, 76, 135, 1);
  font-size: 12px;
  font-weight: 400;
  padding: 8px 12px;
}

.dashboard_planbill_cards .dashboard_planbill_card_price {
  position: absolute;
  bottom: 3%;
  width: 89%;
  height: 150px;
}

.dashboard_planbill_cards .dashboard_planbill_card_price h2 {
  font-size: 24px;
  font-weight: 700;
  color: rgba(51, 51, 51, 1);
  text-align: center;
}
.dashboard_planbill_cards .dashboard_planbill_card_price h4 {
  font-size: 16px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  text-align: center;
}
.dashboard_planbill_cards .dashboard_planbill_card_price_second h4 {
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
}

.dashboard_planbill_cards .dashboard_planbill_card_price button {
    background-color: rgba(172, 236, 179, 1);
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    width: 40%;
    padding-block: 6px;
    border: 0;
    outline: 0;
    color: rgba(0, 128, 15, 1);
    display: block;
    margin: 10px auto;
    margin-top: 20px;
}

.dashboard_planbill_cards .dashboard_planbill_card_price_second button {
  background-color: rgba(2, 76, 135, 1);
  color: white;
  /* margin-top: 43px; */
}

.dashboard_planbill_cards .dashboard_planbill_card_price span {
  text-decoration: underline;
  font-size: 14px;
  color: rgba(2, 76, 135, 1);

  width: fit-content;
  margin: auto;
  cursor: pointer;
  display: block;
}

.dashboard_planbill_cards .dashboard_planbill_card_price_second p {
  font-weight: 300;
  text-align: center;
}

.dashboard_header_div .dashboard_header_logout_open button {
  justify-content: flex-start;
  padding-left: 50px;
}

.about_mission_container .about_mission_right {
  gap: 0px;
  justify-content: flex-start;
}

.about_mission_container .about_mission_right h1 {
  font-size: 42px;
}

.about_mission_container .about_mission_right p {
  font-size: 22px;
}

.about_mission_container .about_vision_left {
  margin-top: 0;
}

.meet_team_middle_container .middle_team_heading h1 {
  font-size: 66px;
}

.meet_team_middle_container .middle_team_heading button {
  font-size: 24px;
}

.meet_founder_container .meet_founder_right h6 {
  font-size: 18px;
}

.meet_founder_container .meet_founder_right p {
  font-size: 20px;
}

.meet_footer_container button {
  border-radius: 6px;
}

.program_access_container .program_access_left h1 {
  font-size: 32px;
}

.account_suspend_modal_body img {
  width: 100px;
  display: block;
  margin: auto;
}

.account_suspend_modal_body h2 {
  font-size: 24px;
  color: rgba(51, 51, 51, 1);
  margin-top: 6px;
}

.account_suspend_modal_body p {
  font-size: 16px;
  color: rgba(92, 92, 92, 1);
}

.account_suspend_modal_body button {
  background-color: rgba(2, 76, 135, 1);
  color: white;
  padding: 10px 24px;
  border-radius: 4px;
  display: block;
  margin-left: auto;
  font-size: 18px;
  border: 0;
  outline: 0;
}

.account_suspend_modal_body svg {
  color: rgba(105, 105, 105, 1);
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 20px;
}

.dashboard_plans_congrats_div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  margin-top: 50px;
}

.dashboard_plans_congrats_div img {
  width: 501px;
  height: 324px;
}

.dashboard_plans_congrats_div p {
  font-size: 20px;
  color: rgba(28, 30, 36, 1);
}



.dashboard_resources_container {
  padding: 16px;
}

.dashboard_resources_container h2 {
  font-size: 30px;
  font-weight: 600;
  color: rgba(51, 51, 51, 1);
  margin: 0;
}

.dashboard_resources_container .dashboard_resources_folder_container {
  padding-top: 30px;
}

.dashboard_resources_container .dashboard_resources_folder_container h3 {
  font-size: 24px;
  color: rgba(51, 51, 51, 1);
  font-weight: 500;
}

.dashboard_resources_folder_container .dashboard_resources_folder_div {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
  padding-top: 18px;
}

.dashboard_resources_folder_div .dashboard_resources_folder {
  border: 1px solid rgba(218, 218, 218, 1);
  background-color: white;
  padding: 12px 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  width: 23%;
}

.dashboard_resources_folder_div .dashboard_resources_folder img {
  width: 20px;
  height: 20px;
}

.dashboard_resources_folder span {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboard_resources_folder svg {
  cursor: pointer;
}

.dashboard_resources_file_container {
  padding-top: 30px;
}

.dashboard_resources_file_container h3 {
  font-size: 24px;
  color: rgba(51, 51, 51, 1);
  font-weight: 500;
}

.dashboard_resources_file_container .dashboard_resources_file_div {
  display: flex;
}

.dashboard_resources_file_container .dashboard_resources_file_div {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
  padding-top: 18px;
}

.dashboard_resources_file_div .dashboard_resources_file {
  border: 1px solid rgba(218, 218, 218, 1);
  /* background-color: white; */
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
  padding: 12px 24px;
  border-radius: 4px;
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: column;
  /* justify-content: space-between; */
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  /* width: 23%; */
}

.dashboard_resources_file_div .dashboard_resources_file img {
  width: 80px;
  height: 80px;
}

.dashboard_resources_recent_file_container {
  padding-top: 30px;
}

.dashboard_resources_recent_file_container h3 {
  font-size: 24px;
  color: rgba(51, 51, 51, 1);
  font-weight: 500;
}

.dashboard_resources_recent_file_container table {
  border: 1px solid rgba(213, 213, 213, 1);
  width: 100%;
  /* table-layout: auto; */
  border-radius: 8px;
  margin-top: 18px;
}

.dashboard_resources_recent_file_container table thead tr {
  border-bottom: 1px solid rgba(213, 213, 213, 1);
}

/* .dashboard_resources_recent_file_container table thead tr  */

.dashboard_resources_recent_file_container table thead tr th {
  width: 33%;
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  padding: 8px 12px;
  border-right: 1px solid rgba(213, 213, 213, 1);
}

.dashboard_resources_recent_file_container table tbody tr td {
  padding: 8px 12px;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(92, 92, 92, 1);
  border-right: 1px solid rgba(213, 213, 213, 1);
  border-bottom: 1px solid rgba(213, 213, 213, 1);
}

.dashboard_resources_recent_file_container
  table
  tbody
  tr
  td
  .dashboard_resources_recent_file_td {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboard_resources_recent_file_container table tbody tr td img {
  width: 20px;
  height: 20px;
}

/*  */
.dashboard_flash_div {
  position: fixed;
  top: 2%;
  background: rgba(2, 76, 135, 1);
  width: 92.2%;
  display: flex;
  align-items: center;
  z-index: 999;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 65px;
  gap: 15px;
  padding-inline: 30px;
}

.dashboard_flash_div h4 {
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0;
}

.dashboard_flash_div p {
  margin: 0;
  font-size: 16px;
}

.dashboard_flash_div .caution_div {
  font-size: 20px;
}

.dashboard_flash_div .cross_icon {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 20px;
  cursor: pointer;
}



/*  */
