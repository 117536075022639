@media screen and (max-width: 1450px) {
  .course_new_carousel_item .course_new_carousel_item_text {
    padding-left: 92px;
  }
  .course_new_carousel_item .course_new_carousel_item_img {
    width: 116px;
  }
}
@media screen and (max-width: 1400px) {
  .program_banner_container .program_banner_right {
    height: 350px;
  }
  .program_tab_container .program_tab_logo {
    left: 0;
  }
  .course_new_carousel_item .course_new_carousel_item_img {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 150px;
  }
  .course_new_carousel_item .course_new_carousel_item_text {
    box-shadow: none;
    padding: 8px;
    width: auto;
  }
  .course_new_carousel_item {
    margin: 0;
    background-color: #fff;
    box-shadow: 0px 0px 8px #d9d9d9;
    padding: 8px 12px;
    flex-direction: column;
  }
  .course_new_carousel_item_active .course_new_carousel_item_text {
    border: none;
  }
  .course_new_carousel_item_active {
    border: 2px solid #024c87;
    border-radius: 4px;
  }
}

@media screen and (max-width: 1300px) {
  .masterclass_certificate_container .masterclass_certificate_left {
    width: 100%;
    height: 502px;
  }
  .masterclass_certificate_container .masterclass_certificate_left p {
    font-size: 9px;
    bottom: 21%;
  }
  .dashboard_tab_container .dashboard_tab_text h2 {
    font-size: 18px;
  }
}

@media screen and (max-width: 1250px) {
  .mentor_banner_container .mentor_banner_main_div {
    padding: 0;
  }
  .middle_section_search .middle_section_input_all {
    flex-direction: column;
  }
  .middle_find_btn {
    width: 100%;
    box-shadow: none;
    border-radius: 4px;
  }
  .middle_section_search .middle_section_input_all select {
    width: 100%;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0px 4px 0px #e3e3e3;
    padding: 6px 12px;
  }
  .middle_section_input_all .middle_section_wrapper .middle_program {
    width: 60%;
  }
  .middle_section_input_all .middle_section_wrapper {
    justify-content: space-between;
    width: 100%;
  }
  .home_header_container .home_header_right ul li a {
    font-size: 12px;
  }
  .home_header_container .home_header_right ul {
    padding-left: 10px;
  }
  .middle_Section_cards {
    display: none;
  }
  .job_team_right .job_team_box .ourjob_desc img {
    display: none;
  }
  .home_subheader_container .home_subheader_left {
    width: 80%;
  }
  .home_header_right ul li {
    text-align: center;
  }
}

@media screen and (max-width: 1200px) {
  .program_navbar_container .program_navbar_div {
    flex-direction: column;
    gap: 18px;
    align-items: start;
    margin-bottom: 10px;
  }
  .program_navbar_container .program_navbar_div .program_navbar_list ul li {
    font-size: 15px;
  }
  .program_advisor_container .program_advisor_card {
    padding: 30px;
  }
  .program_advisor_container .program_advisor_card .program_advisor_img img {
    position: static;
    width: 100%;
    height: 100%;
  }
  .program_navbar_container .program_navbar_btn {
    display: flex;
    justify-content: end;
    width: 100%;
  }
  .program_banner_container .program_banner_right {
    height: 288px;
  }
  .middle_item_wrapper_2 {
    max-width: 100%;
  }
  .middle_item_desc_3 p {
    text-align: center;
  }
  .our_stories_container_col_1 {
    padding: 0px 20px;
  }
}

@media screen and (max-width: 990px) {
  .dashboard_notification_container .dashboard_notification_item_info {
    width: 100%;
  }
  .dashboard_notification_container {
    width: 60%;
    bottom: 4%;
  }
  .dashboard_notification_container .dashboard_notification_item {
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }
  .dashboard_notification_container .dashboard_notification_item_info {
    align-items: start;
  }

  .middle_section_carousel .middle_item_desc_div {
    max-width: 100%;
  }
  .home_find_program_btn {
    margin-top: 26px;
  }
  .homecount_container .homecount_heading p {
    max-width: 100%;
  }
  .homeprogram_card_content,
  .homeprogram_card .homeprogram_card_rating {
    margin-bottom: 14px;
  }
  .homeprogram_card_rating .homeprogram_card_star {
    align-items: end;
  }
  .homeprogram_card .homeprogram_card_img img {
    height: 320px;
  }
  .masterclass_banner_container .masterclass_banner_right ul li {
    justify-content: start;
  }
  .masterclass_banner_container::before,
  .masterclass_banner_container::after {
    content: none;
  }
  .masterclass_banner_container {
    padding-bottom: 80px;
  }
  .masterclass_register_container .masterclass_register_row {
    position: static;
    margin-top: 62px;
    padding: 0px 60px;
  }
  .masterclass_register_container .masterclass_register_div {
    height: 100%;
  }
  .masterclass_feature_options .masterclass_feature_detail {
    padding: 30px 20px 20px 20px;
  }
  .masterclass_instructor_container::after {
    content: none;
  }
  .masterclass_instructor_container .row {
    display: flex;
    flex-direction: column-reverse;
  }
  .masterclass_instructor_container .masterclass_instructor_right {
    justify-content: center;
  }
  .masterclass_instructor_container .masterclass_instructor_left h1 {
    margin-top: 20px;
  }
  .masterclass_instructor_container .masterclass_instructor_left h2 {
    margin-top: 10px;
  }
  .masterclass_journey_container {
    padding: 40px 0px;
  }
  .masterclass_journey_container .masterclass_journey_right {
    padding: 0;
    margin-top: 20px;
  }
  .masterclass_journey_container .masterclass_journey_left h1 {
    position: relative;
    z-index: 9;
  }
  .masterclass_certificate_container .masterclass_certificate_left {
    width: 50%;
    height: 495px;
    margin-bottom: 20px;
  }
  .masterclass_certificate_container .masterclass_certificate_right {
    padding: 0;
  }
  .masterclass_faq_container .masterclass_faq_heading h4 {
    width: 100%;
  }
  .masterclass_journey_container::after {
    width: 50%;
    right: 0;
  }

  .masterclass_instructor_container .masterclass_instructor_right img {
    width: 60%;
  }
  .masterclass_feature_container::after {
    content: none;
  }
  .masterclass_feature_container .masterclass_feature_heading h2 {
    width: 100%;
  }

  .enroll_steps_container .enroll_step_card {
    padding: 72px;
  }
  .enroll_steps_container {
    margin-top: 6rem;
  }
  .enroll_stepthree_container .enroll_stepthree_price {
    float: none;
  }

  .program_navbar_container .program_navbar_btn {
    display: block;
    width: auto;
  }
  .program_navbar_container .program_navbar_div {
    flex-direction: row;
    gap: 0;
    align-items: center;
    margin-bottom: 0;
  }
  .dashboard_right_div {
    height: auto;
  }
  .dashboard_module_container,
  .dashboard_assignment_list {
    height: 100%;
  }
  .auth_root_container {
    margin-top: 80px;
  }
  .enroll_stepthree_methods .checkout_details {
    width: 90%;
  }
  .enroll_form_Container .enroll_stepthree_div .payment_option_div {
    width: 100%;
  }
  .dashboard_module_wrapper,
  .dashboard_assignment_wrapper {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
  }
  .dashboard_profile_container .dashboard_profile_password_wrapper {
    height: auto;
  }
  .assignment_resourses_container .assignment_resource_icon {
    margin-top: 14px;
  }
  .program_banner_container .program_subheader_container {
    position: static;
  }
  .program_subheader_container .program_subheader_detail {
    flex-direction: column;
  }
  .program_subheader_container .program_subheader_detail h2 {
    font-size: 15px;
  }
  .program_banner_container .program_banner_right {
    height: 450px;
  }
  .enroll_form_Container {
    padding: 120px 0px 65px 0px;
  }
  .enroll_steptwo_contact .react-tel-input {
    width: 18% !important;
  }
  .enroll_form_Container .enroll_steps_div .enroll_step_round span {
    text-align: center;
  }

  .enroll_form_Container .enroll_step_line {
    width: 50%;
  }
  .enroll_stepone_inputall label .enroll_stepone_date {
    width: 34%;
  }

  .program_banner_container .program_banner_right img,
  .program_banner_container .program_banner_right iframe {
    left: 14%;
    top: 8%;
  }
  .blog_desc_card_container .blog_card_col {
    order: 2;
  }
  .apply_section_container {
    padding: 120px 0px 30px 0px;
  }
  .study_and_internship_banner_container {
    padding: 120px 0px 60px 0px;
  }
  .apply_section_container .apply_section_left_div {
    height: 100%;
    order: 2;
    margin-top: 40px;
  }
  .apply_form_container {
    padding: 0;
  }
  .study_internship_footer_container .study_internship_footer_div1,
  .study_internship_footer_container .study_internship_footer_div2,
  .study_internship_footer_container .study_internship_footer_div3 {
    display: none;
  }
  .study_internship_footer_container .study_internship_footer_heading {
    padding: 0;
  }
  .study_and_internship_card_container .study_and_internship_card_desc {
    margin-top: 40px;
  }
  .study_and_internship_banner_container .study_internship_banner_right {
    margin-top: 40px;
  }
  .study_and_internship_story_container .study_and_internship_story_card_div {
    height: 100%;
  }
  .signup-section .outer-box::after {
    content: none;
  }
  .signup-section .title-column .sec-title .text {
    margin-bottom: 40px;
  }
  .study_and_internship_story_container .our_stories_container_col_2 {
    height: 100%;
  }
  .placed_candidate_advice_container .placed_candidate_advice_left {
    margin-bottom: 30px;
  }
  .placed_candidate_advice_container .placed_candidate_advice_heading h1 {
    max-width: 60%;
  }
  .placed_canididate_banner_right {
    position: static;
    width: 100%;
  }
  .placed_candidate_banner_container {
    padding: 120px 0px 65px 0px;
  }
  .mentor_tutorial_container .row {
    display: flex;
    justify-content: center;
  }
  .mentor_banner_container .mentor_banner_main_div {
    height: 100%;
    padding: 120px 0px 65px 0px;
  }
  .mentor_tutorial_container .mentor_tutorial_card {
    margin-bottom: 18%;
  }
  .mentor_tutorial_container .mentor_tutorial_heading h2 {
    width: 50%;
  }
  .mentor_main_carousel .mentor_carditem_heading .mentor_carditem_hr {
    margin: 30px 0px;
  }
  .mentor_main_carousel .mentor_carditem_heading h1 {
    font-size: 38px;
    max-width: 100%;
  }
  .mentor_main_carousel .mentor_carditem_heading button {
    margin: 30px 0px;
  }
  .mentor_team_container .mentor_card_circle {
    width: 61%;
  }
  .mentor_faq_container .mentor_faq_heading h2 {
    max-width: 100%;
  }
  .mentor_faq_container .mentor_faq_intouch {
    flex-direction: column;
    align-items: start;
    gap: 20px;
  }
  .mentor_main_carousel .mentor_card_item .mentor_card_item_left img {
    width: 100%;
    height: 600px;
  }
  .mentor_team_container .mentor_team_heading p {
    width: 100%;
  }
  .mentor_banner_container .mentor_banner_main_div .mentor_banner_heading {
    max-width: 100%;
  }
  /* .blog_desc_card_container .blog_card_right img {
    height: 60vh;
  } */
  .testimonial_video_banner_container {
    padding: 120px 0px 65px 0px;
  }
  .testimonial_video_banner_container .testimonial_banner_right {
    justify-content: center;
    margin-top: 20px;
  }
  .blog_desc_card_container .blog_card_right {
    margin: 30px 0px;
  }
  .blog_desc_card_container .blog_card_left .blog_card_para1 h1 {
    max-width: 100%;
  }
  .home_main_header_container {
    display: none;
  }
  .middle_item_desc_3 p {
    max-width: 100%;
  }
  .home_mobile_nav {
    display: flex;
  }
  .middle_section_container {
    margin-top: 60px;
  }
  .about_middle_container {
    margin-top: 60px;
  }
  .about_middle_container .about_miidle_list {
    margin-top: 20px;
  }
  .about_images_container .about_img_wrapper {
    transform: translateY(0);
    height: 100%;
    margin-bottom: 65px;
  }
  .job_how_works_container .job_how_works_hr {
    display: none;
  }
  .job_how_works_container .job_work_div {
    border: none;
  }

  .home_getstarted_container .getstart_cards {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .home_Certificate_container .home_certificate_right {
    margin-bottom: 50px;
  }
  .getstart_carousel .owl-dots {
    display: none;
  }
  .getstart_carousel .owl-nav {
    margin-bottom: 20px;
  }
  .ourstory_play_video {
    position: static;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .our_stories_container_col_2 {
    padding: 20px 28px;
  }
  .our_stories_container_col_1 {
    padding: 20px;
  }
  .home_dicover_container .swiper-pagination {
    display: none;
  }
  .middle_find_btn {
    padding: 10px;
  }
  .about_vision_container .row {
    flex-direction: column-reverse;
  }
  .about_story_container .about_story_right .about_story_right_two {
    width: 100%;
    margin: 30px 0px;
  }
  .about_footer_container .footer_second_div .about_footer_form {
    transform: translateY(0);
  }
  .footer_first_div .about_footer_img {
    display: none;
  }
  .about_footer_container .footer_second_div .about_footer_form::after {
    content: none;
  }
  .about_footer_container .footer_second_div {
    height: 100%;
    padding: 10%;
  }
  .about_footer_container .footer_second_div .about_footer_heading h1 {
    margin-bottom: 30px;
  }
  .meet_founder_container .meet_founder_img img {
    width: 100%;
    height: 100%;
  }
  .meet_founder_container .meet_founder_right h2 {
    margin-top: 20px;
  }
  .meet_team_middle_container .middle_team_heading h1 {
    font-size: 66px;
  }
  .meet_team_middle_main {
    margin-top: 66px;
  }
  .job_solution_container .job_solution_first .job_solution_item {
    margin-top: 20px;
    justify-content: center;
  }
  .job_solution_container
    .job_solution_first
    .job_solution_item
    .job_solution_rotate {
    bottom: 30%;
  }
  .job_categories_right .job_categories_cards {
    padding: 12px 26px;
  }
  .job_team_right {
    margin-top: 15px;
  }
  .job_categories_container .job_categories_left .job_category_heading svg {
    display: block;
  }
  .job_categories_right .job_categories_options {
    justify-content: center;
  }
  .job_description_banner_container .job_desc_banner_img {
    display: none;
  }
  .job_description_details .job_description_form {
    position: static;
    margin-top: 30px;
  }
  .contactus_card_container .contactus_img_1,
  .contactus_card_container .contactus_img_2,
  .contactus_card_container .contactus_img_3,
  .contactus_card_container .contactus_img_4,
  .contactus_card_container .contactus_img_5 {
    display: none;
  }
  .contactUs-banner-container {
    height: 40vh;
  }
  .contactus_card_container {
    margin-top: 40px;
  }
  .our_policy_carousel_container {
    height: 100%;
    transform: translateY(0);
    padding: 30px 10px;
  }
  .our_policy_banner_container {
    height: 100%;
    padding: 80px 0px;
  }
  .our_policy_carousel .owl-nav {
    position: static;
    flex-direction: row;
  }
  .blog_joinus_container .blog_joinus_right {
    margin-top: 20px;
  }
  .blog_gallery_two {
    margin-top: 15px;
    margin-bottom: 65px;
  }
  .blog_gallery_container {
    height: 100%;
  }
  .blog_gallery_container::after {
    content: none;
  }
  .blog_desc_container .blog_desc_left p {
    padding-bottom: 40px;
  }
  .blogbanner_container .blogbanner_left {
    position: static;
    clip-path: none;
    background-color: transparent;
    width: 100%;
    height: 100%;
  }
  .blogbanner_container .blogbanner_left h1 {
    max-width: 100%;
  }
  .blogbanner_container .blogbanner_card {
    position: static;
    width: 100%;
    padding: 0;
  }
  .blogbanner_container .blogbanner_card .blogbanner_card_wrapper {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .blogbanner_container .blogbanner_card .blogbanner_card_inp input {
    border: 1px solid #989ea6;
    padding: 11px 8px;
  }
  .blogbanner_container .blog_banner_middle {
    display: none;
  }
  .blogbanner_container .blog_banner_black {
    display: none;
  }
  .testimonial_apply_container .testimonial_apply_left button {
    margin: 40px 0px;
  }
  .placed_desc {
    padding: 0;
    margin-top: 28px;
  }
  .community_placed_container::after {
    width: 100%;
  }
  /* .placed_carousel-item {
		padding: 0px 35px;
	} */
  .quote_icon_div {
    display: none;
  }
  .testimonial_desc p {
    height: 195px;
  }
  .community_placed_container .placed_heading h1 {
    max-width: 100%;
  }
  .community_testimonial_heading h1 {
    max-width: 100%;
  }
  .placed-carousel .placed_carousel-item .placed_img_div {
    width: 100%;
  }
  .placed-carousel .owl-nav {
    padding: 0;
  }
  .whatTestimonies_banner_container {
    background-position: center;
    padding: 120px 0px 65px 0px;
  }
  .whatTestimonies_client_container .whatTestimonies_client_heading h2 {
    max-width: 100%;
  }
  .whatTestimonies_client_container .whatTestimonies_client_cards {
    grid-template-columns: repeat(2, 1fr);
  }
  .what_testimonies_reach_container .what_testimonies_reach_img img {
    width: 100%;
  }
  .what_testimonies_reach_container .what_testimonies_reach_div {
    justify-content: start;
  }
  .what_testimonies_reach_container .what_testimonies_reach_heading {
    max-width: 100%;
  }
  .study_and_internship_card_container {
    padding: 65px 0px;
  }
  .program_overview_right .program_overview_card {
    margin-top: 40px;
  }
  .program_advisor_container .program_advisor_card .program_advisor_img img {
    position: static;
    height: auto;
    width: 100%;
  }
  .program_advisor_container .program_advisor_card {
    padding-top: 0;
  }
  .program_advisor_container .program_advisor_card_details {
    padding: 12px;
  }
  .program_preview_container .program_preview_syllabus_btn button {
    margin-bottom: 40px;
  }
  .program_preview_container
    .program_preview_accordin
    .program_preview_accordin_header::after {
    left: -4%;
  }
  .program_preview_container .program_preview_accordin::after {
    width: 1px;
  }
  .program_certificate_container .program_certificate_list {
    margin-top: 40px;
  }
  .program_access_container .program_access_right .program_access_right_img_2,
  .program_access_container .program_access_right .program_access_right_img_1 {
    left: 0;
  }
  .program_navbar_container .program_navbar_list {
    display: flex;
    gap: 15px;
    align-items: center;
  }
  .program_navbar_container .program_navbar_list svg path {
    fill: black;
  }
  .program_navbar_container .program_navbar_list svg {
    scale: 1.3;
  }
  .program_navbar_container .program_navbar_div .program_navbar_list ul li {
    display: none;
  }
  .program_navbar_container
    .program_navbar_div
    .program_navbar_list
    ul
    .active {
    display: block;
  }
  .program_navbar_container .program_navbar_div .program_navbar_list_mobile ul {
    display: block;
    position: absolute;
    background-color: #fff;
    width: 100%;
    top: 65px;
    text-align: start;
    padding: 10px 20px;
    left: 0;
  }
  .program_navbar_container
    .program_navbar_div
    .program_navbar_list_mobile
    ul
    li {
    display: block;
    padding: 10px 0px;
  }
  .instructor_section_container .instructor_section_left {
    text-align: center;
  }
  .instructor_slider_carousel .instructor_slider_item_col {
    order: 2;
  }
  .hireus_highlight_container .hireus_highlight_card {
    margin-top: 60px;
  }
  .hireus_form_container .hireus_form_right img {
    width: 47%;
  }
  .hireus_banner_container {
    padding: 120px 0px 65px 0px;
  }
  .hireus_form_container .hireus_form_left .react-tel-input {
    width: 45%;
  }
  .middle_section_carousel .owl-prev,
  .middle_section_carousel button.owl-next {
    display: none;
  }
  .course_new_carousel_item .course_new_carousel_item_img {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 150px;
  }
  .course_new_carousel_item .course_new_carousel_item_text {
    box-shadow: none;
    padding: 8px;
    width: auto;
  }
  .course_new_carousel_item {
    margin: 0;
    background-color: #fff;
    box-shadow: 0px 0px 8px #d9d9d9;
    padding: 8px 12px;
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .blog_dashboard_domain_svg {
    display: none;
  }
  .middle_section_carousel .middle_item_desc_1 {
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 60px;
    padding: 0;
  }
  .home_getstarted_container .home_getstart_right::before {
    height: 69%;
    width: 95%;
    margin: 65px 0px;
  }
  .middle_item_desc_2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about_middle_container {
    height: 70vh;
  }
  .middle_item_desc_3 {
    justify-content: center;
  }
  .home_certificate_left img {
    width: 100%;
  }
  .home_Certificate_container .home_certificate_left .home_certificate_tab {
    left: 66px;
    top: -147px;
    width: 59%;
  }
  .mySwiper .swiper-button-next {
    left: 51%;
  }
  .mySwiper .swiper-button-prev {
    left: 37%;
  }
  .our_stories_container_col_2 .ourstory_card img {
    height: 200px;
  }
  .job_middle_section_container {
    padding-top: 100px;
  }
  .display-md-none {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .blogbanner_container .blogbanner_left h1 {
    font-size: 30px;
    text-align: center;
    font-weight: 500;
  }

  .blogbanner_container .blogbanner_card {
    display: none;
  }

  .blogbanner_container .blogbanner_left {
    width: 100%;
  }
  .blogbanner_right {
    display: none;
  }
  .blog_cards_searchbar .blog_cards_searchbar_tab span::before {
    background: none;
  }

  .blog_cards_searchbar_tab h3 {
    font-weight: 500;
  }

  .blogbanner_container {
    padding-top: 60px;
    padding-bottom: 40px;
  }

  .blog_cards_searchbar {
    padding-block: 0;
    padding-inline: 0;
  }

  .blog_cards_container {
    padding-bottom: 0;
  }

  .blog_desc_container .blog_desc_left h1 {
    color: black;
    font-size: 29px;
  }

  .blog_cards_data_slider {
    display: none;
  }
  .blog_desc_container .blog_desc_right .blog_desc_item h1 {
    font-size: 20px;
  }
  .blog_desc_container .blog_desc_right .blog_desc_item h2 {
    font-size: 20px;
  }

  .blog_desc_container .blog_desc_right .blog_desc_item {
    padding: 30px;
    text-align: left;
    box-shadow: 0px 0px 5px 1px rgba(217, 217, 217, 1);
  }

  .blog_gallery_container::after {
    /* bottom: 0; */
    width: 200px;
    height: 200px;
    top: 27%;
  }

  .blog_desc_container {
    padding-top: 40px;
  }

  .blog_joinus_container .blog_joinus_right .blog_tagone {
    box-shadow: 0px 0px 5px 1px rgba(217, 217, 217, 1);
  }

  .blog_joinus_container .blog_joinus_right .blog_tagtwo {
    box-shadow: 0px 0px 5px 1px rgba(217, 217, 217, 1);
}
  .auth_root_container .auth_root_display {
    display: none;
  }
  .dashboard_notification_container {
    width: 100%;
  }
  .middle_section_carousel .middle_carousel_item_new,
  .middle_carousel_item_1,
  .middle_carousel_item_2,
  .middle_carousel_item_3 {
    height: 375px;
  }
  .homeprogram_card .homeprogram_card_tool,
  .homeprogram_card .homeprogram_card_rating button {
    display: none;
  }
  .homecount_container .homecount_heading h2 {
    font-size: 24px;
  }
  .homecount_container .homecount_heading {
    margin: 30px 0px;
  }
  .homecount_container {
    margin-bottom: 20px;
  }
  .homeprogram_card_content .homeprogram_card_list {
    display: none;
  }
  .homeprogram_card_content .homeprogram_card_list_show {
    display: flex;
    margin-bottom: 4px;
  }
  .homeprogram_container .homeprogram_heading h2 {
    font-size: 24px;
  }
  .homeprogram_card_content .homeprogram_card_list_show img {
    display: none;
  }
  .homeprogram_card {
    margin: 12px 0px;
  }
  .homeprogram_card .homeprogram_card_img img {
    height: 152px;
  }
  .homeprogram_container {
    background-color: #fff;
    padding: 20px 0px;
  }
  .homecount_counter {
    border-radius: 2px;
    background: #e3f1fc;
    box-shadow: 0px 0px 4px 0px #e3e3e3;
  }
  .homecount_container .homecount_number {
    font-size: 40px;
  }
  .homecount_container h6 {
    font-size: 20px;
  }
  .homecount_container .homecount_heading p {
    font-size: 16px;
  }
  .middle_section_search .middle_section_input_all {
    gap: 14px;
  }
  .middle_find_btn {
    padding: 12px;
  }
  .middle_section_carousel .middle_item_desc_div h2 {
    font-size: 32px;
  }
  .middle_section_carousel .middle_carousel_item_new {
    padding: 20px;
  }
  .middle_section_carousel .middle_item_desc_div p {
    font-size: 14px;
  }
  .middle_section_search {
    margin: 0;
  }
  .homeprogram_card_content {
    flex-direction: column;
  }
  .homeprogram_card_content .homeprogram_card_price span:first-child {
    text-align: start;
  }
  .homeprogram_card .homeprogram_card_rating {
    flex-direction: column;
    gap: 0px;
    margin: 0px;
    align-items: start;
  }
  .dashboard_tab_container .dashboard_tab_text h2 {
    font-size: 18px;
  }
  .dashboard_new_row {
    padding: 10px;
  }
  .dashboard_new_work_container .dashboard_new_work_div {
    height: 100%;
  }
  .homeprogram_card_price {
    display: flex;
    width: 100%;
    gap: 10px;
    align-items: center;
  }
  .homeprogram_card_content .homeprogram_card_info h2 {
    font-size: 16px;
    margin-bottom: 4px;
    margin-top: 4px;
  }
  .home_getstrted_heading {
    display: block;
  }
  .home_getstrted_heading h2 {
    color: #1f1f1f;
    font-family: "DM Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 14px;
    padding: 0px 33px;
  }
  .features-section-heading {
    display: block;
  }
  .features-section {
    padding-top: 30px;
  }
  .features-section-heading h2 {
    color: #fff;
    text-align: center;
    margin: 10px 0px;
    margin-bottom: 35px;
    font-family: "DM Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 33.6px */
  }
  .homeprogram_card_star span {
    font-size: 12px;
  }
  .homeprogram_card_content .homeprogram_card_list span {
    font-size: 12px;
  }
  .homeprogram_card_content .homeprogram_card_price span:first-child {
    font-size: 14px;
    width: 60%;
    width: 40%;
  }
  .homeprogram_card_content .homeprogram_card_price span:last-child,
  .homeprogram_card_content .homeprogram_card_price span:last-child b {
    font-size: 10px;
  }
  .assignment_detail_container {
    padding: 8px;
    height: 100%;
  }
  .display-sm-none {
    display: none;
  }
  .dashboardnew_calender_container {
    padding: 20px 8px;
  }
  .react-calendar {
    padding: 8px;
  }

  .payment_modal .payment_modal_container {
    padding: 0;
  }
  .enroll_stepthree_methods .checkout_details .checkout_img_wrapper {
    height: 200px;
    margin-bottom: 10px;
  }
  .enroll_stepthree_methods .checkout_details {
    width: 100%;
  }
  .enroll_stepthree_div .payment_option_div .razorpay_option_btn,
  .paypal_btn_wrapper {
    width: 100%;
  }
  .enroll_form_Container .enroll_stepthree_div .payment_option_div {
    flex-direction: column;
  }
  .assignment_resourses_container .assignment_deadline_div {
    flex-direction: column;
    text-align: center;
  }
  .dashboard_header_div .dashboard_header_totalhour {
    display: none;
  }
  .dashboard_main_div {
    padding: 15px;
    padding-left: 25px;
  }
  .dashboard_main_div .dashboad_content_div {
    height: 84vh;
  }
  .fixed_dashboard_close {
    display: none;
  }
  .fixed_dashboard_open {
    display: block;
  }
  .dashboard_main_div .fixed_dashboard_header {
    left: 0;
  }
  .dashboard_header_div .dashboard_header_list .isopen .isactive::after {
    left: -1px;
  }
  .dashboard_mobilenav_container {
    display: flex;
  }
  .dashboard_mobilenav_container_close {
    display: none !important;
  }
  .enroll_form_Container .enroll_step_line {
    width: 0;
  }
  .auth_img_wrapper {
    display: none;
  }
  .dashboard_video_container .dashboard_watermark {
    right: 7%;
  }
  .schedule_summary_tab .schedule_summary_list {
    flex-direction: column;
  }
  .schedule_task_container .schedule_task_item {
    flex-direction: column;
    align-items: start;
  }
  .dashboard_schedule_container {
    padding: 15px;
  }
  .dashboard_schedule_container {
    flex-direction: column;
  }
  .dashboard_new_header_container {
    flex-direction: column-reverse;
    align-items: start;
    padding-top: 20px;
  }
  .dashboard_new_header_container .dashboard_new_header_inp,
  .dashboard_new_header_right .dashboard_new_header_inp input {
    width: 100%;
  }
  .dashboard_new_header_container .dashboard_new_header_left h2 {
    font-size: 28px;
    margin: 0;
  }
  .dashboard_new_header_right .dashboard_new_header_info {
    justify-content: space-between;
    width: 100%;
  }
  .dashboard_new_header_container .dashboard_new_header_right {
    flex-direction: column;
    align-items: start;
    width: 100%;
  }
  .dashboard_schedule_info .dashboard_schedule_desc p {
    height: auto;
  }
  .dashboard_schedulelist_item .dashboard_schedulelist_text {
    flex-direction: column;
    align-items: start;
  }
  .dashboard_new_work_container .dashboard_new_work_list {
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }
  .dashboard_schedule_info {
    width: 100%;
  }
  .schedule_task_container .schedule_task_item .schedule_task_time {
    width: 100%;
  }
  .schedule_task_container .schedule_task_item .schedule_task_time h3 {
    text-align: start;
  }
  .dashboard_course_qanda_container .dashboard_course_ques_div {
    flex-direction: column;
    align-items: start;
    gap: 12px;
  }
  .dashboard_course_qanda_container
    .dashboard_course_ans_div
    .dashboard_qanda_profile_div {
    flex-direction: column;
  }
  .dashboard_course_notes_container .dasboard_course_notes_textarea button {
    width: auto;
    margin-bottom: 10px;
  }
  .dasboard_course_notes_options .dasboard_course_notes_options_edit,
  .dasboard_course_notes_options .dasboard_course_notes_options_delete {
    width: auto;
  }
  .dashboard_coursenav_container ul {
    flex-direction: column;
    gap: 10px;
  }
  .dashboard_profile_info .dashboard_profile_form_inp label {
    padding: 8px;
    font-size: 12px;
  }
  .dashboard_profile_info
    .dashboard_profile_info_form
    .dashboard_profile_form_span {
    font-size: 12px;
  }
  .dashboard_profile_info_form .react-tel-input {
    width: 22%;
  }
  .assignment_instruction_container,
  .assignment_resourses_container {
    padding: 14px;
  }
  .assignment_resourses_container .assignment_resource_icon span {
    font-size: 14px;
  }
  .program_fees_container .program_fees_right .program_fees_right_btn button {
    margin-bottom: 18px;
    border-radius: 8px;
    padding: 8px 12px;
    width: 100%;
    background: #024c87;
  }

  .mentor_community_container {
    background-color: transparent;
    padding: 0;
  }
  .mentor_community_carousel {
    display: none !important;
  }

  .program_access_container {
    display: none;
  }
  .program_dates_container {
    padding: 30px 6px;
  }

  .program_dates_container .program_dates_table {
    border-radius: 8px;
    background: #fff;
    padding: 8px 12px;
  }
  .program_dates_container .program_dates_table table th {
    color: #363636;
    font-family: "DM Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 28px */
  }

  .program_dates_container .program_dates_table table tr th,
  .program_dates_container .program_dates_table table tr td {
    padding: 14px 8px;
    width: auto;
  }

  .program_dates_container .program_dates_heading button {
    border-radius: 8px;
    border: 1px solid #fff;
    margin-top: 14px ;
  }

  .program_overview_card_info_right{
    width: 80%;
  }

  .program_preview_container .program_preview_accordin::after {
   left: 2px ;
  }

  .home_getstarted_mobile_div {
    display: block;
  }

  .program_faq_container {
    padding: 30px 0px ;
  }

  .program_faq_container .program_faq_heading h1 {
    color: #363636;
text-align: center;
font-family: "DM Sans";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 52px; /* 260% */
text-transform: capitalize;
  }

  .program_recommend_container , .program_footer_container {
    display: none;
  }

  .program_dates_container .program_dates_table table td {
    color: #8c8c8c;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 112.5%; /* 18px */
  }

  .program_dates_container .program_dates_heading h1 {
    color: #fff;
    text-align: center;
    font-family: "DM Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 132%; /* 31.68px */
  }

  .mentor_community_container .mentor_community_heading h1,
  .mentor_community_container .mentor_community_heading h2 {
    color: #363636;
    text-align: center;
    font-family: "DM Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 132%; /* 26.4px */
  }

  .program_fees_container .program_fees_left .program_fees_left_heading h2 {
    color: #fff;
    font-family: "DM Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 180% */
  }
  .program_fees_container .program_fees_left .program_fees_left_heading p {
    color: #fff;
    text-align: center;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  }

  .program_fees_container .program_fees_left .program_fees_left_logo {
    margin: 8px 0px;
    background-color: #fff;
    padding: 5px 0px;
  }
  .program_fees_container .program_fees_left .program_fees_left_btn h2 {
    color: #fff;
    font-family: "DM Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px; /* 180% */
  }
  .program_fees_container .program_fees_left .program_fees_left_btn h3 {
    color: #fff;
    font-family: "DM Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 180% */
  }
  .program_fees_container .program_fees_left {
    padding: 20px;
  }

  .program_fees_container .program_fees_left .program_fees_left_btn button {
    border-radius: 4px;
    background: #fff;
    width: 58%;
    padding: 8px 12px;
  }

  .dashboard_graph_container .dashboard_graph_info {
    flex-direction: column;
    align-items: start;
  }
  .dashboard_header_div .dashboard_header_logo_open img {
    height: 38px;
    width: 65px;
  }
  .program_subheader_detail .program_discount_text {
    flex-direction: column;
    gap: 0;
  }

  .program_navbar_container {
    display: none;
  }
  .program_tab_container .program_tab_rating span {
    font-size: 24px;
  }
  .program_tab_container .program_tab_logo .program_tab_one {
    justify-content: center;
  }
  .program_tab_container .program_tab_logo .program_tab_one img {
    width: 30%;
  }
  .program_tab_container .program_tab_heading h1 {
    font-size: 24px;
    text-align: center;
  }

  .home_courses_container .home_course_row {
    flex-direction: column;
  }
  .thanks_container .countdown_div .count_down_txt h4 {
    font-size: 16px;
  }
  .detail_description {
    flex-direction: column;
    width: 100%;
  }
  .thanks_footer_two p {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .thanks_footer_one,
  .thanks_container .social-icon-two {
    justify-content: center;
  }
  .detail_date_div {
    width: 100%;
    text-align: center;
  }
  .detail_date_div .detail_date {
    display: inline-block;
  }
  .thanks_btn_wrapper {
    flex-direction: column;
    align-items: center;
  }
  .enroll_form_Container .enroll_stepone_div .enroll_stepone_heading,
  .enroll_steptwo_div .enroll_steptwo_heading {
    flex-direction: column;
  }
  .enroll_form_Container .enroll_stepone_course_Detail {
    flex-direction: column;
  }
  .enroll_stepone_inputall label {
    width: 100%;
  }
  .PaymentButton-Button-contents {
    margin-left: 0;
  }
  .program_banner_container .program_banner_right {
    height: 250px;
  }
  .program_navbar_container .program_navbar_btn button {
    padding: 8px 12px;
  }
  .testimonial_video_banner_container .testimonial_banner_wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 20px;
  }
  .home_getstarted_container .home_google_btn button {
    gap: 20px;
  }

  .apply_section_container .apply_section_bg_desc .apply_section_bg_btn button {
    margin-top: 10px;
  }
  .apply_section_container .apply_section_bg_desc .apply_section_bg_btn a {
    justify-content: center;
  }
  .apply_section_container .apply_section_heading h1 {
    font-size: 32px;
  }
  .study_internship_program_container .study_internship_program_list {
    align-items: start;
    flex-direction: column;
  }
  .signup-section .outer-box {
    padding: 30px 20px;
    display: flex;
    justify-content: center;
  }
  .study_and_internship_banner_container .study_internship_banner_tab_2 {
    width: auto;
    position: static;
  }
  .study_and_internship_banner_container .study_internship_banner_right {
    width: 100%;
  }
  .study_and_internship_banner_container
    .study_internship_banner_right
    .study_internship_banner_tab_1 {
    right: 0;
    padding: 20px 0px 20px 40px;
  }
  .study_and_internship_card_container {
    padding: 0px;
    padding-bottom: 65px;
  }
  .signup-section .form-column .inner-column {
    margin-left: 0;
  }
  .study_and_internship_banner_container .study_internship_banner_tab_2 {
    margin-top: 10px;
  }
  .study_and_internship_card_container .study_and_internship_card_tab2 {
    margin-top: 40px;
  }
  .study_internship_footer_container .study_internship_footer_heading h1 {
    font-size: 32px;
  }
  .whatTestimonies_client_container .whatTestimonies_client_cards {
    grid-template-columns: repeat(1, 1fr);
  }
  .what_testimonies_reach_container .what_testimonies_reach_heading h1 {
    font-size: 42px;
  }
  .community_placed_container .container {
    padding: 0 10px;
  }
  .placed_canididate_banner_left .placed_candidate_banner_cards {
    flex-direction: column;
    align-items: start;
    gap: 20px;
  }
  .placed_canididate_banner_left
    .placed_candidate_banner_cards
    .placed_candidate_banner_hr {
    display: none;
  }

  .placed_candidate_advice_container .placed_candidate_advice_heading h1 {
    max-width: 100%;
  }
  .mentor_team_container .mentor_carditem_link_div {
    justify-content: start;
  }
  .testimonial_apply_container .testimonial_apply_left p {
    max-width: 100%;
    margin: 20px 0px;
  }
  .testimonial_apply_container::after {
    width: 100%;
  }
  .blogbanner_container .blogbanner_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }
  .blogbanner_container .blogbanner_left {
    padding: 0;
    font-size: 32px;
  }

  .blogbanner_container {
    padding: 120px 0px 65px 0px;
  }
  .blogcard_container .blogcard_list ul {
    gap: 14px;
  }
  .mentor_tutorial_container .mentor_tutorial_heading h2 {
    max-width: 100%;
  }
  .mentor_tutorial_container .mentor_tutorial_heading h1 {
    text-align: center;
  }
  .mentor_main_carousel .mentor_carditem_heading h1 {
    font-size: 32px;
  }
  .mentor_main_carousel .mentor_carditem_links a img {
    margin-top: 20px;
  }
  .mentor_main_carousel .mentor_carditem_desc h2,
  .mentor_main_carousel .mentor_carditem_desc h3 {
    font-size: 24px;
  }
  .mentor_main_carousel .mentor_card_item .mentor_card_item_left img {
    height: 300px;
  }
  .mentor_banner_container .mentor_banner_main_div .mentor_banner_heading p {
    max-width: 100%;
  }
  .blogbanner_container .blogbanner_card .blogbanner_card_inp {
    width: 100%;
  }
  .about_tab_container .about_tab_content {
    border-radius: 0;
    padding: 10px 22px;
    margin-bottom: 20px;
  }
  .job_categories_container .job_categories_left {
    padding: 20px 0px;
  }
  .job_cards_container .job_cards_icon {
    display: none;
  }
  .ourjobs_carousel .ourjobs_carousel_item {
    display: flex;
    justify-content: center;
  }
  .meet_team_middle_container::after {
    content: none;
  }
  .job_categories_right .job_categories_options {
    text-align: center;
  }
  .mentor_second_carrousel .active {
    display: flex;
    justify-content: center;
  }
  .mentor_faq_container .mentor_faq_heading h1 {
    font-size: 32px;
  }
  .job_middle_section_container .job_middle_left h1 {
    font-size: 42px;
  }
  .job_middle_section_container .job_middle_left p {
    font-size: 24px;
  }
  .job_middle_section_container .job_middle_left .job_middle_inp_group {
    width: 100%;
  }
  .job_middle_section_container .job_middle_left .job_middle_inp_group button {
    font-size: 18px;
  }
  .job_team_right .job_team_box .ourjob_desc .job_arrow {
    left: 42%;
  }
  .job_team_right .job_team_box .ourjob_desc {
    padding: 10px 15px;
  }
  .about_middle_container {
    height: 100%;
    padding: 36px 0px;
  }
  .about_tab_container .about_tab_btn_1 {
    margin-top: 18px;
  }
  .about_story_container .about_story_right .about_story_right_two {
    flex-direction: column;
  }
  .about_middle_container h1 {
    font-size: 32px;
    line-height: normal;
    letter-spacing: normal;
  }
  .about_tab_container .about_tab_img_wrapper {
    margin-top: 0;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    margin-bottom: 15px;
  }
  .about_middle_container .about_middle_video p {
    width: 100%;
  }
  .about_footer_container .footer_second_div {
    padding: 5% 0;
  }
  .mySwiper .swiper-button-next {
    left: 50%;
  }
  .mySwiper .swiper-button-prev {
    left: 24%;
  }
  .middle_section_search .middle_section_input_all label {
    display: none;
  }
  .middle_section_search .middle_section_input_all {
    padding: 18px 15px;
  }

  .middle_section_input_all .middle_section_wrapper .middle_program {
    width: 100%;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0px 4px 0px #e3e3e3;
    padding: 6px 12px;
    appearance: none;
    -webkit-appearance: none;
    background-image: url("../images/drop-down-arrow.png");
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: right 1rem center;
  }

  .middle_section_search .middle_section_input_all select {
    appearance: none;
    -webkit-appearance: none;
    background-image: url("../images/drop-down-arrow.png");
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: right 1rem center;
  }
  .middle_section_carousel .middle_item_desc_1 h2 {
    font-size: 51px;
  }
  .middle_section_carousel .middle_item_desc_1 h4 {
    font-size: 22px;
  }
  .middle_item_desc_3 h1 {
    font-size: 51px;
    max-width: 100%;
  }

  .middle_item_desc_2 h1 {
    font-size: 51px;
  }
  .home_mobile_drawer {
    width: 80% !important;
  }
  .home_getstarted_container .home_getstart_right::before {
    height: 78%;
    width: 90%;
    margin: 65px 0px;
  }
  .home_find_program_btn {
    margin-bottom: 21px;
    margin-top: 6px;
    padding: 0px 26px;
  }
  .middle_section_search {
    box-shadow: none;
  }

  .ourclient_container {
    padding: 45px 0px;
  }
  .home_courses_container .home_maincard_explore {
    padding: 10px 22px;
    width: 100%;
  }
  .home_courses_container .home_maincard_explore a {
    text-align: center;
  }
  .ourstory_play_video button {
    left: 0;
  }
  .ourstory_play_video {
    justify-content: center;
  }
  .team-section {
    padding: 20px;
  }
  .home_getstarted_container .home_getstrted_left {
    padding: 20px;
  }
  .feature-block .inner-box {
    display: flex;
    gap: 30px;
    align-items: center;
    /* flex-direction: column; */
    padding-left: 0;
    padding: 0px 14px;
  }
  .team-block {
    scale: 0.83;
  }
  .feature-block .icon svg {
    scale: 0.8;
  }
  .feature-block {
    margin-bottom: 14px;
  }
  .sec-title h2 {
    font-size: 24px;
  }
  .feature-block .title {
    font-size: 16px;
  }
  .home_getstarted_container .home_started_heading {
    font-size: 14px;
    font-weight: 400;
    line-height: 0;
  }
  .feature-block .icon {
    position: static;
  }
  .feature-block .inner-box:before {
    content: none;
  }
  .owl-theme .owl-dots {
    display: none;
  }
  .home_getstarted_container .home_getstart_right {
    display: none;
  }
  .home_getstarted_mobile_testimonial {
    display: block;
  }
  .mobile_testimonial_carousel .owl-dots {
    display: block !important;
  }
  .getstart_carousel .getstartcarousel_item {
    width: 300px;
  }
  .job_description_banner_container .job_banner_main_div {
    padding: 128px 10px;
  }
  .job_description_banner_container .job_banner_heading p {
    font-size: 16px;
    padding: 0px 6px;
  }
  .job_description_banner_container .job_banner_list ul {
    flex-direction: column;
    align-items: start;
  }
  .job_description_detail .job_decription_tab h4 {
    width: 50%;
  }
  .contactus_form_container {
    padding: 0px 22px;
    padding-bottom: 30px;
  }
  .blogcard_container .blog_typeone_second,
  .blogcard_container .blog_card_typetwo_second {
    margin-top: 15px;
  }
  .typeone_carousel .owl-nav,
  .typetwo_carousel .owl-nav {
    position: static;
    text-align: start;
  }
  .study_and_internship_banner_container
    .study_and_internship_banner_left
    .study_and_internship_banner_heading
    h1 {
    font-size: 42px;
  }
  .placed_customer_carousel .placed_customer_item {
    height: 400px;
  }
  .program_tab_container .program_tab_logo {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: static;
    background-color: transparent;
    box-shadow: none;
    padding: 0px;
    padding-top: 12px;
  }
  .program_tab_container {
    padding: 45px 0px;
    background-color: #fff;
  }
  .program_overview_container {
    padding-top: 0px;
  }
  .program_overview_right .program_overview_card::after,
  .program_overview_right .program_overview_card::before {
    content: none;
  }

  .program_overview_right .program_overview_card {
    border-radius: 8px 8px 0px 0px;
    background: #fff;
    box-shadow: 0px 0px 12px 0px #e3e3e3;
    position: relative;
    padding: 0;
  }
  .program_advisor_container .program_advisor_card {
    margin: 0;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 62.9px 0px rgba(179, 179, 179, 0.25);
  }
  .program_advisor_container .program_advisor_card .program_advisor_img {
    display: flex;
    justify-content: center;
  }
  .program_advisor_container .program_advisor_card .program_advisor_img img {
    margin-top: 24px;
    width: 142px;
    height: 142px;
    border-radius: 50%;
    object-fit: cover;
  }
  .program_advisor_container .program_advisor_card h2 {
    color: #363636;
    font-family: "DM Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 138%; /* 27.6px */
    text-align: center;
    margin-bottom: 4px;
  }

  .program_advisor_container
    .program_advisor_card
    .program_advisor_card_links
    a {
    width: 20%;
  }
  .program_advisor_container .program_advisor_card h3 {
    font-size: 14px;
  }
  .program_advisor_container .program_advisor_card .program_advisor_card_hr {
    height: 2px;
  }

  .program_advisor_container .program_advisor_card h3 {
    color: #b3b3b3;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 167.5%; /* 26.8px */
    margin-bottom: 4px;
  }
  .program_preview_container {
    padding: 0px;
  }
  .program_preview_container .program_preview_heading h1 {
    color: #363636;
    font-family: "DM Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 243.5%; /* 58.44px */
    margin-bottom: 0;
  }
  .program_preview_container .program_preview_accordin_div {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 12px 0px #e3e3e3;
  }
  .program_preview_container .program_preview_accordin .accordion-button {
    background: #fff;
  }
  .program_preview_container
    .program_preview_accordin
    .program_preview_accordin_header
    .program_preview_accordin_title
    h3 {
    color: #363636;
    font-family: "DM Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 123%; /* 24.6px */
  }
  .program_preview_container
    .program_preview_accordin
    .program_preview_accordin_body {
    gap: 8px;
  }
  .program_preview_container
    .program_preview_accordin
    .program_preview_accordin_header
    button {
    display: none;
  }

  .program_preview_container .program_preview_syllabus_btn {
    margin: 0px 10px;
    margin-top: 20px;
  }
  .program_preview_container .program_preview_right {
    border-radius: 18px;
    background: #fff;
    box-shadow: 0px 0px 41.1px 0px rgba(217, 217, 217, 0.65);
  }

  .program_preview_container
    .program_preview_right
    .program_preview_form_heading {
    color: #363636;
    text-align: center;
    font-family: "DM Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140.5%; /* 28.1px */
  }

  .program_preview_right .program_preview_form_check label {
    color: #8c8c8c;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140.5%; /* 22.48px */
  }
  .program_preview_right .program_preview_form_submit button {
    border-radius: 4px;
    background: #daeeff;
    border: none;
    width: 90%;
  }
  .program_steps_container {
    padding: 14px 0px;
    background: #ecf4ff;
    margin-top: 30px;
  }
  .program_steps_container .program_steps_heading h1 {
    color: #363636;
    text-align: center;
    font-family: "DM Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 243.5%; /* 58.44px */
    margin: 0;
  }
  .program_steps_container .program_steps_heading p {
    color: #8c8c8c;
    text-align: center;
    font-family: "DM Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 144.5%; /* 17.34px */
    margin: 0px;
    margin-bottom: 16px;
  }
  .program_eligibility_container {
    padding: 30px 0px;
  }
  .program_eligibility_container .program_eligibility_heading h1 {
    color: #363636;
    font-family: "DM Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 243.5%; /* 58.44px */
    text-transform: capitalize;
    margin: 0;
  }
  .program_eligibility_container .program_eligibility_heading p {
    color: #8c8c8c;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 144.5%; /* 23.12px */
    margin: 0;
  }
  .program_certificate_container {
    padding: 0;
  }
  .program_certificate_container .program_certificate_heading h1 {
    color: #363636;
    font-family: "DM Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 132%; /* 31.68px */
  }
  .program_certificate_container .program_certificate_heading p {
    color: #8c8c8c;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 144.5%; /* 20.23px */
    max-width: 100%;
  }

  .program_steps_container .row,
  .program_certificate_container .program_certificate_list {
    display: none;
  }
  .program_fees_container .program_fees_heading h1 {
    color: #363636;
    font-family: "DM Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 144%; /* 34.56px */
  }
  .program_fees_container .program_fees_heading p {
    color: #8c8c8c;
    font-family: "DM Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 144.5%; /* 21.675px */
    max-width: 100%;
    margin: 0;
  }

  .program_fees_container .program_fees_div .program_fees_col {
    order: 2;
  }

  .program_fees_container .program_fees_div .row {
    border-radius: 16px 10px;
  }
  .program_fees_container .program_fees_right .program_fees_right_heading h2 {
    color: #363636;
    font-family: "DM Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 180% */
  }
  .program_fees_container .program_fees_right {
    padding: 18px;
  }
  .program_fees_container .program_fees_right .program_fees_right_heading p {
    color: #8c8c8c;
    text-align: center;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }
  .program_fees_container .program_fees_right .program_fees_right_icons {
    margin: 32px 0px;
  }
  .program_fees_container .program_fees_right .program_fees_right_icons h6 {
    color: #8c8c8c;
    text-align: center;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }
  .program_fees_container .program_fees_right .program_fees_right_btn h2 {
    color: #8c8c8c;
    text-align: center;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
  }

  .program_fees_container .program_fees_right .program_fees_right_btn h3 {
    color: #363636;
    font-family: "DM Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 180% */
  }
  .program_steps_container .program_steps_phone_card {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 14px;
  }

  .program_steps_container .program_steps_phone_card .program_steps_phone_img {
    width: 13%;
  }

  .program_steps_phone_card .program_steps_phone_desc span {
    color: #8c8c8c;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 144.5%;
    letter-spacing: 0.32px;
    text-transform: capitalize;
  }
  .program_steps_phone_card .program_steps_phone_desc {
    width: 75%;
  }
  .program_steps_phone_card .program_steps_phone_desc h4 {
    color: #8c8c8c;
    font-family: "DM Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 144.5%; /* 17.34px */
  }

  .program_preview_container .program_preview_syllabus_btn button {
    border-radius: 8px;
    background: #024c87;
    width: 100%;
  }
  .program_preview_container .program_preview_accordin .accordion-body {
    background-color: #fff;
  }

  .program_advisor_container .program_advisor_card p {
    color: #8c8c8c;
    text-align: center;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 20.3px */
  }

  .program_preview_container
    .program_preview_accordin
    .program_preview_accordin_header {
    display: block;
  }
  .program_preview_container
    .program_preview_accordin
    .program_preview_accordin_header::after {
    left: -9%;
  }
  .program_fees_container .program_fees_div {
    padding: 0px 4%;
  }
  .program_footer_container .program_footer_btns button {
    margin-top: 20px;
    margin-right: 12px;
  }
  .program_overview_right
    .program_overview_card
    .program_overview_card_content {
    padding: 0;
  }

  .program_overview_right .program_overview_card h2 {
    color: #363636;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 144.5%; /* 23.12px */
  }

  .program_overview_right .program_overview_card p,
  .program_overview_right .program_overview_card ul li {
    color: #8c8c8c;
    font-family: "DM Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 144.5%;
  }
  .program_overview_card_info .program_overview_card_info_left {
    display: block;
    width: 15%;
  }
  .program_overview_right .program_overview_card .program_overview_card_hr {
    display: none;
  }

  .program_overview_right
    .program_overview_card
    .program_overview_card_print
    button {
    border-radius: 9px;
    background: #024c87;
    padding: 8px 28px;
    width: 90%;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 161%; /* 25.76px */
  }
  .program_tools_container {
    margin-top: 14px;
  }
  .program_skillphone_container {
    display: block;
    background: #024c87;
    padding: 14px 0px;
    margin-top: 28px;
  }
  .program_skillphone_container h2 {
    color: #fff;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 145%; /* 23.2px */
  }

  .program_skillphone_container ul {
    padding: 0;
    margin: 0;
  }

  .program_skillphone_container ul li {
    list-style: none;
  }
  .program_skillphone_container ul li .program_skillphone_li {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 8px;
  }
  .program_skillphone_container ul li .program_skillphone_li svg {
    scale: 0.6;
  }
  .program_skillphone_container ul li .program_skillphone_li button {
    background-color: transparent;
    border: none;
  }
  .program_advisor_container {
    padding: 30px 0px;
  }
  .program_advisor_container .program_advisor_heading h1 {
    color: #363636;
    font-family: "DM Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 243.5%; /* 58.44px */
  }
  .program_tools_container h2 {
    text-align: center;
    color: #363636;
    text-align: center;
    font-family: "DM Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 243.5%; /* 58.44px */
  }
  .program_skill_covered_container {
    display: none;
  }

  .program_overview_right .program_overview_card .program_overview_card_print {
    display: flex;
    justify-content: center;
    padding-bottom: 18px;
    margin-top: 14px;
  }

  .program_overview_right .program_overview_card .program_overview_card_h2 {
    background-color: #024c87;
    padding: 10px 14px;
    color: #fff;
    font-family: "DM Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 144.5%; /* 34.68px */
    text-transform: capitalize;
    border-radius: 8px 8px 0px 0px;
  }
  .program_overview_right
    .program_overview_card
    .program_overview_card_details {
    padding: 0px 14px;
  }
  .instructor_banner_container .instructor_banner_left_img img {
    height: 30rem;
  }
  .instructor_banner_container .instructor_banner_right_tab p {
    font-size: 18px;
  }
  .instructor_banner_container .instructor_banner_right_form_div {
    padding: 8%;
  }
  .instructor_banner_container .instructor_banner_right_form p {
    max-width: 100%;
  }
  .instructor_slider_container .instructor_slider_heading h1 {
    font-size: 42px;
  }
  .instructor_slider_container .instructor_slider_item_desc {
    padding: 10%;
  }
  .instructor_banner_container .instructor_banner_left_desc {
    padding: 30px;
  }
  .instructor_section_container .instructor_section_rignt {
    padding: 20px;
    padding-top: 0;
  }
  .hireus_banner_container .hireus_banner_left h1 {
    font-size: 42px;
    margin-bottom: 30px;
  }
  .hireus_banner_container .hireus_banner_list ul {
    margin-top: 20px;
  }
  .hireus_banner_container .hireus_banner_right img {
    width: 100%;
    margin-top: 30px;
  }
  .hireus_form_container .hireus_form_right img {
    position: static;
    width: 100%;
    margin-top: 40px;
  }
  .hireus_testimonial_container .hireus_testimonial_heading h1 {
    max-width: 100%;
  }
  .our_policy_carousel .our_policy_item .our_policy_card_description p {
    height: 100%;
  }
  .home_mobile_nav a img {
    height: 40%;
    width: 40%;
  }
  .enroll_steps_container .enroll_step_card {
    padding: 52px 20px;
  }
  .enroll_stepone_container .enroll_stepone_info .enroll_stepone_heading h2 {
    font-size: 28px;
  }
  .enroll_stepone_container .enroll_stepone_right h4 {
    font-size: 18px;
  }
  .enroll_stepone_container .enroll_stepone_right h2 {
    font-size: 20px;
  }
  .enroll_stepone_container .enroll_stepone_info {
    flex-direction: column;
    align-items: start;
  }
  .enroll_stepone_container .enroll_stepone_inps label,
  .enroll_steptwo_container .enroll_steptwo_contact_div label {
    font-size: 18px;
  }
  .enroll_stepone_container .enroll_stepone_btn {
    justify-content: end;
    width: 100%;
  }

  .enroll_stepone_container .enroll_stepone_inps select,
  .enroll_stepone_container .enroll_stepone_inps input,
  .enroll_steptwo_container .enroll_steptwo_contact {
    width: 100%;
  }
  .enroll_steptwo_container .enroll_steptwo_contact input {
    padding-left: 28px;
  }
  .enroll_steptwo_container .enroll_steptwo_checkbox label {
    font-size: 14px;
  }
  .enroll_stepthree_container .enroll_stepthree_priceinfo {
    padding: 6px 12px;
  }

  .enroll_stepthree_container
    .enroll_stepthree_pricedetails
    .enroll_stepthree_price_label,
  .enroll_stepthree_container .enroll_stepthree_price_total {
    font-size: 16px;
    padding: 8px 14px;
  }
  .enroll_stepthree_container .enroll_stepthree_price {
    font-size: 20px;
    width: 100%;
  }
  .enroll_stepthree_container .enroll_stepthree_refrell input {
    width: 100%;
  }
  .enroll_stepfour_container .enroll_stepone_inps svg {
    right: 4%;
  }
  .enroll_stepfour_container .enroll_stepfour_submit button {
    width: 100%;
  }
  .enroll_stepfour_container .enroll_stepfour_right .enroll_stepfour_heading h4,
  .enroll_stepfour_container .enroll_stepfour_submit button {
    font-size: 16px;
  }

  .enroll_stepfour_container .enroll_stepfour_left {
    text-align: center;
  }
  .enroll_steptwo_container .enroll_steptwo_checkbox input {
    scale: 1;
    margin-top: 4px;
  }
  .enroll_steptwo_container .enroll_steptwo_checkbox {
    align-items: start;
  }
  .masterclass_banner_container .masterclass_banner_left h1,
  .masterclass_feature_container .masterclass_feature_heading h1 {
    font-size: 42px;
  }
  .masterclass_journey_container .masterclass_journey_left h1,
  .masterclass_certificate_container .masterclass_certificate_right h1,
  .masterclass_faq_container .masterclass_faq_heading h1,
  .masterclass_instructor_container .masterclass_instructor_left h1 {
    font-size: 32px;
    line-height: normal;
    width: 100%;
  }
  .masterclass_certificate_container .masterclass_certificate_left {
    width: 90%;
    height: 500px;
  }
  .masterclass_certificate_container .masterclass_certificate_right {
    width: 100%;
  }
  .masterclass_certificate_container .masterclass_certificate_right svg {
    height: 50px;
    width: 50px;
  }
  .masterclass_register_container .masterclass_register_row {
    padding: 0px 10px;
  }
  .masterclass_feature_detail .masterclass_feature_info {
    padding: 0;
    margin-top: 12px;
    width: 100%;
  }

  .masterclass_journey_container .masterclass_journey_right {
    width: 100%;
  }
  .masterclass_instructor_container .masterclass_instructor_left p,
  .masterclass_faq_container .masterclass_faq_heading h4,
  .masterclass_feature_container .masterclass_feature_heading h2,
  .masterclass_feature_detail .masterclass_feature_info span,
  .masterclass_certificate_container .masterclass_certificate_right h4,
  .masterclass_faq_accordian .masterclass_faq_items h3,
  .masterclass_faq_accordian .masterclass_faq_body p {
    width: 100%;
    font-size: 16px;
  }
  .masterclass_feature_options .masterclass_feature_list ul {
    flex-direction: column;
    width: auto;
  }
  .masterclass_feature_options .masterclass_feature_list {
    position: relative;
  }

  .masterclass_feature_options .masterclass_feature_list .feature-svg-down {
    display: inline-block;
    fill: #07227a;
    position: absolute;
    right: 3%;
    top: 21%;
    height: 28px;
    width: 28px;
  }
  .masterclass_feature_options .masterclass_feature_list .feature-svg-up {
    display: inline-block;
    fill: #07227a;
    position: absolute;
    right: 3%;
    top: 5%;
    height: 28px;
    width: 28px;
  }
  .masterclass_feature_options .masterclass_feature_list ul li {
    display: none;
  }
  .masterclass_feature_options .masterclass_feature_list .feature-open li {
    display: flex;
  }
  .masterclass_feature_options .masterclass_feature_list ul li:first-child {
    display: flex;
  }
  .masterclass_register_container .masterclass_register_right {
    padding: 24px;
  }
  .dashboard_video_container video,
  .dashboard_video_container iframe,
  .dashboard_video_container .video-react {
    height: 222px ;
  }
  .dashboard_video_container .video-react .video-react-big-play-button {
    top: 38%;
    left: 37%;
  }

  .dashboard_video_container video, .dashboard_video_container iframe, .dashboard_video_container .video-react {
    border-radius: 0;
  }
 

  .dashboard_video_container {
    padding: 0;
    padding-top: 4px ;
  }

  .module_video_placeholder {
    height: auto;
  }
  .module_video_placeholder img {
    height: 300px;
    width: 300px;
  }
  .auth_root_container {
    padding: 8px 16px;
    padding-top: 90px;
    margin: 0;
  }
  .program_banner_container {
    padding-top: 90px;
  }
  .program_banner_container .program_banner_left ul li {
    color: #fff;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 23.2px */
  }
  .program_banner_container .program_banner_left ul {
    margin-top: 20px;
  }
  .program_banner_container .program_banner_left button {
    display: none;
  }
  .program_banner_container .program_banner_left h1 {
    font-size: 36px;
    letter-spacing: -1.08px;
  }
  .program_banner_container {
    background-image: none;
    z-index: 3;
  }

  .program_banner_container .row {
    padding: 0px 15px;
    margin-bottom: 10px;
  }
  .program_banner_container .program_banner_left ul,
  .program_banner_container .program_banner_left h1 {
    z-index: 3;
    position: relative;
  }

  .program_banner_container .program_banner_info {
    z-index: 3;
    display: block;
    position: relative;
  }

  .program_banner_container .program_banner_info  .program_banner_info_shimmer {
    width: 100% !important;
    border-radius: 6px ;
  }
  .program_banner_container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 62%;
    background-image: url("https://d1db1k41ipas2c.cloudfront.net/program_bg_banner+(1).png");
    z-index: 1;
  }

  .program_overview_left {
    display: none;
  }
}

@media screen and (max-width: 426px) {
  .program_banner_container .program_banner_right {
    height: 259px;
  }
}

@media screen and (max-width: 405px) {
  .program_banner_container .program_banner_right {
    height: 235px;
  }
  .razorpay_btn_wrapper .PaymentButton-Button-text {
    font-size: 14px;
  }
}

@media screen and (max-width: 376px) {
  .program_banner_container .program_banner_right {
    height: 224px;
  }
  .middle_section_container {
    margin-top: 68px;
  }
  .meet_team_middle_container {
    height: auto;
  }
}

@media screen and (max-width: 350px) {
  .getstart_carousel .getstartcarousel_item {
    width: 268px;
  }
}

@media screen and (max-width: 325px) {
  .program_banner_container .program_banner_right {
    height: 190px;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1800px) {
  .middle_carousel_item_1,
  .middle_carousel_item_2,
  .middle_carousel_item_3 {
    height: 78vh;
  }
}
