@media screen and (max-width: 2520px) {
  .placed_candidate_testi_bluebox #placed_candidate_testi_bluebox_vectorimg {
    width: 450px;
  }
}

@media screen and (max-width: 1501px) {
  .placed_candidate_testi_bluebox p {
    padding-left: 90px;
  }
}

@media only screen and (min-width: 1030px) and (max-width: 1366px) {
  .new_featured_program_item_data_right_buttons button {
    width: 120px;
    font-size: 18px;
  }

  .new_featured_program_item_data_right_buttons button.active span {
    padding-inline: 18px;
    font-size: 16px;
  }
  .about_middle_container h1 {
    font-size: 50px;
  }
  .job_middle_section_container .job_middle_left .job_middle_inp_group button {
    width: 170px;
  }
  .program_preview_container .program_preview_accordin::after {
    left: 4px;
  }
  .program_navbar_container {
    padding-block: 12px;
  }
  .program_navbar_container .program_navbar_btn button {
    padding: 12px 24px;
    margin-bottom: 0;
  }
  .program_navbar_container .program_navbar_div .program_navbar_list ul li {
    padding-bottom: 0;
  }
  .course_new_carousel_item_active .course_new_carousel_item_text {
    margin-top: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .blog_dashboard_container_row .blog_dashboard_container_child_first {
    width: 0;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .blog_dashboard_logo {
    justify-content: flex-start;
  }

  .blog_dashboard_container_row .blog_dashboard_container_child_first {
    width: 0;
  }

  .placed_candidate_testi_item {
    max-width: 70%;
  }

  .blog_dashboard_mobile_sidebar_cross {
    display: block;
  }

  .blog_dashboard_home .blog_dashboard_home_navbar {
    justify-content: flex-start;
    gap: 50px;
    padding-top: 2%;
  }

  .blog_dashboard_home_navbar .blog_dashboard_home_navbar_input input {
    width: 500px;
  }

  .blog_dashboard_sidebar {
    position: absolute;
    top: 0;
    transform: translateX(-100%);
    z-index: 99;
  }

  .blog_dashboard_sidebar .blog_dashboard_sidebar_btn {
    display: none;
  }

  .blog_dashboard_hamburger_icon {
    display: block;
    position: absolute;
    right: 3%;
    top: 2%;
    background-color: #024c87;
    color: white;
    font-size: 28px;
    border: none;
    outline: none;
    width: 50px;
    height: 50px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .blog_desktop_mobile_active {
    /* display: block; */
    transform: translateX(49%);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    height: 100vh;
    position: relative;
    z-index: 999;
  }

  .blog_dashboard_mobile_sidebar_cross {
    display: block;
  }

  .placed_candidate_testi_bluebox #placed_candidate_testi_bluebox_vectorimg2,
  .placed_candidate_testi_bluebox #placed_candidate_testi_bluebox_vectorimg {
    display: none;
  }

  .placed_candidate_testi_carousel .owl-nav .owl-next button {
    right: 8%;
  }

  .placed_candidate_testi_carousel .owl-nav .owl-prev button {
    left: 8%;
  }

  .blog_signup_left_div {
    display: none;
  }

  .blog_otp_left_div {
    display: none;
  }

  .blog_cards_left_article .blog_cards_article_section {
    width: 240px;
  }

  .blog_cards_right_container {
    padding-inline: 0;
  }

  .blog_cards_searchbar .blog_cards_searchbar_tab span {
    justify-content: space-between;
    gap: 0;
  }

  .blogbanner_container {
    height: 75vh;
  }

  .blogbanner_container .blogbanner_card {
    top: 42%;
  }
}

@media screen and (max-width: 990px) {
  .dashboard_resources_folder_div .dashboard_resources_folder {
    width: 48%;
  }
  .dashboard_resources_file_div .dashboard_resources_file {
    width: 48%;
  }
  .student_placed_banner_container {
    height: auto;
  }
  .student_placed_banner_container_div {
    flex-direction: column-reverse;
    padding: 20px;
  }

  .student_placed_banner_container_div .student_placed_banner_left {
    padding-right: 0;
  }

  .student_placed_banner_container_div img {
    width: 100%;
    height: 100%;
  }

  /* end */
  .blog_dashboard_register_footer {
    display: none;
  }

  .blog_dashboard_register_btn {
    margin-top: 0;
  }

  .blog_dashboard_register_btn button {
    width: 250px;
    height: 50px;
    font-size: 18px;
  }

  .blog_dashboard_register_form form {
    margin-top: 0;
  }
  .blog_dashboard_choose_domain_container .blog_dashboard_choose_domain {
    margin: 0;
    width: auto;
  }

  .blog_dashboard_home_navbar .blog_dashboard_home_navbar_input input {
    width: auto;
  }

  .blog_dashboard_register .blog_dashboard_register_img img {
    display: none;
  }
  .login_image {
    display: none;
  }

  .placed_candidate_testi_bluebox p {
    padding: 30px;
  }

  .placed_candidate_testi_bluebox #placed_candidate_testi_image {
    top: -22%;
  }

  .mentor_team_container .mentor_card_circle {
    width: 75%;
  }

  .blog_description_content_container {
    padding: 20px;
  }
}

@media screen and (max-width: 768px) {
  .our_university_container .our_university_container_div {
    justify-content: center;
  }
  .student_university_container .student_university_content {
    justify-content: center;
  }
  /* end */
  .dashboard_certificate_header img {
    top: 50px;
    width: 200px;
  }
  .dashboard_certificate_refer_container {
    flex-direction: column-reverse;
    align-items: center;
    gap: 20px;
  }
  .dashboard_certificate_refer_container h3 br {
    display: none;
  }
  .blog_dashboard_register {
    height: auto;
    padding: 20px;
  }
  .blog_dashboard_form {
    padding-inline: 30px;
  }

  .blog_dashboard_change_password {
    width: 100%;
    margin-bottom: 10px;
  }

  .blog_dashboard_profile_div {
    flex-direction: column;
    align-items: center;
  }

  .blog_dashboard_profile_edit {
    margin: 0;
  }

  .blog_dashboard_profile_text {
    text-align: center;
  }

  .blog_dashboard_profile_social {
    justify-content: center;
  }

  .blog_dashboard_profile_tabs {
    justify-content: center;
  }

  .blog_dashboard_choose_domain_container {
    padding-inline: 20px;
  }

  .blog_dashboard_choose_domain_container .blog_dashboard_choose_domain {
    margin: 0;
    width: auto;
  }

  .blog_dashboard_choose_domain_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 150px;
    height: 100vh;
  }

  .blog_dashboard_home .blog_dashboard_home_navbar {
    flex-direction: column;
    gap: 20px;
    padding-top: 3%;
    align-items: baseline;
    padding-inline: 20px;
  }

  .blog_dashboard_home_navbar_input {
    width: 70%;
  }

  .blog_dashboard_home_navbar .blog_dashboard_home_navbar_input input {
    width: 100%;
  }

  .blog_dashboard_insert_content_container .blog_dashboard_insert_content_div {
    width: auto;
  }

  .blog_dashboard_home_blog_create_box {
    width: auto;
  }
  .placed_candidate_testi_item {
    max-width: 90%;
  }

  .placed_candidate_testi_carousel .owl-nav .owl-next button {
    right: -4%;
  }

  .placed_candidate_testi_carousel .owl-nav .owl-prev button {
    left: -4%;
  }

  .placed_candidate_testi_cards .owl-carousel .owl-stage-outer {
    height: 460px;
  }

  .blog_ai_content .blog_ai_img {
    width: auto;
    height: auto;
    padding-block: 30px;
  }

  .blog_card_main_content h2 {
    font-size: 20px;
  }

  .blog_cards_left_article {
    display: none;
  }

  .blog_cards_searchbar {
    flex-direction: column;
    padding-inline: 10px;
  }

  .blog_cards_searchbar .blog_cards_searchbar_tab {
    width: 100%;
  }

  .blog_cards_searchbar .blog_cards_search {
    width: 100%;
  }

  .blog_cards_searchbar_tab_tag {
    display: none;
  }

  .blog_cards_search .blog_cards_search_input input {
    width: 100%;
  }

  .blog_description_content_container .blog_dashboard_choose_domain_three_btn {
    bottom: -2%;
  }
  .blog_account_type_box p {
    max-width: 100%;
  }

  .blog_dashboard_insert_content_container {
    display: flex;
    flex-direction: column;
    height: 100vh;

    justify-content: space-between;
    padding-top: 140px;
  }

  .blog_dashboard_editor_container .blog_dashboard_editor_preview button {
    position: absolute;
    z-index: 9;
    top: 30px;
    left: 20px;
  }

  .dashboard_notification_container {
    width: 75%;
  }
  .blogbanner_container {
    height: auto;
  }
  .home_testi_video_left_div video {
    width: 100%;
  }
  .home_testi_video_left_div .home_test_video_layer {
    width: 55%;
  }
  .new_featured_program_item_data_right {
    display: none;
  }
  .dashboard_planbill_header .dashboard_planbill_title_dropdown {
    width: auto;
  }
  .dashboard_planbill_cards_container {
    width: auto;
    flex-direction: column;
    gap: 12px;
  }
  .dashboard_planbill_cards_container .dashboard_planbill_cards {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .dashboard_flash_div {
    position: fixed;
    top: 70px;
    left: 0;
    padding-inline: 10px;
    gap: 0;
    z-index: 99;
    border-radius: 0;
    width: 100%;
  }

  .dashboard_flash_div .caution_icon {
    font-size: 30px;
  }

  .dashboard_flash_div h4 {
    font-size: 16px;
  }

  .dashboard_flash_div p {
    font-size: 14px;
  }
  .dashboard_resources_folder_div .dashboard_resources_folder {
    width: 100%;
  }
  .dashboard_resources_file_div .dashboard_resources_file {
    width: 100%;
  }
  .dashboard_resources_container {
    padding: 20px 0;
  }
  .dashboard_help_btn .dashboard_help_button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .dashboard_help_btn .dashboard_help_button span {
    display: none;
  }

  .dashboard_schedule_mobile_btn {
    margin-left: auto;
    display: flex;
    justify-content: end;
    color: black;
    background-color: rgba(82, 187, 56, 1);
    color: white;
    padding: 4px 12px;
    width: fit-content;
    font-size: 12px;
    font-weight: 500;
    border-radius: 5px;
    text-transform: capitalize;
  }

  .dashboard_schedule_mobile_btn_disabled {
    background-color: gray;
  }
  .assignment-fixed-btn-disable {
    bottom: 13%;
  }
  .enroll_stepthree_paynow_btn {
    position: static;
    margin-top: 0;
    margin-bottom: 12px;
  }
  .assignment-fixed-msg-btn {
    bottom: 22%;
  }
  .assignment-fixed-btn {
    width: 45px;
    height: 45px;
  }
  .dashboard_certificate_mobile_card {
    margin-block: 3px;
  }

  .dashboard_notification_container {
    height: 65%;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    border-radius: 16px 16px 0 0;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .dashboard_notification_container .dashboard_notification_list {
    height: 75%;
  }

  .dashboard_new_mobile_footer
    .dashboard_new_mobile_footer_div.active
    svg
    path {
    fill: #ffffff;
  }

  .dashboard_schedule_container {
    flex-direction: row;
    padding: 10px;
  }

  .home_gradient_bg {
    background: transparent;
  }

  .dashboard_schedule_mobile_container_head {
    font-size: 16px;
    color: rgba(51, 51, 51, 1);
    font-weight: 700;
    margin-bottom: 10px;
  }

  .dashboard_schedule_info .dashboard_schedule_btn {
    display: none;
  }
  .dashboard_schedule_container .dashboard_schedule_upcoming {
    display: none;
  }
  .dashboard_schedule_info .dashboard_schedule_desc p {
    font-size: 10px;
    font-weight: 400;
    color: rgba(125, 129, 133, 1);
    height: 93px;
    margin: 0;
  }
  .dashboard_schedule_info .dashboard_schedule_desc h4 {
    font-size: 12px;
    font-weight: 500;
    color: rgba(13, 42, 65, 1);
    margin: 0;
  }
  .dashboard_schedule_container .dashboard_schedule_img {
    height: 130px;
    width: 297px;
  }

  .dashboard_schedule_container .dashboard_schedule_img .live_span {
    padding: 4px 15px;
    border-radius: 3px;
    font-size: 12px;
  }

  .dashboard_schedule_container .upcoming_class_profile_name {
    font-size: 11px;
    font-weight: 400;
    color: rgba(116, 116, 116, 1);
    gap: 8px;
    display: flex;
    align-items: center;
    margin-block: 5px;
  }

  .countdown_live_schedule h6 {
    font-size: 8px;
  }
  .dashboard_main_div {
    background-color: white;
    /* position: relative; */
    /* padding-left: 15px; */
    height: auto;
    padding: 0;
  }
  .dashboard_ticket_chats_container .dashboard_ticket_chats_box {
    width: 100%;
  }
  .dashboard_main_div .dashboad_content_div {
    /* height: auto; */
    /* height: 74vh; */
    overflow-y: auto;
    margin-top: 58px;
    background-color: transparent;
    padding-inline: 10px;
    height: 76vh;
  }

  .dashboard_new_mobile_footer {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99;
  }
  .dashboard_new_mobile_nav {
    display: block;
    width: 100%;
    position: fixed;
    padding: 10px;
    left: 0;
    top: 0;
    z-index: 99;
    background-color: white;
    box-shadow: 0px 4px 6px 0px #ebebeb;
  }

  .dashboard_new_mobile_nav .logo {
    width: 100px;
  }

  .dashboard_new_header_container {
    display: none;
  }

  .dashboard_newcourse_container {
    display: none;
  }

  .dashboard_new_mobile_course_container h2 {
    font-size: 16px;
    font-weight: 700;
    color: #333333;
    margin-bottom: 10px;
  }

  .dashboard_new_mobile_course_container {
    margin-top: 40px;
    padding-inline: 10px;
  }

  .dashboard_ticket_chats_box .dashboard_ticket_chats_box_header {
    padding: 18px 10px;
  }

  .dashboard_ticket_chats_box .dashboard_ticket_chats_box_header svg {
    font-size: 18px;
  }

  .dashboard_new_mobile_course_container .dashboard_new_mobile_course_div {
    /* background-image: url("../images/new_dash_course.png"); */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    display: flex;
    height: 107px;
    overflow: hidden;
    border-radius: 15px;
    border: 1px solid rgba(210, 210, 210, 1);
  }

  .dashboard_new_mobile_course_div .dashboard_new_mobile_course_div_content {
    width: 98%;
    background-image: url("../images/new_dash_linear2.png");
    padding: 10px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .dashboard_new_mobile_course_div_content h3 {
    color: white;
    font-weight: 400;
    font-size: 14px;
    width: 55%;
  }

  .dashboard_new_mobile_course_div_content .view_btn_div {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .dashboard_new_mobile_course_div_content .view_btn_div img {
    width: 22px;
    height: 22px;
    object-fit: cover;
    border-radius: 50%;
  }

  .dashboard_new_mobile_course_div_content .view_btn_div h4 {
    color: white;
    font-weight: 700;
    font-size: 15px;
    margin: 0;
    text-decoration: underline;
  }

  .dashboard_schedule_mobile_container h2 {
    font-size: 16px;
    color: rgba(51, 51, 51, 1);
    font-weight: 700;
    margin-bottom: 10px;
  }

  .dashboard_schedule_mobile_container
    .dashboard_schedule_mobile_upcoming_class {
    box-shadow: 0px 0px 5.1px 0px rgba(210, 210, 210, 1);
    border-radius: 4px;
    padding: 12px;
    display: flex;
    gap: 10px;
  }

  .dashboard_schedule_mobile_upcoming_class .upcoming_class_img {
    border-radius: 4px;
    width: 137px;
    height: 111px;
    position: relative;
  }

  .dashboard_schedule_mobile_upcoming_class .upcoming_class_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  .dashboard_schedule_mobile_upcoming_class
    .upcoming_class_img
    .countdown_live_schedule {
    padding-inline: 6px;
  }

  .dashboard_schedule_mobile_upcoming_class
    .upcoming_class_img
    .countdown_live_schedule
    h6 {
    font-size: 8px;
  }

  .dashboard_schedule_mobile_upcoming_class .upcoming_class_content h3 {
    color: rgba(13, 42, 65, 1);
    font-size: 10px;
    font-weight: 500;
    text-transform: capitalize;
  }

  .dashboard_schedule_mobile_upcoming_class .upcoming_class_content p {
    color: rgba(125, 129, 133, 1);
    font-size: 8px;
    font-weight: 400;
  }

  .dashboard_schedule_mobile_upcoming_class .upcoming_class_profile_name {
    display: flex;
    align-items: center;
    color: rgba(116, 116, 116, 1);
    font-size: 10px;
    font-weight: 400;
    gap: 10px;
    margin-bottom: 10px;
  }

  .upcoming_class_profile_name img {
    width: 22px;
    height: 22px;
    object-fit: cover;
  }

  .dashboard_ticket_chats_container .dashboard_ticket_chats_list {
    width: 100%;
  }

  .dashboard_ticket_chats_listitem_detail h4 {
    flex-direction: column;
    gap: 4px;
  }

  .dashboard_ticket_chats_container .dashboard_ticket_chats_list_items {
    height: 59vh;
  }

  .dashboard_ticket_chats_box_message_container .dashboard_ticket_chatlist {
    height: 36vh;
  }
  .dashboard_ticket_chats_listitem_detail {
    width: 70%;
  }
  .dashboard_new_work_container {
    display: none;
  }

  .dashboardnew_calender_container {
    display: none;
  }

  .dashboard_schedulelist_mobile_container {
    margin-top: 24px;
  }

  .dashboard_schedulelist_mobile_container h2 {
    font-size: 16px;
    font-weight: 700;
    color: rgba(28, 30, 36, 1);
    margin-bottom: 10px;
  }

  .dashboard_schedulelist_mobile_container .dashboard_schedulelist_mobile_item {
    display: flex;
    align-items: center;
    border-radius: 8px;
    justify-content: space-between;
    color: black;
    background-color: rgba(246, 250, 251, 1);
    border-left: 8px solid rgba(2, 76, 135, 1);
    padding: 11px;
    margin-bottom: 10px;
  }

  .dashboard_schedulelist_mobile_item h3 {
    color: rgba(31, 31, 31, 1);
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 1px;
  }

  .dashboard_schedulelist_mobile_item span {
    font-size: 10px;
    font-weight: 400;
    color: rgba(31, 31, 31, 1);
  }

  .dashboard_schedulelist_mobile_item p {
    font-weight: 400;
    font-size: 12px;
    color: rgba(2, 76, 135, 1);
    margin-bottom: 1px;
  }

  .dashboard_schedulelist_mobile_item
    .dashboard_schedulelist_mobile_item_header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .dashboard_schedulelist_mobile_item_header h5 {
    font-size: 14px;
    text-transform: capitalize;
    color: #024c87;
  }

  .dashboard_schedulelist_mobile_item .three-dots {
    color: rgba(140, 140, 140, 1);
    display: block;
    margin-left: auto;
  }

  .dashboard_new_mobile_footer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 9px;
    /* gap: 30px; */
  }

  .dashboard_new_mobile_footer .dashboard_new_mobile_footer_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    color: #afacab;
    font-size: 12px;
  }

  .dashboard_new_mobile_footer .dashboard_new_mobile_footer_div svg {
    width: 40px;
  }

  .dashboard_new_mobile_footer
    .dashboard_new_mobile_footer_div.active
    svg
    path {
    fill: #ffffff;
  }

  .dashboard_new_mobile_footer .dashboard_new_mobile_footer_div.active {
    color: white;
  }

  .dashboard_mobile_course_video_page {
    color: black;
    margin-top: 15px;
  }

  .dashboard_mobile_course_video_page .dashboard_mobile_course_video_page_tabs {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 22px;
  }

  .dashboard_mobile_course_video_page_tabs h3 {
    color: rgba(51, 51, 51, 1);
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 10px;
  }

  .dashboard_mobile_course_video_page_tabs h3.active {
    border-bottom: 3px solid black;
    border-radius: 2px;
  }

  .dashboard_mobile_course_video_page_overview {
    margin-top: 14px;
  }

  .dashboard_mobile_course_video_page_overview h3 {
    color: rgba(13, 42, 65, 1);
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
  }

  .dashboard_mobile_course_video_page_overview
    .dashboard_mobile_course_video_page_profile {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 12px;
  }

  .dashboard_mobile_course_video_page_profile img {
    width: 20px;
    border-radius: 50%;
    height: 20px;
  }

  .dashboard_mobile_course_video_page_profile span {
    color: rgba(116, 116, 116, 1);
    font-size: 12px;
  }

  .dashboard_mobile_course_video_page_overview p {
    color: rgba(125, 129, 133, 1);
    font-size: 12px;
  }

  .dashboard_mobile_course_video_page_list {
    display: flex;
    align-items: center;
    border-radius: 8px;
    /* margin-bottom: 10px; */
    padding: 10px;
  }

  .dashboard_mobile_course_video_page_list.active {
    border-radius: 8px;
    background: rgba(217, 217, 217, 0.61);
    padding: 10px;
  }

  .dashboard_mobile_course_video_page_list
    .dashboard_mobile_course_video_page_list_img
    img {
    width: 90px;
    height: 54px;
  }

  .dashboard_mobile_course_video_page_list
    .module_course_video_page_list_title
    h4 {
    color: rgba(13, 42, 65, 1);
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
  }

  .dashboard_mobile_course_video_page_list
    .module_course_video_page_list_title {
    margin-left: 10px;
    /* padding: 10px; */
  }

  .module_course_video_page_list_title .module_course_video_page_list_profile {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .module_course_video_page_list_profile img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
  }

  .module_course_video_page_list_profile span {
    color: rgba(116, 116, 116, 1);
    font-size: 12px;
    font-weight: 400;
  }

  .dashboard_mobile_course_video_page_list #mobile_course_video_page_btn {
    width: 23px;
    height: 23px;
    margin-left: auto;
  }

  .dashboard_mobile_course_study_container {
    padding-inline: 20px;
    margin-top: 20px;
  }

  .dashboard_mobile_course_study_container h2 {
    color: rgba(51, 51, 51, 1);
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  .dashboard_mobile_course_study_container
    .dashboard_mobile_course_study_material
    .col-6 {
    /* display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 15px; */
    margin-top: 12px;
  }

  .dashboard_mobile_course_study_material_box svg {
    height: 30px;
    width: 30px;
    scale: 1;
  }

  .dashboard_mobile_course_refrence
    .dashboard_mobile_course_study_material_box
    svg {
    height: 20px;
    width: 20px;
  }
  .dashboard_assignment_list .dashboard_assignment_filter {
    margin-top: 35px;
  }

  .dashboard_mobile_course_study_material
    .dashboard_mobile_course_study_material_box {
    display: flex;
    align-items: center;
    gap: 10px;
    box-shadow: 0px 0px 4px 0px rgba(217, 217, 217, 1);
    padding: 8px;
    border-radius: 8px;
    justify-content: space-between;
  }

  .dashboard_mobile_course_study_material
    .dashboard_mobile_course_study_material_box
    h5 {
    font-weight: normal;
    font-size: 12px;
    margin: 0;
    text-wrap: wrap;
  }

  .dashboard_mobile_course_study_material .pdf {
    width: 22px;
    height: 27px;
  }

  .dashboard_mobile_course_study_material .download {
    width: 14px;
    height: 14px;
  }

  .dashboard_mobile_course_video_qna_container {
    padding-inline: 10px;
    margin-top: 30px;
    position: relative;
    height: 35vh;
  }

  .dashboard_mobile_course_video_qna_container h2 {
    color: rgba(51, 51, 51, 1);
    font-weight: 500;
    font-size: 15px;
    margin: 0;
  }
  .dashboard_mobile_course_video_qna_container h3 {
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
    font-size: 14px;
    padding-bottom: 10px;
    text-transform: capitalize;
    border-bottom: 1px dashed rgba(87, 87, 87, 1);
  }
  .dashboard_mobile_course_video_qna_container p {
    color: rgba(125, 129, 133, 1);
    font-size: 12px;
    font-weight: 400;
    margin-top: 14px;
    margin-bottom: 0;
  }

  .dashboard_mobile_course_video_qna_container
    .accordion-button:not(.collapsed) {
    background: none;
  }

  .dashboard_mobile_course_video_qna_container .accordion-button:focus {
    box-shadow: none;
  }

  .dashboard_mobile_course_video_qna_container .accordion-item {
    border: none;
    margin-bottom: 10px;
  }

  .dashboard_mobile_course_video_qna_container .accordion-header {
    box-shadow: 0px 0px 4px 0px rgba(193, 193, 193, 1);
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .dashboard_mobile_course_video_qna_container .accordion-body {
    box-shadow: 0px 0px 4px 0px rgba(193, 193, 193, 1);
    border-radius: 5px;
  }

  .dashboard_mobile_course_video_qna_input {
    position: fixed;
    bottom: 90px;
    /* left: 0; */
    border: 1px solid rgba(229, 229, 229, 1);
    border-radius: 8px;
    width: 95%;
    overflow: hidden;
    /* right: 0; */
  }

  .dashboard_mobile_course_video_qna_input input {
    width: 100%;
    border: 0;
    outline: 0;
    padding: 10px 12px;
    color: rgba(217, 217, 217, 1);
  }

  .dashboard_mobile_course_video_qna_input input::placeholder {
    color: rgba(217, 217, 217, 1);
  }

  .dashboard_mobile_course_video_qna_input .send_btn {
    position: absolute;
    right: 16px;
    font-size: 22px;
    top: 9px;
    color: rgba(217, 217, 217, 1);
  }

  .dashboard_mobile_course_video_qna_profile_div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .dashboard_mobile_course_video_qna_profile_div img {
    width: 28px;
    height: 28px;
    object-fit: cover;
  }

  .dashboard_mobile_course_video_qna_profile_div .username {
    margin-left: 10px;
  }

  .dashboard_mobile_course_video_qna_profile_div .username h4 {
    margin: 0;
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
    font-weight: 500;
  }

  .dashboard_mobile_course_video_qna_profile_div .username p {
    margin: 0;
    font-size: 12px;
    color: rgba(188, 188, 188, 1);
    font-weight: 400;
  }

  .dashboard_mobile_course_video_qna_profile_div span {
    margin-left: auto;
    color: rgba(188, 188, 188, 1);
    font-size: 12px;
    font-weight: 400;
  }

  /* .dashboard_mobile_video_container {
    position: relative;
  }

  .dashboard_mobile_video_container .mobile_course_play {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    transform: translate(-50%, -50%);
  } */

  /* end */
  .new_featured_program_container {
    display: none;
  }

  .new_featured_mobile_program_container {
    display: block;
  }
  .dashboard_ticket_container .dashboard_ticket_header {
    display: none;
  }
  .dashboard_helpcenter_box {
    /* position: absolute; */
    right: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    top: auto;
    /* height: 583px; */
    /* top: 23%; */
    border-radius: 12px 12px 0 0;
  }

  .dashboard_helpcenter_contact_query_input
    .dashboard_helpcenter_contact_query_input_box
    input {
    width: 265px;
  }

  .student_university_header p {
    width: auto;
    /* color: rgba(116, 116, 116, 1); */
    font-size: 16px;
  }

  .student_university_header h2 {
    font-size: 24px;
  }

  .student_university_container .student_university_showmore_btn {
    width: 140px;
    height: 40px;
    font-size: 16px;
  }

  .student_placed_banner_container_div h2 {
    font-size: 24px;
  }

  .student_placed_banner_container_div p {
    font-size: 16px;
  }

  .student_placed_banner_container_div .student_placed_banner_left {
    padding-top: 24px;
  }

  .student_university_container .student_university_content {
    margin-block: 30px;
  }
  /*  */
  .dashboard_certificate_header h2 {
    font-size: 15px;
  }
  .dashboard_certificate_header p {
    font-size: 11px;
  }
  .dashboard_certificate_header img {
    width: 100px;
    right: 5px;
    top: 22%;
  }

  .dashboard_certificate_container {
    padding: 15px;
    padding-top: 30px;
  }

  .dashboard_certificate_container .dashboard_certificate_header {
    padding: 15px;
  }

  .dashboard_certificate_download_section button {
    display: none;
  }

  .dashboard_certificate_download_section
    .dashboard_certificate_mobile_download_btn {
    display: block;
    width: 36px;
    height: 36px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dashboard_certificate_container .dashboard_certificate_download_section h2 {
    font-size: 20px;
    font-weight: 700;
  }

  .dashboard_certificate_download_section h3 {
    font-size: 16px;
    font-weight: 500;
  }

  .dashboard_certificate_refer_container img {
    display: none;
  }

  .dashboard_certificate_refer_container h3 {
    font-size: 16px;
    font-weight: 700;
  }

  .dashboard_certificate_input button {
    font-size: 14px;
    width: 136px;
    height: 36px;
  }

  .dashboard_certificate_input {
    width: 100%;
    padding: 14px;
    margin-top: 14px;
  }

  .dashboard_certificate_input label {
    font-size: 12px;
  }

  .dashboard_certificate_refer_container {
    padding-block: 18px;
    padding: 10px;
    margin-top: 30px;
  }

  .dashboard_certificate_input input {
    font-size: 16px;
  }

  .dashboard_certificate_rating_container h2 {
    font-size: 20px;
  }

  .dashboard_certificate_rating_container p {
    font-size: 12px;
  }

  .dashboard_certificate_container .dashboard_certificate_rating_container {
    padding: 16px;
  }

  .dashboard_certificate_refer_container
    .dashboard_certificate_refer_container_header {
    width: 100%;
  }

  .dashboard_certificate_img .certificate_img {
    width: 116px;
  }

  .dashboard_certificate_img {
    align-items: end;
  }

  .dashboard_certificate_container .certificate_thankyou_text {
    display: none;
  }

  .dashboard_certificate_container .certificate_triangle {
    display: none;
  }

  .dashboard_certificate_container .dashboard_certificate_cards_container {
    display: none;
  }

  .dashboard_course_mobile_container_div {
    margin-top: 30px;
    color: #333333;
    /* padding-lef: 10px; */
  }

  .dashboard_course_mobile_container_div .dashboard_course_mobile_container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .dashboard_course_mobile_container .dashboard_course_mobile_div {
    box-shadow: 0px 0px 4px 0px rgba(217, 217, 217, 1);
    border-radius: 5px;
    padding: 12px 19px;
    display: flex;
  }

  .dashboard_course_mobile_div img {
    width: 100px;
    object-fit: cover;
    border-radius: 4px;
  }

  .dashboard_course_mobile_div h3 {
    font-weight: 400;
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
  }

  .dashboard_course_mobile_div p {
    font-weight: 700;
    font-size: 15px;
    color: rgba(51, 51, 51, 1);
    text-transform: capitalize;
    text-decoration: underline;
    /* margin-top: auto; */
    margin: 0;
  }

  .dashboard_mobile_course_thumbnail_container {
    margin-top: 13px;
  }

  .dashboard_mobile_course_thumbnail_container
    .dashboard_mobile_course_thumbnail_div
    img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  .dashboard_mobile_course_thumbnail_div
    .dashboard_mobile_course_thumbnail_content {
    padding: 10px;
    color: black;
  }

  .dashboard_mobile_course_thumbnail_content h4 {
    color: rgba(51, 51, 51, 1);
    font-size: 14px;
    font-weight: 400;
  }

  .dashboard_mobile_course_thumbnail_content
    .dashboard_mobile_course_thumbnail_profile {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .dashboard_mobile_course_thumbnail_container
    .dashboard_mobile_course_thumbnail_profile
    img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
  }

  .dashboard_mobile_course_thumbnail_profile p {
    margin: 0;
    color: rgba(116, 116, 116, 1);
    font-weight: 400;
    font-size: 12px;
  }

  .dashboard_mobile_course_thumbnail_div .dashboard_mobile_course_tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-top: 10px;
  }

  .dashboard_mobile_course_tabs h3 {
    font-weight: 500;
    font-size: 16px;
  }

  .dashboard_mobile_course_tabs .active {
    border-bottom: 2px solid #000;
  }

  .dashboard_mobile_course_overview span {
    color: rgba(125, 129, 133, 1);
    font-size: 12px;
    font-weight: 400;
  }

  .dashboard_mobile_course_overview p {
    color: rgba(125, 129, 133, 1);
    font-size: 12px;
    font-weight: 400;
  }

  .dashboard_mobile_course_module_container {
    margin-top: 10px;
  }

  .dashboard_mobile_course_module_container .accordion-button:not(.collapsed) {
    background: none;
    /* padding: 10px; */
  }

  .dashboard_mobile_course_module_container .accordion-button:focus {
    box-shadow: none;
  }

  .dashboard_mobile_course_module_container .accordion-button::after {
    content: none;
  }

  .dashboard_mobile_course_module_container .accordion-button {
    padding: 10px;
    display: flex;
    align-items: flex-start;
  }

  .dashboard_mobile_course_module_container .accordion-item {
    box-shadow: 0px 0px 4px 0px rgba(193, 193, 193, 1);
    border: 0;
    margin-bottom: 20px;
    border-radius: 5px;
    overflow: hidden;
  }

  .dashboard_mobile_course_module_container .accordion-body {
    padding: 0;
  }

  .dashboard_mobile_course_module_container
    .dashboard_mobile_course_module_acc_header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }

  .dashboard_mobile_course_module_acc_header .dashboard_mobile_course_acc_icon {
    min-width: fit-content;
    color: rgba(2, 76, 135, 1);
  }

  .dashboard_mobile_course_module_container
    .dashboard_mobile_course_module_acc_header
    h2 {
    color: rgba(87, 87, 87, 1);
    font-size: 17px;
    font-weight: 500;
    margin: 0;
  }

  .dashboard_mobile_course_module_acc_header span {
    color: rgba(217, 217, 217, 1);
    font-size: 13px;
    font-weight: 400;
  }

  .dashboard_mobile_course_module_acc_header p {
    font-size: 12px;
    margin-bottom: 0;
    font-weight: 400;
    color: rgba(125, 129, 133, 1);
    margin-top: 4px;
  }

  .dashboard_mobile_course_module_videos_item_container {
    padding-inline: 10px;
    padding-block: 10px;
  }

  .dashboard_mobile_course_module_videos_item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .dashboard_mobile_course_module_videos_item
    .dashboard_mobile_course_module_videos_item_img
    img {
    width: 90px;
    height: 54px;
    object-fit: cover;
    border-radius: 3px;
  }

  .dashboard_mobile_course_module_videos_item .module_course_videos_item_title {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }

  .module_course_videos_item_title h4 {
    color: rgba(13, 42, 65, 1);
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
  }

  .module_course_videos_item_title .module_course_videos_item_profile {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .module_course_videos_item_title .module_course_videos_item_profile img {
    width: 20px;
    height: 20px;
    object-fit: cover;
    border-radius: 50%;
  }

  .module_course_videos_item_profile p {
    margin-bottom: 0;
    color: rgba(116, 116, 116, 1);
    font-size: 12px;
  }

  .dashboard_mobile_course_module_videos_item #module_course_video_btn {
    width: 23px;
    height: 23px;
    margin-left: auto;
  }

  .blog_cards_data {
    display: none;
  }

  .blog_dashboard_home_blog_no img {
    width: 100%;
  }

  .blog_dashboard_register_form {
    padding: 20px;
  }

  .blog_dashboard_register {
    padding: 10px;
  }

  .blog_dashboard_register_form h1 {
    font-size: 24px;
  }

  .blog_dashboard_profile_container {
    padding-inline: 20px;
  }

  .blog_dashboard_choose_domain_container .blog_dashboard_upload_thumb {
    padding-inline: 20px;
    width: auto;
  }

  .blog_dashboard_home_blog_cards_img {
    width: 100%;
  }

  .blog_dashboard_home_blog_cards_img img {
    object-fit: cover;
    height: 200px;
    width: 100%;
  }

  .blog_dashboard_home_blog_cards p {
    width: auto;
  }

  .blog_dashboard_insert_content_div .blog_dashboard_insert_content_data {
    width: auto;
  }

  .blog_dashboard_insert_content_btn::before {
    width: 0;
  }

  .blog_dashboard_insert_content_btn::after {
    width: 0;
  }

  .blog_dashboard_register_form {
    padding: 20px;
  }

  .placed_candidate_testi_item {
    max-width: 100%;
  }

  .placed_candidate_testi_carousel .owl-nav .owl-prev button,
  .placed_candidate_testi_carousel .owl-nav .owl-next button {
    display: none;
  }

  .ourteam_carousel.dashboard_newcourse_carousel .owl-nav .owl-prev,
  .ourteam_carousel.dashboard_newcourse_carousel .owl-nav .owl-next {
    display: none;
  }

  .mentor_card_circle_main_div {
    display: none;
  }

  .mentor_team_container {
    padding-bottom: 65px;
  }

  .blog_description_content_container .blog_dashboard_choose_domain_three_btn {
    bottom: -4%;
    flex-direction: column-reverse;
    gap: 10px;
  }

  .blog_account_type_select {
    flex-direction: column;
  }

  .blog_account_type_select_box {
    width: 100%;
  }

  .blog_signup_form input {
    width: 100%;
  }

  .blog_signup_form button {
    width: 100%;
  }

  .blog_dashboard_form_container .blog_dashboard_upload_img {
    width: 100%;
    height: 350px;
  }

  .blog_dashboard_profile_update {
    left: 105px;
  }

  .blog_dashboard_banner .blog_dashboard_cover_edit {
    width: 50px;
    height: 50px;
  }

  .blog_dashboard_banner .blog_dashboard_cover_edit svg {
    font-size: 22px;
  }

  .blog_cards_right_container {
    padding-inline: 10px;
  }

  .blogbanner_container .blogbanner_card .blogbanner_card_inp {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
  }

  .blogbanner_container .blogbanner_card .blogbanner_card_inp button {
    width: 90%;
  }

  .blogbanner_container .blogbanner_card .blogbanner_card_inp input {
    width: 90%;
  }

  .blog_desc_container .blog_desc_right .blog_desc_item1 {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .blog_desc_container .blog_desc_right .blog_desc_item2 {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .blog_desc_container .blog_desc_right .blog_desc_item3 {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-inline: 0;
  }

  .blogbanner_container .blogbanner_card .blogbanner_card_wrapper {
    width: 90%;
  }

  .blogbanner_container .blogbanner_left h1 {
    font-size: 24px;
    padding-inline: 20px;
    text-align: center;
    font-weight: 500;
  }

  .blogbanner_container .blogbanner_card {
    display: none;
  }

  .blogbanner_container .blogbanner_left {
    width: 100%;
  }

  .blogbanner_right {
    display: none;
  }

  .blog_cards_searchbar .blog_cards_searchbar_tab span::before {
    background: none;
  }

  .blog_cards_searchbar_tab h3 {
    font-weight: 500;
  }

  .blogbanner_container {
    padding-top: 60px;
    padding-bottom: 40px;
  }

  .blog_cards_searchbar {
    padding-block: 0;
    padding-inline: 0;
  }

  .blog_cards_container {
    padding-bottom: 0;
  }

  .blog_desc_container .blog_desc_left h1 {
    color: black;
    font-size: 24px;
  }

  .blog_desc_container .blog_desc_left p {
    font-size: 16px;
  }

  .blog_cards_data_slider {
    display: none;
  }

  .blog_desc_container .blog_desc_right .blog_desc_item h1 {
    font-size: 16px;
  }

  .blog_desc_container .blog_desc_right .blog_desc_item h2 {
    font-size: 16px;
  }

  .blog_desc_container .blog_desc_right .blog_desc_item {
    padding: 30px;
    text-align: left;
    box-shadow: 0px 0px 5px 1px rgba(217, 217, 217, 1);
  }

  .blog_gallery_container::after {
    /* bottom: 0; */
    width: 200px;
    height: 200px;
    top: 27%;
  }

  .blog_desc_container {
    padding-top: 0;
  }

  .blog_joinus_container .blog_joinus_right .blog_tagone {
    box-shadow: 0px 0px 5px 1px rgba(217, 217, 217, 1);
    padding: 16px;
  }

  .blog_joinus_container .blog_joinus_right .blog_tagtwo {
    box-shadow: 0px 0px 5px 1px rgba(217, 217, 217, 1);
    padding: 16px;
  }

  .blog_joinus_container .blog_joinus_left h1 {
    font-size: 24px;
    margin-bottom: 16px;
  }

  .blog_joinus_container .blog_joinus_left p {
    font-size: 16px;
  }

  .blog_joinus_container .blog_joinus_right .blog_tagone h2 {
    margin: 0;
    font-size: 16px;
    color: black;
    font-weight: 500;
  }

  .blog_joinus_container .blog_joinus_right .blog_tagtwo h2 {
    font-size: 16px;
    color: black;
    margin-bottom: 10px;
    margin-top: 0;
  }

  .blog_joinus_container .blog_joinus_right .blog_tagtwo p {
    font-size: 16px;
  }

  .blog_card_main .blog_card_main_content {
    border-radius: 0;
    height: auto;
  }

  .blog_desc_card_container .blog_card_right {
    display: none;
  }

  .blog_card_main .blog_card_main_content {
    border-radius: 0;
  }

  .blog_card_main img {
    border-radius: 0;
  }

  .blog_main_badge .blog_badge_circle {
    width: 12px;
    height: 12px;
  }

  .blog_dashboard_mobile_header {
    /* margin-top: ; */
    display: block;
    box-shadow: 0px 4px 6px 0 rgba(235, 235, 235, 1);
  }

  .blog_dashboard_home .blog_dashboard_home_navbar {
    display: none;
  }

  .blog_dashboard_home_content {
    margin-top: 0;
    padding-inline: 0;
  }

  .blog_dashboard_home_content::after {
    content: "";
    background: 0;
  }

  .blog_dashboard_home_content_tabs {
    justify-content: center;
    margin-top: 0;
  }

  .blog_dashboard_home_content_tabs button {
    background-color: none;
    border: 0;
  }

  .blog_dashboard_home_content_tabs button.active {
    background-color: transparent;
    border-radius: 0;
    border-bottom: 3px solid black;
  }

  .blog_dashboard_home_blog_cards {
    flex-direction: column;
    padding: 0;
  }

  .blog_dashboard_home_content_left {
    padding: 20px;
  }
  .blog_dashboard_hamburger_icon {
    right: 10%;
    display: none;
  }

  .blog_dashboard_home {
    overflow-x: hidden;
  }
  .blog_dashboard_home_content_right {
    display: none;
  }
  .blog_dashboard_home_content .blog_dashboard_home_divider_div {
    display: none;
  }
  .blog_dashboard_home_blog_cards {
    display: none;
  }

  .blog_dashboard_choose_domain h2 {
    text-align: center;
  }

  .blog_dashboard_choose_domain p {
    text-align: center;
  }

  .blog_dashboard_choose_domain_container {
    padding-top: 70px;
    padding-bottom: 20px;
    height: auto;
  }
  .blog_dashboard_choose_domain .blog_dashboard_choose_domain_next_btn {
    padding-inline: 20px;
  }

  .blog_dashboard_choose_domain_container .blog_dashboard_upload_thumb {
    border: 0;
  }

  .blog_dashboard_upload_thumb p {
    font-size: 18px;
    width: fit-content;
  }

  .blog_dashboard_upload_thumb_container {
    flex-direction: column-reverse;
    padding-top: 20px;
  }

  .blog_dashboard_choose_domain_three_btn button {
    background-color: transparent;
    border: 0;
    font-size: 14px;
    /* padding: 0; */
  }

  .blog_dashboard_choose_domain_three_btn
    .blog_dashboard_choose_domain_cancel_btn {
    border: 0;
    padding: 0;
    font-size: 30px;
    color: gray;
  }

  .blog_dashboard_choose_domain_three_btn
    .blog_dashboard_choose_domain_cancel_btn
    .blog_dashboard_choose_domain_mobile_arrow {
    font-size: 30px;
    color: gray;
  }

  .blog_dashboard_upload_thumb h2 {
    font-size: 24px;
  }

  .blog_dashboard_insert_content_container {
    flex-direction: column-reverse;
    justify-content: flex-end;
    gap: 40px;
    padding-top: 30px;
    height: auto;
  }

  .blog_dashboard_insert_content_data input {
    font-size: 24px;
  }

  .blog_dashboard_insert_content_rightbar_middle
    .blog_dashboard_insert_content_rightbar_middle_cross {
    position: absolute;
    top: -19px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 0px 4px 0px rgba(235, 235, 235, 1);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 8px;
    background-color: white;
  }

  .blog_dashboard_editor_container {
    padding-top: 80px;
  }

  .blog_dashboard_editor_container .blog_dashboard_edit_inp_item {
    gap: 20px;
  }

  .blog_dashboard_insert_content_container .blog_dashboard_edit_options {
    flex-direction: row;
    justify-content: space-between;
    width: 245px;
    margin: auto;
    align-items: center;
  }

  .blog_dashboard_edit_options .blog_dashboard_edit_hr {
    width: 10%;
    transform: rotate(90deg);
    margin-inline: 10px;
  }

  #blog_dashboard_preview_back_btn {
    background-color: black;
    padding: 12px 24px;
    margin-left: auto;
  }

  #blog_dashboard_preview_back_btn p {
    color: white;
    font-size: 16px;
    margin: 0;
  }

  .blog_dashboard_insert_content_rightbar.active {
    top: 59%;
    width: 100%;

    border: 0;
    border-radius: 50px;
    box-shadow: 0px -1px 4px 0 rgba(235, 235, 235, 1);
    padding-top: 40px;
  }

  .blog_card_main {
    height: auto;
  }

  .program_subheader_detail .program_discount_text h3 {
    display: none;
  }

  .middle_team_heading {
    padding-top: 20px;
  }

  .job_categories_right .job_categories_options {
    padding: 0;
    gap: 0;
  }

  .job_categories_right .job_categories_options .job_categories_select select {
    width: 110px;
    font-size: 16px;
  }

  .job_categories_right .job_categories_options .job_categories_select label {
    font-size: 16px;
    margin-bottom: 6px;
  }
  .job_categories_right {
    padding-inline: 10px;
  }

  .job_how_works_container h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .job_categories_right .job_Categories_hr {
    display: none;
  }
  .job_middle_section_container .job_middle_left .job_middle_inp_group button {
    font-size: 16px;
    width: 110px;
  }
  .job_cards_container {
    padding: 0 0 65px 0;
  }

  .terms_and_condition_container,
  .return_refund_container,
  .privacy_policy_container {
    padding-top: 40px;
  }
  .study_and_internship_banner_container {
    padding-top: 60px;
  }

  .placed_candidate_customer_container {
    margin: 0;
  }
  .study_internship_program_container {
    padding: 0 0 65px 0;
  }
  .study_and_internship_banner_container
    .study_and_internship_banner_left
    .study_and_internship_banner_heading
    h1
    svg {
    margin-bottom: 0;
    width: 50px;
  }

  .what_testimonies_reach_container {
    padding: 0 0 65px 0;
  }
  .placed_candidate_testi_info_mobile .location {
    color: white;
  }
  .placed_candidate_map_container {
    padding: 0;
  }
  .placed_candidate_advice_container {
    padding-top: 0;
  }
  .placed_candidate_map_container .placed_candidate_map_heading {
    margin-bottom: 0;
  }
  /*  */
  .about_middle_container h1 {
    font-size: 28px;
  }
  .about_location_container .about_location_cards .about_card_item h1 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  .about_location_container .about_location_cards .about_card_item p {
    font-size: 18px;
  }
  .about_location_container .about_location_map h2 {
    font-size: 24px;
  }
  .about_location_container .about_location_map h1 {
    font-size: 28px;
  }
  .about_tab_container {
    margin-top: 40px;
  }
  .about_tab_container .about_tab_content {
    font-size: 18px;
  }
  .about_tab_container .about_tab_btn_1 span {
    font-size: 18px;
  }
  .about_tab_container .about_tab_btn_2 span {
    font-size: 18px;
  }
  .about_story_container .about_story_right .about_story_right_one h1 {
    font-size: 20px;
  }
  .about_story_container .about_story_right .about_story_right_one h2 {
    font-size: 18px;
  }
  .about_story_container .about_story_right .about_story_right_two p {
    font-size: 18px;
  }
  .about_story_container .about_story_list ul li h3 {
    font-size: 20px;
  }
  .about_story_container .about_story_list ul li p {
    font-size: 18px;
  }
  .about_mission_container .about_mission_right h1 {
    font-size: 28px;
  }
  .about_mission_container .about_mission_right p {
    font-size: 20px;
  }
  .about_mission_container .about_mission_right {
    gap: 5px;
  }
  .about_footer_container .footer_second_div .about_footer_heading h1 {
    font-size: 28px;
    padding: 0;
  }
  .about_footer_container .footer_second_div .about_footer_heading {
    margin: 0;
  }
  .about_mission_container .about_vision_left {
    margin-top: 0;
  }
  .about_location_container .about_location_map {
    margin-top: 30px;
  }
  .meet_team_middle_container .middle_team_heading h1 {
    font-size: 28px;
  }
  .meet_team_middle_container .middle_team_heading button {
    padding-inline: 20px;
    font-size: 16px;
  }
  .meet_team_middle_main {
    padding-bottom: 0;
  }
  .meet_founder_container .meet_founder_right h2 {
    font-size: 28px;
  }
  .meet_founder_container .meet_founder_right h1 {
    font-size: 28px;
  }
  .meet_founder_container .meet_founder_right p {
    font-size: 18px;
  }
  .meet_founder_container .meet_founder_right h4 {
    font-size: 28px;
  }
  .meet_founder_container .meet_founder_right h6 {
    font-size: 18px;
  }
  .meet_global_team_container .meet_global_heading h1 {
    font-size: 28px;
  }
  .meet_team_container .meet_team_heading h1 {
    font-size: 28px;
  }
  .meet_global_team_container .meet_global_heading h1 {
    margin-top: 0;
  }
  .meet_global_team_container {
    padding-bottom: 0;
  }
  .meet_footer_container h1 {
    font-size: 18px;
  }
  .meet_footer_container h2 {
    font-size: 22px;
  }
  .meet_footer_container button {
    padding-inline: 40px;
    font-size: 18px;
  }
  .meet_team_container .meet_team_card_container {
    padding-bottom: 0;
  }
  .meet_team_middle_container {
    height: auto;
  }
  .job_middle_section_container .job_middle_left h1 {
    font-size: 28px;
  }
  .job_middle_section_container .job_middle_left p {
    font-size: 18px;
  }
  .job_solution_container .job_solution_first h1 {
    font-size: 28px;
  }
  .job_solution_container .job_solution_first .job_solution_item h4 {
    font-size: 28px;
  }
  .job_solution_container .job_solution_second h1 {
    font-size: 28px;
  }
  .job_solution_container .job_solution_second p {
    font-size: 18px;
  }
  .job_team_join_container .job_team_left h1 {
    font-size: 28px;
  }
  .job_team_join_container .job_team_left p {
    font-size: 18px;
  }
  .job_cards_container .job_cards_heading h1 {
    font-size: 28px;
  }
  .mobile_nav_header .mobile_nav_logo {
    padding-bottom: 0;
  }
  .contactus_card_container .contactus_button_wrapper button {
    margin-bottom: 20px;
  }
  .contactus_card_container .contactus_card_item h4 {
    height: 0;
  }
  .our_policy_banner_container .our_policy_left h1 {
    font-size: 28px;
  }
  .our_policy_banner_container .our_policy_right p {
    font-size: 16px;
  }
  .study_and_internship_banner_container
    .study_and_internship_banner_left
    .study_and_internship_banner_heading
    h1 {
    font-size: 28px;
  }
  .study_and_internship_banner_container
    .study_and_internship_banner_left
    .study_and_internship_banner_heading
    h2 {
    font-size: 16px;
    line-height: 25px;
  }
  .study_and_internship_banner_left .study_internship_banner_start_desc {
    align-items: flex-start;
  }
  .study_and_internship_card_container
    .study_and_internship_card_tab2
    .study_and_internship_card_tab2_heading
    h4 {
    font-size: 20px;
  }
  .study_internship_program_container .study_internship_program_heading h1 {
    font-size: 24px;
  }
  .whatTestimonies_client_container .whatTestimonies_client_heading h1 {
    font-size: 24px;
  }
  .whatTestimonies_client_container .whatTestimonies_client_heading h2 {
    font-size: 18px;
  }
  .sec-title h2 br {
    display: none;
  }
  .sec-title h2 {
    font-size: 22px !important;
    line-height: 30px;
  }
  .signup-section .title-column .sec-title .text {
    font-size: 16px;
  }
  .study_internship_footer_container .study_internship_footer_heading h4 {
    font-size: 18px;
  }
  .what_testimonies_reach_container .what_testimonies_reach_heading h1 {
    font-size: 28px;
  }
  .community_placed_container .placed_heading h1 {
    font-size: 34px;
  }
  .placed_heading::after {
    display: none;
  }
  .placed_carousel-item {
    padding-top: 30px;
  }
  .placed_desc h1 {
    font-size: 24px;
  }
  .placed_desc p {
    font-size: 18px;
  }
  .what_testimonie_map_container .what_testimonies_map_heading h1 {
    font-size: 28px;
  }
  .mentor_banner_container .mentor_banner_main_div .mentor_banner_heading h1 {
    font-size: 28px;
  }
  .mentor_banner_container .mentor_banner_main_div .mentor_banner_heading p {
    font-size: 18px;
  }
  .mentor_banner_container
    .mentor_banner_main_div
    .mentor_banner_heading
    button {
    margin-block: 5px;
    font-size: 18px;
  }
  .mentor_tutorial_container .mentor_tutorial_heading h2 {
    width: 100%;
  }
  .mentor_tutorial_container .mentor_tutorial_card h1 {
    font-size: 24px;
  }
  .mentor_tutorial_container .mentor_tutorial_card p {
    font-size: 16px;
  }
  .mentor_tutorial_container .mentor_tutorial_card .mentor_tutorialcard_desc {
    height: auto;
    padding: 30px;
  }
  .whatTestimonies_banner_container .whatTestimonies_banner_heading h1 {
    font-size: 28px;
  }

  .hireus_banner_container .hireus_banner_left h1 {
    font-size: 28px;
  }
  .hireus_banner_container {
    padding-top: 85px;
  }
  .hireus_solution_container .hireus_solution_left h1 {
    font-size: 28px;
  }
  .hireus_solution_container .hireus_solution_left p {
    font-size: 18px;
  }
  .hireus_solution_container .hireus_solution_right .hireus_solution_card h2 {
    font-size: 24px;
  }
  .hireus_solution_container .hireus_solution_right .hireus_solution_card p {
    font-size: 16px;
  }
  .hireus_form_container {
    padding-top: 0;
  }
  .hireus_banner_container .hireus_banner_desc p {
    font-size: 18px;
  }
  .instructor_slider_carousel .instructor_slider_item_desc h1 {
    font-size: 30px;
  }
  .instructor_slider_carousel .instructor_slider_item_desc p {
    margin-block: 15px;
  }
  .instructor_slider_container {
    padding-top: 0;
  }
  .instructor_banner_container .instructor_banner_right_tab h1 {
    font-size: 28px;
  }
  .instructor_banner_container .instructor_banner_right_tab {
    padding-block: 5%;
  }
  .study_and_internship_avatar_list ul {
    margin-bottom: 0;
  }
  .study_and_internship_banner_container .study_internship_banner_tab_2 {
    margin-top: 0;
  }
  .new_featured_mobile_program_container .new_featured_mobile_program_item {
    width: 90%;
    margin-left: 2.5%;
  }
  .new_featured_mobile_program_carousel .owl-nav .owl-prev {
    left: 1%;
  }
  .new_featured_mobile_program_carousel .owl-nav .owl-next {
    right: 1%;
  }
  .our_university_img_box img {
    width: 70px;
    margin-inline: 20px;
  }
  .our_university_container .our_university_container_div {
    row-gap: 10px;
  }
  .home_getstarted_mobile_div {
    margin-bottom: 55px;
    padding-block: 30px;
    /* padding-bottom: 10px; */
  }
  .home_getstarted_mobile_div::before {
    /* height: 67vh; */
    top: 0;
  }
  .mobile_testimonial_item .mobile_testimonial_link {
    position: absolute;
    top: -9px;
    right: -8px;
  }
  .home_getstarted_container .mobile_testimonial_item {
    /* margin-block: 30px; */
    box-shadow: none;
    margin: 0;
  }
  .new_featured_program_mentors_content .new_featured_program_mentors_img {
    border: 1px solid #ccc;
  }

  .slick-dots {
    bottom: -69px;
    margin-bottom: 10px;
  }

  .slick-dots li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    margin-inline: 8px;
  }

  .slick-dots li button {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    transition: width 0.3s ease;
    background-color: #d6d6d6;
  }

  .slick-dots li.slick-active button {
    background-color: #024c87;
  }

  .slick-dots li button:before {
    content: "";
  }

  /* start */
  /* end */

  .home_getstarted_mobile_div .swiper {
    width: 70%;
    /* height: 320px; */
  }

  .home_getstarted_mobile_div .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    /* color: #fff; */
  }

  .home_testi_video_outer_container h2 {
    display: none;
  }

  .home_testi_video_inner_container .home_testi_video_right_div h2 {
    display: flex;
    padding-right: 0;
    font-size: 20px;
    padding-bottom: 13px;
  }

  .home_testi_video_inner_container .home_testi_video_right_div p {
    font-size: 14px;
  }
  /* .home_testi_video_left_div {
    height: 410px;
  } */
  /* .home_testi_video_left_div .home_testi_video_div {
    height: 400px;
  } */

  .home_testi_video_div video {
    /* height: 100%; */
    width: -webkit-fill-available;
  }
  .home_testi_video_div .home_test_video_layer {
    width: 70%;
  }
  .home_testi_video_right_div {
    margin-top: 20px;
  }
  .home_testi_video_inner_container .home_testi_video_left_div {
    box-shadow: none;
    height: 410px;
  }
  .home_testi_video_right_div .home_testi_content {
    padding-top: 30px;
    gap: 15px;
    justify-content: space-between;
    gap: 0;
  }
  .home_testi_content_div img {
    width: 100px;
    height: 40px;
  }
  .home_testi_content .home_testi_content_div {
    padding-inline: 12px;
  }

  .new_program_fees_tab button {
    font-size: 16px;
    padding-inline: 20px;
  }
  .new_program_fees_container .new_program_fees_tab {
    gap: 0;
  }
  .new_program_fees_container_div .new_program_fees_container_div_right {
    display: none;
  }
  .new_program_fees_container_div .new_program_fees_container_div_left {
    flex-basis: auto;
    padding: 20px;
    border-radius: 8px;
  }
  .new_program_fees_container_div_left ul {
    display: none;
  }
  .new_program_fees_container_div_left button {
    margin-bottom: 14px;
    font-size: 16px;
  }

  .new_program_fees_container_div_left p {
    font-size: 16px;
  }
  .new_program_fees_container_div_left h2 {
    font-size: 22px;
  }

  .new_program_fees_container_div_left .new_program_fees_onetime_mobile h2 {
    font-size: 26px;
  }
  .new_program_fees_container_div_left .new_program_fees_onetime_mobile h1 {
    font-size: 38px;
    font-weight: bold;
    margin-left: 20px;
  }
  .new_program_fees_container_div_left .new_program_fees_accept_img {
    margin-bottom: 20px;
  }

  .new_program_fees_container_div_left .new_program_fees_onetime_button {
    width: 100%;
    background-color: white;
    color: rgba(23, 81, 208, 1);
    font-weight: bold;
    font-size: 18px;
    width: 70%;
    margin: auto;
    display: block;
  }

  .monthly_new_program_fees_container_div {
    display: none;
  }

  .new_program_fees_mobile_carousel {
    margin-top: 40px;
    padding: 10px;
  }

  .new_program_fees_mobile_carousel .monthly_new_program_fees_right {
    border: 3px solid #0000;
    border-radius: 8px;
    background: linear-gradient(#ffffff, #ffffff) padding-box,
      linear-gradient(var(--angle), #070707, #687aff) border-box;
    animation: 8s rotate linear infinite;
    color: #353535;
    padding: 20px;
    height: 470px;
  }

  .new_program_fees_mobile_carousel .monthly_new_program_fees_right button {
    background-color: rgba(241, 241, 241, 1);
    color: rgba(53, 53, 53, 1);
    display: block;
    margin: auto;
    margin-bottom: 15px;
    font-size: 16px;
  }

  .new_program_fees_mobile_carousel .monthly_new_program_fees_right ul {
    display: block;
    margin-bottom: 20px;
  }
  .new_program_fees_mobile_carousel .monthly_new_program_fees_right ul li {
    font-size: 16px;
  }
  .new_program_fees_mobile_carousel
    .monthly_new_program_fees_right
    .new_program_fees_start_now {
    background-color: rgba(53, 53, 53, 1);
    padding-block: 12px;
    border-radius: 4px;
    color: white;
    width: 80%;

    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%);
  }
  .enroll_stepthree_price .enroll_stepthree_price_div {
    margin-right: auto;
  }
  .enroll_stepone_container .enroll_stepone_btn .enroll_stepone_next {
    margin-top: 20px;
  }
  .enroll_stepthree_installment_note h5 span {
    font-size: 18px;
  }
  .enroll_stepthree_priceinfo .enroll_stepthree_installment .accordion-body {
    padding-inline: 0;
  }
  .enroll_stepthree_priceinfo .enroll_stepthree_installment .accordion-button {
    padding-inline: 0;
  }
  .enroll_stepthree_acc_body ul li h4 {
    width: 174px;
  }
  .enroll_stepthree_installment_note h5 {
    font-size: 16px;
  }
  .enroll_stepthree_installment .enroll_stepthree_installment_note {
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 15px;
  }
  .enroll_stepthree_container .enroll_stepthree_priceinfo {
    padding-inline: 0;
  }
  .enroll_steptwo_container .enroll_steptwo_checkbox {
    flex-direction: column;
  }
  .enroll_steptwo_container .enroll_steptwo_checkbox label {
    margin-top: 0;
  }
  .dashboard_planbill_detail {
    flex-direction: column;
    align-items: flex-start;
  }
  .dashboard_planbill_header .dashboard_planbill_title_dropdown h4 {
    font-size: 18px;
    align-items: flex-start;
  }
  .dashboard_planbill_title_dropdown h4 svg {
    height: auto;
  }
  .dashboard_planbill_container {
    padding: 10px;
  }
  .dashboard_planbill_container .dashboard_planbill_header {
    margin-top: 20px;
  }
}

@media screen and (max-width: 426px) {
  .blog_dashboard_banner {
    height: 200px;
  }

  .blog_dashboard_sidebar_logout_btn {
    width: auto;
  }

  .blog_dashboard_upload_div .blog_upload_div {
    padding: 20px;
  }

  .blog_dashboard_choose_domain_container .blog_dashboard_choose_domain {
    padding: 20px;
  }

  .blog_dashboard_home_navbar .blog_dashboard_home_navbar_input input {
    padding-left: 40px;
    font-size: 16px;
  }

  .blog_dashboard_home_navbar_input .blog_dashboard_home_search {
    font-size: 16px;
    top: 36%;
  }

  .blog_dashboard_home .blog_dashboard_home_navbar {
    padding-top: 5%;
  }

  .blog_dashboard_home_content_left_header {
    flex-direction: column;
  }

  .blog_dashboard_register_btn {
    flex-direction: column;
    gap: 15px;
  }

  .blog_dashboard_register_footer {
    display: none;
    flex-direction: column;
  }

  .login_form {
    padding: 20px;
  }

  .login_form .btn {
    width: 180px;
  }

  .placed_candidate_testi_heading h2 {
    font-size: 26px;
  }

  .placed_candidate_testi_heading p {
    max-width: 100%;
    font-size: 16px;
    margin: 10px 0 40px 0;
  }

  .placed_candidate_testi_item {
    background-image: url(../images/placed_testi_mobile.png);
    border: none;
    box-shadow: none;
    background-repeat: no-repeat;
    height: 430px;
    background-size: 100% 100%;
  }

  .placed_candidate_testi_bluebox {
    background-color: transparent;
  }

  .placed_candidate_testi_info {
    top: 0;
    left: 30px;
  }

  #placed_testi_linkedin_img {
    display: none;
  }

  #placed_testi_mobile_link {
    display: flex;
  }

  #placed_testi_mobile_link img {
    width: 30px;
    object-fit: cover;
  }

  .placed_candidate_testi_bluebox {
    display: none;
  }

  .placed_candidate_testi_bluebox #placed_candidate_testi_bluebox {
    display: none;
  }

  .placed_candidate_testi_item #placed_candidate_testi_image_mobile {
    display: block;
    position: absolute;
    top: 30px;
    left: 78%;
  }

  .placed_candidate_testi_item .placed_candidate_testi_bluebox_mobile {
    display: block;
    position: absolute;
    top: 23%;
    padding: 20px;
  }

  .placed_candidate_testi_item .placed_candidate_testi_bluebox_mobile p {
    color: white;
    height: 150px;
    overflow-y: auto;
  }

  .placed_candidate_testi_bluebox_mobile .placed_candidate_testi_info_mobile {
    display: block;
    margin-bottom: 20px;
  }

  .placed_canididate_banner_right {
    display: none;
  }

  .placed_canididate_banner_left {
    background-image: linear-gradient(
        180deg,
        rgba(2, 76, 135, 0.69) 0%,
        rgba(0, 19, 33, 0.69) 100%
      ),
      url(../images/banner_mobile.png);
    background-repeat: no-repeat;
    /* background: linear-gradient(180deg, rgba(2, 76, 135, 0.69) 0%, rgba(0, 19, 33, 0.69) 100%); */
    background-size: cover;
    background-position: right;
    padding: 35px;
  }

  .placed_candidate_banner_container {
    padding: 0;
    margin-top: 65px;
  }

  .placed_canididate_banner_left .placed_candidate_banner_heading h1 {
    color: white;
    font-size: 36px;
  }

  .placed_canididate_banner_left .placed_candidate_banner_heading h2 {
    color: white;
    font-weight: 300;
    font-size: 14px;
    margin-top: 18px;
  }

  .placed_canididate_banner_left .placed_candidate_banner_getstart button {
    background: white;
    color: #024c87;
    font-weight: 500;
    font-family: DM sans;
    border: 2px solid white;
    border-radius: 5px;
    padding-inline: 20px;
    text-transform: capitalize;
    margin-block: 18px;
  }

  .placed_canididate_banner_left .placed_candidate_banner_cards {
    display: none;
  }

  .placed_candidate_customer_container {
    padding-top: 0;
  }

  .placed_candidate_advice_container .placed_candidate_advice_heading h1 {
    font-size: 24px;
  }

  .placed_candidate_advice_container .placed_candidate_advice_desc h1 {
    font-size: 20px;
  }

  .placed_candidate_advice_container .placed_candidate_advice_desc p {
    font-size: 16px;
  }

  .placed_candidate_advice_container
    .placed_candidate_advice_tab
    .placed_candidate_advice_icon_div
    span {
    font-size: 18px;
  }

  .placed_candidate_advice_container .placed_candidate_advice_whyoeson_desc h4 {
    font-size: 20px;
  }

  .placed_candidate_advice_container .placed_candidate_advice_whyoeson_desc p {
    font-size: 16px;
  }

  .placed_candidate_map_container .placed_candidate_map_heading h1 {
    font-size: 26px;
  }

  .placed_candidate_map_container .placed_candidate_map_heading h2 {
    font-size: 16px;
  }

  .placed_candidate_customer_container .placed_candidate_customer_heading h1 {
    font-size: 24px;
    font-weight: 600;
  }

  .placed_candidate_company_viewmore_btn {
    background: transparent;
    border: 0;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 11px;
    color: #024c87;
    font-weight: 600;
  }

  .blog_card_social .blog_card_vector3 {
    display: none;
  }

  .blog_full_description .blog_full_description_content h1 {
    font-size: 20px;
  }

  .blog_full_description .blog_full_description_content p {
    font-size: 16px;
  }

  .blog_ai_content h1 {
    font-size: 20px;
  }

  .blog_card_social_full {
    flex-direction: column;
    height: auto;
    align-items: baseline;
  }

  .blog_description_articles h1 {
    font-size: 20px;
  }

  .blog_ai_content p {
    font-size: 16px;
  }

  .blog_card_profile {
    height: auto;
  }

  .blog_card_main_content h2 {
    font-size: 16px;
  }

  .blog_cards_data_slider {
    padding-inline: 10px;
  }

  .blog_cards_search_input .blog_cards_search_icon {
    left: 15px;
  }

  .blog_cards_searchbar_tab h3 {
    font-size: 18px;
    padding-bottom: 10px;
    text-align: center;
  }

  .blog_cards_searchbar .blog_cards_searchbar_tab span {
    justify-content: space-between;
    gap: 0;
  }

  .blog_dashboard_upload_thumb button {
    font-size: 18px;
  }

  .blog_dashboard_insert_content_rightbar {
    width: 305px;
  }

  .blog_dashboard_insert_content_container .blog_dashboard_edit_options {
    padding-inline: 20px;
  }

  .blog_upload_div h2 {
    text-align: center;
  }

  .blog_dashboard_insert_content_data img {
    width: 100%;
  }

  .blog_dashboard_edit_inps .blog_dashboard_edit_image img {
    width: 100%;
    height: 100%;
  }

  .blog_dashboard_choose_domain_three_btn div {
    gap: 18px;
  }

  .blog_dashboard_insert_content_container {
    padding-inline: 10px;
  }

  .blog_dashboard_password_form_div h2 {
    padding-left: 0;
    margin-bottom: 8px;
  }

  .blog_dashboard_password_form_div p {
    padding-left: 0;
  }

  .blog_dashboard_password_form .blog_dashboard_input label {
    margin-bottom: 10px;
  }

  .blog_dashboard_form {
    padding-inline: 20px;
  }

  .blog_dashboard_password_form_div .blog_dashboard_password_form {
    padding-inline: 16px;
  }

  .dashboard_notification_container {
    width: 100%;
  }

  .blog_dashboard_choose_domain_preview_three_btn {
    padding-inline: 10px;
  }

  .home_testi_video_div .home_test_video_layer {
    width: 80%;
  }

  .home_testi_content_div img {
    width: 80px;
    height: 30px;
  }
}

/* .dashboard_mobile_course_video_qna_input */
/* .dashboard_new_mobile_footer .dashboard_new_mobile_footer_div */
