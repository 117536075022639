* {
  padding: 0;
  margin: 0;
  /* box-sizing: border-box */
}

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Francois+One&family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&family=Hina+Mincho&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Italianno&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Italianno&display=swap");

body {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  overflow-x: hidden;
  font-family: DM Sans, sans-serif;
  background-color: #fff;
  box-sizing: border-box;
}

:root {
  --bg-black: #1c1e24;
  --bg-gray: #f5f5f5;
  --dark-gray: #575757;
  --primary-blue: #024c87;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

html {
  overflow-x: hidden;
}
a,
a:hover {
  text-decoration: none;
  transition: 0.3s;
  color: inherit;
}

a:hover {
  color: inherit;
}

img {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}
.padding-0 {
  padding: 0;
}

/* Home page css start  */
.home_header_container {
  padding: 4px 27px;
  display: flex;
  justify-content: space-between;
  background: #024c87;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.home_main_header_container {
  position: relative;
  z-index: 999;
}
.home_main_header_container .header_main_div {
  background: rgba(0, 0, 0, 0.6);
  height: 70vh;
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 9999999999;
  top: 0;
  position: fixed;
  overflow: hidden;
  margin-top: 120px;
  /* margin-top: 124px; */
}

.home_main_header_container .header_main_div_program{
  margin-top: 137px ;
}

.home_header_container .home_header_logo img {
  width: 113px;
  height: 54px;
}
.home_header_left {
  display: flex;
  /* justify-content: space-evenly; */
  align-items: center;
  gap: 22px;
  justify-content: space-between;
  width: 49%;
}

.home_header_container .home_header_search input {
  color: #989ea6;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #fff;
  border: none;
  padding: 8px 8px;
  width: 400px;
  outline: none;
  border-radius: 5px 0px 0px 5px;
}

.home_header_container .home_header_search {
  display: flex;
  position: relative;
}
.home_header_search .header_search_bar_list {
  position: absolute;
  top: 92%;
  width: 100%;
  z-index: 90;
  background-color: #fff;
  color: #4a4a4a;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
.home_header_search .header_search_bar_list ul {
  list-style: none;
  padding: 0;
}

.home_header_search .header_search_bar_list ul li {
  padding: 8px 12px;
  border-bottom: 1px solid #d6d6d6;
  cursor: pointer;
  font-weight: 500;
}
.home_header_container .container {
  display: flex;
  justify-content: space-between;
}

.home_header_container .home_header_search button {
  background: #ff9a28;
  outline: none;
  border: none;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 5px 5px 0px;
}

.home_header_container .home_header_right ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  color: #ffff;
  align-items: center;
  height: 100%;
}

.home_header_container .home_header_right ul li a {
  text-decoration: none;
  color: #fff;
  font-family: DM Sans;
  font-weight: 400;
  font-size: 14px;
}

.home_header_container .home_header_right ul li a img {
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 50%;
}

.home_subheader_container {
  background: #fff;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.home_subheader_container .header_browse {
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 18px;
  font-weight: 700;
  padding: 8px 0px;
}

.home_header_container .home_header_list_hr a {
  display: block !important;
  font-size: 40px !important;
  font-weight: 100 !important;
}

.home_subheader_container .home_subheader_left {
  display: flex;
  align-items: center;
  gap: 58px;
  /* width: 61%; */
  justify-content: center;
  padding: 6px 0px;
  width: 64%;
}

.home_subheader_container .home_subheader_list ul {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px;
  color: #000;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0;
  cursor: pointer;
  margin: 0;
}

.home_subheader_container .home_subheader_list ul li {
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 5px 0px;
}

.home_subheader_container .home_subheader_list ul li span {
  color: #000;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.home_subheader_container .home_subheader_right {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12%;
}

.home_subheader_container .home_subheader_right button {
  border-radius: 5px;
  background: #024c87;
  color: #fff;
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 6px 22px;
  border: 1px solid #024c87;
}

.subheader_list_detail {
  position: absolute;
  width: 100%;
  z-index: 99;
  background-color: #ffffff;
}

.subheader_list_fullwidth,
.subheader_list_fullwidth .row,
.subheader_list_fullwidth .subheader_about_container {
  height: 100%;
}

.subheader_list_detail .subheader_list_course_list {
  height: 57vh;
  overflow-y: auto;
  margin-bottom: 30px;
  margin-top: 0px;
  padding: 38px;
  padding-top: 0px;
}
.subheader_list_fullwidth .subheader_list_course_list {
  height: 80vh;
}
.subheader_list_detail .subheader_list_course_list::-webkit-scrollbar {
  width: 0px;
}

.subheader_about_container {
  position: relative;
}

.subheader_about_container .subheader_howit_works {
  width: 100%;
  display: flex;
  justify-content: end;
}

.subheader_about_container .subheader_howit_works button,
.subheader_about_container .subheader_howit_works a {
  background-color: transparent;
  border: none;
  color: #024c87;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 8px;
}

.subheader_about_container .subheader_about_list ul {
  list-style: none;
  padding: 0;
  color: #46484e;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.subheader_about_container .subheader_about_list ul li {
  padding: 14px 8px;
}

.subheader_about_container .subheader_about_list ul li:hover {
  text-decoration: underline;
}

.subheader_about_container::after {
  content: none;
  position: absolute;
  right: 0;
  bottom: 0;
  background-image: url("../images/oeson_bg_1.png");
  width: 393px;
  height: 93%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.subheader_course_container .subheader_sidebar {
  background: #fff;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 100%;
}

.subheader_course_container .subheader_sidebar .subheader_course_list ul {
  list-style: none;
  padding: 0;
  color: #7d8185;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 117.2%; /* 23.44px */
  letter-spacing: -0.4px;
}

.subheader_course_container .subheader_sidebar h2 {
  color: #000;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 117.2%; /* 28.128px */
  letter-spacing: -0.48px;
  padding: 0px 20px;
}
.subheader_course_container .subheader_sidebar .subheader_course_list ul li {
  width: 100%;
  cursor: pointer;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-top: 1px solid #d0d0d0; */
  border-bottom: 1px solid #d0d0d0;
}

.subheader_course_container
  .subheader_sidebar
  .subheader_course_list
  ul
  .active {
  background: #dae6e6;
}

.subheader_course_container .suheader_course_card {
  border-radius: 10px;
  position: relative;
  background: #fff;
  /* height: 150px; */
  padding: 18px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 4px 8px 0px rgba(0, 0, 0, 0.25);
}

.subheader_course_container .suheader_course_card_padding {
  padding: 18px;
}
.subheader_course_container .suheader_course_card button {
  border-radius: 0px 11px;
  position: absolute;
  right: 0;
  top: 0;
  width: 98px;
  border: none;
  padding: 4px 0px;
  color: #fff;
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.subheader_course_container .suheader_course_card h3 {
  color: #46484e;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.subheader_course_container .suheader_course_card h5 {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* new design csss  */
  margin-bottom: 20px;
}

.subheader_course_container .suheader_course_card .suheader_course_card_title {
  display: flex;
  gap: 20px;
}

.suheader_course_card .suheader_course_card_title h4 {
  color: #46484e;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.subheader_course_container .suheader_course_card .suheader_course_card_desc {
  margin-top: 44px;
}
.subheader_course_container .suheader_course_card .subheader_course_icon {
  position: absolute;
  left: 27%;
  bottom: 21%;
}

.home_course_card_div {
  position: relative;
  margin-top: 110px;
}

.home_course_card_div .home_course_card_img {
  position: absolute;
  top: -28%;
  left: 10%;
  width: 80%;
}

.home_course_card {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 12px;
  padding-top: 72px;
}

.home_course_card_img img {
  height: 142px;
  border-radius: 8px;
  object-fit: cover;
}

.home_course_card .home_course_card_icon {
  display: flex;
  padding: 8px 12px;
  margin-top: 14px;
  justify-content: end;
}

.home_course_card .home_course_card_icon button {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #024c87;
  border: 1px solid #fff;
}
.home_course_card .home_course_card_icon button svg {
  fill: #fff;
  font-size: 22px;
}
.home_course_card .home_course_card_title {
  background: #024c87;
  padding: 4px 14px;
}
.home_course_card .home_course_card_title h4 {
  color: #fff;
  text-align: center;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 144.5%; /* 34.374px */
  text-transform: capitalize;
  margin: 0;
}

.home_course_card_list {
  padding: 8px 12px;
  margin-top: 14px;
  overflow: hidden;
  height: 168px;
}
.home_course_card_list .home_course_card_item {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}

.home_course_card_list .home_course_card_item img,
.home_course_card_list .home_course_card_item svg {
  height: 25px;
  width: 25px;
}
.home_course_card_list .home_course_card_item span {
  color: #8c8c8c;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 144.5%; /* 23.12px */
  text-transform: capitalize;
}

.middle_section_carousel {
  position: relative;
}

.middle_carousel_item_1 {
  /* background-image: url("../images/section_img_1.png"); */
  height: 73vh;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  display: flex;
  align-items: end;
  justify-content: center;
  padding-bottom: 30px;
  position: relative;
}
.middle_carousel_item_2 {
  /* background-image: url("../images/section_img_2.png"); */
  height: 73vh;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  display: flex;
  padding-bottom: 30px;
  position: relative;
}

.middle_carousel_item_3 {
  /* background-image: url("../images/section_img_3.png"); */
  height: 73vh;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  display: flex;
  padding-bottom: 30px;
  position: relative;
}

.middle_item_desc_2 {
  z-index: 2;
}

.middle_item_wrapper_2 {
  padding: 30px 0px;
  max-width: 50%;
}

.middle_item_desc_2 h1 {
  color: #fff;
  font-family: Gothic A1;
  font-size: 65px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -4.5px;
}
.middle_item_desc_2 p {
  color: #fff;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  max-width: 80%;
  line-height: normal;
  margin: 20px 0px;
  letter-spacing: -0.9px;
}

.middle_item_desc_2 button {
  padding: 6px 22px;
  color: #fff;
  border: 2px solid #fff;
  background: transparent;
}

.middle_section_carousel .owl-stage {
  display: flex;
}

.middle_carousel_item_1::after,
.middle_carousel_item_2::after,
.middle_carousel_item_3::after {
  content: "";
  background-image: url("../images/middle_bg_dark.png");
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}

.middle_item_desc_3 {
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 40px 0px;
}
.middle_item_desc_3 h1 {
  color: #fff;
  text-align: center;
  font-family: Gothic A1;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.2px;
  max-width: 50%;
}

.middle_item_desc_3 p {
  color: #fff;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.9px;
  max-width: 30%;
}

.middle_item_desc_3 button {
  border: 2px solid rgba(255, 255, 255, 0);
  background: #ff9a28;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 6px 42px;
  color: #fff;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.9px;
}

.middle_section_carousel .middle_item_desc_1 h2 {
  color: #fff;
  font-family: Gothic A1;
  font-size: 82px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -4.5px;
}
.middle_section_carousel .middle_item_desc_1 {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0px 59px;
  justify-content: space-between;
  z-index: 2;
  margin-bottom: 25px;
}
.middle_section_carousel .middle_item_title {
  width: 85%;
  text-align: center;
}
.middle_section_carousel .middle_item_desc_1 h4 {
  color: #fff;
  font-family: DM Sans;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2px;
  text-align: center;
}

.middle_section_carousel .middle_item_desc_div {
  max-width: 50%;
}
.middle_section_carousel .middle_carousel_item_new {
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  display: flex;
  align-items: center;
  padding: 0px 80px;
  padding-bottom: 30px;
  position: relative;
}

.middle_section_carousel .middle_carousel_item_new,
.middle_carousel_item_1,
.middle_carousel_item_2,
.middle_carousel_item_3 {
  height: 77vh;
}

.middle_section_carousel .middle_item_desc_div h2 {
  color: #fff;
  font-family: "DM Sans";
  font-size: 55px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.middle_section_carousel .middle_item_desc_div p {
  color: #fff;
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 38.4px */
  letter-spacing: 1.2px;
}

.middle_section_carousel .middle_item_desc_btns {
  display: flex;
  align-items: center;
  gap: 18px;
}

.middle_section_carousel .middle_item_desc_btns button {
  border-radius: 5px;
  padding: 8px 14px;
  margin-top: 20px;
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.08px;
}
.middle_section_carousel .middle_item_desc_btns button:first-child {
  background-color: #024c87;
  color: #fff;
  border: 1px solid #024c87;
}
.middle_section_carousel .middle_item_desc_btns button:last-child {
  background-color: #fff;
  border: 1px solid #fff;
  color: #024c87;
}

.middle_section_carousel .middle_section_explore span {
  color: rgba(2, 76, 135, 0.92);
  text-align: center;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.08px;
}

.middle_section_carousel .middle_section_explore {
  position: relative;
  height: 150px;
  width: 150px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.middle_section_carousel .middle_section_explore .middle_circle {
  position: absolute;
  top: -35px;
  right: 6px;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: #ff9a28;
}

.middle_section_carousel .owl-prev {
  position: absolute;
  top: 0%;
  height: 100%;
  height: 100%;
  width: 100px;
  font-size: 0 !important ;
}

.middle_section_carousel .owl-prev:hover {
  font-size: 66px !important;
}

.middle_section_carousel button.owl-next {
  position: absolute;
  right: 0;
  top: 0%;
  height: 100%;
  width: 100px;
  font-size: 0 !important ;
}

.middle_section_carousel button.owl-next:hover {
  font-size: 66px !important;
}
.middle_section_search {
  overflow: hidden;
  margin: 0px 51px;
  border-radius: 8px;
  margin-top: -34px;
  background: #fff;
  box-shadow: 0px 0px 13.9px 0px #d9d9d9;
  position: relative;
  z-index: 4;
  padding: 14px 22px;
}
.middle_section_search .middle_section_input_all label {
  color: #024c87;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.middle_section_search .middle_section_input_all {
  display: flex;
  gap: 19px;
  align-items: center;
  height: 100%;
}

.middle_section_search .middle_section_input_all select {
  border: none;
  outline: none;
  /* border-bottom: 2px solid #024c87; */
  width: 80%;
  background-color: transparent;
  color: #7d8185;
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.home_find_program_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.middle_find_btn {
  background: #024c87;
  border: none;
  padding: 8px 22px;
  border-radius: 8px;
  color: #fff;
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* our courses style  */

.home_courses_container {
  background-color: #e6eeee;
  padding: 59px 0px;
}

.home_courses_container .home_course_maincard {
  background: #fff;
}

.home_courses_container .home_course_maincard h1 {
  color: #1f386b;
  font-family: Gothic A1;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 126.5%;
  letter-spacing: -2.88px;
}

.home_courses_container .home_course_maincard p {
  color: #012237;
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 159.5%;
  letter-spacing: -1.54px;
  margin: 20px 0px;
}

.home_courses_container .home_course_maincard a {
  color: #ff9a28;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 159.5%; /* 38.28px */
  letter-spacing: -1.32px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.home_courses_container .home_course_maincard a button {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  background-color: #ff9a28;
  border: none;
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
}

.home_courses_container .home_course_maincard a button svg {
  fill: #fff;
}

.home_courses_container .home_maincard_right {
  background-color: #f9f6f7;
  height: 100%;
  padding: 15px 22px;
}

.home_courses_container .home_maincard_right h4 {
  color: #012237;
  font-family: DM Sans;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 166.5%; /* 66.6px */
  letter-spacing: 1.4px;
}
.home_courses_container .home_maincard_right p {
  color: #012237;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  letter-spacing: normal;
}

.home_courses_container .home_maincard_explore a {
  color: #fff;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
}

.home_courses_container .home_maincard_explore {
  background-color: #0177b5;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  width: 45%;
}

.home_courses_container .home_course_row {
  display: flex;
}

.home_course_card .home_course_item {
  height: 150px;
  display: flex;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;
  position: relative;
  z-index: 2;
}
.home_course_card .home_course_item h3 {
  z-index: 2;
  color: #fff;
  font-family: DM Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.home_course_card .home_course_item::after {
  content: "";
  background-image: url("../images/middle_bg_dark.png");
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}

.home_dicover_container {
  background-color: #fff;
}

.home_dicover_container .home_discover_heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home_dicover_container .home_discover_heading h1 {
  color: #989ea6;
  text-align: center;
  margin-top: 40px;
  font-family: Hina Mincho;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 126.5%; /* 126.5px */
  letter-spacing: -4.5px;
}

.home_dicover_container .home_discover_heading h2 {
  color: #00237c;
  text-align: center;
  font-family: Francois One;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 126.5%; /* 101.2px */
  letter-spacing: 0.8px;
}

.home_dicover_container .home_discover_heading button {
  margin-top: 20px;
  border: 2px solid rgba(255, 255, 255, 0);
  background: #ff9a28;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 6px 30px;
  color: #fff;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.8px;
}

.home_discover_swiper {
  margin: 40px 0px;
}
.home_discover_swiper .mySwiper {
  padding-bottom: 68px;
  padding-top: 40px;
}

.mySwiper .swiper-button-next,
.mySwiper .swiper-button-prev {
  top: auto;
  bottom: 0;
  z-index: 20;
}

.mySwiper .swiper-button-next {
  left: 54%;
  bottom: -2%;
}

.mySwiper .swiper-button-prev {
  left: 39%;
  bottom: -2%;
}

.mySwiper .swiper-button-prev {
  background-image: url("../icons/prev.svg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 100px;
  width: 60px;
  height: 60px;
  transform: scale(0.4);
}

.mySwiper .swiper-button-next {
  background-image: url("../icons/next.svg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-right: 100px;
  width: 60px;
  height: 60px;
  transform: scale(0.4);
}
.mySwiper .swiper_image_wrapper {
  height: 400px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.mySwiper .swiper_image_wrapper h1 {
  font-size: 32px;
  z-index: 4;
}
.mySwiper .swiper_image_wrapper::after {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  content: "";
  z-index: 1;
  background-image: url("../images/blue_bg.png");
}
.mySwiper .swiper-button-next::after,
.mySwiper .swiper-button-prev::after {
  content: "";
}

.our_stories_container {
  margin-top: 55px;
  position: relative;
  overflow-x: hidden;
}

.our_stories_container_col_1 {
  background: #ff9a28;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  padding: 0px 120px;
}

.our_stories_container_col_1 h2 {
  color: #000;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.44px;
}

.our_stories_container_col_1 p {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.675px;
}

.our_stories_container_col_2 {
  background: #f4eaea;
  display: flex;
  justify-content: start;
  padding-top: 382px;
  padding-bottom: 30px;
}

.our_stories_container_col_2 .ourstory_card {
  padding: 20px;
  background-color: #fff;
  z-index: 2;
  position: relative;
}

.our_stories_container_col_2 .ourstory_card img {
  width: 100%;
  height: 160px;
  object-fit: cover;
}

.our_stories_container_col_2 .ourstory_card h4 {
  color: #000;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.72px;
}

.our_stories_container_col_2 .ourstory_card p {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.54px;
}

.our_stories_container_col_2 .ourstory_card button {
  border: 1px solid #ff9a28;
  color: #f89321;
  font-family: DM Sans;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.495px;
  width: 100%;
  background-color: transparent;
  padding: 6px 0px;
}

.ourstory_play_video {
  position: absolute;
  width: 100%;
  top: 14%;
  z-index: 1;
  height: 350px;
  background-image: url("../images/ourstory_bg_1.png");
  display: flex;
  align-items: center;
}

.ourstory_play_video button {
  background-color: transparent;
  border: none;
  display: flex;
  width: 85px;
  position: relative;
  left: 20%;
}

.home_Certificate_container h1 {
  color: #00237c;
  margin-bottom: 50px;
  text-align: center;
  font-family: Francois One;
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: 126.5%; /* 101.2px */
  letter-spacing: -3.6px;
}

.home_Certificate_container .home_certificate_left .home_certificate_tab {
  background-color: #fff;
  clip-path: circle(63.8% at 50% 100%);
  height: 151px;
  padding-top: 88px;
  width: 53%;
  flex-direction: column;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  top: -134px;
  left: 8px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.home_Certificate_container .home_certificate_left .home_certificate_tab h4 {
  color: #7d8185;
  text-align: center;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 126.5%; /* 25.3px */
  letter-spacing: -0.9px;
}

.home_Certificate_container .home_certificate_left .home_certificate_tab h3 {
  color: #191e24;
  text-align: center;
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 126.5%; /* 35.42px */
  letter-spacing: -1.26px;
}

.home_Certificate_container .home_certificate_right {
  background: #cfeaff;
  padding: 25px;
}

.home_Certificate_container
  .home_certificate_right
  .certificate_right_title
  h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 36px;
  text-align: start;
  font-style: normal;
  font-weight: 700;
  line-height: 126.5%;
  letter-spacing: -2.025px;
  margin-bottom: 40px;
}
.home_Certificate_container {
  margin-top: 55px;
  position: relative;
}
.home_Certificate_container .container {
  position: relative;
  z-index: 2;
}
.home_Certificate_container::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  background-image: url("../images/certificate_bg_1.png");
  width: 100%;
  height: 100%;
  background-size: contain;
  z-index: 1;
}

.home_Certificate_container .certificate_pulse_icon,
.home_Certificate_container .certificate_reciept_icon,
.home_Certificate_container .cettificate_code_icon,
.home_Certificate_container .certificate_addplus_icon {
  padding: 10px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home_Certificate_container .certificate_pulse_icon {
  background: #ff717a;
}
.home_Certificate_container .certificate_reciept_icon {
  background: #f3b45d;
}

.home_Certificate_container .cettificate_code_icon {
  background: #7d72c2;
}

.home_Certificate_container .certificate_addplus_icon {
  background: transparent;
  padding: 18px 0px;
}

.home_certificate_right h6 {
  color: #191e24;
  font-family: DM Sans;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 126.5%; /* 13.915px */
  letter-spacing: -0.495px;
  margin-top: 4px;
}

.course_section_carousel .course_carousel_item .course_item_desc {
  border-radius: 9px;
  padding: 20px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  margin: 0px 15px;
  transform: translateY(-30px);
}
.course_carousel_item .course_item_desc .program_rating_btn {
  background: green;
  padding: 3px 8px;
  color: #fff;
  margin-bottom: 15px;
  border-radius: 8px;
  font-weight: 700;
  display: inline-block;
}

.course_carousel_item .course_item_desc .price_hike {
  color: green;
  font-size: 14px;
}
.course_carousel_item .course_item_desc .price_hike span {
  color: gray;
  text-decoration: line-through;
}
.home_course_card_container .course_section_carousel .course_carousel_item img,
.program_recommend_container
  .course_section_carousel
  .course_carousel_item
  img {
  height: 280px;
  object-fit: cover;
}

.course_section_carousel .course_carousel_item .course_item_desc h2 {
  color: #000;
  text-align: start;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 126.5%; /* 45.54px */
  letter-spacing: -1.62px;
  /* margin-bottom: 20px; */
}

.course_section_carousel .course_carousel_item .course_item_details {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}

.course_section_carousel .course_carousel_item .course_item_tag {
  background: #deff69;
  padding: 4px 14px;
  text-align: center;
  border-radius: 8px;
  display: inline-block;
}
.course_section_carousel .course_carousel_item .course_item_details h6 {
  color: #46484e;
  margin: 0;
  text-align: center;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 126.5%; /* 30.36px */
  letter-spacing: -1.08px;
}

.course_section_carousel .owl-nav {
  display: flex;
  gap: 120px;
  justify-content: center;
  transform: translateY(26px);
}

.course_section_carousel .owl-nav button img {
  height: 22px;
}

.course_section_carousel .owl-nav button {
  background-color: transparent !important;
}

/* .owl-theme .owl-nav.owl-nav button:hover {
  background-color: transparent;
} */

/* get started styles */

/* .home_getstarted_container {
  padding: 60px 0px;
} */

.home_getstarted_container .home_started_heading {
  color: #191e24;
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
}

.home_getstarted_container .home_started_heading span {
  color: #0d55be;
  font-family: Italianno;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 99.5%;
  letter-spacing: 3.2px;
}

.home_getstarted_container .home_google_btn button {
  border-radius: 5px;
  width: 100%;
  border: 1px solid #81868a;
  background: rgba(217, 217, 217, 0);
  padding: 6px 10px;
  display: flex;
  align-items: center;
  gap: 85px;
  margin: 40px 0px;
}

.home_getstarted_container .home_google_btn button img {
  height: 31px;
  width: 31px;
  object-fit: cover;
}

.home_getstarted_container .home_google_btn button span {
  color: #191e24;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 96.5%; /* 19.3px */
  letter-spacing: 0.6px;
}

.home_getstarted_container .getstarted_input_all input,
.home_getstarted_container .getstarted_input_all select {
  width: 100%;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid #81868a;
  background: rgba(217, 217, 217, 0);
  outline: none;
  margin-bottom: 15px;
}

.home_getstarted_container .getstrted_submit button {
  width: 100%;
  border-radius: 5px;
  background: #024c87;
  border: none;
  color: #fff;
  text-align: center;
  padding: 14px 0px;
  margin-top: 8px;
}

.home_getstarted_container .getstarted_links {
  margin: 40px 0px;
  color: #7d8185;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 92%; /* 18.4px */
  letter-spacing: 0.3px;
  text-align: center;
}

.home_getstarted_container .getstarted_links a {
  color: #000;
}

.home_getstarted_container .home_getstart_right {
  position: relative;
  display: flex;
  align-items: center;
}

.home_getstarted_container .home_getstart_right::after {
  content: "";
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-repeat: no-repeat;
  height: 100%;
  background-image: url("../images/getstart_bg_1.png");
}

.home_getstarted_container .home_getstart_right::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  background: #012e6a;
  height: 76%;
  width: 90%;
  z-index: 1;
  margin: 92px 0px;
}

.home_getstarted_container .home_getstrted_left {
  padding: 120px 20px;
}

.home_getstarted_container .mobile_testimonial_item {
  border-radius: 19px;
  background: #fff;
  margin: 15px 30px;
  box-shadow: 0px 4px 31px 0px rgba(182, 182, 182, 0.25);
  padding: 40px 16px;
  position: relative;
}
.mobile_testimonial_item .mobile_testimonial_item_img {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.mobile_testimonial_item .mobile_testimonial_item_img img {
  width: 76px;
  height: 76px;
  object-fit: cover;
  border-radius: 50%;
}

.mobile_testimonial_item .mobile_testimonial_item_desc h4 {
  color: #363636;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26.5px; /* 165.625% */
}

.homeprogram_card_flex {
  display: flex;
}

.homeprogram_card_flex .homeprogram_card_info {
  width: 100%;
}

.homeprogram_card_flex .homeprogram_card_phonebtn {
  width: 100%;
}

.homeprogram_card_flex .homeprogram_card_phonebtn button {
  border-radius: 4px;
  height: 100%;
  width: 100%;
  padding: 8px;
  background: #024c87;
}

.mobile_testimonial_item .mobile_testimonial_item_desc p {
  color: #757575;
  text-align: center;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 23.8px */
  height: 138px;
  overflow-y: auto;
}

.mobile_testimonial_item .mobile_testimonial_link {
  position: absolute;
  top: -30px;
  right: -18px;
}

.home_getstarted_mobile_div {
  position: relative;
  margin-bottom: 40px;
  display: none;
}

.mobile_testimonial_carousel .owl-dots {
  translate: 0 20px;
}

.mobile_testimonial_carousel .owl-dots .owl-dot.active span,
.mobile_testimonial_carousel .owl-dots .owl-dot:hover span {
  background-color: #024c87;
}
.home_getstarted_mobile_testimonial {
  display: none;
}

.home_getstarted_mobile_testimonial h2 {
  color: #363636;
  text-align: center;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
  margin-bottom: 35px;
}

.home_getstarted_mobile_div::before {
  content: "";
  background-color: #024c87;
  position: absolute;
  right: 0;
  top: -6%;
  width: 50%;
  height: 100%;
}
.home_getstrted_heading {
  display: none;
}
.features-section-heading {
  display: none;
}

.mobile_testimonial_item .mobile_testimonial_link img {
  width: 77px;
  height: 77px;
}

.getstartcarousel_item .getstartcarousel_desc p {
  color: #191e24;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.9px;
  height: 94px;
  overflow-y: auto;
}

.getstartcarousel_item .getstartcarousel_desc p::-webkit-scrollbar {
  width: 0px;
}

/* Track */
.getstartcarousel_item .getstartcarousel_desc p::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.getstartcarousel_item .getstartcarousel_desc p::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

/* Handle on hover */
.getstartcarousel_item .getstartcarousel_desc p::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.getstartcarousel_item .getstartcarousel_desc {
  background: #fff;
  padding: 22px 16px;
}

.getstartcarousel_item .getstartcarousel_desc h6 {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.9px;
}

.getstart_carousel .owl-prev span {
  background-color: #729dc4;
  padding: 8px 10px;
  display: inline-block;
}

.getstart_carousel .owl-nav button {
  margin: 0 !important;
}

.getstart_carousel .owl-next span {
  background-color: #418ddc;
  padding: 8px 10px;
  display: inline-block;
}

.getstart_carousel .owl-nav {
  justify-content: start;
  gap: 0;
  margin: 0;
}

.ourclient_container {
  padding: 85px 0px;
}

.ourclient_container .ourclient_container_heading h1 {
  position: relative;
  font-size: 50px;
  color: #191e24;
  font-family: DM Sans, sans-serif;
  font-weight: 700;
  text-align: center;
  margin-bottom: 60px;
}

.home_getstarted_container .getstart_cards {
  padding-right: 30px;
  padding-left: 20px;
  width: 100%;
}

.middle_section_input_all .middle_section_wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 80%;
}

.middle_section_input_all .middle_section_wrapper .middle_program {
  width: 48%;
}

.home_certificate_left img {
  width: 68%;
}

/* mobile header styles  */

.home_mobile_nav {
  display: none;
  justify-content: space-between;
  padding: 10px 15px;
  align-items: center;
  background: #ffff;
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  z-index: 99;
}

.mobile_course_tabs {
  gap: 12px;
  padding: 10px 0px;
  padding-bottom: 0;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.mobile_course_tabs button.active {
  color: #024c87;
  border-bottom: 2px solid #024c87;
}

.mobile_course_tabs button {
  background-color: transparent;
  border: none;
  font-size: 16px;
  padding-bottom: 3px;
  font-weight: bold;
}

.home_mobile_nav a img {
  height: 46px;
  width: 95px;
}

.home_mobile_nav button {
  border: none;
  background-color: transparent;
}

.home_mobile_nav button svg {
  font-size: 32px;
  color: #024c87;
}

.home_mobile_drawer {
  width: 60% !important;
  padding: 25px 30px;
  /* background-color: #024c87 !important; */
}

.mobile_nav_header .mobile_nav_logo a img {
  width: 36%;
  margin-bottom: 30px;
}

.mobile_nav_header .mobile_nav_logo {
  padding-bottom: 30px;
}

.mobile_nav_header .mobile_nav_logo button,
.mobile_nav_login {
  width: 100%;
  border: none;
  background-color: #024c87;
  color: #fff;
  padding: 6px 22px;
  margin-top: 10px;
  border-radius: 6px;
}

.mobile_nav_header .mobile_nav_logo .mobile_nav_profile {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile_nav_header .mobile_nav_logo .mobile_nav_profile a {
  height: 200px;
  width: 200px;
}
.mobile_nav_header .mobile_nav_logo .mobile_nav_profile a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.mobile_nav_list ul {
  padding: 0;
  list-style: none;
  color: #000;
}

.mobile_nav_list ul li {
  padding: 10px 0px;
  font-size: 22px;
}
.mobile_nav_list .sub_drop_item li {
  font-size: 15px;
  padding: 10px;
  color: #8e8e8e;
}

.mobile_nav_list_2 ul li {
  color: #8e8e8e;
  font-size: 16px;
}

.mobile_nav_list_2 ul {
  padding: 0;
  list-style: none;
  color: #000;
}

.mobile_nav_list_2 {
  margin-top: 22px;
}

.mobile_nav_list_2 ul li {
  padding: 10px 0px;
  font-size: 18px;
}

.mobile_nav_list .sub_drop_item {
  padding: 10px;
}

.mobile_nav_list ul li .drop_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile_course_steps {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 0px 10px;
  margin-top: 25px;
}

.mobile_course_step_item span {
  color: #7a7a7a;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 14px;
  display: inline-block;
  width: 100%;
  margin-left: 22px;
  position: relative;
  cursor: pointer;
}

.mobile_course_step_item ul li {
  color: #7a7a7a;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: 1px solid #ebebeb;
  padding: 10px;
  margin-left: 24px;
}

.mobile_course_step_item span svg {
  position: absolute;
  left: -14%;
  scale: 1.5;
  top: 42%;
}

.mobile_course_step_item span.active {
  border-radius: 4px;
  background: rgba(227, 241, 252, 0.57);
}

.mobile_course_steps::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 2px;
  background-color: #7a7a7a;
}

.drop_item svg {
  fill: #8e8e8e;
}

.drop_item .mobile_nav_item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.drop_item .mobile_nav_item svg {
  fill: none;
}

.drop_item .mobile_nav_item span {
  color: #8e8e8e;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.main_mobile_nav_conatiner {
  height: 70vh;
  overflow-y: auto;
  /* padding-right: 18px; */
  overflow-x: hidden;
}

.main_mobile_nav_conatiner::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.main_mobile_nav_conatiner::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.main_mobile_nav_conatiner::-webkit-scrollbar-thumb {
  background: transparent;
}

.getstart_carousel .getstartcarousel_item img {
  height: 280px;
}

.getstart_carousel .getstartcarousel_item .review_list_linkedin {
  height: 30px;
  width: 30px;
}

/* .getstart_carousel .getstartcarousel_item {
  width: 300px;
} */

.getstart_carousel .owl-stage {
  display: flex;
}

.middle_Section_cards {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 3%;
}

.middle_Section_cards .middle_card_item {
  display: flex;
  width: 292px;
  align-items: center;
  flex-direction: column;
  color: #fff;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.9px;
  justify-content: center;
  padding: 25px 36px;
  position: relative;
  border-radius: 6px;
  background: rgba(25, 30, 36, 0.53);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
}

/********************************************* About Page CSS ********************************************* */

.about_middle_container {
  height: 88vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #012e6a;
}
.about_middle_container .container {
  margin-bottom: 10%;
}

.about_middle_container h6 {
  color: #ff9a28;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100.023%; /* 24.005px */
}

.about_middle_container h1 {
  color: #fff;
  font-family: Inter;
  font-size: 65px;
  font-style: normal;
  font-weight: 700;
  line-height: 100.023%; /* 96.022px */
  letter-spacing: -2.88px;
}

.about_middle_container .about_middle_video {
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 15px;
}
.about_middle_container .about_middle_video button {
  background-color: transparent;
  border: none;
}
.about_middle_container .about_middle_video p {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145.023%;
  letter-spacing: normal;
  margin: 0;
  width: 32%;
}
.about_middle_container .about_middle_video p a {
  color: #f89321;
}
.about_middle_container .about_middle_video button svg {
  height: 67px;
}

.about_middle_container .about_miidle_list ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.about_middle_container .about_miidle_list {
  height: 100%;
  display: flex;
  align-items: center;
}

.about_middle_container .about_miidle_list ul li {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.about_middle_container .about_miidle_list ul li span {
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 145.023%; /* 34.805px */
}

.about_images_container .about_img_wrapper {
  transform: translateY(-44%);
  height: 85vh;
}
.about_images_container .about_img_wrapper {
  display: flex;
  gap: 20px;
  align-items: center;
}
.about_images_container .about_img_wrapper .about_img_2 {
  margin-top: 22%;
}

.about_location_container .about_location_cards .about_card_item h1 {
  color: #191e24;
  font-family: DM Sans;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: 96.5%; /* 92.64px */
}

.about_location_container .about_location_cards .about_card_item p {
  color: #191e24;
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 96.5%; /* 30.88px */
  letter-spacing: 0.96px;
}

.about_location_container .about_location_cards {
  display: flex;
  justify-content: center;
}

.about_location_container .about_location_cards .about_card_item {
  text-align: center;
  padding: 30px 0px;
}

.about_location_container
  .about_location_cards
  .about_card_item:nth-child(odd) {
  background: linear-gradient(0deg, #f3f3f3 0%, #f3f3f3 100%), #f3f3f3;
}

.about_location_container .about_location_map {
  margin-top: 65px;
  text-align: center;
}

.about_location_container .about_location_map h2 {
  color: #ff9a28;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}

.about_location_container .about_location_map h1 {
  color: #1a2025;
  font-family: DM Sans;
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 30px;
}

.about_tab_container {
  margin-top: 65px;
}

.about_tab_container .about_tab_content {
  border-radius: 100px 0px 0px 0px;
  background: #012e6a;
  padding: 71px 41px;
  color: #fff;
  font-family: DM Sans;
  font-size: 30px;
  font-style: normal;
  padding-bottom: 28px;
  font-weight: 400;
  height: 100%;
}
.about_tab_container .about_tab_content_right {
  border-radius: 0px 0px 100px 0px;
}

.about_tab_container .about_tab_btn_1,
.about_tab_container .about_tab_btn_2 {
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 31px 12px;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
}
.about_tab_container .about_tab_btn_1 span,
.about_tab_container .about_tab_btn_1 svg path {
  color: #191e24;
  fill: #191e24;
}

.about_tab_container .about_tab_btn_1 {
  background: #ff9a28;
}
.about_tab_container .about_tab_btn_2 {
  background: #1a76c6;
}

.about_story_container {
  margin-top: 65px;
}

.about_story_container .about_story_right .about_story_right_one h1 {
  color: #ff9a28;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
}

.about_story_container .about_story_right .about_story_right_one h2 {
  color: #191e24;
  font-family: DM Sans;
  font-size: 33px;
  font-style: normal;
  font-weight: 500;
}

.about_story_container .about_story_right .about_story_right_two {
  display: flex;
  align-items: center;
  gap: 22px;
  width: 90%;
}
.about_story_container .about_story_right .about_story_right_two img {
  width: 55%;
}

.about_story_container .about_story_right .about_story_right_two p {
  color: #81868a;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}

.about_story_container .about_story_list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.about_story_container .about_story_list ul li {
  display: flex;
  align-items: start;
  gap: 10px;
}

.about_story_container .about_story_list ul li h3 {
  color: #191e24;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
}

.about_story_container .about_story_list ul li p {
  color: #81868a;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
}

.about_story_container .about_story_right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.about_mission_container {
  margin-top: 8%;
}

.about_mission_container .about_mission_right {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  height: 100%;
}
.about_mission_container .about_mission_right h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 62px;
  font-style: normal;
  font-weight: 700;
}
.about_mission_container .about_mission_right p {
  color: #81868a;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
}
.about_mission_container .about_vision_left {
  justify-content: start;
  margin-top: 40px;
}

.about_mission_container .about_mission_left {
  padding: 10px;
}

.about_footer_container .footer_second_div {
  height: 425px;
  background-color: #4f46ba;
}

.about_footer_container .footer_second_div .about_footer_heading {
  height: 100%;
  display: flex;
  align-items: center;
}

.about_footer_container {
  margin-top: 8%;
}
.about_footer_container .footer_second_div .about_footer_heading h1 {
  color: #fff;
  font-family: DM Sans;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  padding-bottom: 42px;
}

.about_footer_container .footer_second_div .about_footer_form {
  border-radius: 0px 0px 0px 70px;
  background: #fff;
  box-shadow: 5px 10px 7px 10px rgba(0, 0, 0, 0.25);
  padding: 60px 40px;
  position: relative;
}

.about_footer_container .footer_second_div .about_footer_form {
  transform: translateY(-69%);
}

.about_footer_container .footer_second_div .about_footer_form::after {
  content: "";
  position: absolute;
  top: -97px;
  right: -123px;
  scale: 0.7;
  background-image: url("../images/about_footer_2.png");
  width: 46%;
  height: 35%;
  background-repeat: no-repeat;
}

.about_footer_container
  .footer_second_div
  .about_footer_form
  .about_footer_inputall
  label {
  color: #989ea6;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  width: 100%;
}
.about_footer_container
  .footer_second_div
  .about_footer_form
  .about_footer_inputall {
  margin-bottom: 30px;
}
.about_footer_container
  .footer_second_div
  .about_footer_form
  .about_footer_inputall
  input,
.about_footer_container
  .footer_second_div
  .about_footer_form
  .about_footer_inputall
  select {
  width: 100%;
  padding: 8px 0px;
  outline: none;
  border: none;
  border-bottom: 2px solid #000;
}

.about_footer_container
  .footer_second_div
  .about_footer_form
  .about_footer_btn
  button {
  margin-top: 30px;
  width: 100%;
  background-color: #1b1c31;
  padding: 13px;
  color: #fff;
  text-align: center;
  border: none;
}

.footer_first_div .about_footer_img {
  padding: 60px 0px;
}

.footer_first_div .about_footer_img img {
  width: 50%;
}

.about_tab_container .about_tab_img_wrapper {
  padding-right: 0;
  margin-top: 15px;
}
.about_tab_container .about_tab_img_wrapper img {
  height: 100%;
  object-fit: cover;
}

/************************************************ Meet Our Team Css ****************************************************************/

.meet_team_middle_main {
  padding-bottom: 80px;
  background: #edeff4;
}
.meet_team_middle_container {
  background: #edeff4;
  height: 72vh;
  display: flex;
  align-items: center;
  position: relative;
}
.meet_team_middle_container .container {
  padding-bottom: 30px;
}

.meet_team_middle_container::after {
  position: absolute;
  right: 0;
  top: 0;
  width: 35%;
  height: 100%;
  content: "";
  /* ../images/meet_our_team_1.png */
  background-image: url("https://d1db1k41ipas2c.cloudfront.net/meet_our_team_1+(1).png");
  background-size: cover;
}

.meet_team_middle_container .middle_team_heading h1 {
  color: #024c87;
  font-family: DM Sans;
  font-size: 86px;
  font-style: normal;
  font-weight: 700;
}

.meet_team_middle_container .middle_team_heading button {
  border-radius: 50px;
  background: #f89321;
  padding: 4px 42px;
  border: none;
  color: #fff;
  text-align: center;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  margin: 15px 0px;
}

.meet_team_middle_container .middle_team_list {
  margin-top: 10px;
  color: #024c87;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.meet_team_middle_container .middle_team_list .middle_team_list_item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
}
.meet_team_middle_container .middle_team_list svg path {
  fill: #024c87;
}

.meet_team_middle_container h4 {
  color: #024c87;
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  margin-top: 20px;
}

.meet_founder_container {
  background: #fff;
  box-shadow: 0px 4px 15px 2px rgba(0, 0, 0, 0.25);
  padding: 65px 0px;
}

.meet_founder_container .meet_founder_img img {
  width: 90%;
  height: 90%;
}

.meet_founder_container .meet_founder_right h2 {
  color: #024c87;
  font-family: DM Sans;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
}

.meet_founder_container .meet_founder_right .meet_founder_right_hr {
  margin: 20px 0px;
  height: 4px;
  width: 25%;
  background-color: #024c87;
}

.meet_founder_container .meet_founder_right h1 {
  color: #024c87;
  font-family: DM Sans;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
}

.meet_founder_container .meet_founder_right p {
  color: #024c87;
  font-family: DM Sans;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
}
.meet_founder_container .meet_founder_right .founder_sign_img img {
  width: 25%;
}

.meet_founder_container .meet_founder_right h4 {
  color: #024c87;
  font-family: DM Sans;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  margin-top: 10px;
}

.meet_founder_container .meet_founder_right h6 {
  color: #024c87;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
}

.meet_global_team_container {
  background: #edeff4;
  padding: 65px 0px;
}

.meet_global_team_container .meet_global_heading h1 {
  color: #024c87;
  font-family: DM Sans;
  text-align: center;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  margin: 20px 0px 45px 0px;
}

.meet_team_container {
  background: #edeff4;
  padding-top: 65px;
}

.meet_team_container .meet_team_heading h1 {
  color: #024c87;
  font-family: DM Sans;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  padding-bottom: 65px;
}

.meet_team_container .meet_team_card_container {
  background: #fff;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.25);
  padding: 65px 0px;
}

.meet_footer_container {
  background: #4f46ba;
  padding: 30px 0px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.meet_footer_container .meet_footer_img img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.meet_footer_container h1 {
  color: #fff;
  text-align: center;
  font-family: DM Sans;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  margin-top: 15px;
}

.meet_footer_container h2 {
  color: #fff;
  text-align: center;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  margin-top: 15px;
}

.meet_footer_container button {
  margin-top: 15px;
  background: #ff9a28;
  padding: 8px 82px;
  color: #fff;
  text-align: center;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  border: none;
}

/************************************************************** Our Job Page Css **********************************************/

.job_middle_section_container {
  background: #012e6a;
  display: flex;
  align-items: center;
  padding-top: 65px;
}

.job_middle_section_container .job_middle_left h1 {
  color: #fff;
  font-family: DM Sans;
  font-size: 66px;
  font-style: normal;
  font-weight: 700;
}

.job_middle_section_container .job_middle_left h1 span {
  color: #f89321;
}

.job_middle_section_container .job_middle_left p {
  color: #aeaebd;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
}

.job_middle_section_container .job_middle_left .job_middle_inp_group button {
  background: #f89321;
  color: #fff;
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  padding: 4px 15px;
  border: none;
}
.job_middle_section_container .job_middle_left .job_middle_inp_group input {
  width: 56%;
  padding: 6px 0px;
  outline: none;
  border: none;
}
.job_middle_section_container .job_middle_left .job_middle_inp_group {
  background-color: #fff;
  padding: 4px;
  width: 70%;
  margin: 40px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.job_how_works_container {
  background: #204674;
  padding: 30px 0px;
}

.job_how_works_container h1 {
  color: #fff;
  font-family: DM Sans;
  width: 40%;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
}

.job_how_works_container .job_how_works_hr {
  width: 35%;
  height: 2px;
  background-color: #fff;
}

.job_how_works_container .job_works_icon {
  height: 90px;
  width: 90px;
  display: flex;
  padding: 6px 13px;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  padding-left: 20px;
}
.job_how_works_container span {
  display: block;
  margin-top: 5px;
  color: #fff;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.job_how_works_container .job_work_div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-right: 2px solid #fff;
}

.job_solution_container {
  padding: 65px 0px;
}

.job_solution_container .job_solution_first h1 {
  color: #191e24;
  font-family: DM Sans;
  font-size: 44px;
  margin-bottom: 30px;
  font-style: normal;
  text-align: center;
  font-weight: 700;
}

.job_solution_container .job_solution_first .job_solution_item {
  height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: end;
  background-position: center;
  position: relative;
}
.job_solution_container .job_solution_first .job_solution_item::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../images/middle_bg_dark.png");
  z-index: 1;
}
.job_solution_container .job_solution_first .job_solution_item h4 {
  color: #fff;
  font-family: DM Sans;
  font-size: 32px;
  z-index: 4;
  font-style: normal;
  font-weight: 700;
  text-align: center;
}
/* .job_solution_container .job_solution_first .job_slolution_cards .row {
  display: flex;
  gap: 20px;
} */

.job_solution_container
  .job_solution_first
  .job_solution_item
  .job_solution_rotate {
  transform: rotate(-90deg);
  /* translate: 0px -65px; */
  position: absolute;
  bottom: 15%;
  width: 100%;
}
.job_solution_container .job_solution_second {
  margin-top: 30px;
}
.job_solution_container .job_solution_second h1 {
  color: #191e24;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
}

.job_solution_container .job_solution_second p {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
}

.job_solution_container .container {
  padding: 0px 40px;
}

.job_categories_container .job_categories_left {
  background-color: #204674;
  padding: 40px 0px;
  height: 100%;
}

.job_categories_container .job_categories_left h1 {
  color: #fff;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.job_categories_container .job_categories_left .job_categories_list ul {
  list-style: none;
  margin-top: 20px;
  padding: 0;
}

.job_categories_container .job_categories_left .job_categories_list ul li {
  padding: 12px 33px;
  cursor: pointer;
  color: #c2c2c2;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.job_categories_container
  .job_categories_left
  .job_categories_list
  ul
  .activeid {
  background-color: #fff;
  color: #204674;
}

.job_categories_right {
  padding: 20px 0px;
  background: #edeff4;
  height: 100%;
}

.job_categories_right .job_categories_options {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 20px;
  padding: 0px 80px;
}

.job_categories_right .job_categories_options .job_categories_select label {
  color: #000;
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
}

.job_categories_right .job_categories_options .job_categories_select select {
  margin: 0px 8px;
  color: #fff;
  padding: 8px 20px;
  color: #fff;
  width: 133px;
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  border: none;
  outline: none;
  appearance: none;
  background: #1a76c6 url("../icons/angleUp.svg") 86% center no-repeat !important;
}

.job_categories_right .job_Categories_hr {
  background-color: #000;
  color: #000;
  font-weight: normal;
  width: 2px;
  height: 58px;
}

.job_categories_right .job_categories_cards .job_categories_item {
  background: #fff;
  box-shadow: 6px 12px 8px 0px rgba(32, 70, 116, 0.3);
  padding: 40px;
  margin-top: 20px;
}

.job_categories_right .job_categories_cards .job_notfound_txt {
  color: #191e24;
  padding: 30px 0px;
  text-align: center;
}

.job_categories_right .job_categories_cards .job_categories_item h2 {
  color: #191e24;
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
}
.job_categories_right .job_categories_cards {
  padding: 40px 80px;
}
.job_categories_right .job_categories_cards .job_categories_item p {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  height: 82px;
  overflow: hidden;
}

.job_team_join_container {
  padding: 65px 0px;
}

.job_team_join_container .job_team_left h1 {
  color: #024c87;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}

.job_team_join_container .job_team_left p {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 22px;
  font-style: italic;
  font-weight: 400;
}

.job_team_join_container .job_team_left .job_team_img img {
  width: 70%;
  height: 70%;
}

.job_team_right {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.job_team_right .job_team_box {
  background-color: #204674;
  padding: 15px 10px;
  position: relative;
}
.job_team_right .job_team_box .job_person_img {
  position: absolute;
  bottom: 15px;
  height: 85px;
  width: 85px;
  left: 43%;
  border-radius: 50%;
  z-index: 99;
}

.ourjobs_carousel .ourjob_carosel_img {
  height: 85px;
  width: 85px;
  display: flex;
  align-items: center;
}

.ourjobs_carousel .ourjobs_carousel_item img {
  width: 61px;
  height: 61px;
  object-fit: cover;
  position: relative;
  border-radius: 50%;
}

.ourjobs_carousel .owl-stage .owl-item .ourjobs_tooltip {
  display: none;
}

.job_team_right .job_team_box .ourjob_desc .job_arrow {
  clip-path: polygon(50% 100%, 0 38%, 100% 37%);
  background: #fff;
  height: 50px;
  width: 50px;
  position: absolute;
  bottom: -8%;
  left: 45%;
}
.ourjobs_carousel .ourjobs_carousel_item .currentteamclass {
  height: 81px;
  width: 81px;
  left: -10px;
}

.ourjobs_carousel .owl-stage-outer {
  left: 10px;
}

.job_team_right .job_team_box .ourjob_desc {
  background-color: #fff;
  margin: 20px;
  margin-bottom: 50px;
  padding: 25px 40px;
  text-align: center;
  position: relative;
  /* clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 59% 75%, 49% 88%, 41% 75%, 0% 75%); */
}

.job_team_right .job_team_box .ourjob_desc img {
  position: absolute;
  top: -18%;
  right: -16%;
  width: 20%;
}

.job_team_right .job_team_box .ourjob_desc p {
  color: #7d8185;
  text-align: center;
  font-family: DM Sans;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  margin-top: 30px;
  height: 190px;
  overflow-y: auto;
}

.job_team_right .job_team_box .ourjob_desc p::-webkit-scrollbar {
  width: 0px;
}
.job_team_right .job_team_box .ourjob_desc h2 {
  color: #024c87;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.job_team_right .job_team_box .ourjob_desc h4 {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}

.job_cards_container {
  padding: 65px 0px;
}

.job_cards_container .container {
  position: relative;
}

.job_cards_container .job_cards_heading h1 {
  color: #024c87;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 15px;
}

.job_cards_container .job_cards_heading p {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  max-width: 95%;
}

.job_cards_container .job_cards_icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 7%;
}

.job_cards_container .job_cards_item {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 30px 60px;
  position: relative;
  margin-top: 15px;
  text-align: center;
  height: 90%;
}

.job_cards_container .job_cards_item::after {
  position: absolute;
  background-image: url("../images/job_bg_blue.png");
  content: "";
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
}

.job_cards_container .job_cards_item h2 {
  color: #fff;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  z-index: 2;
  position: relative;
}

.job_cards_container .job_cards_item p {
  color: #fff;
  text-align: center;
  font-family: DM Sans;
  font-size: 22px;
  font-style: italic;
  font-weight: 400;
  z-index: 2;
  position: relative;
}

.job_categories_container .job_categories_left .job_category_heading {
  display: flex;
  justify-content: space-between;
  padding: 0px 25px;
}

.job_categories_container .job_categories_left .job_category_heading svg {
  font-size: 24px;
  display: none;
}

/************************************************************* job description  Css *************************************************/

.job_description_banner_container {
  background-color: #1e212a;
}

.job_description_banner_container .job_banner_heading h1 {
  color: #fff;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.job_description_banner_container .job_banner_heading p {
  color: #dfdfea;
  font-family: DM Sans;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
}

.job_description_banner_container .job_banner_btn_wrapper button {
  padding: 8px 35px;
  border: none;
  margin: 0px 10px;
}

.job_description_banner_container .job_banner_btn_wrapper .job_apply_btn {
  background: #2999ff;
  color: #fff;
}

.job_description_banner_container .job_banner_btn_wrapper .job_recommended_btn {
  border: 1px solid #fff;
  color: #fff;
  background-color: transparent;
}

.job_description_banner_container .job_banner_list ul {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 30px 0px;
  padding: 0;
}

.job_description_banner_container .job_banner_list ul li h3 {
  color: #989ea6;
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.job_description_banner_container .job_banner_list ul li {
  display: flex;
  align-items: center;
  gap: 5px;
}

.job_description_banner_container .job_banner_list ul li h5 {
  color: #fff;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin: 0;
}

.job_description_banner_container .job_desc_banner_img {
  clip-path: polygon(100% 0, 17% 0, 0 73%, 15% 100%, 100% 100%);
  height: 100%;
}
.job_description_banner_container .job_desc_banner_img img {
  height: 100%;
  object-fit: cover;
}

.job_description_banner_container .job_banner_main_div {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 128px 40px;
}

.job_description_detail .job_decription_tab {
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 0px 30px;
  padding-top: 30px;
}

.job_description_detail .job_decription_tab h4 {
  color: #989ea6;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding-bottom: 14px;
  border-bottom: 4px solid #024c87;
  width: 12%;
  text-align: center;
  border-radius: 2px;
}

.job_description_detail .job_description_points {
  height: 388px;
  overflow-y: auto;
  overflow-x: hidden;
}

.job_description_detail .job_description_points::-webkit-scrollbar {
  width: 0;
}

.job_description_detail .job_description_points h4 {
  color: #024c87;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.job_description_detail .job_description_points h3 {
  color: #989ea6;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.job_description_detail .job_description_details {
  padding: 35px 0px;
  margin-bottom: 98px;
}

.job_description_details .job_desc_detail {
  margin-top: 35px;
}

.job_description_details .job_desc_detail h2 {
  color: #989ea6;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.job_description_details .job_desc_detail p,
.job_description_details .job_desc_list ul li {
  color: #616161;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.job_description_details .job_desc_list ul li {
  margin-top: 20px;
}

.job_description_details .job_description_form {
  background: #fff;
  box-shadow: -4px 4px 10px 5px rgba(0, 0, 0, 0.06);
  padding: 20px 30px;
  position: absolute;
  top: -45%;
  left: 0;
}

.job_description_details
  .job_description_form
  .job_description_form_heading
  h1 {
  color: #000;

  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}

.job_description_details .job_description_form .active .job_descfile_class {
  border: 2px dashed #00ae00 !important;
}

.job_description_form .active .job_descfile_class svg path {
  fill: #00ae00;
}

.job_description_form .active .job_descfile_class h4,
.job_description_form .active .job_descfile_class h5 {
  color: #00ae00 !important;
}
.job_description_details .job_description_form .job_descrption_inputall {
  margin-top: 15px;
}

.job_description_details .job_description_form .job_descrption_inputall label {
  color: #b4b6b7;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  width: 100%;
  font-weight: 700;
  margin-bottom: 8px;
}

.job_description_details .job_description_form .job_descrption_inputall input {
  border: 2px solid #989ea6;
  background-color: transparent;
  width: 100%;
  outline: none;
  border-radius: 3px;
  padding: 10px 12px;
}
.job_description_details
  .job_description_form
  .job_descrption_inputall
  .job_descfile_class {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 2px dashed #989ea6;
  background: rgba(217, 217, 217, 0);
  margin-top: 10px;
  padding: 6px 10px;
  justify-content: center;
  cursor: pointer;
}
.job_description_details .job_description_form .job_description_form_heading p {
  color: #656568;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.job_description_details
  .job_description_form
  .job_descrption_inputall
  .job_descfile_class
  h4 {
  color: #626668;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.job_description_details
  .job_description_form
  .job_descrption_inputall
  .job_descfile_class
  h5 {
  color: #989ea6;
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}

.job_description_detail .job_descrption_submit button {
  width: 100%;
  background: #2999ff;
  padding: 10px;
  margin-top: 20px;
  border: none;
  color: #fff;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

/*************************************************** application submit page ****************************************************/

.application_submit_section_container {
  background: #f8f8f8;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 120px 0px;
  padding-bottom: 0;
  position: fixed;
  width: 100%;
  height: 100vh;
}
.application_submit_section_container .application_submit_img {
  text-align: center;
}

.application_submit_section_container .application_submit_img img {
  width: 30%;
}

.application_submit_section_container .application_submit_heading h1 {
  color: #191e24;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  margin-top: 20px;
}

.application_submit_section_container .application_submit_heading h2 {
  color: #191e24;
  text-align: center;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

/******************************************************** contact us page css ***************************************************/

.contactUs-banner-container {
  /* ../images/contactus_banner.png */
  height: 70vh;
  background-image: url("https://d1db1k41ipas2c.cloudfront.net/contactus_banner+(1).png");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  align-items: center;
}

.contactUs-banner-container::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("../images/contact_bg.png");
  top: 0;
  left: 0;
  z-index: 1;
}

.contactUs-banner-container .contactus_heading {
  z-index: 2;
  position: relative;
}
.contactUs-banner-container .contactus_heading h1 {
  color: #fff;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.contactUs-banner-container .contactus_heading h2 {
  color: #fff;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.contactus_card_container {
  margin-top: 128px;
}

.contactus_card_container .container {
  position: relative;
}

.contactus_card_container .contactus_card_item {
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #fff;
  box-shadow: 8px 9px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 25px 30px;
  z-index: 3;
  margin-top: 22px;
  position: relative;
}

.contactus_card_container .contactus_card_item h2 {
  color: #191e24;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.contactus_card_container .contactus_card_item h3 {
  color: #626466;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.08px;
}

.contactus_card_container .contactus_card_item h4 {
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 46px;
  overflow: hidden;
}

.contactus_card_container .contactus_button_wrapper button {
  border: 2px solid #616466;
  padding: 8px;
  display: flex;
  outline: none;
  background: transparent;
  margin-bottom: 60px;
}

.contactus_card_container .contactus_img_1,
.contactus_card_container .contactus_img_2,
.contactus_card_container .contactus_img_3,
.contactus_card_container .contactus_img_4,
.contactus_card_container .contactus_img_5 {
  width: 9%;
}

.contactus_card_container .contactus_img_1 {
  position: absolute;
  left: -2%;
  top: -14%;
  z-index: 1;
}

.contactus_card_container .contactus_img_3 {
  position: relative;
  width: 6%;
  left: 4%;
  top: 28px;
}
.contactus_card_container .contactus_img_5 {
  position: absolute;
  left: 45%;
  top: -40%;
}
.contactus_card_container .contactus_img_4 {
  position: absolute;
  right: -4%;
  top: -47%;
}

.contactus_card_container .contactus_img_2 {
  position: absolute;
  right: -2%;
  bottom: -16%;
}

.contactus_form_container {
  background: #024c87;
  margin-top: 65px;
}

.contactus_form_container .contact_form_first {
  background: #013966;
  width: 50%;
  height: 100%;
}

.contactus_form_container .contact_form_all {
  padding: 40px 0px;
}

.contactus_form_container .contact_form_all .contact_form_inputall label {
  color: #fff;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 8px;
  width: 100%;
}
.contactus_form_container .contact_form_all .contact_form_inputall {
  margin-top: 10px;
}
.contactus_form_container .contact_form_all .contact_form_inputall input,
.contactus_form_container .contact_form_all .contact_form_inputall textarea,
.contactus_form_container .contact_form_all .contact_form_inputall select {
  background: #013c6c;
  width: 100%;
  padding: 8px;
  border: none;
  color: #ffff;
  outline: none;
}

.contactus_form_container .contact_form_all .contact_form_submit button {
  background-color: #fff;
  color: #024c87;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 8px 22px;
  border: none;
  margin-top: 15px;
}

.contactus_form_container .contact_form_third {
  width: 60%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactus_map_container {
  padding: 65px 0px;
}

.contactus_map_container .contactus_map_heading {
  text-align: center;
}
.contactus_map_container .contactus_map_heading h2 {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.contactus_map_container .contactus_map_heading h3 {
  color: #7054e6;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.contactus_map_container .contactus_map_heading h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.contactus_map_container .contactus_map_img {
  margin-top: 65px;
}

.contactus_map_container .contactus_mapcards {
  margin-top: 60px;
}
.contactus_map_container .contactus_mapcards .contactus_map_item {
  text-align: center;
  margin-top: 30px;
}
.contactus_map_container .contactus_map_heading h1 {
  font-size: 32px;
}
.contactus_map_container .contactus_map_heading h2 {
  font-size: 22px;
}
.contactus_map_container .contactus_mapcards .contactus_map_item h4 {
  color: #191e24;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.contactus_map_container .contactus_mapcards .contactus_map_item h5 {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.contactus_map_container .contactus_mapcards .contactus_map_item h6 {
  color: #53389f;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/************************************************************ our policy css *****************************************************/

.our_policy_banner_container {
  background-color: #000;
  padding: 65px 0px;
  height: 70vh;
}

.our_policy_banner_container .our_policy_left h1 {
  color: #fff;
  font-family: Inter;
  font-size: 52px;
  font-style: normal;
  font-weight: 400;
}

.our_policy_banner_container .our_policy_right p {
  color: #989ea6;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.our_policy_banner_container .our_policy_right .our_policy_btn_wrapper {
  display: flex;
  gap: 15px;
  margin-top: 40px;
}

.our_policy_banner_container .our_policy_right .our_policy_btn_wrapper button {
  padding: 10px 32px;
  border: none;
}

.our_policy_banner_container
  .our_policy_right
  .our_policy_btn_wrapper
  .our_policy_learnmore {
  background: #f6c15b;
  color: #191e24;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.our_policy_banner_container
  .our_policy_right
  .our_policy_btn_wrapper
  .our_policy_startfree {
  border: 2px solid #393838;
  background: #161614;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

/* .our_policy_carousel .owl-item .our_policy_item:nth-child(3n+1){
  background: #f6c15b;
}
.our_policy_carousel .owl-item .our_policy_item:nth-child(3n + 2) {
  background: #6cbcfc;
}
.our_policy_carousel .owl-item .our_policy_item:nth-child(3n) {
  background: #d2ea92;
} */

.our_policy_carousel .our_policy_item .our_policy_card_description {
  padding: 18px 20px;
  position: relative;
}

.our_policy_carousel .our_policy_item .our_policy_card_description h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.our_policy_carousel .our_policy_item .our_policy_card_description p {
  color: #2a3037;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 88px;
}

.our_policy_carousel .owl-nav .disabled {
  opacity: 0.8;
}

.our_policy_carousel_container {
  transform: translateY(-68%);
  height: 44vh;
}
.our_policy_carousel .owl-prev button,
.our_policy_carousel .owl-next button,
.our_policy_carousel .our_policy_item .our_policy_arrow {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  background-color: #161614;
  padding: 12px;
}

.our_policy_carousel .our_policy_item .our_policy_arrow {
  padding: 5px;
  position: absolute;
  top: -18%;
  left: 80%;
}

.our_policy_carousel .owl-nav [class*="owl-"]:hover {
  background-color: transparent;
}

.our_policy_carousel .owl-nav {
  position: absolute;
  left: -17%;
  top: 22%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/*************************************************** Blog page css *******************************************************************/

.blogbanner_container {
  padding: 65px 0px;
  position: relative;
}

.blogbanner_container .blogbanner_left h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  max-width: 52%;
}

.blogbanner_container .blogbanner_left {
  clip-path: polygon(100% 0, 0 0, 0 100%);
  background: #d9d9d9;
  padding: 22px 55px;
  position: absolute;
  left: 0;
  top: 0;
  width: 80%;
  height: 72%;
  z-index: 2;
}

.blogbanner_container .blog_banner_black {
  clip-path: polygon(100% 100%, 0 0, 0 100%);
  background-color: #000;
  width: 80%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 78%;
  z-index: 1;
}

.blogbanner_container .blogbanner_card {
  z-index: 3;
  position: absolute;
  top: 38%;
  left: 0;
  padding: 0px 55px;
  width: 27%;
}

.blogbanner_container .blogbanner_card .blogbanner_card_wrapper {
  background: #fff;
  box-shadow: 9px -6px 16px -6px rgba(0, 0, 0, 0.25);
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 70%;
}

.blogbanner_container
  .blogbanner_card
  .blogbanner_card_wrapper
  .blogbanner_blogicon {
  background: #f89321;
  height: 81px;
  width: 81px;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0px 10px;
}

.blogbanner_container .blogbanner_card .blogbanner_card_wrapper h6 {
  color: #989ea6;
  text-align: center;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  margin-top: 20px;
  font-weight: 400;
  line-height: normal;
}

.blogbanner_container .blogbanner_card .blogbanner_card_wrapper h1 {
  color: #000;
  text-align: center;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.blogbanner_container .blogbanner_card .blogbanner_card_inp {
  margin-top: 20px;
}

.blogbanner_container .blogbanner_card .blogbanner_card_inp input {
  width: 70%;
  background: #fff;
  padding: 12px 8px;
  border: none;
  outline: none;
}

.blogbanner_container .blogbanner_card .blogbanner_card_inp button {
  border: none;
  width: 30%;
  padding: 12px 8px;
  background-color: #f89321;
  color: #fff;
}

.blogbanner_container .blog_banner_middle {
  top: 42%;
  left: 34%;
  position: absolute;
}

.blogbanner_container .blog_banner_middle img {
  width: 70%;
}

.blogcard_container {
  padding: 65px 0px;
}

.blogcard_container .blogcard_list {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}

.blogcard_container .blogcard_list ul {
  list-style: none;
  display: flex;
  gap: 50px;
  padding: 0;
}

.blogcard_container .blogcard_list ul li {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
  padding-bottom: 4px;
}

.blogcard_container .blogcard_list ul .active {
  border-bottom: 3px solid #00237c;
  color: #000;
}

.blogcard_container .blog_card_typeone .blog_typeone_first {
  height: 405px;
  background-image: url("../images/blog_1.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.blogcard_container .blog_card_typeone .blog_typeone_first::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("../images/blog_blue.png");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}

.blogcard_container .blog_card_typeone .blog_typeone_div1 {
  z-index: 2;
}

.blogcard_container .blog_typeone_first .blog_typeone_first_heading {
  padding: 0px 20px;
}

.blogcard_container .blog_typeone_first button {
  background: #fff;
  padding: 0px 12px;
  width: 45%;
  color: #024c87;
  font-family: DM Sans;
  font-size: 20px;
  border: none;
  font-style: normal;
  font-weight: 400;
}

.typeone_carousel .blog_typeone_item .blog_typeone_img img {
  height: 212px;
  object-fit: cover;
}
.typeone_carousel .blog_typeone_item .blog_typeone_card_desc {
  margin-top: 10px;
}

.typeone_carousel .blog_typeone_item .blog_typeone_card_desc h1 {
  color: #024c87;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.typeone_carousel .blog_typeone_item .blog_typeone_card_desc h4 {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.typeone_carousel .blog_typeone_item .blog_typeone_card_desc p {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.typeone_carousel .blog_typeone_item .blog_typeone_card_desc button {
  color: #024c87;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  border: none;
  background-color: transparent;
}

.typeone_carousel .owl-nav,
.typetwo_carousel .owl-nav {
  position: absolute;
  right: 5px;
  top: -14%;
}

.typetwo_carousel .owl-nav {
  top: -19%;
}

.typeone_carousel .owl-nav button:hover,
.typetwo_carousel .owl-nav button:hover {
  background: transparent !important;
}

.blogcard_container .blog_card_typetwo {
  margin-top: 65px;
}

.blog_card_typetwo .blog_card_typetwo_first .card_typetwo_heading h1 {
  color: #fff;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
}

.blog_card_typetwo .blog_card_typetwo_first .card_typetwo_heading h2 {
  color: #fff;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
}

.blog_card_typetwo .blog_card_typetwo_first .card_typetwo_heading {
  z-index: 2;
}

.blog_card_typetwo .blog_card_typetwo_first {
  position: relative;
  height: 270px;
  display: flex;
  align-items: end;
  padding: 20px;
  background-image: url("../images/blog_5.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.blog_card_typetwo .blog_card_typetwo_first::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/blog_blue.png");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}

.typetwo_carousel .blog_typetwo_item {
  position: relative;
  height: 270px;
  display: flex;
  align-items: end;
  padding: 20px;
  background-repeat: no-repeat;
  background-size: cover;
}

.typetwo_carousel .blog_typetwo_item::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("../images/blog_transparent.png");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}

.typetwo_carousel .blog_typetwo_item h1 {
  color: #fff;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  z-index: 2;
  cursor: pointer;
}

.typetwo_carousel .blog_typetwo_item h1:hover {
  text-decoration: underline;
}

.blogcard_container .blogcard_seemore_btn {
  display: flex;
  justify-content: end;
  margin: 10px 0px 20px 0px;
}

.blogcard_container .blogcard_seemore_btn button {
  background-color: #f89321;
  padding: 12px;
  color: #fff;
  font-family: DM Sans;
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.blog_desc_container .blog_desc_left h1 {
  color: #012e6a;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}

.blog_desc_container .blog_desc_left p {
  margin-top: 20px;
  color: #7d8185;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.blog_desc_container .blog_desc_right .blog_desc_item {
  background: #fff;
  padding: 40px;
  margin-bottom: 30px;
}
.blog_desc_container .blog_desc_right .blog_desc_item1 {
  box-shadow: 0px 4px 19px 5px rgba(0, 0, 0, 0.25);
}
.blog_desc_container .blog_desc_right .blog_desc_item2 {
  border: 1px solid #989ea6;
}
.blog_desc_container .blog_desc_right .blog_desc_item3 {
  box-shadow: 0px 4px 35px 1px rgba(0, 0, 0, 0.1);
  padding: 100px;
}
.blog_desc_container .blog_desc_right .blog_desc_item h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}

.blog_desc_container .blog_desc_right .blog_desc_item h2 {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.blog_gallery_container {
  position: relative;
  height: 420px;
}

.blog_gallery_container .blog_gallery_left {
  z-index: 2;
  position: relative;
}

.blog_gallery_container::after {
  clip-path: circle(50.1% at 2% 50%);
  position: absolute;
  top: -14%;
  content: "";
  height: 100%;
  left: 0;
  transform: scale(1.5);
  width: 30%;
  background-color: #c4fee2;
  z-index: 1;
}

.blog_joinus_container {
  margin-bottom: 65px;
}
.blog_joinus_container .blog_joinus_left h1 {
  color: #191e24;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}

.blog_joinus_container .blog_joinus_left p {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 18px;
  margin-top: 15px;
  font-style: normal;
  font-weight: 400;
}

.blog_joinus_container .blog_joinus_right .blog_tagone {
  background: #fff;
  box-shadow: 0px 4px 35px 1px rgba(0, 0, 0, 0.1);
  padding: 20px 40px;
}

.blog_joinus_container .blog_joinus_right .blog_tagone h2 {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.blog_joinus_container .blog_joinus_right .blog_tagtwo h2 {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.blog_joinus_container .blog_joinus_right .blog_tagtwo {
  background: #fff;
  box-shadow: 0px 4px 35px 1px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  padding: 20px 40px;
  position: relative;
}
.blog_joinus_container .blog_joinus_right .blog_tagtwo::after {
  content: "";
  height: 100%;
  width: 0.6%;
  background-color: #f89321;
  position: absolute;
  left: 0;
  top: 0;
}
.blog_joinus_container .blog_joinus_right .blog_tagtwo p {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

/*************************************************************** blog description css ***********************************************/

.blog_desc_card_container .blog_card_left .blog_card_para1 h1 {
  color: #183154;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  max-width: 70%;
}

.blog_desc_card_container {
  padding: 65px 0px;
}

.blog_desc_card_container .blog_card_left .blog_card_para1 h2 {
  color: #000;
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  margin-top: 25px;
}

.blog_desc_card_container .blog_card_left .blog_card_para1 p,
.blog_desc_card_container .blog_card_left .blog_card_para2 p {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  margin-top: 20px;
}

.blog_desc_card_container .blog_card_left .blog_card_para2 h2 {
  color: #012e6a;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

/* .blog_desc_card_container .blog_card_right img { */
/* height: 80vh;
  object-fit: cover; */
/* } */

/********************************************************* Testimonial video css ***************************************************/
.testimonial_video_banner_container {
  background: #fbf9f9;
  padding: 65px 20px;
}
.testimonial_video_banner_container
  .testimonial_banner_left
  .testimonial_banner_heading
  h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 30px;
}

.testimonial_video_banner_container
  .testimonial_banner_left
  .testimonial_banner_heading
  h1
  span {
  color: #024c87;
  font-weight: 700;
}

.testimonial_video_banner_container
  .testimonial_banner_left
  .testimonial_banner_heading
  p {
  color: #000;
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  margin: 20px 0px;
}

.testimonial_video_banner_container .testimonial_banner_wrapper {
  display: flex;
  justify-content: space-between;
  margin: 40px 0px;
  align-items: center;
  width: 85%;
}

.testimonial_video_banner_container
  .testimonial_banner_wrapper
  .testimonial_getstart {
  color: #fff;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  background: #024c87;
  box-shadow: 0px 4px 15px 0px rgba(2, 76, 135, 0.4);
  font-weight: 600;
  padding: 14px 80px;
  border: none;
}

.testimonial_video_banner_container
  .testimonial_watch_div
  .testimonial_watchvideo {
  box-shadow: 0px 4px 15px rgba(2, 76, 135, 0.4);
  height: 61px;
  width: 61px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #fff;
  border: none;
  padding-left: 5px;
}

.testimonial_video_banner_container .testimonial_watch_div {
  display: flex;
  align-items: center;
  gap: 40px;
}

.testimonial_video_banner_container .testimonial_watch_div span {
  color: #024c87;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.testimonial_video_banner_container .testimonial_banner_cards {
  margin-top: 20px;
}

.testimonial_video_banner_container
  .testimonial_banner_cards
  .testimonial_banner_item
  h1 {
  color: #024c87;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
}
.testimonial_video_banner_container
  .testimonial_banner_cards
  .testimonial_banner_item
  h2 {
  color: #000;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.testimonial_video_banner_container .testimonial_banner_right {
  display: flex;
  justify-content: end;
}

.testimonial_video_banner_container .testimonial_banner_right img {
  width: 78%;
}

.testimonial_video_card_container .testmonial_card_heading h1 {
  color: #024c87;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
}

.testimonial_video_card_container {
  padding: 65px 0px;
}

.testimonial_video_card_container .testmonial_card_heading {
  margin-bottom: 50px;
}

.testimonial_video_card_container .testmonial_card_heading h2 {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
}

.testimonial_video_card_container .testimonial_card_item {
  border: 0.2px solid #989ea6;
  background: #fff;
  box-shadow: -4px 4px 6px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
  display: flex;
  align-items: center;
  height: 400px;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
}

.testimonial_video_card_container
  .testimonial_card_item
  .testimonialcard_profile_img {
  width: 30%;
  border-radius: 50%;
  object-fit: cover;
}

.testimonial_video_card_container .testimonial_card_item h2 {
  color: #2a3037;
  text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-top: 10px;
}

.testimonial_video_card_container .testimonial_card_item h3 {
  color: #2a3037;
  text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 15px;
}
.testimonial_video_card_container .testimonial_card_item h4 {
  color: #2a3037;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
}

.testimonial_video_card_container .testimonial_card_item h4 span {
  color: #2a3037;

  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  margin-top: 15px;
}

.testimonial_video_card_container .testimonialcard_uni_img {
  width: 60%;
  margin: 30px 0px;
}

.testimonial_video_card_container .testimonial_card_wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}

.testimonial_video_card_container .testimonia_card_link {
  color: #2a3037;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  border: 0.5px solid #989ea6;
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: inline-block;
  padding: 8px 12px;
}

.testimonial_apply_container {
  padding: 65px 0px;
  position: relative;
}

.testimonial_apply_container::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  height: 100%;
  content: "";
  background-color: #024c87;
  z-index: 1;
}

.testimonial_apply_container .testimonial_apply_left h1 {
  color: #fff;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
}

.testimonial_apply_container .testimonial_apply_left p {
  color: #f3f3f3;
  font-family: DM Sans;
  font-size: 24px;
  max-width: 78%;
  font-style: normal;
  font-weight: 400;
  margin: 40px 0px;
}

.testimonial_apply_container .testimonial_apply_left button {
  color: #024c87;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  background-color: #fff;
  padding: 8px 22px;
  border: none;
}

.testimonial_apply_container .row {
  z-index: 2;
  position: relative;
}

/***************************************************** mentor page css ***********************************************************/

.mentor_banner_container .mentor_banner_main_div {
  height: 80vh;
  /* "../images/mentor_banner.png" */
  background-image: url("https://d1db1k41ipas2c.cloudfront.net/mentor_banner+(1).png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 65px 0px;
  position: relative;
}
.mentor_banner_container .mentor_banner_main_div::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("../images/mentor_transparent.png");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
}
.mentor_banner_container .mentor_banner_main_div .mentor_banner_heading {
  max-width: 50%;
  z-index: 2;
  position: relative;
}

.mentor_banner_container .mentor_banner_main_div .mentor_banner_heading h1 {
  color: #fff;
  font-family: DM Sans;
  font-size: 62px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.mentor_banner_container .mentor_banner_main_div .mentor_banner_heading p {
  color: #fff;
  font-family: DM Sans;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  max-width: 68%;
}

.mentor_banner_container .mentor_banner_main_div .mentor_banner_heading button {
  margin: 40px 0px;
  border: 1px solid #fff;
  background: transparent;
  padding: 8px 22px;
  color: #fff;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
}
.mentor_tutorial_container {
  padding: 65px 0px;
}

.mentor_tutorial_container .mentor_tutorial_heading h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.mentor_tutorial_container .mentor_tutorial_heading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.mentor_tutorial_container .mentor_tutorial_heading h2 {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  width: 32%;
  text-align: center;
  font-weight: 400;
  margin-bottom: 40px;
}

.mentor_tutorial_container .mentor_tutorial_card {
  margin-top: 20px;
  position: relative;
}

.mentor_tutorial_container .mentor_tutorial_card h1 {
  color: #fff;
  font-family: DM Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.mentor_tutorial_container .mentor_tutorial_card p {
  color: #e3e3e3;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  overflow-y: auto;
}

.mentor_tutorial_container .mentor_tutorial_card p::-webkit-scrollbar {
  width: 4px;
  padding-right: 10px;
}

/* Track */
.mentor_tutorial_container .mentor_tutorial_card p::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 8px;
}

/* Handle */
.mentor_tutorial_container .mentor_tutorial_card p::-webkit-scrollbar-thumb {
  background: #e3e3e3;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e3e3e3;
}

.mentor_tutorial_container .mentor_tutorial_card .mentor_tutorialcard_desc {
  padding: 0px 30px;
  width: 80%;
  position: absolute;
  right: 0;
  bottom: -12%;
  height: 280px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.mentor_team_container {
  padding: 65px 0px;
}

.mentor_team_container .mentor_carditem_link_div {
  display: flex;
  align-items: end;
  justify-content: end;
}

.mentor_team_container .mentor_team_heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mentor_team_container .mentor_team_heading h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.mentor_team_container .mentor_team_heading p {
  color: #7d8185;
  text-align: center;
  font-family: DM Sans;
  width: 40%;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
}

.mentor_main_carousel .mentor_card_item {
  background: #162f59;
  padding: 60px 40px;
}

.mentor_main_carousel .mentor_carditem_heading .mentor_carditem_hr {
  height: 2px;
  background: #fff;
  width: 100%;
  margin-bottom: 50px;
}

.mentor_main_carousel .mentor_carditem_heading h1 {
  color: #fff;
  font-family: DM Sans;
  font-size: 47px;
  font-style: normal;
  font-weight: 400;
  max-width: 66%;
}

.mentor_main_carousel .mentor_carditem_heading h1 span {
  color: #fff;
  font-family: DM Sans;
  font-style: normal;
  font-weight: 700;
}

.mentor_main_carousel .mentor_carditem_heading button {
  background: #ff9a28;
  padding: 6px 12px;
  color: #fff;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  border: none;
  margin: 45px 0px;
}

.mentor_main_carousel .mentor_carditem_desc h2 {
  color: #fff;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}

.mentor_main_carousel .mentor_carditem_desc h3 {
  color: #fff;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}

.mentor_main_carousel .mentor_carditem_links {
  display: flex;
  justify-content: end;
  gap: 10px;
  align-items: center;
}
.mentor_main_carousel .mentor_carditem_links a img {
  width: 68%;
}

.mentor_main_carousel .mentor_card_item .mentor_card_item_left img {
  height: 540px;
  width: 88%;
  flex-shrink: 0;
  object-fit: cover;
}

.mentor_team_container .mentor_card_list,
.mentor_team_container .mentor_card_circle {
  margin-top: 55px;
}

.mentor_team_container .mentor_card_circle {
  width: 35%;
}

.mentor_second_carrousel .mentor_second_img,
.mentor_second_carrousel .owl-item {
  height: 130px;
  width: 130px;
}
.mentor_card_circle_main_div {
  display: flex;
  justify-content: center;
}
.mentor_second_carrousel .mentor_second_img img {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  object-fit: cover;
}

.mentor_community_container {
  background: #162f59;
  padding: 60px 20px;
}

.mentor_community_container .mentor_community_heading h1 {
  color: #fff;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
}

.mentor_community_container .mentor_community_heading {
  margin-bottom: 40px;
  text-align: center;
}

.mentor_community_container .mentor_community_div {
  padding: 0px 10px;
  position: relative;
  top: -30px;
}

.mentor_community_container .mentor_community_heading h2 {
  color: #dcdcdc;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
}

.mentor_community_carousel .mentor_community_card p {
  color: #fff;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  height: 196px;
  line-height: 1.6;
  overflow-y: auto;
}

.mentor_community_carousel .mentor_community_card p::-webkit-scrollbar {
  width: 0px;
}
.mentor_community_carousel .mentor_community_card h4 {
  color: #fff;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 30px;
}

.mentor_community_carousel .mentor_community_card_p {
  padding: 30px 20px;
  padding-bottom: 80px;
}
.mentor_community_carousel .mentor_community_card img {
  height: 61px;
  width: 61px;
  border-radius: 50%;
  object-fit: cover;
}

.mentor_community_carousel .mentor_community_card h5 {
  color: #989ea6;
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.mentor_community_carousel .owl-nav {
  text-align: start;
}

.mentor_community_carousel .owl-nav .owl-prev,
.mentor_community_carousel .owl-nav .owl-next {
  height: 41px !important;
  width: 41px !important;
  color: #fff !important;
  font-size: 20px !important;
  background-color: transparent;
  border-radius: 50% !important;
  border: 1px solid #fff !important;
}

.mentor_faq_container {
  padding: 65px 0px;
}

.mentor_faq_container .mentor_faq_div {
  background: #fff;
  box-shadow: 10px 10px 16px 9px rgba(0, 0, 0, 0.25);
  padding: 30px;
}

.mentor_faq_container .mentor_faq_heading h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
}

.mentor_faq_container .mentor_faq_heading h2 {
  color: #000;
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  max-width: 60%;
  margin-top: 10px;
}

.mentor_faq_container .mentor_faq_heading {
  margin-bottom: 40px;
}

.mentor_faq_container .mentor_faq_card {
  margin-top: 20px;
}
.mentor_faq_container .mentor_faq_card h3 {
  color: #000;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.mentor_faq_container .mentor_faq_card p {
  color: #22436f;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.mentor_faq_container .mentor_faq_intouch {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  align-items: center;
}

.mentor_faq_container .mentor_faq_intouch .mentor_faq_intouch_div {
  display: flex;
  align-items: center;
  gap: 20px;
}
.mentor_faq_container .mentor_faq_intouch .mentor_faq_intouch_div img {
  height: 61px;
  width: 61px;
  border-radius: 50%;
}

.mentor_faq_container .mentor_faq_intouch .mentor_faq_intouch_div h3 {
  color: #000;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
.mentor_faq_container .mentor_faq_intouch .mentor_faq_intouch_div h4 {
  color: #22436f;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}

.mentor_faq_container .mentor_faq_intouch .mentor_faq_getintouch_btn button {
  background: #024c87;
  padding: 10px 18px;
  border: none;
  color: #fff;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

/************************************************** Placed candidate page css ********************************************************/

.placed_candidate_banner_container {
  padding: 65px 0px;
  position: relative;
}

.placed_candidate_banner_container .placed_candidate_clientbg {
  margin: 65px 0px;
}

.placed_canididate_banner_left .placed_candidate_banner_heading {
  max-width: 82%;
}

.placed_canididate_banner_left .placed_candidate_banner_heading h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
}
.placed_canididate_banner_left .placed_candidate_banner_heading h1 span {
  font-weight: 700;
}

.placed_canididate_banner_left .placed_candidate_banner_heading h2 {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}

.placed_canididate_banner_left .placed_candidate_banner_getstart button {
  background: #0c212c;
  color: #fff;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  border: none;
  padding: 8px 12px;
  margin: 30px 0px;
}

.placed_canididate_banner_left
  .placed_candidate_banner_cards
  .placed_candidate_banner_items
  h3 {
  color: #012d50;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
}

.placed_canididate_banner_left .placed_candidate_banner_cards {
  display: flex;
  align-items: center;
  gap: 60px;
}
.placed_canididate_banner_left
  .placed_candidate_banner_cards
  .placed_candidate_banner_items
  h4 {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.placed_canididate_banner_left
  .placed_candidate_banner_cards
  .placed_candidate_banner_hr {
  color: rgba(152, 158, 166, 0.16);
  font-size: 120px;
  font-weight: 100;
}

.placed_canididate_banner_right {
  position: absolute;
  right: 0;
  top: 0;
  width: 45%;
}

.placed_candidate_customer_container {
  padding: 65px 0px;
}

.placed_candidate_customer_container .placed_candidate_customer_heading h1 {
  color: #4e4e4e;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  margin-bottom: 50px;
  text-transform: capitalize;
}

.placed_customer_carousel .placed_customer_item {
  padding: 12px 20px;
  height: 420px;
}

.placed_customer_carousel .placed_customer_item p {
  color: #fff;
  font-family: DM Sans;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  height: 268px;
  overflow-y: auto;
}

.placed_customer_carousel .placed_customer_item p::-webkit-scrollbar {
  width: 0px;
}
.placed_customer_carousel .placed_customer_item .placed_customer_hr {
  height: 2px;
  background-color: #fff;
  width: 100%;
  margin: 20px 0px;
}

.placed_customer_carousel .placed_customer_item .placed_customer_item_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}

.placed_customer_carousel .placed_customer_item .placed_customer_img img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.placed_customer_carousel .placed_customer_item .placed_customer_img {
  display: flex;
  align-items: center;
  gap: 20px;
}

.placed_customer_carousel .placed_customer_item .placed_customer_img h5 {
  color: #fff;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.placed_customer_carousel .placed_customer_item .placed_customer_img h6 {
  color: #fff;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}

.placed_customer_carousel .placed_customer_item .palced_customer_link a img {
  height: 40px;
  width: 40px;
}

.placed_customer_carousel .owl-nav,
.placed_story_carousel .owl-nav {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  gap: 60px;
}
.placed_customer_carousel .owl-nav .owl-prev,
.placed_customer_carousel .owl-nav .owl-next,
.placed_story_carousel .owl-nav .owl-prev,
.placed_story_carousel .owl-nav .owl-next {
  transform: scale(1.5);
}

.placed_customer_carousel .owl-nav .owl-prev:hover,
.placed_customer_carousel .owl-nav .owl-next:hover,
.placed_story_carousel .owl-nav .owl-prev:hover,
.placed_story_carousel .owl-nav .owl-next:hover {
  background-color: transparent;
}

.placed_candidate_story_container {
  padding: 65px 0px;
}

.placed_candidate_story_container .placed_candidate_story_heading h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 62px;
  font-style: normal;
  font-weight: 700;
}

.placed_candidate_story_container .placed_candidate_story_heading h1 span {
  color: #ff9a28;
}

.placed_candidate_story_container .placed_candidate_story_heading h2 {
  color: #5b5b5b;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.placed_candidate_story_container .placed_candidate_story_heading {
  text-align: center;
  margin-bottom: 40px;
}

.placed_story_carousel
  .placed_candidate_story_item
  .placed_candidate_story_itemdesc {
  padding: 10px;
}
.placed_story_carousel .placed_candidate_story_item h3 {
  color: #ff9a28;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}

.placed_story_carousel .placed_candidate_story_item .candidate_profile_img {
  height: 400px;
}
.placed_story_carousel .placed_candidate_story_item h4 {
  color: #000;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}
.placed_story_carousel .placed_candidate_story_item .palced_candidate_link {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 20px;
  text-decoration-line: underline;
}
.placed_story_carousel
  .placed_candidate_story_item
  .palced_candidate_link
  span {
  cursor: pointer;
}
.placed_story_carousel
  .placed_candidate_story_item
  .palced_candidate_link
  button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  padding: 10px 0px;
}

.placed_candidate_advice_container {
  padding: 65px 0px;
}

.placed_candidate_advice_container .placed_candidate_advice_heading h1 {
  max-width: 40%;
  color: #4e4e4e;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 40px;
}

.placed_candidate_advice_container .placed_candidate_advice_heading h1 span {
  color: #000;
}

.placed_candidate_advice_container .placed_candidate_advice_left {
  background: #f5f9fa;
  padding: 30px;
}

.placed_candidate_advice_container .placed_candidate_advice_desc h1 {
  color: #4e4e4e;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  margin-top: 20px;
}

.placed_candidate_advice_container .placed_candidate_advice_desc p {
  color: #989ea6;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-top: 20px;
}

.placed_candidate_advice_container .placed_candidate_advice_tab {
  background: #f5f9fa;
  padding: 30px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.placed_candidate_advice_container
  .placed_candidate_advice_tab
  .placed_candidate_advice_icon_div
  button {
  background-color: #e1e7ef;
  height: 61px;
  width: 61px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 10px;
  border: none;
}

.placed_candidate_advice_container
  .placed_candidate_advice_tab
  .placed_candidate_advice_icon_div
  span {
  color: #000;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}

.placed_candidate_advice_container
  .placed_candidate_advice_tab
  .placed_candidate_advice_icon_div {
  display: flex;
  align-items: center;
  gap: 20px;
}

.placed_candidate_advice_container
  .placed_candidate_advice_tab
  .placed_candidate_advice_to
  a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 41px;
  width: 41px;
  border-radius: 50%;
  background-color: #144154;
  color: #fff;
}

.placed_candidate_advice_container .placed_candidate_advice_whyoeson {
  background: #024c87;
  padding: 20px;
}

.placed_candidate_advice_container
  .placed_candidate_advice_whyoeson
  .placed_candidate_advice_whybtn
  button {
  background-color: #ff9a28;
  height: 61px;
  width: 61px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
}

.placed_candidate_advice_container
  .placed_candidate_advice_whyoeson
  .placed_candidate_advice_whybtn {
  margin-bottom: 14px;
}
.placed_candidate_advice_container
  .placed_candidate_advice_whyoeson
  .placed_candidate_advice_whybtn
  button
  span {
  height: 30px;
  width: 15px;
  background-color: #fff;
  display: inline-block;
}

.placed_candidate_advice_container .placed_candidate_advice_whyoeson_desc h4 {
  color: #fff;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.placed_candidate_advice_container .placed_candidate_advice_whyoeson_desc {
  max-width: 80%;
}
.placed_candidate_advice_container .placed_candidate_advice_whyoeson_desc p {
  color: #fff;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.placed_candidate_map_container {
  padding: 65px 0px;
}

.placed_candidate_map_container .placed_candidate_map_heading h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
}

.placed_candidate_map_container .placed_candidate_map_heading h1 span {
  color: #f89321;
}

.placed_candidate_map_container .placed_candidate_map_heading h2 {
  color: #5b5b5b;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.placed_candidate_map_container .placed_candidate_map_heading {
  margin-bottom: 80px;
  text-align: center;
}

/************************************************************* what our testimonies css ************************************************/

.whatTestimonies_banner_container {
  padding: 65px 0px;
  background-image: url("https://d1db1k41ipas2c.cloudfront.net/whattestimonies_banner+(1).jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 80vh;
}

.whatTestimonies_banner_container .whatTestimonies_banner_heading h3 {
  color: #d9d9d9;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.whatTestimonies_banner_container .whatTestimonies_banner_heading h1 {
  color: #fff;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.whatTestimonies_banner_container .whatTestimonies_banner_heading h2 {
  color: #ff9a28;
  font-family: DM Sans;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.whatTestimonies_banner_container .whatTestimonies_banner_heading button {
  border: 2px solid #ff9a28;
  color: #fff;
  padding: 8px 22px;
  color: #fff;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-color: transparent;
}

.whatTestimonies_banner_container .whatTestimonies_banner_heading {
  text-align: center;
}

.whatTestimonies_client_container {
  padding: 65px 0px;
  background: #f7fffc;
}
.whatTestimonies_client_container .whatTestimoniew_client_logo {
  border: 1px solid rgba(152, 158, 166, 0.5);
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 0px 32px;
  height: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.whatTestimonies_client_container .whatTestimoniew_client_logo img {
  width: 36%;
}

.whatTestimonies_client_container .whatTestimonies_client_cards {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(5, 1fr);
}

.whatTestimonies_client_container .whatTestimonies_client_heading h1 {
  color: #6fc3a3;
  font-family: DM Sans;
  /* font-size: 18px; */
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: normal;
}
.whatTestimonies_client_container .whatTestimonies_client_heading h2 {
  color: #000;
  text-align: center;
  font-family: DM Sans;
  /* font-size: 32px; */
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 85%;
}

.whatTestimonies_client_container .whatTestimonies_client_heading {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.community_placed_container {
  padding: 65px 0px;
  position: relative;
}
.community_placed_container .container {
  padding: 0px 40px;
}
.community_placed_container::after {
  content: "";
  position: absolute;
  width: 70%;
  top: 0;
  right: 0;
  background-color: #f6f6fb;
  height: 100%;
  z-index: -2;
}

.community_placed_container .placed_heading {
  position: relative;
}

.community_placed_container .placed_heading h1 {
  font-size: 65px;
  max-width: 60%;
  z-index: 10;
  color: #191e24;
  position: relative;
}

.placed_desc h1 {
  font-size: 33px;
  line-height: 1.4;
  color: #191e24;
}

.placed_desc p {
  font-size: 20px;
  line-height: 1.8;
  color: #7d8185;
  height: 298px;
  overflow-y: auto;
}

.placed_desc p::-webkit-scrollbar {
  width: 0;
}

.placed_name_div h5,
.placed_name_div h6 {
  margin: 0;
  color: #191e24;
  font-weight: 700;
  text-transform: capitalize;
}

.placed_img_div img {
  height: 450px;
  object-fit: cover;
}

.placed-carousel .owl-height {
  height: 100% !important;
}
.placed_carousel-item {
  position: relative;
  padding-top: 65px;
}
.placed_img_div {
  padding: 0px 6px;
  position: relative;
}

.placed_heading::after {
  content: "";
  height: 120px;
  width: 130px;
  background-image: url("../images/contactus_img_1.png");
  position: absolute;
  top: 103px;
  left: 7px;
}

.placed-carousel .owl-prev,
.placed-carousel .owl-next {
  border: none;
  display: flex;
}
.placed-carousel .owl-prev:hover,
.placed-carousel .owl-next:hover {
  background-color: transparent !important;
}

.quote_icon_div {
  position: absolute;
  top: -45px;
  right: -33px;
  z-index: 100;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #41caae;
  border-radius: 50%;
}

.quote_icon_div svg {
  height: 45px;
  width: 45px;
}
.placed-carousel .owl-nav svg {
  height: 85px;
}

.placed-carousel .owl-next {
  transform: scale(2);
}

.placed-carousel .owl-nav {
  margin-top: 20px;
  display: flex;
  justify-content: start;
  gap: 20px;
  align-items: center;
  padding-left: 55px;
}

.quote_icon_div svg path {
  fill: #ffff !important;
}

.placed-carousel .placed_carousel-item .placed_img_div {
  width: 80%;
}

.placed-carousel .placed_linkedin_logo a {
  height: 45px;
  width: 45px;
  border-radius: 4px;
  display: inline-block;
  background: #0177b5;
  color: #fff;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.placed-carousel .placed_company_logo a {
  height: 51px;
  width: 51px;
  border-radius: 4px;
  background: #fff;
  color: #fff;
  display: flex;
}

.what_testimonie_map_container {
  padding: 65px 0px;
}

.what_testimonie_map_container .what_testimonies_map_heading h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
}

.what_testimonie_map_container .what_testimonies_map_heading h2 {
  color: #5b5b5b;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
.what_testimonie_map_container .what_testimonies_map_heading {
  text-align: center;
  margin-bottom: 50px;
}

.what_testimonies_reach_container .what_testimonies_reach_heading h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 62px;
  font-style: normal;
  font-weight: 700;
}

.what_testimonies_reach_container .what_testimonies_reach_heading p {
  color: #989ea6;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.what_testimonies_reach_container .what_testimonies_reach_heading {
  max-width: 70%;
}
.what_testimonies_reach_container .what_testimonies_reach_img {
  display: flex;
  justify-content: center;
}

.what_testimonies_reach_container .what_testimonies_reach_img img {
  width: 80%;
}

.what_testimonies_reach_container .what_testimonies_reach_div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.what_testimonies_reach_container {
  padding: 65px 0px;
}

/************************************************* study + internship page css ******************************************************/

.study_and_internship_banner_container
  .study_and_internship_banner_left
  .study_and_internship_banner_heading
  h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 61px;
  font-style: normal;
  font-weight: 400;
  margin-top: 14px;
}

.study_and_internship_banner_container
  .study_and_internship_banner_left
  .study_and_internship_banner_heading
  h1
  img {
  height: 51px;
  width: 51px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  margin-top: 15px;
}

.study_and_internship_banner_container
  .study_and_internship_banner_left
  .study_and_internship_banner_heading
  h1
  .study_internship_banner_hr {
  display: inline-block;
  /* width: 26%; */
  width: 12%;
  height: 2px;
  background-color: #024c87;
}

.study_and_internship_banner_container
  .study_and_internship_banner_left
  .study_and_internship_banner_heading
  h1
  svg {
  transform: scale(0.6);
  margin-bottom: 34px;
}

.study_and_internship_banner_container
  .study_and_internship_banner_left
  .study_and_internship_banner_heading
  h2 {
  color: #989ea6;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.study_and_internship_banner_left .study_and_internship_banner_wrapper {
  margin-top: 40px;
  display: flex;
  gap: 30px;
  align-items: center;
}
.study_and_internship_banner_left .study_and_internship_banner_wrapper button {
  padding: 8px 22px;
  border: none;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
.study_and_internship_banner_left
  .study_and_internship_banner_wrapper
  .study_internship_banner_getstart {
  background: #ff9a28;
  color: #fff;
}
.study_and_internship_banner_left
  .study_and_internship_banner_wrapper
  .study_internship_banner_browsecourse {
  border: 2px solid #0b61ac;
  background-color: transparent;
  color: #0b61ac;
}

.study_and_internship_banner_left .study_internship_banner_start_desc {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 40px;
}

.study_and_internship_banner_left .study_internship_banner_start_desc span {
  background: #000;
  padding: 10px;
}

.study_and_internship_banner_left .study_internship_banner_start_desc p {
  color: #697079;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  padding-right: 23px;
}
.study_and_internship_banner_container .study_internship_banner_right {
  width: 70%;
  position: relative;
}

.study_and_internship_banner_container
  .study_internship_banner_right
  .study_internship_banner_tab_1 {
  padding: 15% 20px;
  border-radius: 0px 0px 0px 90px;
  background: #ff9a28;
}

.study_and_internship_banner_container
  .study_internship_banner_right
  .study_internship_banner_tab_1 {
  width: 55%;
  position: absolute;
  top: 0;
  right: -40%;
}

.study_and_internship_banner_container
  .study_internship_banner_right
  .study_internship_banner_tab_1
  span {
  display: block;
  margin-top: 20px;
  color: #fff;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.study_and_internship_avatar_list ul {
  padding: 0;
  display: flex;
  list-style: none;
  margin-left: 18px;
}
.study_and_internship_avatar_list ul li img {
  height: 51px;
  width: 51px;
  border-radius: 50%;
  object-fit: cover;
}
.study_and_internship_avatar_list ul li {
  margin-left: -18px;
}
.study_and_internship_banner_container .study_internship_banner_tab_2 {
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 65%;
  position: absolute;
  left: -10%;
  bottom: -6%;
}

.study_and_internship_banner_container .study_internship_banner_tab_2 h6 {
  color: #000;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 20px;
}

.study_and_internship_card_container {
  padding: 120px 0px 65px 0px;
}

.study_and_internship_card_container .study_and_internship_card_tab1 {
  background-image: url("../images/study_and_internship_card__bg1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}

.study_and_internship_card_container
  .study_and_internship_card_tab1
  .study_and_internship_card_tab1_btn {
  position: absolute;
  right: 0;
  bottom: 0;
  background: #024c87;
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 5px;
}
.study_and_internship_card_container
  .study_and_internship_card_tab1
  .study_and_internship_card_tab1_btn
  button {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  border: none;
  color: #024c87;
  font-size: 20px;
}

.study_and_internship_card_container .study_and_internship_card_tab1_div {
  margin-bottom: 30px;
  height: 100%;
}

.study_and_internship_card_container
  .study_and_internship_card_tab1_div
  .study_and_internship_card_tab1_img_1 {
  height: 100%;
}

.study_and_internship_card_container
  .study_and_internship_card_tab1_div
  .study_and_internship_card_tab1_img_1
  img {
  height: 100%;
  object-fit: cover;
}

.study_and_internship_card_container
  .study_and_internship_card_tab1_div
  .study_and_internship_card_tab1_img_2
  img {
  height: 100%;
  margin-top: 5px;
}

.study_and_internship_card_container
  .study_and_internship_card_tab1_div
  .col-7 {
  padding-right: 0;
  padding-top: 5px;
}

.study_and_internship_card_container
  .study_and_internship_card_tab1_heading
  h6 {
  color: #fff4f4;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  max-width: 80%;
}

.study_and_internship_card_container .study_and_internship_card_tab2 {
  background-image: url("../images/study_and_internship_card_bg2.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.study_and_internship_card_container
  .study_and_internship_card_tab2
  .study_and_internship_card_tab2_btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.study_and_internship_card_container
  .study_and_internship_card_tab2
  .study_and_internship_card_tab2_btns
  span {
  background-color: #000;
  display: inline-block;
  padding: 10px;
}

.study_and_internship_card_container
  .study_and_internship_card_tab2
  .study_and_internship_card_tab2_btns
  button {
  padding: 10px 22px;
  border: 2px solid #000;
  color: #000;
  background-color: transparent;
  color: #000;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.study_and_internship_card_container
  .study_and_internship_card_tab2
  .study_and_internship_card_tab2_heading
  h4 {
  color: #000;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}

.study_and_internship_card_container .study_and_internship_card_desc {
  text-align: center;
  padding-top: 10px;
}
.study_and_internship_card_container .study_and_internship_card_desc h2 {
  color: #000;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}
.study_and_internship_card_container .study_and_internship_card_desc h4 {
  color: #989ea6;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.study_and_internship_card_container .study_and_internship_card_subdesc {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  margin-top: 80px;
}

.study_and_internship_card_container .study_and_internship_card_subdesc h2 {
  color: #000;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  margin: 0;
}
.study_and_internship_card_container .study_and_internship_card_subdesc span {
  color: #989ea6;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  width: 45%;
}
.study_internship_program_container .study_internship_program_heading {
  margin-bottom: 40px;
}
.study_internship_program_container .study_internship_program_heading h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}

.study_internship_program_container .study_internship_program_heading h2 {
  color: #697079;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-top: 20px;
}

.study_internship_program_container .study_internship_program_list {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 20px;
}

.study_internship_program_container .study_internship_program_list svg path {
  fill: #1c1b1f;
}

.study_internship_program_container .study_internship_program_list span {
  color: #697079;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-left: 5px;
}

.study_internship_program_container {
  padding: 65px 0px;
}

.study_internship_program_container .study_internship_program_heading h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 32x;
  font-style: normal;
  font-weight: 700;
}

.study_internship_program_container .study_internship_program_heading h2 {
  color: #697079;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.study_and_internship_story_container .study_and_internship_story_heading h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}

.study_and_internship_story_container .study_and_internship_story_heading p {
  color: #697079;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.study_and_internship_story_container .study_and_internship_story_heading {
  margin-bottom: 40px;
}

.study_and_internship_story_container .study_and_internship_story_col_1 {
  background: url("../images/study_intern_story_1.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.study_and_internship_story_container .our_stories_container_col_2 {
  padding: 0px;
  padding: 20px 60px;
  height: 40rem;
  background: #024c87;
}
.study_and_internship_story_container .study_and_internship_story_playbtn {
  top: 16%;
}

/* .study_internship_footer_container {
} */

.study_internship_footer_container {
  position: relative;
}

.study_internship_footer_container .study_internship_footer_heading {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 60px;
}
.study_internship_footer_container .study_internship_footer_heading h1 {
  color: #595a5a;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 40px;
}

.study_internship_footer_container .study_internship_footer_heading h4 {
  color: #595a5a;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.study_internship_footer_container .study_internship_footer_div1 {
  clip-path: circle(56.6% at 0 0);
  width: 300px;
  height: 300px;
  background-color: #024c87;
  position: absolute;
  left: 0;
  top: 0;
}

.study_internship_footer_container .study_internship_footer_div2 {
  clip-path: circle(56.6% at 100% 100%);
  width: 340px;
  height: 340px;
  background-color: #ffca00;
  position: absolute;
  bottom: 0;
  right: 0;
}

.study_internship_footer_container .study_internship_footer_div3 {
  position: absolute;
  bottom: 0;
  left: 45%;
}
.study_and_internship_story_container .study_and_internship_story_card_div {
  height: 100px;
}

/******************************************************* Apply Page Css *********************************************************/

.apply_section_container {
  padding: 20px 0px;
}

.apply_section_container .apply_section_heading h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
}

.apply_section_container .apply_section_heading p {
  color: #697079;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-top: 20px;
}

.apply_section_container .apply_section_heading h2 {
  color: #575757;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  margin: 20px 0px;
}

.apply_section_container .apply_section_bg {
  /* "../images/apply_img_bg1.png" */
  background-image: url("https://d1db1k41ipas2c.cloudfront.net/apply_img_bg1+(1).png"),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 0.74%, rgba(0, 0, 0, 0.7) 90.74%);
  background-size: cover;
  border-radius: 50px 0px;
  background-repeat: no-repeat;
  /* width: 100%; */
  height: 755px;
  position: relative;
  display: flex;
  align-items: end;
  padding: 30px 20px;
}

.apply_section_container .apply_section_bg .apply_section_bg_icon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
}
.apply_section_container .apply_section_bg .apply_section_bg_icon a svg {
  transform: scale(0.6);
}

.apply_section_container .apply_section_bg_desc h3 {
  color: #fff;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}
.apply_section_container .apply_section_bg_desc h4 {
  color: #fff;
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
}

.apply_section_container .apply_section_bg_desc .apply_section_bg_btn button {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
  padding: 8px;
  width: 100%;
}

.apply_section_container .apply_section_bg_desc .apply_section_bg_btn a {
  display: flex;
  font-size: 12px;
  align-items: center;
  width: 100%;
}

.apply_section_container .apply_section_bg_desc .apply_section_bg_btn a svg {
  transform: scale(0.4);
}

.apply_section_container .apply_section_content .apply_section_content_para p {
  color: #697079;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin: 30px 0px;
}

.apply_section_container .apply_section_content .apply_section_content_card h5,
.apply_section_container
  .apply_section_content
  .apply_section_content_card
  ul
  li {
  color: #575757;
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  margin-top: 10px;
}
.apply_section_container .apply_section_content .apply_section_content_card p {
  color: #697079;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.apply_form_container {
  padding: 20px;
  padding-bottom: 0;
}
.apply_form_container .apply_form_card {
  background: #fff;
  box-shadow: -3px 4px 19px 8px rgba(0, 0, 0, 0.25);
  padding: 30px;
}

.apply_form_container .apply_form_card .apply_form_inputall label {
  width: 100%;
  color: #575757;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.apply_form_container .apply_form_card .apply_form_inputall {
  margin-top: 20px;
}
.apply_form_container .apply_form_card .apply_form_inputall input,
.apply_form_container .apply_form_card .apply_form_inputall select,
.apply_form_container .apply_form_card .apply_form_inputall textarea {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #5f5f5f;
  padding: 12px 20px;
  outline: none;
}

.apply_form_container
  .apply_form_card
  .apply_form_inputall
  .apply_form_contact {
  display: flex;
  border-radius: 4px;
  border: 1px solid #5f5f5f;
  position: relative;
}

.apply_form_container
  .apply_form_card
  .apply_form_inputall
  .apply_form_contact::after {
  content: "";
  height: 50%;
  width: 2px;
  margin: 12px 0px;
  background: #d9d9d9;
  position: absolute;
  top: 0;
  left: 16%;
}
.apply_form_container
  .apply_form_card
  .apply_form_inputall
  .apply_form_contact
  input {
  border: none;
}
.apply_form_container
  .apply_form_card
  .apply_form_inputall
  .apply_form_contact
  .form_phone_input {
  padding: 12px 50px;
  padding-right: 0;
}
.apply_form_container
  .apply_form_card
  .apply_form_inputall
  .apply_form_contact
  span {
  color: #000;
}
.apply_form_container
  .apply_form_card
  .apply_form_inputall
  .apply_form_contact
  .react-tel-input {
  width: 18%;
}

.apply_form_container .apply_form_card .apply_form_submit button {
  width: 100%;
  border-radius: 6px;
  background: #024c87;
  padding: 12px 22px;
  border: none;
  margin-top: 30px;
  color: #fff;
}

.apply_section_container .apply_section_left_div {
  height: 66rem;
  overflow-y: auto;
}

.apply_section_container .apply_section_left_div::-webkit-scrollbar {
  width: 5px;
}
.apply_section_container .apply_section_left_div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
.apply_section_container .apply_section_left_div::-webkit-scrollbar-thumb {
  background: #024c87;
  border-radius: 10px;
}
.apply_section_container
  .apply_section_left_div::-webkit-scrollbar-thumb:hover {
  background: #024c87;
}

.loader_container {
  background-color: #024c87;
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

/***************************************************** Program Page CSS ***********************************************************/

.program_banner_container {
  padding-top: 120px;
  /* ../images/program_bg_banner.png */
  background-image: url("https://d1db1k41ipas2c.cloudfront.net/program_bg_banner+(1).png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.program_banner_container .program_banner_left button {
  background: #f9ebc7;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: none;
  margin-bottom: 20px;
}

.program_banner_container .program_banner_left button span {
  color: #000;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.program_banner_container .program_banner_left h1 {
  color: #fff;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.program_banner_container .program_banner_left ul {
  list-style: none;
  padding: 0;
  margin-top: 30px;
  margin-bottom: 30px;
}

.program_banner_container .program_banner_left ul li {
  color: #fff;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 30px;
  line-height: 2.3;
}
.program_banner_container .program_subheader_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #024c87;
  padding-top: 9px;
  padding-bottom: 4px;
}

.program_banner_container .program_banner_info img {
  height: 215px;
  object-fit: cover;
  border-radius: 6px;
}

.program_banner_container .program_banner_info .program_banner_info_desc h3 {
  color: #363636;
  font-family: "DM Sans";
  font-size: 22.788px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 27.346px */
  margin-top: 8px;
}

.program_banner_container .program_banner_info .program_banner_info_desc span {
  color: #8c8c8c;
  font-family: "DM Sans";
  font-size: 17.091px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
}

.program_banner_container .program_banner_info .program_banner_info_rating {
  display: flex;
  align-items: end;
  gap: 10px;
}

.program_banner_container
  .program_banner_info
  .program_banner_info_rating
  span {
  color: #363636;
  text-align: center;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 17.091px */
}

.program_banner_container .program_banner_info .program_banner_info_price {
  display: flex;
  justify-content: space-between;
}
.program_banner_info .program_banner_info_price b {
  color: #363636;
  text-align: center;
  font-family: "DM Sans";
  font-size: 19.94px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 23.928px */
}

.program_banner_info
  .program_banner_info_price
  .program_banner_info_price_detail
  h4 {
  color: #8c8c8c;
  font-family: "DM Sans";
  font-size: 14.243px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 17.091px */
}

.program_banner_info_price .program_banner_info_price_detail h4 span {
  text-decoration: line-through;
}

.program_banner_info_price .program_banner_info_btn {
  display: flex;
  align-items: center;
}
.program_overview_card_info .program_overview_card_info_left {
  display: none;
}

.program_overview_card_content .program_overview_card_info {
  display: flex;
  align-items: start;
  gap: 10px;
}
.program_skillphone_container {
  display: none;
}

.program_banner_info_price .program_banner_info_btn button {
  border-radius: 5.697px;
  background: #024c87;
  padding: 0px 26px;
  color: #fff;
  font-family: "DM Sans";
  font-size: 19.94px;
  font-style: normal;
  font-weight: 700;
  line-height: 42.822px; /* 214.756% */
  border: 1px solid #024c87;
}

.program_subheader_container .program_subheader_detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.program_subheader_container .program_subheader_detail h2 {
  display: flex;
  align-items: center;
  font-size: 18px;
  gap: 8px;
}
.program_subheader_detail .program_discount_text {
  display: flex;
  align-items: center;
  color: #ffd381;
  font-size: 15px;
  gap: 4px;
}
.program_subheader_detail .program_discount_text h3 {
  font-size: 15px;
  font-weight: 700;
}
.program_subheader_detail .program_discount_text h5 {
  font-size: 13px;
  font-weight: 400;
}

.program_subheader_detail .program_discount_text h5 span {
font-size: 14px;
color: #FFF;
font-weight: 700;
}

.program_subheader_detail .program_discount_text h6 {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
}
.program_subheader_detail .countdown_div {
  display: flex;
  gap: 4px;
}
.program_subheader_detail .count_down_txt {
  display: flex;
}
.program_subheader_detail .count_down_txt h4 {
  font-size: 15px;
  margin: 0;
}
.program_subheader_detail .program_discount_text a {
  margin-bottom: 8px;
  text-transform: uppercase;
  text-decoration: underline;
  /* font-size: 15px; */
  font-size: 14px;
}
.program_subheader_detail .program_discount_text svg,
.program_subheader_detail svg {
  margin-bottom: 4px;
  scale: 1.2;
  margin-right: 4px;
}
.program_navbar_container {
  background: #fff;
  padding-top: 20px;
  position: sticky;
  top: 0;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 18px 18px -10px;
}
.program_navbar_container .program_navbar_div .program_navbar_list {
  display: flex;
  align-items: center;
}

.program_navbar_container .program_navbar_div .program_navbar_list ul {
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  text-align: center;
  margin: 0;
}
.program_navbar_container .program_navbar_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.program_navbar_container .program_navbar_div .program_navbar_list ul li {
  color: #989ea6;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  padding-bottom: 8px;
}

.program_navbar_container .program_navbar_div .program_navbar_list ul .active {
  border-bottom: 2px solid #f89321;
  color: #4b4b4b;
}

.program_navbar_container .program_navbar_btn button {
  border-radius: 5px;
  background: #ff8000;
  padding: 12px 65px;
  border: none;
  color: #fff;
  margin-bottom: 8px;
  font-weight: 700;
}

.program_tab_container {
  padding: 65px 0px;
  padding-bottom: 120px;
  background: #eaf1f9;
  position: relative;
}

.program_tab_container .program_tab_heading h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.program_tab_container .program_tab_heading h1 span {
  font-weight: 700;
}

.program_tab_container .program_tab_logo {
  background: #fefefe;
  box-shadow: 0px 0px 30px 5px rgba(2, 76, 135, 0.51);
  padding: 40px 24px;
  display: flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  bottom: -22%;
}

.program_tab_container .program_tab_logo .program_tab_one {
  display: flex;
  align-items: center;
  gap: 20px;
}

.program_tab_container .program_tab_logo .program_tab_one img {
  width: 40%;
}

.program_tab_container .program_tab_rating span {
  color: #000;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
}
.program_tab_container .program_tab_rating {
  display: flex;
  align-items: center;
}
.program_tab_container .program_tab_rating svg {
  scale: 0.6;
}

.program_overview_container {
  padding-top: 100px;
  color: #000;
}

.program_overview_container
  .program_overview_left
  .program_overview_heading
  h2 {
  color: #000;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}

.program_overview_container .program_overview_left .program_overview_heading p {
  color: #292d32;
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  margin-top: 20px;
}

.program_overview_container .program_overview_left .program_overview_list h2 {
  color: #000;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}

.program_overview_container .program_overview_left .program_overview_list {
  margin-top: 30px;
}

.program_overview_container .program_overview_left .program_overview_list ul {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-top: 30px;
}

.program_overview_container
  .program_overview_left
  .program_overview_list
  ul
  li
  .program_overview_li {
  display: flex;
  align-items: center;
  gap: 10px;
}
.program_overview_container
  .program_overview_left
  .program_overview_list
  ul
  li {
  margin-top: 20px;
}
.program_overview_container
  .program_overview_left
  .program_overview_list
  ul
  .program_overview_li
  svg
  path {
  fill: #024c87;
}

.program_overview_container
  .program_overview_left
  .program_overview_list
  ul
  .program_overview_li
  span {
  color: #000;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  display: block;
  font-weight: 400;
}

.program_overview_container
  .program_overview_left
  .program_overview_list
  ul
  .program_overview_li
  button {
  border: none;
  background-color: transparent;
}

.program_banner_container .program_banner_right {
  /* ../images/program_banner_2.png */
  background-image: url("https://d1db1k41ipas2c.cloudfront.net/program_banner_2+(1).png");
  height: 400px;
  background-size: cover;
  margin-bottom: 10px;
  position: relative;
  background-repeat: no-repeat;
}

.program_banner_container .program_banner_right img,
.program_banner_container .program_banner_right iframe {
  width: 72%;
  height: 73%;
  position: absolute;
  top: 9%;
  left: 14%;
  object-fit: contain;
}

.program_banner_container .program_banner_right button {
  background-color: #fff;
  color: #0077b5;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  font-size: 18px;
  position: absolute;
  top: 29%;
  left: 46%;
}

.program_banner_container .program_banner_right button::before {
  content: "";
  position: absolute;
  top: -25%;
  left: -25%;
  height: 90px;
  width: 90px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate1 2s;
  animation: pulsate1 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.75);
  background: rgba(198, 16, 0, 0);
}

.program_overview_right .program_overview_card h2 {
  color: #024c87;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.program_overview_right .program_overview_card p {
  color: #292d32;
  font-family: DM Sans;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
}
.program_overview_right .program_overview_card ul li {
  color: #292d32;
  font-family: DM Sans;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
}
.program_overview_right .program_overview_card .program_overview_card_details {
  margin-top: 30px;
}
.program_overview_right .program_overview_card {
  background: #fefefe;
  padding: 20px;
}

.program_overview_right .program_overview_card .program_overview_card_hr {
  margin: 30px 0px;
  background: #d9d9d9;
  height: 1px;
  width: 100%;
}

.program_overview_right
  .program_overview_card
  .program_overview_card_print
  button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none;
  background-color: transparent;
  color: #0077b5;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
.program_overview_right
  .program_overview_card
  .program_overview_card_print
  button
  svg {
  scale: 1.5;
}

.program_overview_right .program_overview_card {
  position: relative;
  background: linear-gradient(
    to right,
    #7054e6 0%,
    #7054e6 50%,
    #000 50%,
    #000 100%
  );
}

.program_overview_right .program_overview_card::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(
    to right,
    #024c87 0%,
    #024c87 50%,
    #f89321 50%,
    #f89321 100%
  );
}
.program_overview_right .program_overview_card::after {
  position: absolute;
  background-color: #fff;
  content: "";
  height: 98%;
  width: 94%;
  top: 1%;
  left: 3%;
  z-index: 2;
}

.program_overview_right .program_overview_card .program_overview_card_content {
  position: relative;
  z-index: 10;
  padding: 35px 26px;
}

.program_skill_covered_container h2 {
  color: #000;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}

.program_skill_covered_container .program_skill_list ul {
  padding: 0;

  color: #3b4047;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.program_skill_covered_container .program_skill_list ul li {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.program_skill_covered_container .program_skill_list ul li svg path {
  fill: #024c87;
}

.program_skill_covered_container button {
  color: #1179ef;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  background-color: transparent;
  border: none;
  margin-left: 20px;
  margin-top: 20px;
}

.program_skill_covered_container {
  padding-top: 65px;
}

.program_tools_container {
  margin-top: 65px;
}
.program_tools_container h2 {
  color: #000;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}

.program_advisor_container .program_advisor_heading h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
}

.program_advisor_container .program_advisor_card {
  background: #fff;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.25);
  padding: 30px 0px;
  margin: 0px 12%;
  margin-top: 30px;
}

.program_advisor_container .program_advisor_card h2 {
  color: #000;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}

.program_advisor_container .program_advisor_card h3 {
  color: #989ea6;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.program_advisor_container .program_advisor_card p {
  color: #5c6065;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  height: 220px;
  overflow-y: auto;
}
.program_advisor_container .program_advisor_card p::-webkit-scrollbar {
  width: 0px;
}
.program_advisor_container .program_advisor_card .program_advisor_card_links {
  display: flex;
  justify-content: end;
  align-items: center;
}

.program_advisor_container
  .program_advisor_card
  .program_advisor_card_links
  img {
  width: 60%;
}

.program_advisor_container .program_advisor_card .program_advisor_img {
  position: relative;
}

.program_advisor_container .program_advisor_card .program_advisor_img img {
  position: absolute;
  left: -40%;
  height: 280px;
  width: 280px;
}

.program_advisor_container {
  padding: 120px 0px;
}

.program_advisor_container .program_advisor_card .program_advisor_card_hr {
  background: #024c87;
  height: 4px;
  margin-top: 20px;
}
.program_steps_container .program_steps_phone_card {
  display: none;
}

.program_preview_container {
  padding: 65px 0px;
}

.program_preview_container .program_preview_heading h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 30px;
}

.program_preview_container .program_preview_accordin_div {
  background: #ecf4ff;
  padding: 20px;
}

.program_preview_container .program_preview_accordin .accordion-item {
  border: none;
}

.program_preview_container .program_preview_accordin .accordion-button {
  border: none;
  background: #ecf4ff;
  box-shadow: none;
}

.program_preview_container .program_preview_accordin .accordion-body {
  position: relative;
  background: #ecf4ff;
}

.program_preview_container
  .program_preview_accordin
  .accordion-body
  .program_preview_accordin_body_hr {
  height: 1px;
  background-color: #989ea6;
  width: 100%;
  margin-top: 20px;
}

.program_preview_container
  .program_preview_accordin
  .program_preview_accordin_header {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  padding-right: 10px;
  position: relative;
}
.program_preview_container
  .program_preview_accordin
  .program_preview_accordin_header::after {
  position: absolute;
  left: -3%;
  top: 6px;
  height: 8px;
  width: 8px;
  background-color: #989ea6;
  border-radius: 50%;
  content: "";
}

.program_preview_container
  .program_preview_accordin
  .program_preview_accordin_header
  .program_preview_accordin_title {
  display: flex;
  align-items: center;
}

.program_preview_container
  .program_preview_accordin
  .program_preview_accordin_header
  .program_preview_accordin_title
  h3 {
  color: #000;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.program_preview_container
  .program_preview_accordin
  .program_preview_accordin_header
  button {
  color: #1179ef;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  border: none;
  background-color: transparent;
}

.program_preview_container
  .program_preview_accordin
  .program_preview_accordin_body
  .program_preview_accordin_play
  button {
  background-color: #1179ef;
  color: #fff;
  display: flex;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.program_preview_container
  .program_preview_accordin
  .program_preview_accordin_body
  .program_preview_accordin_play
  span {
  color: #1179ef;
  font-size: 16px;
  font-weight: 600;
}

.program_preview_container
  .program_preview_accordin
  .program_preview_accordin_body
  .program_preview_accordin_play {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.program_preview_container
  .program_preview_accordin
  .program_preview_accordin_body
  p {
  color: #5c6065;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.program_preview_container
  .program_preview_accordin
  .program_preview_accordin_body {
  display: flex;
  align-items: center;
  gap: 30px;
}

.program_preview_container .program_preview_accordin {
  position: relative;
}
.program_preview_container .program_preview_accordin::after {
  position: absolute;
  left: 0%;
  height: calc(100% - 55px);
  width: 2px;
  content: "";
  background: #989ea6;
  top: 24px;
  z-index: 20;
}

.program_preview_container .program_preview_right {
  background: #fff;
  box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.25);
  padding: 20px;
}
.program_preview_container
  .program_preview_right
  .program_preview_form_heading {
  color: #000;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}

.program_preview_container
  .program_preview_right
  .program_preview_form_input_all
  input,
.program_preview_container
  .program_preview_right
  .program_preview_form_input_all
  select {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #d9d9d9;
  padding-bottom: 8px;
  width: 100%;
  outline: none;
}

.program_preview_container
  .program_preview_right
  .program_preview_form_input_all,
.program_preview_container .program_preview_right .program_preview_form_radio {
  margin-top: 30px;
}

.program_preview_container
  .program_preview_right
  .program_preview_form_radio
  span,
.program_preview_container
  .program_preview_right
  .program_preview_form_radio
  label {
  color: #292d32;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.program_preview_container .program_preview_right .program_preview_form_radio {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.program_preview_container .program_preview_right .program_preview_form_check {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.program_preview_right .program_preview_form_check label {
  color: #292d32;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.program_preview_right .program_preview_form_submit {
  text-align: center;
  margin-top: 30px;
}

.program_preview_right .program_preview_form_submit button {
  border: 3px solid #0077b5;
  color: #0077b5;
  background-color: transparent;
  padding: 8px 52px;
}
.program_preview_container .program_preview_syllabus_btn {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.program_preview_container .program_preview_syllabus_btn button {
  background: #1179ef;
  color: #fff;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  padding: 6px 42px;
  border: none;
}
.program_modal .modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.program_modal video {
  height: 70%;
  width: 70%;
}
.program_modal h4,
.program_modal p {
  color: #000;
  margin-top: 20px;
}
.program_steps_container {
  padding: 65px 0px;
}

.program_steps_container .program_steps_heading h1 {
  color: #0d2733;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}

.program_steps_container .program_steps_heading p {
  color: #5c6065;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin: 30px 0px;
}

.program_steps_container .program_steps_card {
  background: #fff;
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.25);
  padding: 30px 20px;
  margin-top: 20px;
}

.program_steps_container .program_steps_card span {
  color: #5c6065;
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 10px;
}

.program_steps_container .program_steps_card h3 {
  color: #0d2733;
  font-family: DM Sans;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  margin-top: 10px;
}

.program_steps_container .program_steps_card h4 {
  color: #5c6065;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
  height: 75px;
  overflow: hidden;
}

.program_steps_container .program_steps_card_icon svg {
  scale: 0.8;
}

.program_eligibility_container {
  padding: 65px 0px;
}
.program_eligibility_container .program_eligibility_heading h1 {
  color: #0d2733;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}

.program_eligibility_container .program_eligibility_heading p {
  color: #5c6065;
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  margin: 20px 0px;
}

.program_eligibility_container .program_eligibility_card {
  background: #fff;
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.25);
  padding: 20px;
  display: flex;
  gap: 15px;
  margin-top: 20px;
  align-items: center;
}
.program_eligibility_container .program_eligibility_card span {
  color: #5c6065;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
.program_eligibility_container .program_eligibility_card svg {
  scale: 1.4;
}
.program_eligibility_container .program_eligibility_card svg path {
  fill: #8bbacc;
}

.program_certificate_container {
  padding: 65px 0px;
}

.program_certificate_container .program_certificate_heading h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}

.program_certificate_container .program_certificate_heading p {
  color: #5c6065;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin: 20px 0px;
  max-width: 88%;
}

.program_certificate_container .program_certificate_list h2 {
  color: #000;
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 10px;
  padding: 0px 30px;
}
.program_certificate_container .program_certificate_list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.program_certificate_container .program_certificate_list ul li {
  color: #5c6065;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.program_certificate_container .program_certificate_list {
  padding: 20px;
}

.program_certificate_container .program_certificate_list svg path {
  fill: #8bbacc;
}

.program_fees_container {
  padding: 65px 0px;
  padding-bottom: 4px ;
}

.program_fees_container .program_fees_heading h1 {
  color: #0d2733;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}

.program_fees_container .program_fees_heading p {
  max-width: 88%;
  color: #5c6065;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin: 20px 0px;
}

.program_fees_container .program_fees_left {
  background: #0983e8;
  padding: 80px 20px;
  text-align: center;
}

.program_fees_container .program_fees_left .program_fees_left_heading h2 {
  color: #fff;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.program_fees_container .program_fees_left .program_fees_left_heading p {
  color: #fff;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  text-align: start;
  font-weight: 400;
  max-width: 90%;
}
.program_fees_container .program_fees_left .program_fees_left_heading {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.program_fees_container .program_fees_left .program_fees_left_btn h2 {
  color: #fff;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 20px;
}

.program_fees_container .program_fees_left .program_fees_left_btn h3 {
  color: #fff;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
}

.program_fees_container .program_fees_left .program_fees_left_btn button {
  background-color: #fff;
  border: none;
  padding: 6px 106px;
  color: #1179ef;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-top: 20px;
}

.program_fees_container .program_fees_left .program_fees_left_logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 35px;
  flex-wrap: wrap;
  margin: 30px 0px;
}
.program_fees_container .program_fees_left .program_fees_left_logo img {
  width: 30%;
}

.program_fees_container .program_fees_right {
  padding: 80px 20px;
  padding-bottom: 0;
  text-align: center;
}

.program_fees_container .program_fees_right .program_fees_right_heading h2 {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.program_fees_container .program_fees_right .program_fees_right_heading p {
  color: #292d32;
  text-align: center;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.program_fees_container .program_fees_right .program_fees_right_icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin: 57px 0px;
}

.program_fees_container .program_fees_right .program_fees_right_icons h6 {
  color: #292d32;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
.program_fees_container .program_fees_right .program_fees_right_icons svg {
  height: 60px;
  width: 60px;
}

.program_fees_container .program_fees_right .program_fees_right_btn h2 {
  color: #292d32;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.program_fees_container .program_fees_right .program_fees_right_btn h3 {
  color: #292d32;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
}

.program_fees_container .program_fees_right .program_fees_right_btn h4 {
  color: #292d32;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.program_fees_container .program_fees_right .program_fees_right_btn button {
  background-color: #1179ef;
  border: none;
  padding: 6px 106px;
  color: #fff;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  /* margin-top: 20px; */
}

.program_fees_container .program_fees_div {
  padding: 0px 10%;
  margin-top: 40px;
}

.program_fees_container .program_fees_div .row {
  border: 3px solid #1179ef;
}

.program_fees_container .program_fees_div .program_fees_col {
  padding: 0;
}

.program_access_container .program_access_left {
  padding: 40px;
  height: 100%;
  position: relative;
}
.program_access_container .program_access_left::after {
  position: absolute;
  content: "";
  left: 0;
  width: 50%;
  height: 50%;
  bottom: 0;
  background-image: url("../images/program_access_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.program_access_container .program_access_left h1 {
  color: #024c87;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
}
.program_access_container .program_access_left p {
  color: #024c87;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 243.5%;
}

.program_access_container .program_access_right img {
  width: 80%;
}
.program_access_container .program_access_right {
  padding: 60px 0px;
  background: #ff8021;
  text-align: center;
  position: relative;
}

.program_access_container .program_access_right .program_access_right_img_1 {
  position: absolute;
  top: 35%;
  left: -6%;
  width: 35%;
}
.program_access_container .program_access_right .program_access_right_img_2 {
  position: absolute;
  bottom: -5%;
  left: -6%;
  width: 35%;
}

.program_dates_container {
  padding: 120px 0px 65px 0px;
  background: #024c87;
}
.program_banner_container .program_banner_info {
  display: none;
}

.program_dates_container .program_dates_heading {
  text-align: center;
  margin-bottom: 40px;
}

.program_dates_container .program_dates_heading button {
  color: #fff;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  padding: 8px 22px;
  border: 2px solid #fff;
  background-color: transparent;
}

.program_dates_container .program_dates_heading h1 {
  color: #fff;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
}
.program_dates_container .program_dates_table {
  background: #fff;
  padding: 30px;
}

.program_dates_container .program_dates_table table {
  width: 100%;
}

.program_dates_container .program_dates_table table th {
  color: #000;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.program_dates_container .program_dates_table table td {
  color: #626568;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.program_dates_container .program_dates_table table tr th,
.program_dates_container .program_dates_table table tr td {
  padding: 14px;
  width: 40%;
}

.program_dates_container .program_dates_table table tr:nth-child(even) {
  background: #f3f7f8;
}

.program_dates_container .program_dates_table .program_dates_details {
  margin-top: 60px;
}

.program_dates_container .program_dates_table .program_dates_details p {
  color: #626568;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.program_recommend_container {
  padding: 65px 0px;
  background: #f3f7f8;
}
.program_recommend_container .program_recommend_heading h1 {
  color: #012e6a;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
}

.program_recommend_carousel
  .program_recommend_item
  .program_recommend_item_details {
  padding: 10px;
  background: #fff;
  box-shadow: 0px 4px 21px 16px rgba(0, 0, 0, 0.05);
}

.program_recommend_carousel .program_recommend_item {
  margin: 20px;
}

.program_recommend_carousel
  .program_recommend_item
  .program_recommend_item_details
  p {
  color: #989ea6;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 243.5%; /* 58.44px */
}

.program_recommend_carousel .owl-nav {
  position: absolute;
  top: -10%;
  right: 17px;
}

.program_recommend_carousel .owl-nav .owl-next:hover,
.program_recommend_carousel .owl-nav .owl-prev:hover {
  background: transparent;
}

.program_faq_container {
  padding: 65px 0px;
  background-color: #fff;
}
.program_faq_container .program_faq_heading h1 {
  color: #012e6a;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
}

.program_faq_container .program_faq_list {
  padding: 20px;
}

.program_faq_container .program_faq_list h2 {
  color: #012e6a;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
.program_faq_container .program_faq_list p {
  color: #989ea6;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}

.program_footer_container {
  /* ../images/program_footer_bg.png */
  padding: 65px 0px;
  background-image: url("https://d1db1k41ipas2c.cloudfront.net/program_footer_bg+(1).png");
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.program_footer_container .program_footer_heading h1 {
  color: #fff;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
}

.program_footer_container .program_footer_heading p {
  color: #fff;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin: 20px 0px;
}

.program_footer_container .program_footer_btns {
  margin-top: 30px;
}

.program_footer_container .program_footer_btns button {
  padding: 8px 32px;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  border: 2px solid #fff;
  margin-right: 30px;
}

.program_footer_container .program_footer_btns .program_footer_apply {
  background-color: #fff;
  color: #012e6a;
}

.program_footer_container .program_footer_btns .program_footer_request {
  background-color: transparent;
  color: #fff;
}

.program_navbar_container_active {
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 99;
}
.program_navbar_container .program_navbar_div .program_navbar_list_mobile ul {
  display: none;
}

/************************************************************** Become Instructor CSS ******************************************/

.instructor_banner_container {
  background: #faf6f5;
}

.instructor_banner_container .instructor_banner_left_desc {
  padding: 60px;
}

.instructor_banner_container .instructor_banner_left_desc h2 {
  color: #000;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
}

.instructor_banner_container .instructor_banner_left_desc p {
  color: #989ea6;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.instructor_banner_container .instructor_banner_right_tab {
  padding: 13% 5%;
  background-image: url("../images/instructor_banner_2.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.instructor_banner_container .instructor_banner_right_tab h1 {
  color: #fff;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  margin-top: 20px;
}

.instructor_banner_container .instructor_banner_right_tab h2 {
  color: #fff;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
}

.instructor_banner_container .instructor_banner_right_tab p {
  color: #fff;
  font-family: DM Sans;
  font-size: 28px;
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
}

.instructor_banner_container .instructor_banner_right_tab button {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  padding: 8px 22px;
  margin-top: 40px;
}

.instructor_banner_container .instructor_banner_right_form_div {
  padding: 10% 15%;
}

.instructor_banner_container .instructor_banner_right_form {
  background: #fff;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
  padding: 10% 4%;
}

.instructor_banner_container .instructor_banner_right_form h3 {
  color: #000;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}
.instructor_banner_container .instructor_banner_right_form_heading {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.instructor_banner_container .instructor_banner_right_form p {
  color: #292d32;
  text-align: center;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  margin: 10px 0px 30px 0px;
  max-width: 60%;
}

.instructor_banner_container .instructor_banner_right_input_all input,
.instructor_banner_container .instructor_banner_right_input_all select {
  border: none;
  border-bottom: 2px solid #898989;
  background: transparent;
  padding: 10px;
  outline: none;
  width: 100%;
}

.instructor_banner_container .instructor_banner_right_input_all {
  margin-top: 20px;
  padding: 0px 20px;
}
.instructor_banner_container .instructor_banner_right_submit {
  margin-top: 30px;
  text-align: center;
}
.instructor_banner_container .instructor_banner_right_submit button {
  background: #1179ef;
  border: none;
  padding: 8px 32px;
  color: #fff;
  text-align: center;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  width: 60%;
}

.instructor_banner_container .instructor_banner_left_img img {
  height: 61rem;
  object-fit: cover;
}

.instructor_section_container {
  padding: 65px 0px;
  background-color: #faf6f5;
}

.instructor_section_container .instructor_section_rignt {
  padding: 40px;
}

.instructor_section_container .instructor_section_rignt h2 {
  color: #000;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  margin-top: 40px;
}

.instructor_section_container .instructor_section_rignt p {
  color: #989ea6;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.instructor_section_container .instructor_section_left img {
  width: 80%;
}

.instructor_slider_carousel .instructor_slider_item_desc h1 {
  color: #000;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: DM Sans;
  font-size: 52px;
  font-style: normal;
  font-weight: 400;
}

.instructor_slider_carousel .instructor_slider_profile img {
  height: 700px;
  object-fit: cover;
}
.instructor_slider_carousel .instructor_slider_item_desc p {
  color: #989ea6;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin: 30px 0px;
}
.instructor_slider_carousel .instructor_slider_item_links {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 40px 0px;
}

.instructor_slider_carousel .instructor_slider_item_links a img {
  width: 70%;
}
.instructor_slider_carousel .instructor_slider_sign img {
  width: 75%;
}
.instructor_slider_carousel .instructor_slider_item_links h4 {
  color: #989ea6;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin: 0;
}

.instructor_slider_container {
  padding: 65px 0px;
  background: #faf6f5;
}

.instructor_slider_container .instructor_slider_heading h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 62px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  margin-bottom: 60px;
}

.instructor_slider_container .instructor_slider_item_desc {
  background: #fff;
  padding: 6% 15%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.instructor_slider_carousel .owl-nav {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.instructor_slider_carousel .owl-nav button {
  scale: 1.5;
}

.instructor_slider_carousel .owl-nav .owl-next:hover,
.instructor_slider_carousel .owl-nav .owl-prev:hover {
  background-color: transparent;
}

/******************************************************* Hire Us Page CSS ********************************************************/

.hireus_banner_container {
  padding: 65px 0px;
}

.hireus_banner_container .hireus_banner_left h4 {
  color: #035bbf;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.hireus_banner_container .hireus_banner_left h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 62px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 60px;
}

.hireus_banner_container .hireus_banner_tab {
  border-radius: 75px;
  background: #184acb;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hireus_banner_container .hireus_banner_tab span {
  color: #fff;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}

.hireus_banner_container .hireus_banner_tab button {
  height: 45px;
  width: 45px;
  border: 5px solid #fff;
  border-radius: 50%;
  background: #184acb;
  color: #fff;
  font-size: 18px;
}

.hireus_banner_container .hireus_banner_list ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  margin-left: 24px;
}

.hireus_banner_container .hireus_banner_list ul li {
  height: 65px;
  width: 65px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ebebeb;
  margin-left: -24px;
}
.hireus_banner_container .hireus_banner_list ul li img {
  border-radius: 50%;
  object-fit: cover;
  height: 65px;
  width: 65px;
}
.hireus_banner_container .hireus_banner_list ul li span {
  color: #000;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.hireus_banner_container .hireus_banner_desc {
  padding: 0px 30px;
  position: relative;
  margin-top: 40px;
}

.hireus_banner_container .hireus_banner_desc::after {
  position: absolute;
  height: 100%;
  background: #184acb;
  width: 6px;
  left: 0;
  top: 0;
  content: "";
}

.hireus_banner_container .hireus_banner_desc p {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
}

.hireus_banner_container .hireus_banner_right img {
  width: 85%;
}

.hireus_banner_container .hireus_banner_right {
  text-align: center;
}

.hireus_solution_container {
  background: #eff3fa;
  padding: 65px 0px;
}

.hireus_solution_container .hireus_solution_left h1 {
  color: #1f1f1f;
  font-family: DM Sans;
  margin-bottom: 20px;
  font-size: 52px;
  font-style: normal;
  font-weight: 400;
}
.hireus_solution_container .hireus_solution_left p {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.hireus_solution_container .hireus_solution_left .hireus_solution_tablist {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 40px;
}

.hireus_solution_container .hireus_solution_left .hireus_solution_tab {
  border-radius: 75px;
  background: #fff;
  padding: 10px 40px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
}
.hireus_solution_container .hireus_solution_left .hireus_solution_tab svg {
  scale: 1.5;
}
.hireus_solution_container .hireus_solution_left .hireus_solution_tab svg path {
  fill: #0077b5;
}

.hireus_solution_container .hireus_solution_left .hireus_solution_tab span {
  color: #1a76c6;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.hireus_solution_container .hireus_solution_right .hireus_solution_hr {
  background: #184acb;
  height: 4px;
  width: 100%;
  margin-bottom: 40px;
  margin-top: 20px;
}

.hireus_solution_container .hireus_solution_right .hireus_solution_card h2 {
  color: #1f1f1f;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
}

.hireus_solution_container .hireus_solution_right .hireus_solution_card p {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.hireus_oeson_container {
  padding: 65px 0px;
  background: #fef7ec;
}

.hireus_oeson_container .hireus_oeson_heading h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 40px;
}

.hireus_oeson_container .hireus_oeson_card {
  background: #fff;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
  padding: 20px;
  margin-top: 20px;
}

.hireus_oeson_container .hireus_oeson_card .hire_oeson_desc {
  height: 150px;
  overflow-y: auto;
}

.hireus_oeson_container .hireus_oeson_card .hire_oeson_desc::-webkit-scrollbar {
  width: 0px;
}

.hireus_oeson_container .hireus_oeson_card h2 {
  color: #000;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  line-height: normal;
  margin: 20px 0px;
}

.hireus_oeson_container .hireus_oeson_card p {
  color: #7d8185;
  text-align: center;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.hireus_profile_container {
  padding: 65px 0px;
  background: #013584;
}

.hireus_profile_container .hireus_profile_heading {
  color: #fff;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 40px;
}

.hireus_profile_container .hireus_profile_card {
  background: #fff;
  padding: 20px;
  text-align: center;
  margin-top: 20px;
}

.hireus_profile_container .hireus_profile_card h2 {
  color: #000;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.hireus_profile_container .hireus_profile_card p {
  color: #7d8185;
  text-align: center;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.hireus_profile_container .hireus_profile_card .hireus_profile_card_icon svg {
  height: 70px;
  width: 70px;
}

.hireus_highlight_container {
  padding: 65px 0px;
  background: #f6f6ff;
}

.hireus_highlight_container .hireus_highlight_heading h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 60px;
}

.hireus_highlight_container .hireus_highlight_card {
  background: #fff;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
  padding: 20px;
  position: relative;
}

.hireus_highlight_container .hireus_highlight_card p {
  color: #989ea6;
  text-align: center;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  margin-top: 18px;
}
.hireus_highlight_container .hireus_highlight_card .hireus_highlight_percent {
  height: 65px;
  width: 65px;
  background-color: #013584;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  left: 41%;
  top: -28%;
}
.hireus_highlight_container .hireus_highlight_percent span {
  color: #fff;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.hireus_form_container {
  padding: 65px 0px;
  background: #fef8ec;
  position: relative;
}
.hireus_form_container .hireus_form_left h1 {
  color: #1f1f1f;
  font-family: DM Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
}

.hireus_form_container .hireus_form_left p {
  color: #7d8185;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 40px;
}
.hireus_form_container .hireus_form_left .hire_form_inputall label,
.hireus_form_container .hireus_form_left .hire_form_inputall label {
  color: #989ea6;
  font-family: DM Sans;
  font-size: 16px;
  margin-bottom: 4px;
  font-style: normal;
  font-weight: 400;
  width: 100%;
}
.hireus_form_container .hireus_form_left .hire_form_inputall {
  margin-top: 20px;
}
.hireus_form_container .hireus_form_left .hire_form_inputall input,
.hireus_form_container .hireus_form_left .hire_form_inputall textarea,
.hireus_form_container .hireus_form_left .hire_form_phone_div {
  border-radius: 5px;
  background: #f1f4f6;
  padding: 12px;
  width: 100%;
  border: none;
  outline: none;
}

.hireus_form_container .hireus_form_left .hire_form_phone_div {
  display: flex;
}
.hireus_form_container .hireus_form_left .hire_form_phone_div {
  padding: 0;
}
.hireus_form_container .hireus_form_left .hire_form_phone_div input {
  padding: 10px;
}

.hireus_form_container .hireus_form_left .hire_form_phone_div span {
  color: #000;
}

.hireus_form_container .hireus_form_left .form_phone_input {
  padding: 12px 50px !important;
  padding-right: 0 !important;
  font-size: 15px;
}
.hireus_form_container .hireus_form_left .react-tel-input {
  width: 60%;
}

.hireus_form_container .hireus_form_left .hire_form_submit button {
  border-radius: 5px;
  background: #f89321;
  padding: 16px 22px;
  border: none;
  margin-top: 20px;
  color: #fff;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.hireus_form_container .hireus_form_right img {
  position: absolute;
  right: 0;
  top: 0;
  width: 52%;
  height: 100%;
  object-fit: cover;
}

.hireus_testimonial_container {
  padding: 65px 0px;
}

.hireus_testimonial_container .hireus_testimonial_heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.hireus_testimonial_container .hireus_testimonial_heading h2 {
  color: #1a76c6;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}
.hireus_testimonial_container .hireus_testimonial_heading h1 {
  color: #1f1f1f;
  text-align: center;
  font-family: DM Sans;
  font-size: 52px;
  max-width: 40%;
  font-style: normal;
  font-weight: 400;
}

.hireus_testimonial_container .hire_testimonial_item {
  border-radius: 30px;
  background: #f5f9fc;
  box-shadow: 0px 4px 10px 6px rgba(0, 0, 0, 0.25);
  position: relative;
  margin: 40px 10px;
}

.hire_testimonial_item .hire_testimonial_item_desc {
  padding: 20px;
}
.hire_testimonial_item
  .hire_testimonial_item_desc
  .hire_testimonial_logo
  .hire_logo_img {
  width: 30%;
}

.hire_testimonial_item
  .hire_testimonial_item_desc
  .hire_testimonial_logo
  .hire_quote_img {
  width: 15%;
}
.hire_testimonial_item .hire_testimonial_item_desc .hire_testimonial_logo {
  display: flex;
  align-items: center;
  gap: 25px;
}

.hire_testimonial_item .hire_testimonial_item_desc p {
  color: #353636;
  text-align: center;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-top: 20px;
}

.hire_testimonial_item .hire_testimonial_item_desc h3 {
  color: #989ea6;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-top: 20px;
  text-align: center;
  margin-bottom: 30px;
}

.hire_testimonial_item .hire_testimonial_item_desc h3 span {
  color: #353636;
  font-weight: 700;
}

.hire_testimonial_item .hire_testimonial_img img {
  height: 85px;
  width: 85px;
  object-fit: cover;
  border-radius: 50%;
  position: absolute;
  bottom: -6%;
  left: 40%;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.25);
}

.hire_testimonial_item .hire_testimonial_item_hr {
  border-radius: 2px 2px 30px 30px;
  background: #024c87;
  padding: 30px 0px;
}

.hire_testimonial_carousel .owl-nav {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.hire_testimonial_carousel .owl-nav button {
  scale: 1.5;
}

.hire_testimonial_carousel .owl-nav .owl-next:hover,
.hire_testimonial_carousel .owl-nav .owl-prev:hover {
  background-color: transparent;
}

.terms_and_condition_container,
.return_refund_container,
.privacy_policy_container {
  color: #000;
}

/******************************************************** Enroll Now Page ******************************************************/

.enroll_form_Container {
  padding: 65px 0px;
  background: #f3f3f4;
}

.enroll_form_Container .enroll_steps_div {
  display: flex;
  align-items: center;
  /* gap: 30px; */
  justify-content: center;
}

.enroll_form_Container .enroll_steps_div .enroll_step_round button {
  background-color: #fff;
  border: none;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d9d9d9;
  font-family: DM Sans, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.enroll_form_Container .enroll_steps_div .enroll_step_round span {
  color: #292d32;
  font-family: DM Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  text-align: center;
}

.enroll_form_Container .enroll_steps_div .enroll_step_round {
  flex-direction: column;
  width: 50%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.enroll_form_Container .enroll_step_line {
  background: #d5d9df;
  /* width: 100%; */
  width: 78%;
  height: 2px;
}

.enroll_form_Container .enroll_steps_div .active button {
  background-color: #4361ee;
  color: #fff;
}

.enroll_form_Container .enroll_steps_div .active span {
  color: #4361ee;
}

.enroll_form_Container .enroll_stepone_div,
.enroll_form_Container .enroll_steptwo_div,
.enroll_form_Container .enroll_stepthree_div {
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
  margin-top: 40px;
}

.enroll_form_Container .enroll_stepone_div .enroll_stepone_heading h1,
.enroll_steptwo_div .enroll_steptwo_heading h1,
.enroll_stepthree_div .enroll_stepthree_heading h1 {
  color: #263654;
  font-family: DM Sans, sans-serif;

  font-size: 28px;
  font-style: normal;
  font-weight: 400;
}

.enroll_form_Container .enroll_stepthree_div .payment_option_div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
  width: 61%;
}
.enroll_stepthree_div .payment_option_div .razorpay_option_btn {
  background: #288923;

  color: #fff;
  cursor: pointer;
  border: none;
  padding: 10px 22px;
  border-radius: 6px;
  margin-bottom: 7px;
  font-weight: bolder;
  width: 40%;
}

.enroll_stepthree_methods .checkout_details {
  /* border: 1px solid gray; */
  border-radius: 4px;
  padding: 10px;
  margin: 12px 0px;
  margin-bottom: 20px;
  width: 54%;
}

.enroll_stepthree_methods .checkout_details .checkout_details_desc h2 {
  color: #263654;
  font-size: 22px;
  margin-bottom: 23px;
}

.enroll_stepthree_methods .checkout_details .checkout_details_desc h3 {
  color: #263654;
  font-size: 18px;
}

.enroll_stepthree_methods .checkout_details .checkout_details_desc h4 {
  color: #263654;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}
.enroll_stepthree_methods .checkout_details .checkout_img_wrapper {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
}

.enroll_form_Container .enroll_stepone_div .enroll_stepone_heading,
.enroll_steptwo_div .enroll_steptwo_heading {
  display: flex;
  padding: 18px 0px;
  justify-content: space-between;
  align-items: center;
}

.enroll_form_Container .enroll_stepone_div .enroll_stepone_heading button,
.enroll_steptwo_div .enroll_steptwo_heading button,
.enroll_stepfour_form_submit button {
  border-radius: 5px;
  background: #f8951e;
  padding: 8px 16px;
  color: #fff;
  font-family: DM Sans, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  border: none;
}

.enroll_form_Container .enroll_stepone_course_Detail {
  display: flex;
  align-items: center;
  gap: 25px;
  padding: 10px 0px;
  border-top: 2px solid #d9d9d9;
  border-bottom: 2px solid #d9d9d9;
}

.enroll_stepone_course_Detail .enroll_stepone_course_icon button {
  background-color: #f89321;
  height: 54px;
  width: 54px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 8px;
}

.enroll_stepone_course_Detail .enroll_stepone_course_name h2 {
  color: #000;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  font-family: DM Sans, sans-serif;
}

.enroll_stepone_course_Detail .enroll_stepone_course_name h3 {
  color: #989ea6;
  font-family: DM Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.enroll_stepone_div .enroll_stepone_radiobtns {
  margin-top: 30px;
}
.enroll_stepone_div .enroll_stepone_radiobtns h4 {
  color: #394149;
  font-family: DM Sans, sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
}

.enroll_stepone_div .enroll_stepone_inputall {
  margin-top: 30px;
  display: flex;
  gap: 10px;
  align-items: center;
}
.enroll_stepone_inputall label {
  border-radius: 5px;
  background: #fff;
  width: 70%;
  border: 2px solid #d9d9d9;
  display: flex;
  gap: 10px;
}
.enroll_stepone_inputall .active {
  border: 2px solid #4361ee;
}
.enroll_stepone_inputall label .enroll_stepone_date {
  background-color: #9da8bb;
  color: #fff;
  font-family: DM Sans, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  width: 20%;
  padding: 10px;
}

.enroll_stepone_inputall label .enroll_stepone_desc h4 {
  color: #515f79;
  font-family: DM Sans, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.enroll_stepone_inputall label .enroll_stepone_desc h5 {
  color: #515f79;
  font-family: DM Sans, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.enroll_stepone_inputall label .enroll_stepone_desc {
  padding: 8px 0px;
}

.enroll_stepone_inputall label .enroll_stepone_desc h6 {
  color: #515f79;
  font-family: DM Sans, sans-serif;

  font-size: 13px;
  font-style: normal;
  font-weight: 500;
}

.enroll_steptwo_div .enroll_steptwo_heading {
  padding-bottom: 20px;
  border-bottom: 2px solid #d9d9d9;
}

.enroll_steptwo_div .enroll_steptwo_inps {
  margin-top: 20px;
}
.enroll_steptwo_inps .enroll_steptwo_inputall label {
  color: #5e5f61;
  font-family: DM Sans, sans-serif;
  width: 100%;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 8px;
}

.enroll_steptwo_inps .enroll_steptwo_inputall {
  margin-bottom: 15px;
}

.enroll_steptwo_inps .enroll_steptwo_inputall input,
.enroll_steptwo_inps .enroll_steptwo_inputall select,
.enroll_steptwo_inps .enroll_steptwo_inputall textarea {
  border-radius: 2px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  width: 100%;
  padding: 8px 12px;
  outline: none;
}

.enroll_steptwo_inps .enroll_steptwo_check {
  display: flex;
  align-items: center;
  gap: 10px;
}

.enroll_steptwo_inps .enroll_steptwo_check label,
.enroll_steptwo_inps .enroll_steptwo_check input {
  width: auto;
  margin: 0;
}
.enroll_steptwo_inps .enroll_steptwo_check input {
  scale: 1.5;
}
.enroll_steptwo_inps .enroll_steptwo_check label a {
  color: #0077b5;
}
.enroll_stepthree_div .enroll_stepthree_heading h2 {
  color: #5e5f61;
  font-family: DM Sans, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-left: 25px;
}

.enroll_stepthree_div .enroll_stepthree_img {
  padding: 10px 20px;
  text-align: center;
  border-top: 2px solid #d9d9d9;
  border-bottom: 2px solid #d9d9d9;
  margin: 30px 0px;
}

.enroll_stepthree_div .enroll_stepthree_img img {
  width: 80%;
}

.PaymentButton-Button-contents {
  padding: 10px;
  margin: 1px 0;
  font-style: italic;
  width: 100%;
  text-align: center;
  margin-left: -108px;
}

.razorpay_btn_wrapper svg {
  transform: scale(2);
}

.razorpay_icon {
  background: #1e40a0;
  padding: 0px 30px;
  height: 70px;
  display: flex;
  align-items: center;
  border-radius: 4px 0px 0px 4px;
  clip-path: polygon(0 0, 100% 0, 78% 100%, 0% 100%);
}

.razorpay_btn_wrapper .PaymentButton-Button-text {
  font-weight: bold;
  font-size: 20px;
}

.razorpay_btn_wrapper {
  background: #072654;
  border-color: #072654;
  display: flex;
  align-items: center;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.PaymentButton-Button-rzpBranding {
  font-size: 12px;
  color: #afafaf;
}

.razorpay_div {
  margin: 20px 0px;
  width: 60%;
}

.enroll_stepthree_or {
  text-align: center;
  margin: 30px 0px;
  color: #393939;
  font-family: DM Sans, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
}

.enroll_steptwo_contact .form_phone_input {
  padding-left: 55px !important ;
  background: #ecebeb !important;
}
.enroll_steptwo_contact .react-tel-input {
  width: 10% !important;
}
.enroll_steptwo_contact .country-list .country-name {
  color: #000;
}

.paypal_btn_wrapper {
  /* width: 40%; */
  width: 60%;
  width: 100%;
  margin-top: 22px;
}
.enroll_stepthree_methods {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/******************************************************************* Thnak You page Css ******************************************/
.thanks_container p {
  color: #7d8185;
}

.thanks_container h1,
.thanks_container h2,
.thanks_container h3,
.thanks_container h4,
.thanks_container h5 {
  color: #191e24;
}
.tanks_heading {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tanks_heading .thanks_img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.tanks_heading h4 {
  margin: 0;
  margin: 15px 0px;
  font-size: 20px;
  color: #7eada8;
}

.tanks_heading h1 {
  margin: 0;
  font-size: 30px;
  text-align: center;
}
.thanks_detail_section h4 {
  font-size: 20px;
}

.thanks_detail_section {
  background-color: #e7e9ee;
  padding: 30px 10px;
  /* position: absolute;
  bottom: 0 ; */
  width: 100%;
}

.thanks_container {
  background-color: #ffffff;
  height: 100vh;
  position: relative;
}

/* .detail_description .detail_date {
  height: 210px ;
  width: 210px ;
} */
.detail_description .detail_date p {
  background-color: #ff758a;
  padding: 8px 32px;
  color: #ffff;
  margin: 0;
  text-align: center;
}

.detail_description .detail_date h3 {
  padding: 8px 10px;
  margin: 0;
  color: #262626;
  background-color: #ffff;
  text-align: center;
}

.detail_description {
  gap: 20px;
  width: 65%;
}

.thanks_description_content h3 {
  font-size: 18px;
  margin: 0;
  margin-bottom: 10px;
}

.thanks_description_content p {
  font-size: 16px;
  margin: 0;
  line-height: 1.7;
}

.thanks_btn_wrapper {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 45px;
}

.thanks_btn_wrapper a {
  display: inline-block;
  text-align: center;
  color: #ff758a !important;
  background-color: #ffff;
  border: 2px solid #ff758a;
  padding: 8px 22px;
  width: 186px;
  line-height: 1.2;
  font-weight: bold;
}

.thanks_footer .thanks_footer_hr {
  background-color: #c8cbd2;
  height: 3px;
  margin: 20px 0px;
  margin-top: 80px;
}

.thanks_footer_one {
  display: flex;
  align-items: center;
  gap: 10px;
}

.thanks_footer_two p {
  font-size: 12px;
  margin: 0;
  line-height: 1.4;
  text-align: center;
}

.thanks_container {
  padding-top: 62px;
}

.thanks_container .countdown_div {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.thanks_container .countdown_div .count_down_txt h4 {
  color: #9fa1a5;
  font-size: 24px;
  font-weight: 700;
}

.thanks_container .countdown_div .count_down_txt {
  text-align: center;
}
.offer_container h1,
.offer_container h2,
.offer_container h3,
.offer_container p {
  color: #000;
}

.tranparent_loader {
  background-color: #000;
  opacity: 0.5;
  z-index: 999;
  overflow: hidden;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.fade-in.loaded {
  opacity: 1;
}

.form_error_div {
  color: #dc3545;
  font-size: 0.875em;
  margin-top: 0.25rem;
  width: 100%;
}

@-webkit-keyframes pulsate1 {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75),
      0px 0px 25px 10px rgba(255, 255, 255, 0.75);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
    box-shadow: none;
  }
}

@keyframes pulsate1 {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75),
      0px 0px 25px 10px rgba(255, 255, 255, 0.75);
  }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1);
    opacity: 0;
    box-shadow: none;
  }
}

/****************************************************** Dashboard Page CSS *********************************************************/

.dashboard_main_div {
  height: 100vh;
  /* background: #180600; */
  background: var(--bg-black);
  padding: 15px 15px 15px 0px;
}
.dashboard_header_div {
  position: relative;
  height: 96%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 47px 0px;
}

.dashboard_main_div .transition_sidebar {
  transition: width 0.5s ease-in-out;
}

.dashboard_header_div::-webkit-scrollbar {
  width: 0px;
}

.dashboard_header_div .dashboard_header_logo img {
  height: 60px;
  width: 122px;
}
.dashboard_header_div .dashboard_header_logo_open img {
  height: 40px;
  width: 80px;
}
.dashboard_header_div .dashboard_header_logo {
  display: flex;
  justify-content: center;
}
.dashboard_header_div .dashboard_header_menu {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.dashboard_header_div .dashboard_header_menu svg {
  font-size: 30px;
}

.dashboard_header_div .dashboard_header_list {
  margin-top: 30px;
}

.dashboard_header_div .dashboard_header_list ul {
  padding: 0;
  list-style: none;
}

.dashboard_header_icon_active {
  position: relative;
}

.dashboard_header_icon_active::after {
  content: "";
  position: absolute;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: red;
  top: -3%;
  right: -31%;
}

.dashboard_header_div .dashboard_header_list ul li a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 6px;
}

.dashboard_header_div .dashboard_header_list ul li {
  position: relative;
}

.dashboard_header_div .dashboard_header_list ul .isactive::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 7px;
  background-color: #fff;
  right: -1%;
  top: 0;
  border-radius: 8px;
}

.dashboard_header_div .dashboard_header_list .isopen .isactive::after {
  right: 0%;
}

.dashboard_header_div .dashboard_header_list ul li a svg,
.dashboard_header_div .dashboard_header_logout button svg {
  scale: 1.6;
}

.dashboard_header_div .dashboard_header_list ul li a span {
  font-size: 12px;
  color: #afacab;
  font-weight: 500;
}

.dashboard_header_div .dashboard_header_list ul .isactive a svg path {
  fill: #fff;
}

.dashboard_header_div .dashboard_header_list ul .isactive a svg g rect {
  fill: #fff;
}

.dashboard_header_div .dashboard_header_list ul .isactive a span {
  color: #fff;
}

.dashboard_header_div .dashboard_header_list ul li {
  margin-top: 16px;
}

.dashboard_header_div .dashboard_header_logout button {
  background-color: transparent;
  border: none;
  color: #afacab;
}

.dashboard_header_div .dashboard_header_logout button {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.dashboard_header_div .dashboard_header_logout_open button {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.dashboard_header_div .dashboard_header_logout_open button span {
  font-size: 18px;
}

.dashboard_header_div .dashboard_header_logout {
  margin-top: 25px;
  position: absolute;
  bottom: 2%;
  width: 100%;
}

.dashboard_main_div .dashboad_content_div {
  border-radius: 13px;
  /* background: #fff; */
  background-color: var(--bg-gray);
  height: 95vh;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
}
.dashboard_main_div .dashboad_content_div::-webkit-scrollbar {
  width: 0px;
}

/**************************************************** Dashboard Home CSS ***************************************************************/
.dashboard_info_div {
  border-radius: 13px 0px 0px 13px;
  /* background: #fff7f0; */
  background-color: var(--bg-gray);
  height: 100%;
  padding: 20px;
}

.dashboard_info_div .dashboard_info_heading h2 {
  color: #6e6d7a;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard_info_div .dashboard_info_heading h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard_info_div .dashboard_search {
  border-radius: 5px;
  background: #fefefe;
  position: relative;
  padding: 0px 8px;
}
.dashboard_info_div .dashboard_search svg {
  scale: 0.8;
}

.dashboard_info_div .dashboard_search svg path {
  fill: #292d32;
}
.dashboard_info_div .dashboard_search input {
  width: 85%;
  border: none;
  outline: none;
  padding: 10px;
}

.dashboard_info_div .dashboard_info_card {
  border-radius: 10px;
  background: #fff;
}

.dashboard_info_card .dashboard_info_card_desc {
  padding: 12px;
}
.dashboard_info_card .dashboard_info_card_desc h4 {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.dashboard_info_card_img {
  display: flex;
  align-items: center;
  gap: 12px;
}

.dashboard_info_card_img img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.dashboard_info_card .dashboard_info_card_img h5 {
  color: #6e6d7a;
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  margin: 0;
  font-weight: 500;
  line-height: normal;
}

.dashboard_info_card .dashboard_info_card_tag {
  display: flex;
  align-items: center;
  gap: 18px;
  margin-top: 20px;
}

.dashboard_info_card .dashboard_info_card_tag h3 {
  color: #6e6d7a;
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.dashboard_info_card .dashboard_info_card_progress {
  margin: 8px 0px;
}
.dashboard_info_card .dashboard_info_card_progress h2 {
  color: #6e6d7a;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard_info_card .dashboard_info_img_div img {
  height: 140px;
  object-fit: cover;
  border-radius: 12px;
}

.dashboard_continue .dashboard_continue_heading h3 {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 20px 0px 10px 0px;
}

.dashboard_info_courses .dashboard_info_courses_heading h2 {
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard_info_scrolldiv {
  height: 70vh;
  overflow-y: auto;
  margin-top: 14px;
}
.dashboard_info_scrolldiv::-webkit-scrollbar {
  width: 0px;
}

.dashboard_info_courses .dashboard_info_courses_heading {
  margin: 14px 0px 8px 0px;
}

.dashboard_info_item .dashboard_info_item_img img {
  border-radius: 10px;
  background: #19457b;
  height: 102px;
  object-fit: cover;
}
.dashboard_info_item .dashboard_item_schedulelist {
  margin-top: 20px;
}
.dashboard_info_item .dashboard_item_schedulelist h4 {
  color: #000;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 8px;
}

.dashboard_info_item {
  border-radius: 8px;
  background: #fff;
  margin-top: 10px;
  padding: 15px 20px;
  cursor: pointer;
}

.dashboard_info_item .dashboard_info_item_profile img {
  height: 38px;
  width: 38px;
  object-fit: cover;
  border-radius: 50%;
}
.dashboard_info_item .dashboard_info_item_profile span {
  color: #afacab;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 10px;
}

.dashboard_info_item .dashboard_info_item_profile .dashboard_schedule_btn {
  text-decoration: underline;
  cursor: pointer;
}
.dashboard_info_item .dashboard_info_item_profile {
  display: flex;
  align-items: center;
  margin: 14px 0px;
  margin-bottom: 0;
}
.dashboard_info_item .dashboard_info_item_desc h3 {
  color: #000;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard_info_item_tags {
  display: flex;
  justify-content: space-between;
  color: #afacab;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.dashboard_right_div {
  height: 93vh;
  overflow-y: auto;
}
.dashboard_right_div::-webkit-scrollbar {
  width: 0;
}
.dashboard_graph_container {
  padding: 20px;
}

.dashboard_graph_container .dashboard_graph_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard_graph_container .dashboard_graph_info h2 {
  color: #000;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard_graph_container .dashboard_graph_info h3 {
  color: #afacab;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard_graph_container .dashboard_graph_profile img {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.dashboard_graph_container .dashboard_graph_profile svg {
  scale: 1.8;
  position: relative;
  margin-right: 20px;
}

.dashboard_graph_container .dashboard_graph_profile svg::before {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  left: 0;
  top: 0;
  background-color: #dd483d;
}

.dashboard_graph_container .graph_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0px;
}
.dashboard_graph_container .graph_heading h4 {
  color: #000;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.dashboard_graph_container .graph_heading select {
  border-radius: 10px;
  background: #f7f7f7;
  border: none;
  padding: 8px 18px;
  outline: none;
}

.dashboard_progress_container .dashboard_progress_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px 30px 0px;
}

.dashboard_progress_container .dashboard_progress_heading h2 {
  color: #000;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard_progress_container .dashboard_progress_heading select {
  border-radius: 10px;
  background: #f7f7f7;
  border: none;
  outline: none;
  padding: 8px 16px;
}

.dashboard_progress_container .dashboard_progress_hours h3 {
  color: #6e6d7a;
  text-align: center;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 15px 0px;
}

.dashboard_progress_container .dashboard_progress_hours h3 span {
  color: #292d32;
}
.dashboard_progress_container .dashboard_progress_items {
  padding-left: 15px;
  position: relative;
}

.dashboard_progress_container .dashboard_progress_items::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 5px;
  height: 100%;
  width: 6px;
  background-color: #143a69;
}
.dashboard_progress_container .dashboard_progress_item2::before {
  background-color: #ffbe37;
}
.dashboard_progress_container .dashboard_progress_item3::before {
  background-color: #bf473d;
}

.dashboard_progress_container .dashboard_progress_desc {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.dashboard_progress_container .dashboard_progress_items h4 {
  color: #000;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}
.dashboard_progress_container .dashboard_progress_items span {
  color: #6e6d7a;
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dashboard_progress_container {
  padding: 20px;
}
.dashboard_progress_container .dashboard_progress_assinment_heading {
  margin: 25px 0px 30px 0px;
}
.dashboard_progress_container .dashboard_progress_assinment_heading h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard_progress_container .dashboard_progress_assinment_items {
  border-radius: 7px;
  background: #fafafa;
  padding: 8px 12px;
  margin-top: 10px;
  display: flex;
  gap: 20px;
  align-items: center;
}

.dashboard_progress_assinment_items .dashboard_progress_assinment_icon {
  border-radius: 5px;
  background: #ffeac8;
  padding: 4px;
  width: 74px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard_progress_assinment_items .dashboard_progress_assinment_icon svg {
  scale: 2;
}

.dashboard_progress_assinment_items .dashboard_progress_assinment_desc {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.dashboard_progress_assinment_items .dashboard_progress_assinment_desc h4 {
  color: #000;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard_progress_assinment_items .dashboard_progress_assinment_desc h5 {
  color: #6e6d7a;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard_progress_assinment_items .dashboard_progress_assinment_desc svg {
  fill: #afacab;
  scale: 2;
}

.dashboard_header_div .dashboard_header_list .isopen li a {
  flex-direction: row;
  gap: 12px;
  padding: 4px 0px;
}

.dashboard_header_div .dashboard_header_list .isopen li a span {
  font-size: 18px;
}

.dashboard_header_div .dashboard_header_totalhour {
  border-radius: 10px;
  background: #19457a;
  padding: 16px 42px;
  margin: 0px 20px;
}

.dashboard_header_totalhour .dashboard_header_total {
  display: flex;
  gap: 10px;
  align-items: center;
}

.dashboard_header_totalhour .dashboard_header_total h4 {
  font-size: 12px;
}

.dashboard_main_div .fixed_dashboard_header {
  display: block;
  position: fixed;
  background: #180600;
  background-color: var(--bg-black);
  width: 70%;
  z-index: 99;
  top: 0;
  height: 100%;
  padding: 10px 0px;
}

/************************************************ Dashboard Course Page CSS *******************************************************/
.dashboard_tab_container {
  background: #ff8514;
  padding: 16px 22px;
  background-color: var(--primary-blue);
}

.dashboard_tab_container h1 {
  color: #fff;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard_tab_container .dashboard_tab_text {
  padding: 8px 18px;
  border-radius: 13px;
  background: #fefefe;
  box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.dashboard_tab_container .dashboard_tab_text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
.dashboard_tab_container .dashboard_tab_text svg {
  fill: #1c1b1f;
  scale: 1.7;
  fill: var(--primary-blue);
}
.dashboard_tab_container .dashboard_tab_text h2 {
  color: #6e6d7a;
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  width: 70%;
}

.dashboard_module_container {
  padding-bottom: 10px;
  height: 72vh;
  overflow-y: auto;
}
.dashboard_module_container::-webkit-scrollbar {
  width: 0;
}
.dashboard_module_container .dashboard_module_nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  gap: 18px;
}

.dashboard_module_container .dashboard_module_nav ul li {
  color: #6e6d7a;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  padding-bottom: 2px;
}

.dashboard_module_container .dashboard_module_nav ul .active {
  color: #292d32;
  border-bottom: 2px solid #292d32;
}

.dashboard_module_list {
  padding: 0px 12px;
  margin-top: 10px;
}

.dashboard_module_list .dashboard_module_list_tab {
  border-radius: 8px;
  background: #fefefe;
  box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 18px;
}

.dashboard_module_list .dashboard_module_list_tab h2 {
  color: #6e6d7a;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.dashboard_module_list .dashboard_module_list_tab svg {
  fill: #1c1b1f;
  scale: 1.7;
}

.dashboard_module_list .dashboard_module_video {
  border-radius: 13px;
  background: #fefefe;
  box-shadow: 0px 0px 8px -4px rgba(0, 0, 0, 0.25);
  margin-top: 16px;
  padding: 8px 12px;
}
.dashboard_module_list .dashboard_module_video.active {
  border: 2px solid var(--primary-blue);
}

.dashboard_module_list .dashboard_module_video .dashboard_module_img_div {
  padding-right: 0;
}

.dashboard_module_video .dashboard_module_img {
  width: 100%;
}

.dashboard_module_video .dashboard_module_img img,
.dashboard_module_video .dashboard_module_img video,
.dashboard_module_video .dashboard_module_img iframe {
  border-radius: 7px;
  object-fit: cover;
  /* height: 95px ;
  width: 100%; */
  height: 84px;
  width: 100%;
}

.dashboard_module_video .dashboard_module_img .dashboard_module_live {
  height: 84px;
  width: 118px;
  border-radius: 7px;
  background: url("../images/live_img.jpg");
  background-size: cover;
  position: relative;
}
.dashboard_module_video .dashboard_module_img .dashboard_module_live span {
  background-color: #cc0000e6;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 5px;
  border-radius: 6px;
  margin: 3px;
}
.dashboard_module_video .dashboard_module_img .dashboard_module_live span h5 {
  margin: 0;
  font-size: 14px;
}
.dashboard_module_video .dashboard_module_img .dashboard_module_live span svg {
  fill: #fff;
  margin-right: 6px;
}
.dashboard_module_video .dashboard_module_video_desc h3 {
  color: #000;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard_module_video
  .dashboard_module_video_desc
  .dashboard_module_video_div {
  width: 85%;
}

.dashboard_module_video .dashboard_module_video_desc h4 {
  color: #6e6d7a;
  /* text-align: justify; */
  font-family: DM Sans;
  height: 56px;
  overflow: hidden;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard_module_video .dashboard_module_video_desc {
  display: flex;
  gap: 10px;
  align-items: center;
}

.dashboard_module_video .dashboard_module_video_desc svg {
  height: 80px;
  width: 14%;
}

.dashboard_video_container video,
.dashboard_video_container iframe,
.dashboard_video_container .video-react {
  height: 450px;
  width: 100%;
  border-radius: 12px;
  padding-top: 0 !important;
}

.dashboard_video_container .video-react .video-react-big-play-button {
  top: 45%;
  left: 45%;
  background-color: #024c87;
  border: 2px solid #fff;
}

.dashboard_video_container .dashboard_watermark {
  display: none;
  position: absolute;
  top: 7%;
  right: 3%;
  height: 50px;
  border-radius: 3px;
  width: 110px;
  background-color: #00237c;
}
.dashboard_video_container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #afacab;
  width: 100%;
  height: 1px;
}
.dashboard_video_container {
  padding: 10px;
  padding-top: 20px;
  position: relative;
}

.dashboard_coursenav_container ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  /* justify-content: space-between; */
  justify-content: space-evenly;
  padding: 12px 8px 0px 8px;
}
.dashboard_coursenav_container ul li {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding-bottom: 10px;
  color: #000;
}
.dashboard_coursenav_container ul li span {
  color: #000;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.dashboard_coursenav_container ul .active {
  border-bottom: 2px solid #000;
}

.dashboard_coursenav_container ul li svg {
  scale: 1.3;
}

.dashboard_course_overview_container {
  margin-top: 14px;
  padding: 0px 10px;
  height: 100px;
}
.dashboard_course_overview_container::-webkit-scrollbar {
  width: 0;
}
.dashboard_course_overview_container h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard_course_overview_container .dashboard_course_overview_container_para {
  color: #6e6d7a;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  /* font-weight: 500; */
  line-height: normal;
}

.dashboard_course_overview_container .dashboard_course_overview_container_para p {
  margin-bottom:  0 ;
}

.dashboard_course_overview_container .dashboard_course_overviewprofile img {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  object-fit: cover;
}

.dashboard_course_overview_container .dashboard_course_overviewprofile {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-top: 10px;
  padding-bottom: 10px;
}
.dashboard_course_overview_container .dashboard_course_overviewprofile h3 {
  color: #000;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.dashboard_course_overview_container .dashboard_course_overviewprofile h4 {
  color: #c5c3dc;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.dashboard_course_qanda_container {
  margin-top: 20px;
  padding: 0px 10px;
}

.dashboard_course_qanda_container .dashboard_course_ques_div {
  border-radius: 13px;
  background: #fefefe;
  box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.25);
  padding: 30px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard_course_qanda_container .dashboard_course_ques_div textarea {
  color: #363636;
  font-family: DM Sans;
  font-style: normal;
  width: 90%;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
}
.dashboard_course_qanda_container .dashboard_course_ques_div button {
  color: #fff;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  background: #0073b1;
  padding: 8px 18px;
  border: none;
}

.dashboard_course_qanda_container .dashboard_course_ans_div {
  border-radius: 8px;
  background: #fefefe;
  box-shadow: 0px 0px 8px -4px rgba(0, 0, 0, 0.25);
  margin-bottom: 24px;
  /* padding: 12px; */
  border: 2px solid #ededed;
}

.dashboard_course_qanda_container
  .dashboard_course_ans_div
  .dashboard_qanda_ques {
  padding: 12px;
}

.dashboard_course_qanda_container
  .dashboard_course_ans_div
  .dashboard_qanda_anslist {
  padding: 20px 12px;
  background-color: #f5f5f5;
}

.dashboard_course_qanda_container .dashboard_course_qanda_listdiv {
  height: 65vh;
  overflow-y: auto;
  margin-top: 24px;
  margin-bottom: 15px;
}

.dashboard_course_qanda_container
  .dashboard_course_qanda_listdiv::-webkit-scrollbar {
  width: 0px;
}

.dashboard_course_qanda_container
  .dashboard_course_ans_div
  .dashboard_qanda_profile_div
  img {
  height: 78px;
  width: 78px;
  border-radius: 50%;
  object-fit: cover;
}
.dashboard_course_qanda_container
  .dashboard_course_ans_div
  .dashboard_qanda_profile_div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.dashboard_course_qanda_container .dashboard_qanda_profile_div_desc h3 {
  color: #363636;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.dashboard_course_qanda_container .dashboard_qanda_profile_div_desc h4 {
  color: #6e6d7a;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.dashboard_course_qanda_container .dashboard_qanda_profile_div_desc span {
  color: #6e6d7a;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.dashboard_course_qanda_container
  .dashboard_qanda_answer_div
  .dashboard_qanda_profile_div
  img {
  height: 58px;
  width: 58px;
}

.dashboard_course_qanda_container .dashboard_qanda_answer_div h3 {
  font-size: 16px;
}

.dashboard_course_qanda_container .dashboard_qanda_answer_div h4,
.dashboard_course_qanda_container .dashboard_qanda_answer_div span {
  font-size: 12px;
}
.dashboard_course_qanda_container
  .dashboard_qanda_answer_div
  .dashboard_qanda_ques_div
  h2 {
  font-size: 18px;
}
.dashboard_course_qanda_container .dashboard_qanda_ques_div {
  margin: 14px 0px;
  padding-bottom: 10px;
  border-bottom: 2px solid #c0c0c1;
}
.dashboard_course_qanda_container .dashboard_qanda_ques_div button {
  border: none;
  background-color: transparent;
  color: #100c0c99;
  font-weight: 700;
}
.dashboard_course_qanda_container .dashboard_qanda_ques_div button:hover {
  color: #0073b1;
  text-decoration: underline;
}

.dashboard_course_qanda_container .dashboard_qanda_ques_div .qna-btn-edit {
  background: #0073b1;
  padding: 4px 18px;
  color: #fff;
  border: none;
  height: 100%;
}

.dashboard_course_qanda_container
  .dashboard_qanda_ques_div
  .qna-btn-edit:hover {
  text-decoration: underline;
  color: #fff;
}
.dashboard_course_qanda_container .dashboard_qanda_ques_div span {
  color: #100c0c99;
  height: 8px;
  font-weight: 700;
  margin: 0px 7px;
  display: inline-block;
}
.dashboard_course_qanda_container .dashboard_qanda_ques_div h2 {
  color: #000;
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
}
.dashboard_course_qanda_container .dashboard_qanda_ques_div h5 {
  color: #6e6d7a;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.dashboard_course_qanda_container .dashboard_qanda_ques_div h5 a {
  color: #0085ff;
}

.dashboard_course_qanda_container .dashboard_qanda_likecomment {
  display: flex;
  align-items: center;
  gap: 42px;
  margin-bottom: 30px;
}

.dashboard_qanda_likecomment .dashboard_qanda_likecomment_icons svg {
  fill: #6e6d7a;
  scale: 1.5;
  color: #6e6d7a;
}

.dashboard_qanda_likecomment .dashboard_qanda_likecomment_icons {
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;
}

.dashboard_qanda_likecomment .dashboard_qanda_likecomment_icons span {
  color: #6e6d7a;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.dashboard_course_qanda_container .dashboard_qanda_youranswer {
  display: flex;
  gap: 10px;
  align-items: start;
  width: 100%;
}

.dashboard_course_qanda_container .dashboard_qanda_youranswer img {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  object-fit: cover;
}
.dashboard_course_qanda_container .dashboard_qanda_youranswer h5 {
  color: #6e6d7a;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.dashboard_course_qanda_container .dashboard_qanda_youranswer textarea,
.dashboard_course_notes_container .dasboard_course_notes_textarea textarea {
  width: 100%;
  background: #fff;
  box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.25);
  border: none;
  outline: none;
  border-radius: 8px;
  width: 100%;
  padding: 8px 12px;
}
.dashboard_qanda_youranswer .dashboard_qanda_youranswer_desc {
  width: 100%;
}

.dashboard_course_qanda_container .dashboard_qanda_youranswer button {
  background: #0073b1;
  padding: 4px 18px;
  color: #fff;
  border: none;
  float: right;
  margin-top: 10px;
}

.dashboard_course_notes_container {
  margin: 18px 0px;
  padding: 0px 10px;
}

.dashboard_course_notes_container .dasboard_course_notes_textarea button {
  background: #0073b1;
  padding: 8px 12px;
  margin-top: 14px;
  width: 22%;
  color: #fff;
  border: none;
}
.dashboard_course_notes_container .dasboard_course_notes_textarea {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.dasboard_course_notes_list p {
  color: #6e6d7a;
  text-align: justify;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dasboard_course_notes_list .dasboard_course_notes_options {
  display: flex;
  justify-content: end;
  gap: 10px;
}
.dasboard_course_notes_options .dasboard_course_notes_options_edit {
  background: #0073b1;
  padding: 8px 12px;
  margin-top: 14px;
  width: 10%;
  color: #fff;
  border: none;
}

.dasboard_course_notes_options .dasboard_course_notes_options_delete {
  background: #ff1414;
  padding: 8px;
  margin-top: 14px;
  width: 10%;
  color: #fff;
  border: none;
}

/******************************************** Dashboard Assignment Page Csss **************************************************/

.dashboard_assignment_list {
  margin: 20px 0px;
  padding: 0px 14px;
  height: 74vh;
  margin-top: 0px;
  overflow-y: auto;
}

.dashboard_assignment_list .dashboard_assignment_filter {
  display: flex;
  justify-content: center;
  gap: 35px;
  margin-top: 18px;
  padding: 0px 14px;
}
.dashboard_assignment_list .dashboard_assignment_filter button {
  padding: 4px 16px;
  background-color: transparent;
  border: 1px solid var(--primary-blue);
  border-radius: 34px;
  color: var(--primary-blue);
}
.dashboard_assignment_list .dashboard_assignment_filter .active {
  background-color: #180600;
  color: #fff;
  background-color: var(--primary-blue);
}

.dashboard_assignment_list::-webkit-scrollbar {
  width: 0;
}
.dashboard_assignment_list .dashboard_assignment_list_items {
  border-radius: 13px;
  border: 1px solid #6686a9;
  background: #fefefe;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.25);
  padding: 8px 16px;
  margin-top: 18px;
  cursor: pointer;
}

.dashboard_assignment_list .dashboard_assignment_list_items h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard_assignment_list .dashboard_assignment_list_items p {
  color: #6e6d7a;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 38px;
  overflow: hidden;
}

.dashboard_assignment_list .dashboard_assignment_list_item_active {
  border: 2px solid #024c87;
}

.dashboard_assignment_details h4 {
  color: #afacab;
  font-family: DM Sans;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard_assignment_details .dashboard_assignment_instructor {
  display: flex;
  align-items: center;
  gap: 6px;
}

.dashboard_assignment_details .dashboard_assignment_instructor img {
  height: 42px;
  width: 42px;
  border-radius: 50%;
  object-fit: cover;
}

.dashboard_assignment_details .dashboard_assignment_instructor span,
.dashboard_assignment_details .dashboard_assignment_range span {
  color: #6e6d7a;
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard_assignment_range .progress-bar {
  background: #62acb2 !important;
}

.assignment_instruction_container {
  padding: 14px 45px;
  padding-right: 95px;
  padding: 0;
}

.assignment_instruction_container .assignment_instruction_desc h1,
.assignment_instruction_container .assignment_instruction_content h1 {
  color: #3e3e3e;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
}
.assignment_instruction_container .assignment_instruction_desc p,
.assignment_instruction_container .assignment_instruction_content p {
  margin-top: 20px;
  color: #7d8185;
  font-family: "DM Sans";
  font-size: 15.233px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 22.849px */
}
.assignment_instruction_container .assignment_instruction_info {
  padding-bottom: 10px;
  border-bottom: 2px solid #d9d9d9;
}
.assignment_instruction_info .assignment_instruction_info_heading h2 {
  color: #024c87;
  font-family: "DM Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 144.5%; /* 52.02px */
  text-transform: capitalize;
  margin-bottom: 8px;
}
.assignment_instruction_info .assignment_instruction_info_heading h4 {
  color: #8c8c8c;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 8px;
}
.assignment_instruction_info .assignment_instruction_info_time {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.assignment_instruction_info .assignment_instruction_info_time h6 {
  color: #1f1f1f;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.assignment-fixed-btn {
  box-shadow: 0px 0px 3.9px #8a8a8a;
  border: 1px solid #5fbe00;
  position: fixed;
  bottom: 10%;
  right: 5%;
  background-color: #5fbe00;
  padding: 8px;
  border-radius: 50%;
  width: 69px;
  height: 69px;
  transition: all 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.assignment-fixed-btn:hover {
  height: 50px;
  width: 150px;
  border-radius: 6px;
  padding: 8px 12px;
}

.assignment-fixed-btn span {
  display: none;
  color: #fff;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.assignment-fixed-btn:hover span {
  display: inline-block;
}

.assignment-fixed-btn svg {
  font-size: 22px;
  fill: #fff;
}

.assignment-fixed-msg-btn {
  bottom: 22%;
  transition: none;
}
.assignment-fixed-msg-btn:hover {
  width: 69px;
  border-radius: 50%;
  height: 69px;
}

.assignment-fixed-msg-btn svg {
  font-size: 26px;
}
.assignment-fixed-msg-btn .active-msg circle {
  fill: #bb0000;
}

.assignment-fixed-btn-disable {
  background-color: #d9d9d9;
  border: 1px solid #d9d9d9;
}

.assignment_resourses_container {
  margin-top: 14px;
  border-bottom: 2px solid #d9d9d9;
  padding-bottom: 20px;
}
.assignment_detail_container {
  margin-top: 20px;
  padding: 30px;
  height: 86vh;
  padding-top: 0;
  overflow-y: auto;
}

.assignment_resourses_container h1 {
  margin-bottom: 20px;
  color: #3e3e3e;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.assignment_resourses_container .assignment_deadline_div {
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: 28px 0px;
}
.assignment_resourses_container .assignment_resourses_rating h1 {
  color: #00237c;
  text-align: center;
}

.assignment_resourses_container .assignment_resourses_rating h2 {
  color: #00237c;
  text-align: center;
  font-size: 24px;
  margin: 15px 0px;
  font-weight: bold;
}
.assignment_resourses_container .assignment_resourses_rating h1 span {
  font-size: 44px;
}
.assignment_resourses_container .assignment_resourses_rating h3 span {
  background-color: #ebe9e9;
  border-radius: 14px;
  padding: 8px 22px;
  color: #00237c;
  font-size: 20px;
}
.assignment_resourses_container .assignment_resourses_rating h3 {
  margin: 14px 0px;
  text-align: center;
}
.assignment_resourses_container .assignment_resource_icon {
  padding: 16px 20px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  background: #fff;
  position: relative;
}
.assignment_resourses_container .assignment_resource_icon::after {
  content: "";
  position: absolute;
  width: 1px;
  top: 0;
  left: 21%;
  background: #d9d9d9;
  height: 100%;
}

.assignment_resourses_container
  .assignment_resource_icon
  .assignment_resource_scale
  .icon_scale {
  scale: 2.6;
}

.assignment_resourses_container .assignment_resource_icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* .assignment_resourses_container .assignment_resource_icon svg {
  scale: 1.6;
} */
.assignment_resourses_container .assignment_resource_icon span {
  color: #6e6d7a;
  text-align: justify;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.assignment_resourses_container .assignment_resource_icon input {
  color: #6e6d7a;
  text-align: justify;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  outline: none;
}

.assignment_resourses_container .assignment_resource_driveicon {
  margin-top: 22px;
  padding: 6px;
  justify-content: start;
  gap: 10px;
}
.assignment_resourses_container .assignment_resource_driveicon input {
  width: 100%;
}

.assignment_resourses_container .assignment_resource_driveicon svg {
  scale: 1;
}

.assignment_resourses_work {
  padding: 0;
  padding-bottom: 20px;
  border-bottom: 2px solid #d9d9d9;
}
.assignment_resourses_work .assignment_resource_icon {
  justify-content: start;
  gap: 75px;
  margin-bottom: 16px;
}
/**************************************************** Dashboard Profile CSS ************************************************/
.dashboard_profile_container {
  height: 100%;
}
.dashboard_profile_container .dashboard_profile_password_div {
  background: var(--bg-gray);
  padding: 30px;
  height: 100%;
}

.dashboard_profile_container .dashboard_profile_img {
  text-align: center;
}

.dashboard_profile_container .dashboard_profile_img img {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  object-fit: cover;
}
.dashboard_profile_container .dashboard_profile_img h2 {
  color: #000;
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: normal;
}
.dashboard_profile_container .dashboard_profile_img h3 {
  color: #6e6d7a;
  font-family: DM Sans;
  font-size: 16px;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.dashboard_profile_container .dashboard_profile_password_div {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.dashboard_profile_container .dashboard_profile_password {
  margin-top: 14px;
}

.dashboard_profile_container .dashboard_profile_password h3 {
  color: #000;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 12px;
}

.dashboard_profile_container .dashboard_profile_password input {
  background: #fff;
  width: 100%;
  border: none;
  outline: none;
  padding: 12px;
  margin-top: 12px;
}

.dashboard_profile_container .dashboard_profile_password button {
  margin-top: 20px;
  font-size: 13px;
  padding: 12px;
  border: none;
  color: #fff;
  font-weight: 500;
  background: #2d2f31;
}

.dashboard_profile_info {
  padding: 20px;
  background-color: #fff;
}

.dashboard_profile_info .dashboard_profile_info_heading h2 {
  color: #000;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.dashboard_profile_info .dashboard_profile_info_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard_profile_info .dashboard_profile_info_heading button {
  background: #2d2f31;
  color: #fff;
  font-weight: 500;
  padding: 8px 22px;
  border: none;
  margin-top: 16px;
}
.dashboard_profile_info .dashboard_profile_info_heading h3 {
  color: #6e6d7a;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard_profile_info .dashboard_profile_info_form {
  background: #f9f9f9;
  margin-top: 20px;
  padding: 12px;
  height: 76vh;
  overflow-y: scroll;
}

.dashboard_profile_info .dashboard_profile_info_form::-webkit-scrollbar {
  width: 0px;
}

.dashboard_profile_info .dashboard_profile_info_form h4 {
  color: #000;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
}

.dashboard_profile_info .dashboard_profile_info_form input,
.dashboard_profile_info .dashboard_profile_info_form select {
  background: #fff;
  padding: 12px;
  width: 100%;
  border: none;
  outline: none;
  margin-top: 16px;
}

.dashboard_profile_info .dashboard_profile_info_form input:disabled {
  background-color: #f2f2f2;
  color: #999;
}

.dashboard_profile_info .dashboard_profile_form_inp {
  display: flex;
  align-items: center;
}
.dashboard_profile_info .dashboard_profile_form_inp label {
  width: 40%;
  background: #efefef;
  padding: 12px;
  margin-top: 16px;
  color: #b4b4bb;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.dashboard_profile_info
  .dashboard_profile_info_form
  .dashboard_profile_form_span {
  display: block;
  color: #b4b4bb;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
}

.dashboard_profile_info .dashboard_profile_info_form button {
  background: #2d2f31;
  color: #fff;
  font-weight: 500;
  padding: 8px 22px;
  margin-top: 16px;
}

.dashboard_profile_info_form .react-tel-input {
  width: 18%;
}
.dashboard_profile_info_form .react-tel-input span {
  color: #000;
}
.dashboard_profile_info_form .form_phone_input {
  padding: 13px 5px 9px 56px !important;
}
.dashboard_profile_info_form .react-tel-input .selected-flag .flag {
  margin-top: 0px;
}

.dashboard_profile_info_form .react-tel-input .selected-flag {
  width: 100%;
}

.dashboard_profile_info_form .react-tel-input .selected-flag:before {
  content: none;
}
.dashboard_profile_container .dashboard_profile_password_wrapper {
  height: 100%;
}

/***************************************************** Dashboard schedule *******************************************/

.dashboard_schedule_container {
  padding: 15px;
}
.dashboard_schedule_container .dashboard_schedule_upcoming {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 7px 7px 0px 0px;
  background: linear-gradient(90deg, #024c87 37.9%, #75c1fd 100%);
  color: #fff;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding: 6px 14px;
}
.dashboard_schedule_container .dashboard_schedule_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.dashboard_schedule_container
  .dashboard_schedule_header
  .dashboard_schedule_heading
  h3 {
  color: #6e6d7a;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard_schedule_container
  .dashboard_schedule_header
  .dashboard_schedule_heading
  h2 {
  color: #000;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard_schedule_header .dashboard_schedule_profile svg {
  scale: 1.5;
  margin-top: 5px;
  margin-right: 10px;
}
.dashboard_schedule_header .dashboard_schedule_profile img {
  height: 48px;
  width: 48px;
  object-fit: cover;
  border-radius: 50%;
}

.schedule_summary_project .schedule_summary_project_gallery ul {
  padding: 0;
  margin: 0;
  list-style: none;
  margin: 20px 0px;
}

.schedule_summary_project
  .schedule_summary_project_gallery
  .schedule_summary_gallery_img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #000;
  font-family: DM Sans;
  background-color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-right: -16px;
}
.schedule_summary_project .schedule_summary_project_gallery ul {
  display: flex;
  justify-content: center;
}

.schedule_summary_tab .schedule_summary_list {
  display: flex;
  align-items: center;
  gap: 15px;
}

.schedule_summary_tab .schedule_summary_list .schedule_summary_item {
  padding: 40px 10px;
  width: 100%;
  border-radius: 20px;
}

.schedule_summary_tab .schedule_summary_list .schedule_summary_item h4 {
  margin-top: 12px;
}

.schedule_summary_container .schedule_summary_tab h3 {
  color: #565351;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.275px;
  margin: 15px 0px;
}

.schedule_summary_container .schedule_summary_tab {
  border-radius: 20px;
  background: #fff;
  padding: 30px 20px;
}

.schedule_summary_container .schedule_summary_project {
  border-radius: 20px;
  background: #fff;
  padding: 39px 18px;
  height: 100%;
}

.schedule_summary_container .schedule_summary_project h3 {
  color: #565351;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.275px;
  margin-bottom: 10px;
  text-align: center;
}

.schedule_summary_project .schedule_summary_progress {
  border-radius: 20px;
  border: 1px solid #afacab;
  padding: 10px 15px;
  margin-top: 16px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.schedule_summary_project .schedule_summary_progress h4 {
  color: #565351;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.225px;
}

.dashboard_schedule_container {
  background: #f9f9f9;
  padding: 15px 35px;
}

.schedule_graph_container {
  border-radius: 20px;
  background: #fff;
  padding: 15px;
  margin-top: 30px;
}
.dashboard_calendar_container {
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  padding: 20px 0px;
  justify-content: center;
}
.dashboard_schedule_right {
  padding-left: 30px;
}

.dashboard_calendar_container .react-calendar {
  border: none;
  width: 100%;
}

.schedule_task_container {
  margin-top: 20px;
  padding: 15px;
  border-radius: 20px;
  background: #fff;
  height: 430px;
  overflow-y: auto;
}

.schedule_task_container::-webkit-scrollbar-track {
  background-color: #e5e7e9;
  border-radius: 10px;
}

.schedule_task_container::-webkit-scrollbar {
  width: 8px;
}
.schedule_task_container::-webkit-scrollbar-thumb {
  background: #ff8514;
  border-radius: 10px;
}
.schedule_task_container h2 {
  color: #000;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.schedule_task_container .schedule_task_item {
  border-radius: 10px;
  background: #f4f7f9;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  gap: 10px;
  cursor: pointer;
}

.schedule_task_container .schedule_task_item:hover {
  background: #ff8514;
  transform: rotate(3.814deg);
}

.schedule_task_container .schedule_task_item .schedule_task_time h3 {
  color: #000;
  font-family: DM Sans;
  margin-bottom: 5px;
  font-size: 18px;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.schedule_task_container .schedule_task_item .schedule_task_time {
  border-right: 4px solid #0073b1;
  padding-right: 15px;
  border-radius: 4px;
}

.schedule_task_container .schedule_task_item .schedule_task_desc h4 {
  color: #000;
  font-family: DM Sans;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.schedule_task_container .schedule_task_item .schedule_task_desc span {
  color: #afacab;
  font-family: DM Sans;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.39px;
}

.schedule_task_container .schedule_task_item button {
  border-radius: 15px;
  background: #e5e7e9;
  color: #000;
  font-family: DM Sans;
  border: none;
  padding: 8px 12px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.54px;
}

.schedule_task_container .schedule_task_item:hover h3,
.schedule_task_container .schedule_task_item:hover h4,
.schedule_task_container .schedule_task_item:hover span {
  color: #fff;
}

.schedule_task_container .schedule_task_item:hover button {
  color: #fff;
  background-color: #000;
}
.schedule_task_container .schedule_task_item:hover .schedule_task_time {
  border-right: 4px solid #fff;
}
.apply_form_container .oyip_note_text {
  margin: 15px 0px;
  color: #575757;
  font-size: 16px;
  display: flex;
  gap: 6px;
}
.assignment_submit_modal .assignment_submit_modal_heading h2 {
  color: #576bad;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  width: 100%;
  margin: 0;
  text-align: center;
}

.assignment_submit_modal .assignment_upload_file {
  border: 2px dashed #46484e;
  height: 200px;
  width: 250px;
  padding: 10px 20px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.assignment_submit_modal .assignment_upload_file h4 {
  color: #46484e;
  font-size: 20px;
  margin: 0;
}

.assignment_submit_modal .assignmentList_div .assignment_list_div {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  padding: 8px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  background: #fff;
  position: relative;
}
.assignment_submit_modal .assignmentList_div .assignment_list_div::after {
  content: "";
  position: absolute;
  width: 1px;
  left: 28%;
  height: 100%;
  background-color: #d9d9d9;
}
.assignment_submit_modal .assignmentList_div h4 {
  color: #757575d9;
  margin-top: 10px;
}
.assignment_submit_modal .assignmentList_div .assignment_list_div span {
  color: #6e6d7a;
  text-align: justify;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.assignment_submit_modal .assignmentList_div {
  margin-bottom: 30px;
}

.assignment_submit_modal .assignmentList_div .assignment_list_div svg {
  font-size: 25px;
  cursor: pointer;
  fill: #ff0000;
}

.assignment_submit_modal .assignment_submit_modal_heading {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.assignment_submit_modal .assignment_submit_modal_close {
  fill: #757575d9;
  font-size: 33px;
  cursor: pointer;
}

.assignment_submit_modal .assignment_submit_modal_body .dropzone {
  border: 2px dashed #8c8c8c;
  border-radius: 4px;
  text-align: center;
  margin: 12px 0px;
  padding: 30px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 0px;
}

.assignment_resourses_container .assignment_resourses_notes {
  /* display: flex; */
  /* justify-content: end; */
  /* margin-top: 28px; */
  position: fixed;
  bottom: 22%;
  right: 5%;
}
/* .assignment_resourses_notes .assignment_resourses_notes_disable{
  transform: translateY(-400px);
  transition: all 0.5s ease-in-out;
} */
.assignment_resourses_notes .assignment_resourses_notes_div {
  padding: 12px 18px;
  /* transform: translateY(0px); */
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(140, 140, 140, 0.23);
  width: 400px;
}

.assignment_resourses_notes .assignment_resourses_notes_div h6 {
  color: #024c87;
  font-family: "DM Sans";
  font-size: 15.233px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 22.849px */
}
.assignment_resourses_notes .assignment_resourses_notes_div p {
  color: #7d8185;
  font-family: "DM Sans";
  font-size: 15.233px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 22.849px */
}

.assignment_resourses_container .assignment_resourses_remark {
  margin-top: 35px;
  margin-bottom: 14px;
}

.assignment_resourses_container .assignment_resourses_remark button {
  color: #fff;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 144.5%; /* 23.12px */
  text-transform: capitalize;
  border-radius: 5px;
  padding: 8px 22px;
  background-color: #024c87;
  border: 1px solid #024c87;
}

.assignment_resourses_container
  .assignment_resourses_remark
  .assignment_resourses_remark_disable {
  background: #e3e3e3;
  border: 1px solid #e3e3e3;
}

.assignment_resourses_notes_div .assignment_resourses_notes_instructor {
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 10px;
  gap: 16px;
  align-items: center;
}
.assignment_resourses_notes_div .assignment_resourses_notes_instructor svg {
  fill: #6e6e6e;
  font-size: 28px;
}
.assignment_resourses_notes_div .assignment_resourses_notes_instructor span {
  color: #6e6e6e;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.assignment_submit_modal .assignmentList_div_container {
  padding-bottom: 10px;
  border-bottom: 2px solid #d9d9d9;
  margin-bottom: 30px;
}
.assignment_submit_modal .modal-header {
  border: none;
}
.assignment_submit_modal .assignment_submit_modal_body {
  padding: 10px 18px;
}

.assignment_submit_modal_body .dropzone span {
  color: #fff;
  display: inline-block;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 37px;
  background: #6794b7;
  padding: 8px 22px;
  border: 1px solid #6794b7;
  margin-bottom: 8px;
}
.assignment_submit_modal_body .assignment_submit_modal_inp {
  border-radius: 4px;
  border: 1px solid #b3b3b3;
  margin-top: 10px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
}

.assignment_submit_modal_body .assignment_submit_modal_btn {
  margin-top: 35px;
  display: flex;
  justify-content: center;
}
.assignment_submit_modal_body .assignment_submit_modal_btn button {
  border-radius: 55px;
  background: #5fbe00;
  padding: 8px 14px;
  color: #fff;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  border: 1px solid #5fbe00;
}
.assignment_submit_modal_body .assignment_submit_modal_inp input {
  width: 100%;
  padding: 8px;
  border: none;
  outline: none;
}
.assignment_submit_modal_body .assignment_submit_modal_inp svg {
  fill: #6794b7;
  font-size: 22px;
}
.assignment_submit_modal_body .dropzone p {
  margin: 0;
  color: #999;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.icon_scale {
  scale: 1.7;
  fill: red;
}

.course_batches_select {
  margin-top: 10px;
  padding: 10px;
  background: #fefefe;
  border-radius: 6px;
}
.course_batches_select .batche_list {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px dashed #6e6d7a;
}
.course_batches_select .batche_list:last-child {
  border: none;
}

.course_batches_select .batche_list h5 {
  color: #6e6d7a;
  font-family: DM Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  width: 70%;
}

.course_batches_select .batche_list svg {
  fill: #00237c;
  fill: var(--primary-blue);
  cursor: pointer;
  scale: 0.8;
}

.dashboard_module_wrapper,
.dashboard_assignment_wrapper {
  /* background-color: #fff7f0; */
  padding-right: 0;
  background-color: var(--bg-gray);
}

.module_video_placeholder {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.module_video_placeholder img {
  height: 400px;
  width: 400px;
}

/*********************************************************** Login Page ******************************************************/

.auth_root_container {
  padding: 25px 50px 20px 50px;
  /* padding-top: 32px; */
  /* padding-bottom: 20px; */
  background-color: #f5f5f5;
  border-top: 1px solid #c9c9c9;
  position: fixed;
  height: 100vh;
  width: 100%;
}

.auth_root_container .auth_img_wrapper {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.auth_root_container .auth_img_wrapper img {
  object-fit: cover;
  height: 543px;
}

.auth_root_container .auth_root_row {
  height: 80%;
}

.login_form_container {
  background: #fff;
  box-shadow: 0px 0px 12.3px 2px rgba(0, 0, 0, 0.25);
  padding: 14px 30px;
  padding-bottom: 30px;
}

.login_form_container .login_form_heading h1 {
  color: #000;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
}

.login_form_container .login_form_heading h3 {
  color: #989ea6;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2px;
}

.login_form_container .login_div_form {
  margin-top: 35px;
}

.login_form_container .login_div_form .login_form_inp {
  position: relative;
}
.login_form_container .login_div_form .login_form_inp label {
  color: #000;
  font-family: DM Sans;
  width: 100%;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
.login_form_container .login_div_form .login_form_inp svg {
  fill: gray;
  font-size: 20px;
  position: absolute;
  right: 5%;
  top: 52%;
}
.login_form_container .login_div_form .login_form_inp input {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #989ea6;
  padding: 16px 12px;
  outline: none;
}

.login_form_container .login_div_form .login_form_inp {
  margin-bottom: 10px;
}

.login_form_container .login_div_form .login_form_submit {
  margin-top: 48px;
  margin-top: 167px;
}

.login_form_container .login_div_form .login_form_submit button {
  width: 100%;
  border-radius: 10px;
  background: #ff9a28;
  border: none;
  padding: 14px 20px;
  text-align: center;
  color: #fff;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}

.login_div_form .login_form_signup_with {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin: 20px 0px;
}

.login_div_form .login_form_signup_with span {
  background: #d9d9d9;
  height: 2px;
  display: inline-block;
  width: 50%;
}

.login_div_form .login_form_signup_with h5 {
  color: #d9d9d9;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.8px;
  width: 60%;
  margin: 0;
  text-align: center;
}

.login_form_container .login_form_google button {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #e8837e;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.login_form_container .login_form_google button img {
  width: 33px;
  height: 33px;
}

.login_form_container .login_form_google button span {
  color: #ed5e55;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 2.4px;
}

.login_form_container .login_form_inp_label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login_form_container .login_form_inp_label a {
  color: #7054e6;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2px;
  text-decoration-line: underline;
}

.forgot_form_container .login_div_form .forgot_form_illustration img {
  height: 151px;
  width: 151px;
}

.forgot_form_container .login_div_form .forgot_form_illustration {
  text-align: center;
  margin: 29px 0px;
}

.forgot_form_container .login_div_form .forgot_form_submit {
  margin: 0;
}

.newpass_form_container .login_div_form .newpass_form_submit {
  margin-top: 118px;
}

.otp_form_container .otp_form_div .otp_inp {
  color: #000;
  padding: 0 !important;
  height: 44px;
  margin: 0px 6px;
  margin-top: 10px;
  width: 44px !important;
}

.otp_form_container .otp_form_div .otp_form_resend span {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}
.otp_form_container .otp_form_div .otp_form_resend button {
  border: none;
  background-color: transparent;
  font-size: 16px;
  text-decoration: underline;
}

.otp_form_container .otp_form_div .otp_form_resend {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 60%;
  gap: 10px;
}

/***************************************  Enroll Page Step 4 Css ********************************************/

.enroll_stepfour_form_submit {
  display: flex;
  justify-content: end;
}

/*************************************** Dashboard Mobile Header **********************************************************/

.dashboard_mobilenav_container {
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}

.dashboard_mobilenav_container img {
  height: 20%;
  width: 27%;
}
.dashboard_mobilenav_container svg {
  font-size: 30px;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

/****************************************************** Brochure Modal Program Detail Page ************************************/

.brochure_modal .modal-body,
.brochure_modal .modal-header {
  background: #fff;
}

.brochure_modal .brochure_modal_div {
  width: 100%;
  padding: 8px 16px;
}
.brochure_modal_div .brochure_modal_form .brochure_form_inp input , .brochure_modal_div .brochure_modal_form .brochure_form_inp select  {
  background-color: #eeeeee;
  padding: 12px;
  width: 100%;
  border: none;
  outline: none;
}

.brochure_modal .modal-dialog {
  max-width: 630px;
}

.brochure_modal_div .brochure_modal_form .brochure_form_inp label {
  color: #000;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 8px;
}

.brochure_modal_div .brochure_modal_form .brochure_form_inp {
  margin-bottom: 13px;
}

.brochure_modal_div .brochure_modal_form .brochure_form_submit {
  margin-top: 30px;
}
.brochure_modal_div .brochure_modal_form .brochure_form_submit button {
  width: 100%;
  background-color: #0077b5;
  padding: 8px 22px;
  border: none;
  border-radius: 4px;
  color: #fff;
}

.brochure_modal_div
  .brochure_modal_form
  .brochure_form_inp
  .brochure_form_phone {
  display: flex;
}

.brochure_form_inp .brochure_form_phone .form_phone_input {
  padding: 13px 5px 9px 56px !important;
}

.brochure_form_inp .brochure_form_phone .react-tel-input {
  width: 28%;
}

.brochure_form_inp .brochure_form_phone .react-tel-input span {
  color: #000;
}

.brochure_form_inp .react-tel-input .selected-flag .flag {
  margin-top: -9px;
}

/********************************************************** Course Page Resources tab **********************************************/

.study_material_container .study_material_item a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  background: #fff;
  position: relative;
  margin-bottom: 14px;
}

.study_material_container .study_material_item a span {
  color: #6e6d7a;
  text-align: justify;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.study_material_container .study_material_item a svg {
  fill: #6e6d7a;
  font-size: 18px;
}

.study_material_container .study_material_item a img {
  height: 35px;
  width: 35px;
}

.study_material_container .study_material_item a:hover {
  color: #024c87;
}

/**************************************************** Enroll Page CSS **********************************************************/
.enroll_steps_container {
  margin: 30px 0px;
}
.enroll_stepone_container {
  position: relative;
}

.enroll_steps_container .enroll_step_card {
  border-radius: 2px;
  background: #fff;
  padding: 72px 142px;
  box-shadow: 0px 1px 29.9px -5px #d9d9d9;
  position: relative;
}
.enroll_steps_container .enroll_step_progress {
  position: absolute;
  height: 4px;
  left: 0;
  top: 0;
  background: #18e013;
  width: 100%;
}

.enroll_stepone_container .enroll_stepone_info .enroll_stepone_heading span {
  color: #1c1e24;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.enroll_stepone_container .enroll_stepone_info .enroll_stepone_heading h2 {
  color: #1c1e24;
  font-family: "DM Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.enroll_stepone_container .enroll_stepone_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.enroll_stepone_container .enroll_stepone_btn {
  display: flex;
  gap: 10px;
}
.enroll_stepone_container .enroll_stepone_btn .enroll_stepone_back {
  border-radius: 5px;
  border: 1.423px solid #024c87;
  padding: 4px 12px;
  color: #024c87;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.enroll_stepone_container .enroll_stepone_btn .enroll_stepone_next {
  border-radius: 5px;
  border: 1.423px solid #024c87;
  background: #024c87;
  padding: 4px 12px;
  color: #fff;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.enroll_stepone_container .enroll_stepone_right h2 {
  color: #1c1e24;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.enroll_stepone_container .enroll_stepone_right h4 {
  color: #575757;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.enroll_stepone_container .enroll_stepone_inps label,
.enroll_steptwo_container .enroll_steptwo_contact_div label {
  color: #575757;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  margin-bottom: 4px;
}

.enroll_stepone_container .enroll_stepone_inps select,
.enroll_stepone_container .enroll_stepone_inps input,
.enroll_steptwo_container .enroll_steptwo_contact {
  border-radius: 5px;
  border-top: 1px solid #024c87;
  border-right: 1px solid #024c87;
  border-bottom: 1px solid #024c87;
  border-left: 16px solid #024c87;
  background: #fff;
  width: 100%;
  padding: 10px 16px;
  outline: none;
  width: 80%;
  font-size: 16px;
}

.enroll_steptwo_container .enroll_steptwo_contact {
  padding: 0;
}

.enroll_steptwo_container .enroll_steptwo_contact_div {
  margin-top: 18px;
}

.form_enroll_phone_input {
  width: 20%;
}

.enroll_stepone_container .enroll_stepone_inps {
  margin-top: 18px;
  position: relative;
}

.enroll_stepone_container .enroll_stepone_form {
  margin-top: 28px;
}

.enroll_stepone_left .enroll_stepone_left_img {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 21px;
}

.enroll_stepone_container .enroll_stepone_left {
  height: 100%;
  width: 100%;
  padding: 0px 14px;
}

.enroll_steptwo_container .enroll_steptwo_contact input {
  outline: none;
  border: none;
  font-size: 16px;
  padding-top: 3px;
}

.enroll_steptwo_container .form_phone_input_enroll {
  padding: 13px 5px 9px 56px !important;
  width: 100%;
  border: none;
  outline: none;
}

.enroll_steptwo_container .react-tel-input {
  width: 28% !important;
}

.enroll_steptwo_container .react-tel-input span {
  color: #000;
}

.enroll_steptwo_container .react-tel-input .selected-flag .flag {
  margin-top: -9px;
}

.enroll_steptwo_container .enroll_steptwo_checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 42px;
}
.enroll_steptwo_container .enroll_steptwo_checkbox input {
  scale: 1.5;
}
.enroll_steptwo_container .enroll_steptwo_checkbox label {
  color: #1f1f1f;
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.enroll_steptwo_container .enroll_steptwo_checkbox label a {
  color: #17a1fa;
}

.enroll_stepthree_detail .enroll_stepthree_detail_content h2 {
  color: #1f1f1f;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.enroll_stepthree_detail .enroll_stepthree_detail_content h4 {
  color: #8c8c8c;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.enroll_stepthree_detail .enroll_stepthree_detail_img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
}

.enroll_stepthree_container .enroll_stepthree_price {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  color: #1f1f1f;
  font-family: "DM Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 100%;
  width: 70%;
  float: right;
  padding-bottom: 2px;
}

.enroll_stepthree_price .enroll_stepthree_price_div {
  display: flex;
  /* align-items: center; */
  gap: 8px;
  flex-direction: column;
}

.enroll_stepthree_price_div .enroll_stepthree_price_div_flex {
  display: flex;
  /* justify-content: space-between; */
  gap: 4px ;
}

.enroll_stepthree_price .enroll_stepthree_price_div  span {
  font-weight:400;
  font-size: 18px ;
}

.enroll_stepthree_container .enroll_stepthree_divider {
  background: #d9d9d9;
  height: 2px;
  margin: 42px 0px;
}

.enroll_stepthree_container
  .enroll_stepthree_pricedetails
  .enroll_stepthree_price_label {
  color: #1f1f1f;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  padding: 8px 22px;
  border-bottom: 2px solid #d9d9d9;
  margin-top: 2px;
}

.enroll_stepthree_container .enroll_stepthree_price_total {
  border-radius: 5px;
  background: #d9d9d9;
  padding: 8px 22px;
  margin-top: 30px;
  margin-bottom: 22px;
  display: flex;
  justify-content: space-between;
  color: #1f1f1f;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.enroll_stepthree_container .enroll_stepthree_priceinfo {
  border-radius: 6px;
  padding: 8px 22px;
  background: #fff;
  /* box-shadow: 0px 0px 19.352px 6.961px rgba(217, 217, 217, 0.35); */
}

.enroll_stepthree_container .enroll_stepthree_refrell {
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: end;
  padding-bottom: 22px;
}

.enroll_stepthree_container .enroll_stepthree_refrell input {
  padding: 4px;
  border: none;
  position: relative;
  outline: none;

  border-bottom: 2px dashed #8c8c8c;
}

.enroll_stepthree_container .enroll_stepthree_refrell button {
  padding: 4px 12px;
  border-radius: 5px;
  color: #575757;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  background-color: #d9d9d9;
  border: 1px solid #d9d9d9;
}

.enroll_stepthree_container .enroll_stepthree_refrell .btn-apply {
  background-color: #00ae00;
  border: 1px solid #00ae00;
  color: #fff;
}

.enroll_stepthree_container .enroll_stepthree_refrell .btn-apply-red {
  background-color: red;
  border: 1px solid red;
  color: #fff;
}
.enroll_stepfour_container .enroll_stepfour_submit {
  margin-top: 30px;
}

.enroll_stepfour_container .enroll_stepfour_submit button {
  border-radius: 5px;
  background: #024c87;
  color: #fff;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  padding: 10px 22px;
  border: 1px solid;
  width: 80%;
}
.homecount_container {
  margin-bottom: 80px;
}
.homecount_container .homecount_heading {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
}

.homecount_container .homecount_heading h2 {
  color: #010514;
  text-align: center;
  font-family: "DM Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 62.4px */
}
.homecount_container .homecount_heading p {
  color: rgba(1, 5, 20, 0.8);
  text-align: center;
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
  max-width: 60%;
  margin-top: 12px;
}

.homecount_container .homecount_number {
  background: linear-gradient(90deg, #136cb5 0%, #49bbbd 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-family: "DM Sans";
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 96px */
}

.homecount_container h6 {
  color: rgba(1, 5, 20, 0.8);
  font-family: "DM Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 41.6px */
}

.homeprogram_container {
  background-color: #e6eeee;
  padding: 60px 0px;
}
.homeprogram_container .homeprogram_heading h2 {
  text-align: center;
  color: #1f1f1f;
  font-family: DM Sans;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 40px;
}

.enroll_stepfour_container .enroll_stepfour_right .enroll_stepfour_heading h4 {
  color: #8c8c8c;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.enroll_stepfour_container .enroll_stepfour_heading h4 span {
  color: #17a1fa;
}

.enroll_stepfour_container .enroll_stepfour_left img {
  width: 80%;
}

.enroll_stepfour_container .enroll_stepone_inps svg {
  color: #024c87;
  font-size: 26px;
  position: absolute;
  right: 23%;
  top: 55%;
  cursor: pointer;
}

.enroll_program_added_div .enroll_program_added_img {
  display: flex;
  justify-content: center;
}
.enroll_program_added_div .enroll_program_added_img img {
  width: 40%;
}
.enroll_program_added_div .enroll_program_added_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.enroll_program_added_div .enroll_program_added_text a {
  color: #024c87;
  font-family: "DM Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 24px;
}

.enroll_program_added_div .enroll_program_added_text h4 {
  color: #8c8c8c;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.enroll_program_added_div .enroll_program_added_text button {
  border-radius: 5px;
  background: #024c87;
  color: #fff;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  padding: 8px 12px;
  border: 1px solid;
  margin-top: 20px;
}

.enroll_stepone_container .enroll_stepone_inps select {
  appearance: none;
  -webkit-appearance: none;
  background-image: url("../images/drop-down-arrow.png");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: right 1rem center;
}

.payment_modal_container .payment_modal_heading h1 {
  color: #024c87;
  font-family: "DM Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
}
.payment_modal_container .payment_modal_heading svg {
  color: gray;
  font-size: 22px;
  cursor: pointer;
}

.payment_modal_container .payment_modal_heading h2 {
  color: #1f1f1f;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 85%;
  margin: 14px 0px;
}

.payment_modal_container .payment_modal_heading h4 {
  color: #8c8c8c;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.payment_modal_container .payment_modal_options span {
  color: #1f1f1f;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 30px;
}

.payment_modal_container .payment_modal_options button {
  border-radius: 5px;
  background: #024c87;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none;
  padding: 10px 22px;
  margin: 14px 0px;
}

.payment_modal_container .payment_modal_options button img {
  width: 4%;
}

.payment_modal_container .payment_modal_options button h6 {
  color: #fff;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.payment_modal_container .payment_modal_notes h6 {
  color: #b20000;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
  margin-top: 8px;
}
.payment_modal_container .payment_modal_img {
  text-align: center;
}

.payment_modal_container .payment_modal_img img {
  width: 80%;
  margin: 10px 0px;
}
.payment_modal .modal-dialog {
  max-width: 552px;
}

.payment_modal .payment_modal_container {
  padding: 0px 15px;
}

/***************************************************** master class page css  ************************************************************/

.masterclass_banner_container {
  background: #024c87;
  padding: 120px 0px;
  padding-bottom: 170px;
  position: relative;
}

.masterclass_banner_container::after {
  background-image: url("../images/dots_img.png");
  position: absolute;
  content: "";
  left: 3%;
  top: 5%;
  width: 134px;
  height: 216px;
  background-size: cover;
  background-repeat: no-repeat;
}

.masterclass_banner_container::before {
  background-image: url("../images/dots_img.png");
  position: absolute;
  content: "";
  right: 7%;
  bottom: 8%;
  width: 134px;
  height: 216px;
  background-size: cover;
  background-repeat: no-repeat;
}
.masterclass_banner_container .masterclass_banner_left {
  width: 90%;
}

.masterclass_banner_container .masterclass_banner_left h1 {
  color: #fff;
  font-family: "DM Sans";
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  background: #024c87;
  position: relative;
  z-index: 88;
}

.masterclass_banner_container .masterclass_banner_left h4 {
  color: #fff;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8;
}

.masterclass_banner_container .masterclass_banner_right ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

.masterclass_banner_container .masterclass_banner_right ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  gap: 12px;
}

.masterclass_banner_container .masterclass_banner_right ul li span {
  color: #fff;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #024c87;
  position: relative;
  z-index: 88;
}

.masterclass_register_container .masterclass_register_right {
  border-radius: 22px;
  background: #fff;
  box-shadow: 0px 0px 138.9px 0px rgba(0, 0, 0, 0.12);
  padding: 24px 32px;
}

.masterclass_register_right .masterclass_register_right_heading h2 {
  color: #424242;
  font-family: "DM Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}

.masterclass_register_right
  .enroll_steptwo_container
  .enroll_stepone_inps
  input,
.masterclass_register_right
  .enroll_steptwo_container
  .enroll_stepone_inps
  select,
.masterclass_register_right .enroll_steptwo_container .enroll_steptwo_contact {
  width: 100%;
}

.masterclass_register_right .masterclass_form_btn {
  text-align: center;
  margin-top: 50px;
}

.masterclass_modal_body .masterclass_form_note h6 {
  font-size: 12px;
  margin-top: 26px;
  color: gray;
}

.masterclass_register_right .masterclass_form_btn button {
  border-radius: 5px;
  background: #024c87;
  border: 1px solid #024c87;
  color: #fff;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  padding: 8px 22px;
  width: 70%;
}

.masterclass_register_container .masterclass_register_left {
  height: 80%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 11px 0px 0px 11px;
}

.masterclass_register_container .masterclass_register_div {
  position: relative;
  height: 95vh;
  margin-bottom: 48px;
}

.masterclass_register_container .masterclass_register_row {
  position: absolute;
  top: -124px;
}

.masterclass_feature_container {
  padding-bottom: 68px;
  position: relative;
  z-index: 14;
}

.masterclass_feature_container::after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  height: 100%;
  width: 30%;
  background-image: url("../images/msterclass_img_1.png");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
}

.masterclass_feature_container .masterclass_feature_heading h1 {
  color: #1c1e24;
  text-align: center;
  font-family: "DM Sans";
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 94.1%; /* 60.224px */
}

.masterclass_feature_container .masterclass_feature_heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
}

.masterclass_feature_container .masterclass_feature_heading h2 {
  color: #8a8a8a;
  text-align: center;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 172.8%; /* 34.56px */
  text-transform: capitalize;
  width: 70%;
}

.masterclass_feature_container
  .masterclass_feature_options
  .masterclass_feature_list {
  background: #b5c6ff;
  position: relative;
  z-index: 9;
}

.masterclass_feature_options .masterclass_feature_list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
  margin-top: 45px;
}

.masterclass_feature_options .masterclass_feature_list ul li {
  color: #575757;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.masterclass_feature_options .masterclass_feature_list ul .active {
  color: #07227a;
  border-bottom: 4px solid #07227a;
  font-weight: 700;
}

.masterclass_feature_options .masterclass_feature_detail {
  padding: 40px 80px;
}

.masterclass_feature_detail .masterclass_feature_img {
  background-size: cover;
  background-repeat: no-repeat;
  height: 220px;
  width: 100%;
  border-radius: 11px;
}

.masterclass_feature_detail .masterclass_feature_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 80%;
  padding-left: 28px;
}

.masterclass_feature_detail .masterclass_feature_info h3 {
  color: #000;
  font-family: "DM Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 94.1%; /* 30.112px */
  margin-bottom: 16px;
}

.masterclass_feature_detail .masterclass_feature_info span {
  color: #8c8c8c;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  position: relative;
  z-index: 14;
  line-height: normal;
}
.masterclass_feature_detail .masterclass_feature_info ul {
  margin: 0;
  padding: 0;
  list-style: none;
  /* display: flex; */
  /* align-items: center; */
  /* row-gap: 32px ; */
  margin-top: 20px;
  /* column-gap: 55px ; */
  /* flex-wrap: wrap; */
  z-index: 14;
}
.masterclass_feature_detail .masterclass_feature_info ul li {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 20px;
}
.masterclass_feature_detail .masterclass_feature_info ul li span {
  color: #000;
  font-size: 18px;
}
.masterclass_feature_detail .masterclass_feature_info ul li svg path {
  fill: #024c87;
}
.masterclass_feature_detail .masterclass_feature_info img {
  z-index: 14;
  margin-top: 15px;
}
.masterclass_instructor_container {
  padding-bottom: 68px;
  position: relative;
}

.masterclass_instructor_container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../images/masterclass_img_2.png");
  height: 63%;
  width: 31%;
}

.masterclass_instructor_container .masterclass_instructor_left h1 {
  color: #07227a;
  font-family: "DM Sans";
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 94.1%; /* 60.224px */
}
.masterclass_instructor_container .masterclass_instructor_left h2 {
  color: #1f1f1f;
  font-family: "DM Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 94.1%; /* 30.112px */
  margin-top: 48px;
}

.masterclass_instructor_container .masterclass_instructor_left p {
  color: #575757;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 85%;
  position: relative;
  z-index: 8;
}

.masterclass_instructor_container .masterclass_instructor_right {
  display: flex;
  align-items: end;
  height: 100%;
  width: 100%;
  justify-content: end;
}

.masterclass_instructor_container .masterclass_instructor_right img {
  width: 80%;
}

.masterclass_journey_container {
  background: #024c87;
  padding: 110px 0px;
  position: relative;
}
.masterclass_journey_container::after {
  content: "";
  position: absolute;
  right: 4%;
  top: 0;
  width: 35%;
  height: 100%;
  background-image: url("../images/masterclass_img_3.png");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
}

.masterclass_journey_container .masterclass_journey_left span {
  color: #fff;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 200% */
}

.masterclass_journey_container .masterclass_journey_left h1 {
  color: #fff;
  font-family: "DM Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px; /* 116.667% */
  text-transform: capitalize;
}

.masterclass_journey_container .masterclass_journey_right p {
  color: #fff;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px; /* 168.75% */
}

.masterclass_journey_container .masterclass_journey_right a {
  color: #fff;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  text-decoration-line: underline;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 8px;
}

.masterclass_journey_container .masterclass_journey_right {
  width: 71%;
  padding-left: 62px;
  position: relative;
  z-index: 9;
}

.masterclass_certificate_container {
  margin-top: 52px;
}

.masterclass_certificate_container .masterclass_certificate_right h1 {
  color: #1f1f1f;
  font-family: "DM Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 94.1%; /* 45.168px */
  margin-bottom: 30px;
}

.masterclass_certificate_container .masterclass_certificate_right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-left: 62px;
  width: 85%;
}

.masterclass_certificate_container .masterclass_certificate_right h4 {
  color: #1f1f1f;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  gap: 14px;
  align-items: center;
}

.masterclass_certificate_container .masterclass_certificate_right h4 svg path {
  fill: #1f1f1f;
}

.masterclass_certificate_container .masterclass_certificate_left {
  width: 88%;
  height: 516px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.masterclass_certificate_container .masterclass_certificate_left p {
  position: absolute;
  bottom: 19%;
  padding: 0px 18px;
  text-align: center;
  font-size: 10px;
  color: #000;
}
.masterclass_faq_list .masterclass_faq_accordian {
}
/* masterclass_faq_header */

.masterclass_faq_items .masterclass_faq_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.masterclass_faq_accordian .accordion-button::after {
  content: none;
}

.masterclass_faq_accordian .accordion-button {
  border-radius: 10px;
}
.masterclass_faq_accordian .masterclass_faq_items {
  margin-top: 30px;
  border-radius: 5px;
  border: 2px solid #8c8c8c;
  background: #fff;
}

.masterclass_faq_accordian .masterclass_faq_items_active {
  border: 2px solid #024c87;
}

.masterclass_faq_accordian .masterclass_faq_items h3 {
  color: #1f1f1f;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 129.3%; /* 20.688px */
}

.masterclass_faq_accordian .accordion-button:not(.collapsed) {
  box-shadow: none;
  background-color: transparent;
}
.masterclass_faq_accordian .accordion-button:focus {
  box-shadow: none;
  border-color: transparent;
}

.masterclass_faq_accordian .masterclass_faq_body p {
  color: #1f1f1f;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.masterclass_faq_container {
  margin-top: 62px;
  margin-bottom: 42px;
}

.masterclass_faq_container .masterclass_faq_heading h1 {
  color: #424242;
  font-family: "DM Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 94.1%; /* 45.168px */
}

.masterclass_faq_container .masterclass_faq_heading h4 {
  color: #8a8a8a;
  text-align: center;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 34px */
  width: 70%;
}

.masterclass_faq_container .masterclass_faq_heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
}

.masterclass_feature_options .masterclass_feature_list svg {
  display: none;
}

.masterclass_modal_body .masterclass_modal_body_heading h2 {
  font-size: 22px;
  color: #000;
  margin: 0;
}
.masterclass_modal_body .masterclass_modal_body_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.masterclass_modal_body .masterclass_modal_body_heading svg {
  color: #000;
  font-size: 22px;
  cursor: pointer;
}
.masterclass_modal_body .masterclass_form_btn {
  text-align: center;
  margin-top: 32px;
}
.masterclass_modal_body .masterclass_form_btn button {
  border-radius: 5px;
  background: #024c87;
  border: 1px solid #024c87;
  color: #fff;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  padding: 8px 22px;
  width: 70%;
}

.masterclass_modal_body .enroll_stepone_container .enroll_stepone_inps input {
  width: 100%;
}

.masterclass_modal_body {
  padding: 16px;
}

.masterclass_modal_body .enroll_stepone_inps svg {
  position: absolute;
  right: 3%;
  top: 55%;
  fill: #00237c;
  font-size: 22px;
  cursor: pointer;
}

.work_confirm_modal_body {
  position: relative;
  padding: 16px;
  text-align: center;
}
.work_confirm_modal_body h2 {
  font-size: 22px;
  color: #000;
  margin-top: 22px;
  text-align: center;
}
.work_confirm_modal_body svg {
  fill: #000;
  font-size: 22px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.work_confirm_modal_body button {
  margin-top: 22px;
  width: 100%;
  background-color: #00237c;
  color: #fff;
  border: none;
  padding: 8px 22px;
  border-radius: 6px;
  font-weight: bold;
  font-size: 16px;
}
.work_confirm_modal_body img {
  width: 60%;
}

.confirm_modal_blur {
  opacity: 1 !important;
  background-image: url("../images/work_blur.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.countdown_div_course {
  display: flex;
  align-items: center;
  gap: 12px;
}

.countdown_div_course .count_down_txt {
  display: flex;
  align-items: center;
}

.countdown_div_course .count_down_txt h6 {
  color: #00237c;
}

/***************************************** Dashboard Home Page 2 Css **********************************************************/
.dashboard_new_header_container {
  padding: 10px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard_new_header_container .dashboard_new_header_left h2 {
  color: #8c8c8c;
  font-family: "DM Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 144.5%; /* 46.24px */
  text-transform: capitalize;
  margin-bottom: 8px;
}

.dashboard_new_header_container .dashboard_new_header_left h3 {
  color: #8c8c8c;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 144.5%; /* 28.9px */
  text-transform: capitalize;
}

.dashboard_new_header_container .dashboard_new_header_right {
  display: flex;
  align-items: center;
  gap: 18px;
}

.dashboard_new_header_container .dashboard_new_header_inp {
  border-radius: 5px;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0px 8px;
}

.dashboard_new_header_right .dashboard_new_header_inp input {
  background-color: transparent;
  outline: none;
  border: none;
  padding: 8px;
}
.dashboard_new_header_right .dashboard_new_header_inp svg {
  fill: #8c8c8c;
  font-size: 18px;
}
.dashboard_new_header_right .dashboard_new_header_info img {
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 50%;
}

.dashboard_new_header_right .dashboard_new_header_info svg {
  fill: #024c87;
  color: #024c87;
  font-size: 22px;
}

.dashboard_new_header_right .dashboard_new_header_info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.dashboard_new_header_info .dashboard_new_header_notify {
  position: relative;
  cursor: pointer;
}

.dashboard_new_header_info .dashboard_new_header_notify.active::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background-color: red;
  right: 22%;
  top: 1%;
  border-radius: 50%;
}

.dashboard_new_header_info .dashboard_new_header_notify svg {
  font-size: 26px;
}

.dashboard_new_header_info .dashboard_new_header_name {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}
.dashboard_new_header_name .dashboard_new_header_text h5 {
  color: #979797;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  text-transform: capitalize;
  margin: 0;
  font-weight: 500;
}

.dashboard_new_header_name .dashboard_new_header_text h4 {
  color: #242220;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  text-transform: capitalize;
  margin: 0;
  font-weight: 500;
}

.dashboard_new_header_name .dashboard_new_header_icon svg {
  cursor: pointer;
}

.dashboard_new_header_options .dashboard_new_header_option_label {
  display: flex;
  width: 250px;
  height: 50px;
  padding: 10px;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.dashboard_new_header_options .dashboard_new_header_option_label label {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
  width: 100%;
}

.dashboard_new_header_options .dashboard_new_header_option_label:last-child {
  background-color: #ecf4ff;
}

.dashboard_new_header_name .dashboard_new_header_options {
  position: absolute;
  top: 54px;
  left: -102px;
  box-shadow: 0px 0px 4px 0px #ebebeb;
  background-color: white;
  z-index: 99;
}

.dashboard_newcourse_container {
  padding: 0px 24px;
  margin-top: 16px;
}

.course_new_carousel_item {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: end;
  margin-left: 10px;
}

.course_new_carousel_item .course_new_carousel_item_img {
  width: 120px;
  height: 112px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
  position: absolute;
  left: 0px;
  top: 9px;
}

.course_new_carousel_item .course_new_carousel_item_text {
  box-shadow: 0px 0px 8px #d9d9d9;
  background-color: #fff;
  padding: 10px 20px;
  width: 260px;
  padding-left: 79px;
  border-radius: 6px;
}

.course_new_carousel_item_recommended .course_new_carousel_item_img span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #024c87;
  color: #fff;
  text-align: center;
  border-radius: 8px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.course_new_carousel_item .course_new_carousel_item_text h3 {
  color: #8c8c8c;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.course_new_carousel_item .course_new_carousel_item_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.course_new_carousel_item .course_new_carousel_item_info img {
  height: 42px;
  width: 42px;
  object-fit: cover;
  border-radius: 50%;
}

.course_new_carousel_item .course_new_carousel_item_info button {
  color: #024c87;
  text-align: center;
  font-family: "DM Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-color: transparent;
  border: none;
  text-decoration-line: underline;
}

.course_new_carousel_item_recommended .course_new_carousel_item_info button {
  border-radius: 5px;
  background: #024c87;
  color: #fff;
  padding: 8px 12px;
  font-size: 14px;
  text-decoration: none;
}

.course_new_carousel_item_active .course_new_carousel_item_text {
  border: 2px solid #024c87;
}

.dashboard_new_row {
  padding: 10px 24px;
  margin-top: 24px;
}

.dashboard_schedule_container {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px #d9d9d9;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.dashboard_schedule_container .dashboard_schedule_img {
  width: 297.041px;
  height: 215.671px;
  border-radius: 7px;
  background: url("../images/live_img.jpg");
  background-size: cover;
  position: relative;
}

.dashboard_schedule_container .dashboard_schedule_img .live_span {
  border-radius: 6px;
  background: #d80101;
  padding: 6px 18px;
  color: #fff;
  text-align: center;
  font-family: "DM Sans";
  font-size: 20.311px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  position: absolute;
  bottom: 0;
  right: 0;
}
.dashboard_schedule_info .dashboard_schedule_desc h4 {
  color: #0d2a41;
  font-family: "DM Sans";
  font-size: 20.311px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30.466px */
  text-transform: capitalize;
}

.dashboard_schedule_info .dashboard_schedule_desc .scrollbar-template {
  color: #7d8185;
  font-family: "DM Sans";
  font-size: 15.233px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 22.849px */
  height: 142px;
  overflow-y: auto;
}

.dashboard_schedule_info {
  width: 55%;
}

.dashboard_schedule_info .dashboard_schedule_btn {
  display: flex;
  justify-content: end;
  margin-top: 12px;
  width: 100%;
}

.dashboard_schedule_info .dashboard_schedule_btn button {
  border-radius: 7px;
  border: 1px solid #576bad;
  color: #576bad;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding: 6px 16px;
  background-color: transparent;
  cursor: pointer;
  position: relative;
}

.dashboard_schedule_info .dashboard_schedule_btn button span {
  display: none;
}

.dashboard_schedule_info .dashboard_schedule_btn_active button {
  background: gray;
  border: 1px solid gray;
  color: #fff;
  /* opacity: 0.5; */
  cursor: not-allowed;
}
.dashboard_schedule_info .dashboard_schedule_btn_active:hover button span {
  display: inline-block;
  /* clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 39% 75%, 49% 100%, 57% 75%, 0% 75%); */
  padding: 20px 0px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  padding: 8px;
  z-index: 1;
  bottom: 142%;
  left: 30%;
  margin-left: -56px;
}

.dashboard_schedule_info .dashboard_schedule_btn_active button span text {
  background-color: black;
  clip-path: polygon(46% 100%, 0 0, 100% 0);
  display: inline-block;
  width: 20px;
  position: absolute;
  bottom: -13px;
  right: 45%;
  height: 20px;
}
.scrollbar-template {
  width: 98%;
}

.scrollbar-template::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.scrollbar-template::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 6px;
}

/* Handle */
.scrollbar-template::-webkit-scrollbar-thumb {
  background: var(--primary-blue);
  border-radius: 10px;
}

/* Handle on hover */
.scrollbar-template::-webkit-scrollbar-thumb:hover {
  background: var(--primary-blue);
}

.countdown_live_schedule {
  border-radius: 0px 0px 7px 7px;
  background: #024c87;
  position: absolute;
  bottom: 0;
  padding: 6px 12px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.countdown_live_schedule h6 {
  color: #fff;
  text-align: center;
  font-family: "DM Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 0;
}
.dashboard_new_work_container {
  margin-top: 18px;
}

.dashboard_new_work_container .dashboard_new_work_div {
  height: 330px;
  overflow-y: auto;
  padding: 0px 8px;
}
.dashboard_new_work_container .dashboard_new_work_heading h4 {
  color: #616161;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 144.5%; /* 34.68px */
  text-transform: capitalize;
}

.dashboard_new_work_container .dashboard_new_work_list {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px #d9d9d9;
  padding: 8px 22px;
  margin-bottom: 14px;
}

.dashboard_new_work_container .dashboard_new_work_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard_new_work_list .dashboard_new_work_info img {
  height: 55px;
  width: 55px;
}
.dashboard_new_work_list .dashboard_new_work_info {
  display: flex;
  align-items: center;
  gap: 18px;
}

.dashboard_new_work_list .dashboard_new_work_info h6 {
  color: #576bad;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 0;
}

.dashboard_new_work_container .dashboard_new_work_list a {
  color: #576bad;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  text-decoration-line: underline;
  text-transform: capitalize;
}

.dashboard_schedulelist_container {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px #d9d9d9;
  padding: 10px 18px;
}

.dashboard_schedulelist_container .dashboard_schedulelist_heading h4 {
  color: #1c1e24;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 16px;
}

.dashboard_schedulelist_container .dashboard_schedulelist_item {
  border-radius: 8px;
  background: #f6fafb;
  padding: 8px 18px;
  margin-bottom: 12px;
  position: relative;
}
.dashboard_schedulelist_container .dashboard_schedulelist_item::after {
  position: absolute;
  content: "";
  width: 6px;
  height: 100%;
  left: 0%;
  border-radius: 8px 0px 0px 8px;
  top: 0;
  background-color: #024c87;
}

.dashboard_schedulelist_item .dashboard_schedulelist_text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.dashboard_schedulelist_item .dashboard_schedulelist_text h4 {
  color: #1f1f1f;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dashboard_schedulelist_item .dashboard_schedulelist_text h5 {
  color: #024c87;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dashboard_schedulelist_container .dashboard_schedulelist_item span {
  color: #1f1f1f;
  text-align: right;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.5;
}

.dashboardnew_calender_container {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px #d9d9d9;
  padding: 20px 50px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.dashboard_newcourse_carousel .owl-nav .owl-prev button,
.dashboard_newcourse_carousel .owl-nav .owl-next button {
  border: 1px solid #024c87;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #024c87;
  border-radius: 50%;
}

.dashboard_newcourse_carousel .owl-nav .owl-prev:hover,
.dashboard_newcourse_carousel .owl-nav .owl-next:hover {
  background-color: transparent;
}

.dashboard_newcourse_carousel .owl-nav .owl-prev {
  position: absolute;
  top: 35%;
  left: -21px;
}

.dashboard_newcourse_carousel .owl-nav .owl-next {
  position: absolute;
  top: 35%;
  right: -21px;
}

.dashboard_newcourse_carousel .owl-nav .disabled {
  opacity: 0;
}

/******************************************** Remark Modal  CSS ************************************************/

.remark-modal-body .remark-modal-body-heading h2 {
  color: #576bad;
  text-align: center;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.remark-modal-body .remark-modal-body-heading {
  margin-bottom: 32px;
}

.remark-modal-body .remark-close-btn {
  font-size: 28px;
  fill: #757575d9;
  position: absolute;
  right: 3%;
  cursor: pointer;
}
.remark-modal-body .remark-modal-body-inp span {
  color: #757575d9;
  font-family: "DM Sans";
  font-size: 15.233px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 22.849px */
}
.remark-modal-body .remark-modal-body-remark {
  margin-bottom: 14px;
}

.remark-modal-body .remark-modal-body-remark h6 {
  color: #576bad;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.remark-modal-body .remark-modal-body-inp {
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding: 8px 16px;
}

.remark-modal-body .remark-modal-body-btn {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.remark-modal-body .remark-modal-body-btn button {
  border-radius: 55px;
  background: #5fbe00;
  padding: 8px 22px;
  color: #fff;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  border: 1px solid #5fbe00;
}

.homeprogram_card .homeprogram_card_img img {
  width: 100%;
  object-fit: cover;
  height: 244.03px;
  border-radius: 8px;
}

.homeprogram_card .homeprogram_card_rating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.homeprogram_card_content .homeprogram_card_info h2 {
  color: #1a2434;
  font-family: "DM Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 43.2px */
}

.homeprogram_card_content .homeprogram_card_list {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 10px;
}
.homeprogram_card_content .homeprogram_card_list img {
  height: 30px;
  width: 30px;
}

.homeprogram_card_content .homeprogram_card_list span {
  color: #8c8c8c;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.homeprogram_card_content .homeprogram_card_price span:first-child {
  color: #1f1f1f;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
  display: block;
  text-align: start;
}

.homeprogram_card_content .homeprogram_card_price span:last-child {
  color: #008000;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 24px */
  width: 100%;
}

.homeprogram_card_content .homeprogram_card_price span:last-child b {
  color: #8c8c8c;
  text-align: center;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 24px */
  text-decoration: line-through;
}

.homeprogram_card_content {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: start;
}

.homeprogram_card .homeprogram_card_rating button {
  border-radius: 5px;
  background: #024c87;
  padding: 8px 12px;
  color: #fff;
  font-family: "DM Sans";
  border: 1px solid #024c87;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.08px;
}

.homeprogram_card_rating .homeprogram_card_star {
  display: flex;
  align-items: center;
  gap: 10px;
}

.homeprogram_card .homeprogram_card_tool {
  margin-top: 20px;
}
.homeprogram_card {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(217, 217, 217, 0.5);
  padding: 20px;
}

.homeprogram_card_star span {
  color: #024c87;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  line-height: 120%; /* 19.2px */
  font-weight: 700;
}

.homeprogram_card .homeprogram_card_tool h2 {
  color: #1a2434;
  font-family: "DM Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 38.4px */
}

.home_program_carousel .owl-nav .owl-next,
.home_program_carousel .owl-nav .owl-prev {
  top: 36%;
}
.home_program_carousel .owl-nav .owl-prev button,
.home_program_carousel .owl-nav .owl-next button {
  background: #fff;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
  border: none;
  padding: 10px;
}

.home_program_carousel .owl-dots .owl-dot.active span,
.home_program_carousel .owl-dots .owl-dot:hover span {
  background-color: #024c87;
}

.home_program_carousel .owl-dots {
  margin-top: 40px;
}

/* .homeprogram_card .homeprogram_card_tool  */

.dashboard_notification_container {
  position: fixed;
  width: 38%;
  height: 80%;
  bottom: 3%;
  right: 1%;
  border-radius: 0px 0px 16px 0px;
  background: #fff;
  z-index: 999;
}

.dashboard_notification_container .dashboard_notification_list {
  height: 400px;
  overflow-y: auto;
  width: 100%;
  padding: 0px 10px;
}

.dashboard_notification_container .dashboard_notification_heading h2 {
  color: #024c87;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  line-height: 150%; /* 36px */
}

.dashboard_notification_container .dashboard_notification_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard_notification_container .dashboard_notification_heading svg {
  fill: #024c87;
  font-size: 24px;
  cursor: pointer;
}

.dashboard_notification_container .dashboard_notification_div {
  padding: 14px 20px;
}

.dashboard_notification_container .dashboard_notification_item_text h4 {
  color: #1f1f1f;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dashboard_notification_container .dashboard_notification_item {
  border-radius: 8px;
  background: #f6fafb;
  padding: 8px 22px;
  margin-top: 12px;
  border-left: 8px solid #024c87;
}

.dashboard_notification_container .dashboard_notification_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard_notification_container .dashboard_notification_item_two {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard_notification_container .dashboard_notification_item_div {
  border-radius: 8px;
  background: #f6fafb;
  padding: 8px 22px;
  margin-top: 12px;
  border-left: 8px solid #024c87;
}
.dashboard_notification_container .dashboard_notification_item_info {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 10px;
  width: 40%;
}

.dashboard_notification_container .dashboard_notification_item_content {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 14px;
  margin-top: 8px;
}
.dashboard_notification_container .dashboard_notification_item_content h5 {
  color: #46484e;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin: 0;
}
.dashboard_notification_container .dashboard_notification_item_info span {
  color: #024c87;
  text-align: right;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  line-height: normal;
}

.dashboard_notification_container .dashboard_notification_item_info h6,
.dashboard_notification_container .dashboard_notification_item_content h6 {
  color: #1f1f1f;
  text-align: right;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dashboard_notification_container .dashboard_notification_item_content h6 {
  margin: 0;
}
.dashboard_notification_container .dashboard_notification_clearall {
  margin: 14px 10px;
  display: flex;
  justify-content: end;
}

.dashboard_notification_container .dashboard_notification_clearall button {
  color: #024c87;
  text-align: right;
  font-family: "DM Sans";
  font-size: 15px;
  text-decoration: underline;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-color: transparent;
  border: none;
}

.dashboard_notification_container .dashboard_notification_options {
  display: flex;
  align-items: center;
  gap: 18px;
  margin-top: 30px;
}

.dashboard_notification_container .dashboard_notification_options span {
  color: #8c8c8c;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  display: inline-block;
  padding-bottom: 4px;
  cursor: pointer;
}

.dashboard_notification_options .dashboard_notification_count {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  margin-left: 4px;
  background-color: red;
  display: inline-block;
}

.dashboard_notification_container .dashboard_notification_options .active {
  color: #1c1e24;
  border-bottom: 2px solid #024c87;
}

/**************************************  Blog PAGE CSSS ******************************************/

.blog_dashboard_form_container .blog_dashboard_upload_img {
  height: 80%;
  margin: 40px 0 0 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 66%;
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
}

.blog_dashboard_form_container .blog_dashboard_upload_img span {
  border-radius: 44px;
  background: #f89321;
  padding: 8px 18px;
  border: 1px solid #f89321;
  color: #fff;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.blog_dashboard_form_container .blog_dashboard_upload_img:hover::after {
  content: "";
  background-image: url("../images/middle_bg_dark.png");
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  border-radius: 8px;
}

.blog_dashboard_form_container .blog_dashboard_upload_img:hover span {
  position: relative;
  z-index: 10;
}

.blog_dashboard_edit_inps .blog_dashboard_edit_inp_title {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  width: 100%;
  padding: 8px 12px;
  margin-bottom: 14px;
  outline: none;
}

.blog_dashboard_edit_inps .blog_dashboard_edit_text {
  color: #000;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.blog_dashboard_edit_inps .ql-container {
  min-height: 250px;
}

.blog_dashboard_edit_inps .blog_dashboard_edit_image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog_dashboard_edit_inps .blog_dashboard_edit_image img {
  height: 300px;
  width: 300px;
  object-fit: cover;
}

.blog_dashboard_insert_content_container .blog_dashboard_edit_options {
  border-radius: 41px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px #d9d9d9;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 12px;
}

.blog_dashboard_edit_options .blog_dashboard_edit_options_svg {
  fill: #242220;
  font-size: 22px;
  margin-bottom: 4px;
  cursor: pointer;
}

.blog_dashboard_edit_options .blog_dashboard_edit_hr {
  background-color: #b3b3b3;
  height: 2px;
  width: 100%;
  margin: 12px 0px;
}

.blog_dashboard_edit_options .blog_dashboard_edit_duplicate {
  background-color: transparent;
  border: none;
  scale: 1.5;
}

.blog_dashboard_edit_options .blog_dashboard_edit_deleteicon {
  background-color: transparent;
  border: none;
  scale: 1.1;
  margin-bottom: 8px;
}

.blog_dashboard_insert_content_container .blog_dashboard_content {
  margin-top: 25px;
}

.blog_dashboard_editor_container .blog_dashboard_edit_inp_item {
  margin-top: 13px;
  justify-content: center;
}

.blog_dashboard_editor_container .blog_dashboard_editor_btns {
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
}

.blog_dashboard_editor_container .blog_dashboard_editor_btns button {
  border-radius: 44px;
  border: 1px solid #7d7d7d;
  padding: 8px 18px;
  color: #242220;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
}

.blog_dashboard_editor_container .blog_dashboard_editor_options {
  display: flex;
  gap: 12px;
}

.blog_dashboard_editor_container
  .blog_dashboard_editor_options
  button:first-child {
  color: #242220;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
}

.blog_dashboard_editor_container
  .blog_dashboard_editor_options
  button:last-child {
  color: #fff;
  background: #242220;
}

.blog_dashboard_editor_container .blog_dashboard_editor_preview {
  display: flex;
  justify-content: end;
  width: 75%;
}

.blog_dashboard_editor_container .blog_dashboard_editor_preview button {
  border-radius: 44px;
  border: 1px solid #7d7d7d;
  padding: 6px 18px;
  color: #242220;
  font-family: "DM Sans";
  background-color: transparent;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.blog_card_left .blog_profile_img a {
  width: 100%;
  height: 100%;
}

.blogdashboardnew_calender_container {
  padding: 0;
  margin-top: 26px;
}

.blog_cards_data_carousel .owl-nav button {
  background-color: #024c87;
  height: 40px;
  width: 40px;
  border: 1px solid #024c87;
  border-radius: 50%;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog_cards_data_carousel .owl-nav .owl-prev {
  position: absolute;
  top: 40%;
  left: -2%;
}

.blog_cards_data_carousel .owl-nav .owl-next {
  position: absolute;
  top: 40%;
  right: -2%;
}
.blog_cards_data_carousel .owl-nav [class*="owl-"]:hover {
  background-color: #024c87;
  border-radius: 50%;
}

.blog_cards_data_carousel .owl-dots .owl-dot.active span {
  background-color: #024c87;
}

.blog_cards_data_carousel .owl-dots {
  margin-top: 20px;
}

.CircularProgressbar .CircularProgressbar-text {
  font-size: 11px;
}

.certificate-pdf-container .react-pdf__Page {
  height: 493px;
  overflow: hidden;
}

.certificate-pdf-container .certificate-pdf-canvas {
  border-radius: 6px;
}

.dashboard_mobile_course_video_qna_container
  .dashboard_mobile_course_video_qna_list {
  box-shadow: 0px 0px 4px 0px #d9d9d9;
  padding: 12px;
  border-radius: 5px;
  margin-bottom: 12px;
}

.dashboard_mobile_course_video_qna_container
  .dashboard_mobile_course_video_qna_profile
  img {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  object-fit: cover;
}

.dashboard_mobile_course_video_qna_container
  .dashboard_mobile_course_video_qna_info
  h6 {
  font-family: DM Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  margin: 0;
  color: #5c5c5c;
}

.dashboard_mobile_course_video_qna_container
  .dashboard_mobile_course_video_qna_info
  h4 {
  font-family: DM Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.62px;
  color: #0d2a41;
  margin: 0;
}

.dashboard_mobile_course_video_qna_container
  .dashboard_mobile_course_video_qna_info
  h5 {
  font-family: DM Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 13.02px;
  text-align: left;
  margin: 0;
}

.dashboard_mobile_course_video_qna_container
  .dashboard_mobile_course_video_qna_svg
  svg {
  font-size: 16px;
  fill: #024c87;
}

.dashboard_mobile_course_video_qna_container
  .dashboard_mobile_course_video_qna_svg {
  display: flex;
  justify-content: end;
}

.dashboard_mobile_course_video_qna_select
  .dashboard_mobile_course_video_qna_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.dashboard_mobile_course_video_qna_select
  .dashboard_mobile_course_video_qna_heading svg {
    font-size: 22px ;
fill: #696969;
  }

.dashboard_mobile_course_video_qna_select {
  padding: 8px 12px ;
}

.dashboard_mobile_course_video_qna_select
  .dashboard_mobile_course_video_qna_heading
  h2 {
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 26.04px;
  text-align: center;
  color: #333333;
}


.dashboard_mobile_course_video_qna_select .dashboard_mobile_course_video_qna_list {
  box-shadow: none;
  padding: 0;
}

.dashboard_mobile_course_video_qna_select .dashboard_mobile_course_video_qna_answer {
  border-top: 1px solid #F0F0F0;
  padding: 8px;
  border-bottom: 1px solid #F0F0F0;
display: flex;
align-items: center;
justify-content: space-between;
}

.dashboard_mobile_course_video_qna_select .dashboard_mobile_course_video_qna_answer input {
  border: none;
  width: 80%;
  outline: none;
}

.dashboard_mobile_course_video_qna_select .dashboard_mobile_course_video_qna_answer button {
border: none;
color: #fff;
background-color: #024c87;
padding: 2px 12px ;
border-radius: 6px ;
}

.dashboard_mobile_course_video_qna_answer_list .dashboard_mobile_course_video_qna_answer_desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard_mobile_course_video_qna_answer_list .dashboard_mobile_course_video_qna_answer_time h4  {
  font-family: DM Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 13.02px;
  color: #BCBCBC; 
}

.dashboard_mobile_course_video_qna_answer_list .dashboard_mobile_course_video_qna_answer_time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px ;
}

.dashboard_mobile_course_video_qna_answer_list {
  margin-bottom: 12px ;
}



.dashboard_mobile_course_video_qna_svg .module_video_cards_options {
  position: relative;
}

.module_video_cards_options .module_video_cards_options-div {
  position: absolute;
  right: 42%;
  background-color: #fff;
  padding: 0px 9px;
  width: 106px;
  border-radius: 6px;
}  

.module-schedule-options-div .module-schedule-options-item:first-child {
  color: #024c87;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  text-transform: capitalize;
  fill: #024c87;
}
.module-schedule-options-div .module-schedule-options-item:first-child svg {
  font-size: 16px;
}
.module-schedule-options-div .module-schedule-options-item:last-child {
  color: #e30e00;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  text-transform: capitalize;
}
.module-schedule-options-div .module-schedule-options-item:last-child svg {
  fill: #e30e00;
  font-size: 16px;
}
.module-schedule-options-div .module-schedule-options-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 0px;
  cursor: pointer;
}

.course_qna_modal h2  {
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 26.04px;
  text-align: center;
color: #576BAD;  
}

.course_qna_modal label{
  font-family: DM Sans;
font-size: 16px;
font-weight: 400;
line-height: 20.83px;
text-align: left;
color:#576BAD;
width: 100%;
}

.course_qna_modal textarea {
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  width: 100%;
  padding: 4px ;
  outline: none;
}

.course_qna_modal .course_qna_modal_btn {
  display: flex;
  justify-content: end;
  margin-top: 10px ;
  padding: 0px 8px ;
}

.course_qna_modal .course_qna_modal_btn button{
border-radius: 55px ;
background-color: #5FBE00;
font-family: DM Sans;
padding: 8px 12px ;
font-size: 12px;
font-weight: 500;
line-height: 15.62px;
text-align: left;
color: #fff;
border: 1px solid #5FBE00;
}

.blog_dashboard_mobile_header_rightside_circle.active {
  position: relative;
}

.blog_dashboard_mobile_header_rightside_circle.active span {
  position: absolute;
  top: 8px;
  right: 9px;
  background-color: red;
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.dashboard_new_mobile_nav .blog_dashboard_mobile_header_telegram svg g  rect  {
fill: #024c87;
}

.dashboard_planbill_header .dashboard_planbill_title_dropdown h4 {
  padding-right: 16px ;
  justify-content: space-between;

}
.google-button:hover {
  background-color: aliceblue;
}

.modal-content {
  overflow: hidden;
}