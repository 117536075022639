.features-section {
  position: relative;
  padding: 80px 0 30px;
  background-color: #024c87 !important;
  z-index: 2;
  margin: 55px 0px;
}
.features-section:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../icons/pattern-1.png");
  content: "";
}

.feature-block {
  position: relative;
  margin-bottom: 50px;
  z-index: 1;
}
.feature-block:last-child .inner-box:before {
  display: none;
}
.feature-block .inner-box {
  position: relative;
  overflow: hidden;
  padding-left: 80px;
  min-height: 64px;
  /* margin-left: 10px; */
}
.feature-block .inner-box:before {
  position: absolute;
  right: 0;
  top: 0;
  height: 64px;
  width: 1px;
  background-color: #fff;
  opacity: 0.3;
  content: "";
}
.feature-block .inner-box:hover .icon {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.feature-block .icon {
  position: absolute;
  left: 0;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  font-size: 64px;
  font-weight: 900;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.feature-block .title {
  margin-bottom: 0;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0;
}
.feature-block .text {
  position: relative;
}

/************************************************************* sign up form ******************************************************/

.signup-section {
  position: relative;
  padding: 65px 0px;
}

.signup-section:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  max-height: 190px;
  background-color: #f1f7f7;
  z-index: -1;
  content: "";
}
.signup-section .icon-paper-clip {
  top: -50px;
  left: -140px;
  z-index: 1;
}
.signup-section .float-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
  width: 63%;
  height: 31%;
  background-image: url("../images/icon-pencil-line.png");
}
.signup-section .outer-box {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 60px;
  border-radius: 30px;
  overflow: hidden;
}
.signup-section .outer-box::after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  width: 30%;
  height: 90%;
  background-image: url("https://d1db1k41ipas2c.cloudfront.net/study_intern_form_img1.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.signup-section .title-column {
  position: relative;
}
.signup-section .title-column .sec-title .text {
  margin-top: 20px;
}
.signup-section .form-column .inner-column {
  margin-left: -20px;
}

.signup-form {
  position: relative;
}
.signup-form .form-group {
  position: relative;
  margin-bottom: 20px;
}
.signup-form .form-group:last-child {
  margin-bottom: 0;
}
.signup-form .form-group .select2-container--default .select2-selection--single,
.signup-form .form-group input:not([type="submit"]),
.signup-form .form-group textarea,
.signup-form .form-group select {
  position: relative;
  display: block;
  appearance: none;
  width: 100%;
  padding: 8px 40px;
  font-size: 16px;
  color: #191e24;
  line-height: 28px;
  font-weight: 400;
  background-color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 50px;
  margin-bottom: 0;
  transition: all 300ms ease;
  outline: none;
}
.signup-form .form-group ::-webkit-input-placeholder {
  color: #7d8185;
}
.signup-form .form-group ::-moz-input-placeholder {
  color: #7d8185;
}
.signup-form .form-group ::-ms-input-placeholder {
  color: #7d8185;
}
.signup-form .form-group input:focus,
.signup-form .form-group select:focus,
.signup-form .form-group textarea:focus {
  border-color: #024c87;
}
.signup-form .form-group textarea {
  height: 100px;
  resize: none;
}
.signup-form .form-group input[type="submit"],
.signup-form .form-group button {
  margin-top: 5px;
  text-transform: uppercase;
}

.sec-title-white h2 {
  color: #fff !important;
  font-size: 42px !important;
}

.trial_input_div {
  position: relative;
  display: block;
  width: 100%;
  padding: 8px 40px;
  font-size: 16px;
  color: #191e24;
  line-height: 28px;
  font-weight: 400;
  background-color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 50px;
  margin-bottom: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  margin-bottom: 25px;
}

.trial_input_div select {
  border: none;
  padding: 6px 0px;
  color: #7c858c;
  appearance: none;
  text-align: start;
}
.trial_input_div input {
  width: 100%;
  border: none;
  outline: none;
}


.btn-style-one {
  position: relative;
  font-size: 12px;
  padding: 13px 35px;
  font-weight: 700;
  overflow: hidden;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 50px;
  z-index: 1;
  background: #191e24;
  transition: all 500ms ease;
  border: none;
}
