@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Francois+One&family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&family=Hina+Mincho&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Italianno&display=swap");

.team-section {
  position: relative;
  padding: 120px 0 90px;
  overflow: hidden;
}
.team-section .sec-title {
  margin-bottom: 25px;
}

.team-block {
  position: relative;
  margin-bottom: 40px;
}
.team-block .inner-box {
  position: relative;
}
.team-block .inner-box:hover .image-box:after {
  border-color: #024c87;
  -webkit-clip-path: polygon(
    25% -70%,
    75% -70%,
    120% 50%,
    75% 170%,
    25% 170%,
    -20% 50%
  );
  clip-path: polygon(
    25% -70%,
    75% -70%,
    120% 50%,
    75% 170%,
    25% 170%,
    -20% 50%
  );
}
.team-block .inner-box:hover .image img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transform-origin: top center;
  transform-origin: top center;
}
.team-block .inner-box:hover .social-links {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  opacity: 1;
  visibility: visible;
}
.team-block .inner-box:hover .share-icon {
  background-color: #024c87;
}
.team-block .image-box {
  position: relative;
  height: 275px;
  width: 275px;
  border-radius: 50%;
  padding: 27px 27px;
  margin: 0 auto 20px;
}
.team-block .image-box:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50%;
  border: 8px solid #f2f8f8;
  border-top: 0;
  content: "";
  border-radius: 0 0 200px 200px;
  -webkit-transition: all 700ms ease;
  transition: all 700ms ease;
}
.team-block .image-box:after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50%;
  border: 8px solid #f2f8f8;
  border-top: 0;
  -webkit-clip-path: polygon(
    50% 0,
    50% 0,
    50% 50%,
    50% 100%,
    50% 100%,
    50% 50%
  );
  clip-path: polygon(50% 0, 50% 0, 50% 50%, 50% 100%, 50% 100%, 50% 50%);
  content: "";
  border-radius: 0 0 200px 200px;
  -webkit-transition: all 700ms ease;
  transition: all 700ms ease;
}
.team-block .image-box .image {
  position: relative;
  overflow: hidden;
  height: 220px;
  width: 220px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 0;
  z-index: 1;
}
.team-block .image-box .image img {
  width: 100%;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}
.team-block .share-icon {
  position: absolute;
  left: 30px;
  top: 20px;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  color: #024c87;
  background-color: #ffffff;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: 3;
}
.team-block .social-links {
  position: absolute;
  left: 35px;
  top: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #ffffff;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: top;
  transform-origin: top;
  z-index: 3;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  border-radius: 20px;
  overflow: hidden;
  padding: 10px 0;
  -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
}
.team-block .social-links a {
  position: relative;
  height: 35px;
  width: 40px;
  display: block;
  font-size: 14px;
  line-height: 35px;
  text-align: center;
  color: #191e24;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.team-block .social-links a:hover {
  color: #024c87;
}
.team-block .info-box {
  position: relative;
  text-align: center;
}
.team-block .info-box .name {
  z-index: 2;
  margin-bottom: 10px;
  color: #000;
}
.team-block .info-box .designation {
  position: relative;
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #7d8185;
  letter-spacing: 1px;
  z-index: 2;
  line-height: 1em;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}

.sec-title h2 {
  position: relative;
  font-size: 50px;
  color: #191e24;
  font-family: DM Sans, sans-serif;
  font-weight: 700;
  margin-top: -5px;
  margin-bottom: 0;
}

.sec-title .sub-title {
  color: #024c87;
  text-align: center;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 17.6px; /* 110% */
  letter-spacing: 1.6px;
  text-transform: uppercase;
  margin-bottom: 20px ;
  display: inline-block
}
